<template>
  <v-card 
    class="mx-1 my-1"
    :width="width"
    :min-width="minWidth"
    flat
    :outlined="outlined"
    @click="$emit('click')"
  >
    <v-row class="no-gutters">
      <div class="col-auto">
        <div :class="color" class="fill-height" >&nbsp;</div>
      </div>
      <div class="col" :class="divClass">
        <h4 class="text-truncate text-uppercase" v-if="large">{{ heading }}</h4>
        <h5 class="text-truncate text-uppercase" v-if="!large">{{ heading }}</h5>
        
        <span :class="(large) ? 'title' : 'subtitle-1'" v-if="description"><v-icon :color="color" :large="large">{{ icon }}</v-icon> {{ description }}</span>
        
        <div class="ma-0 text-center">
          <slot />
        </div>
      </div>
    </v-row>
  </v-card>
</template>

<script>
  
  export default {
    name: 'VideonMetricTile',
    props: {
      heading: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: 'question_mark'
      },
      outlined: {
        type: Boolean,
        default: true
      },
      large: {
        type: Boolean,
        default: false
      },
      description: {
        type: String
      },
      width: {
        type: String,
        default: '200px',
      },
      minWidth: {
        type: String,
        default: '200px'
      }
    },
    computed: {
      divClass() {
        var classes = []
        
        classes.push(this.color + '--text')
        
        if (this.large) {
          classes.push('pa-3')
        } else {
          classes.push('pa-2')
        }
        
        return classes.join(' ')
      }
    }
  }
</script>