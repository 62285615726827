import helpers from './helpers.js'

export default {
  dynamicSupportRequirements: {
    minimumDaemonVersion: helpers.parseVersion('device', 'v10.0.0'),
    minimumAgentVersion: helpers.parseVersion('cloud', 'v1.1.0'),
  },
  
  isDynamicSupported(device) {
    if (device.cloud_version.dev || helpers.versionCheck(this.dynamicSupportRequirements.minimumAgentVersion, device.cloud_version)) {
      if (device.product_name == 'edgecaster_max' || helpers.versionCheck(this.dynamicSupportRequirements.minimumDaemonVersion, device.daemon_version)) {
        return true
      }
    }
    return false
  }
}