<template>
  <div v-if="capabilities && capabilities.hardware">
    <v-row justify="center" v-if="icon" style="margin-top: -70px; margin-bottom: -70px">
      <v-icon size="256">{{ icon }}</v-icon>
    </v-row>
    <v-row>
      <videon-state-tile
        heading="CPU Cores"
        v-if="capabilities.hardware.cpu"
      >
        <ul>
          <li v-for="(core, index) in capabilities.hardware.cpu.cores" v-bind:key="index">{{ core.max_frequency }}Ghz {{ core.name }}</li>
        </ul>
      </videon-state-tile>
      <videon-state-tile
        heading="GPU"
        v-if="capabilities.hardware.gpu"
      >
        {{ capabilities.hardware.gpu.name }}
      </videon-state-tile>
      <videon-state-tile
        heading="DSP"
        v-if="capabilities.hardware.dsp"
      >
        {{ capabilities.hardware.dsp.name }}
      </videon-state-tile>
      <videon-state-tile
        heading="Hardware Encoder"
        v-if="capabilities.hardware.encoder"
      >
        {{ capabilities.hardware.encoder.name }}
      </videon-state-tile>
      <videon-state-tile
        heading="Hardware Decoder"
        v-if="capabilities.hardware.decoder"
      >
        {{ capabilities.hardware.decoder.name }}
      </videon-state-tile>
      <videon-state-tile
        heading="Memory"
        v-if="capabilities.hardware.memory"
      >
        {{ capabilities.hardware.memory.name }}
      </videon-state-tile>
      <videon-state-tile
        heading="Storage"
        v-if="capabilities.hardware.storage"
      >
        {{ capabilities.hardware.storage.name }}
      </videon-state-tile>
      <videon-state-tile
        heading="Network Interfaces"
        v-if="capabilities.hardware.network"
      >
        <ul>
          <li v-for="(network_device, index) in capabilities.hardware.network.network_devices" v-bind:key="index">{{ network_device.name }} [{{ network_device.device }}]</li>
        </ul>
      </videon-state-tile>
    </v-row>
  </div>
</template>
<script>
  export default {
    name: 'HardwareCapabilities',
    props: ['capabilities', 'icon'],
  }
</script>