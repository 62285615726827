<template>
  <v-card 
    :flat="flat"
  >
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          v-if="!flat"
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        User's Organizations
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-alert dense outlined type="error" v-if="error" class="ma-5 mb-0">
      {{ errorText }}
    </v-alert>
    
    <v-card-text>
    
      <v-data-table
        :headers="headers"
        :items="orgs"
        :loading="loading"
        hide-default-footer
        :items-per-page="200"
      >
        <template v-slot:item.org_name="{ item }">
          <span class="subtitle-2">{{ item.org_name }}</span>
        </template>
        <template v-slot:item.access="{ item }">
          <access-level-label :access_level="item.access" />
        </template>
      </v-data-table>
      
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'UserOrgListing',
    
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
        editable: false,
            
        headers: [
          { text: 'Organization Name', align: 'start', value: 'org_name', sortable: true },
          { text: 'Access Level', align: 'center', value: 'access', sortable: true },
        ],
      
        orgs: [],
      }
    },
    
    props: {
      user_guid: {
        type: String,
        required: true
      },
      flat: {
        type: Boolean,
        default: false
      }
    },
    
    computed: {
      ...mapGetters('user', ['user', 'isOrgAdmin']),
    },


    mounted() {
      this.loadOrgs()
    },
    methods: {
      loadOrgs() {
        var that = this
        this.loading = true
        this.orgs = []
        
        if (!this.user_guid) {
          this.error = true
          this.errorText = 'user_guid not supplied'
          return
        }
        
        this.axios.get('/orgs', {params:{'user_guid': this.user_guid}}).then((response) => {
          console.log('UserOrgListing loadOrgs response', response)
          
          if (response.data.orgs && response.data.orgs.length > 0) {
            this.orgs = response.data.orgs.map((org) => this.$organizations.getOrg(org.org_guid, org))
          }
          
          that.loading = false
        }).catch((error) => {
          that.error = true
          that.errorText = this.$helpers.parseError(error)
          that.loading = false
        })
      },
      editOrg(item) {
        console.log('UserOrgListing editOrg', item)
        this.$router.push('/orgs/' + item.org_guid)
      },
    }
  }
</script>