<template>
  <v-card 
    class="mx-auto"
  >
    <v-toolbar flat>
      <v-toolbar-title>
        <v-icon size="32px">settings</v-icon>
        
        {{ organizationName }} Alert Settings
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      
      <v-card
        flat
      >
        <v-card-text>
          <v-row v-if="loading" class="ma-2" justify="center">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
            ></v-progress-linear>
            <div class="subtitle-1 mt-5 primary--text" v-if="pending">Alert configuration update is still pending...</div>
          </v-row>
          
          <v-row v-if="!loading" class="mx-2">
            <v-container class="pa-0 my-3">
              <h3>Alert Selection</h3>
              
              <v-data-table
                v-model="selectedAlerts"
                item-key="name"
                :items="availableAlerts"
                show-select
                :headers="headers"
                hide-default-footer
              >
                <template v-slot:item.name="{ item }">
                  <span class="text-no-wrap subtitle-2">
                    {{ item.label || $helpers.getLabel(item.name) }}
                  </span>
                </template>
                <template v-slot:item.condition="{ item }">
                  <span class="text-no-wrap caption">
                    {{ $helpers.alertCondition(item) }}
                  </span>
                </template>
                <template v-slot:item.notify_on_close="{ item }">
                  <v-checkbox v-model="item.notify_on_close" style="display: inline-block; margin:0px; padding:0px" hide-details :disabled="!canEdit"/>
                </template>
              </v-data-table>
            </v-container>
            
            <v-container class="pa-0 my-3" v-if="canEdit">
              <h3>Alert Recipients</h3>
              
              <p class="mt-3 mb-0">
                Please select the users in your organization that want to receive alerts.
              </p>
              <user-selection v-model="selectedUsers" class="mb-5" :disabled="!canEdit"/>
            
              <p class="mt-3 mb-0">
                Please add any additional email addresses you want to receive alerts. This can be used for external automation/bot integrations or e-mail distribution lists.
              </p>
              <email-selection v-model="selectedEmails" class="mb-5" :disabled="!canEdit"/>
            </v-container>
          </v-row>
          
          
        </v-card-text>
        <v-card-actions v-if="!loading && canEdit">
          <v-row
             align="center"
             justify="end"
             class="ma-2 mt-0"
           > 
             <v-btn
               color="secondary"
               @click="loadConfig"
               class="ma-1"
               text
               :disabled="!hasChanges"
             >
               Revert
             </v-btn>
             <v-btn
               color="primary"
               @click="saveConfig"
               class="ma-1"
               :disabled="!hasChanges"
             >
               Update
             </v-btn>
           </v-row>
        </v-card-actions>
      </v-card>
      
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import UserSelection from '../user/OrgUserSelection.vue'
  import EmailSelection from '../common/EmailListSelectionComboBox.vue'
  
  export default {
    name: 'AlertConfig',
    
    components: {
      UserSelection,
      EmailSelection
    },
    
    data() {
      return {
        error: false,
        errorText: '',
                
        headers: [
          { text: 'Alert', align: 'start', value: 'name', sortable: false },
          { text: 'Condition', align: 'start', value: 'condition', sortable: false },
          { text: 'Notify on Close', align: 'center', value: 'notify_on_close', sortable: false },
        ],
        
        availableAlerts: [
          {'name': 'online', 'label': 'Device Offline', 'type': 'state', 'threshold_value': 'false', 'threshold_condition': 'EqualToThreshold', 'notify_on_close': true},
          {'name': 'thermal_som_temp_current', 'namespace': 'thermal', 'label': 'High Temperature', 'type': 'metric', 'threshold_value': '80', 'threshold_condition': 'GreaterThanThreshold', 'notify_on_close': true},
          {'name': 'memory_system_percent_used', 'namespace': 'memory', 'label': 'High Memory Utilization', 'type': 'metric', 'threshold_value': '90', 'threshold_condition': 'GreaterThanThreshold', 'notify_on_close': true},
          {'name': 'filesystem_/data_percent_used', 'namespace': 'filesystem', 'label': 'High Data Utilization', 'type': 'metric', 'threshold_value': '90', 'threshold_condition': 'GreaterThanThreshold', 'notify_on_close': true},
          {'name': 'video_input_format', 'label': 'Undefined Input Video Format', 'type': 'shadow', 'threshold_value': 'FORMAT_UNDEFINED', 'threshold_condition': 'EqualToThreshold', 'notify_on_close': true}
        ],
        
        selectedAlerts: [],
        
        showUserSelect: false,
        selectedUsers: [],
        
        selectedEmails: [],
        
        loading: true,
        pending: false,
        
        hasChanges: false,
        
        config: false,
        
        configLoadAttempts: 0,
      }
    },
    computed: {
      ...mapGetters('user', ['organization']),
      
      organizationName() {
        if (this.organization) {
          return (this.organization.org_name) ? this.organization.org_name : this.organization.org_guid
        }
        return 'No Organization'
      },
      
      canEdit() {
        if (this.organization) {
          return this.$organizations.getOrg(this.organization.org_guid).canEdit()
        }
        return false
      },
    },
    watch: {
      availableAlerts: {
        deep: true,
        handler() {
          if (!this.loading) {
            this.hasChanges = true
          }
        }
      },
      selectedAlerts() {
        if (!this.loading) {
          this.hasChanges = true
        }
      },
      selectedUsers() {
        if (!this.loading) {
          this.hasChanges = true
        }
      },
      selectedEmails() {
        if (!this.loading) {
          console.log('emails changed', this.selectedEmails)
          this.hasChanges = true
        }
      }
    },
    mounted() {
      this.loadConfig()
    },
    methods: {
      loadConfig() {
        this.loading = true
        this.hasChanges = false
        this.configLoadAttempts++
        
        this.axios.get('/alerts/config').then((response) => {
          console.log('AlertConfig loadConfig response', response)
          
          this.config = response.data.config
          
          // keep looking for changes while pending
          if (this.config.status == 'pending' && this.configLoadAttempts < 10) {
            console.log('AlertConfig loadConfig config status is pending')
            
            this.pending = true
            setTimeout(() => {
              this.loadConfig()
            }, 2000)
            
            return
          }
          
          if (this.config.alerts) {
            this.availableAlerts = this.availableAlerts.map((thisAlert) => {
              var configAlert = this.config.alerts.find(x => x.name == thisAlert.name)
              if (configAlert) {
                // fix stale config... ugh
                configAlert.label = thisAlert.label
                configAlert.type = thisAlert.type
                configAlert.threshold_value = thisAlert.threshold_value
                configAlert.threshold_condition = thisAlert.threshold_condition
                return configAlert
              }
              return thisAlert
            })
            
            this.selectedAlerts = this.config.alerts.reduce(function(result, thisAlert) {
              if (thisAlert.enabled) {
                result.push(thisAlert)
              } 
              return result
            }, [])
          }
          
          if (this.config.user_list) {
            this.selectedUsers = this.config.user_list.map((user_guid) => this.$users.getUser(user_guid))
          }
          
          if (this.config.email_list) {
            this.selectedEmails = this.config.email_list.map((email) => email)
          }
          
          // watchers are async and slow??
          setTimeout(() => {
            this.loading = false
            this.pending = false
            this.configLoadAttempts = 0
          }, 500)
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      saveConfig() {
        this.loading = true
        console.log('AlertConfig saveConfig')
        
        var updateBody = { 'config': this.config }
        
        delete updateBody.config.status
        
        updateBody.config.alerts = this.availableAlerts.map((thisAlert) => {
          var selectedAlert = this.selectedAlerts.find(x => x.name === thisAlert.name)
          if (selectedAlert) {
            thisAlert.enabled = true
          } else {
            thisAlert.enabled = false
          }
          
          return thisAlert
        })
        
        updateBody.config.user_list = this.selectedUsers.map((user) => (user.user_guid))
        updateBody.config.email_list = this.selectedEmails
        
        console.log('AlertConfig saveConfig updateBody:', updateBody)
        
        this.axios.patch('/alerts/config', updateBody).then((response) => {
          console.log('AlertConfig saveConfig response', response)
          
          // delay reload config
          setTimeout(() => {
            this.loadConfig()
          }, 3000)
        }).catch((error) => {
          console.log('AlertConfig saveConfig error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      
    }
  }
</script>