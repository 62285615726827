<template>
  <videon-card heading="TOTP Login" max-width="440">
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
      
      <v-row class="ma-1">
        <v-col cols="12">
          <v-form @submit.prevent="TOTPContinue">
            <p class="mb-5 body-2">
              Please enter the TOTP code from your authentication app to continue.
            </p>
            
            <v-text-field
              v-model="code"
              label="Code"
              @input="$v.code.$touch()"
              @blur="$v.code.$touch()"
              :error="$v.code.$invalid"
              autocomplete="off"
              single-line
              outlined
            ></v-text-field>
            
            <v-btn
              color="primary"
              @click="TOTPContinue"
              :disabled="$v.code.$invalid"
              class="mx-auto ma-2"
              width="100%"
            >
              Continue
            </v-btn>
            
            
          </v-form>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="5"><v-divider /></v-col>
        <v-col cols="2" class="text-center overline justify-start">or</v-col>
        <v-col cols="5"><v-divider /></v-col>
      </v-row>
      <v-row class="ma-1">
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="cancel"
            class="mx-auto ma-2"
            width="100%"
            text
          >
            Cancel Login
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </videon-card>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  export default {
    name: 'AuthTPTP',
    
    mixins: [validationMixin],

    data() {
      return {
        code: ''
      }
    },

    props: {
      error: {
        type: Boolean,
        default: false
      },
      errorText: {
        type: String,
        default: ''
      }
    },

    validations: {
      code: {
        required,
        enforceLength: (value) => value.length == 6,
        enforceCode: (value) => (/[0-9]{6}/).test(value)
      }
    },

    methods: {
      TOTPContinue() {
        this.$v.$touch()

        this.$emit('submit', this.code);
      },
      cancel() {
        this.$store.dispatch('user/setStage', 'capture_email')
        this.$store.dispatch('user/resetEmail')
      }
    }
  }
</script>