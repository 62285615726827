<template>
  <v-autocomplete
    v-model="timezone"
    :items="timezones"
    :filter="filter"
    @change=valueChanged
    :disabled="disabled"
    label="Timezone"
    outlined
  />
</template>

<script>

  export default {
    name: 'TimezoneSelector',
    
    data() {
      return {
        timezone: '',
      }
    },
    
    props: {
      value: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      },
      product_name: {
        type: String,
        default: 'edgecaster'
      }
    },
    
    watch: {
      value(value) {
        this.timezone = value
      },
      
      timezone() {
        this.$emit('input', this.timezone || '')
      }
    },
    
    mounted() {
      this.timezone = this.value
    },
    
    computed: {
      timezones() {
        // convert the list in to text/value object
        var timezones = this.$helpers.getTimezones(this.product_name).map((item) => {
          return {'text': item, 'value': item}
        })
        
        if (this.product_name == 'edgecaster') {
          // hack for edgecaster UTC
          timezones.unshift({'text': 'UTC', 'value': 'UTC'})
        }
        
        return timezones
      },
    },
    
    
    methods: {
      valueChanged() {
        this.$emit('change', this.timezone)
      },
      
      filter(item, queryText, itemText) {
        queryText = queryText.toLocaleLowerCase().replace(' ', '_')
        return itemText.toLocaleLowerCase().indexOf(queryText) > -1
      },
    }
  }
</script>