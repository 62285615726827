<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <fleet-listing-box v-if="!fleet_guid" />
        
        <fleet-box v-if="fleet_guid" :fleet_guid="fleet_guid"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import FleetListingBox from '../components/fleet/FleetListing.vue'
  import FleetBox from '../components/fleet/Fleet.vue'
  
  export default {
    name: "Fleets",
    components: {
      FleetListingBox,
      FleetBox
    },
    data() {
      return {
        fleet_guid: this.$route.params.fleet_guid,
      }
    },
    watch: {
      $route() {
        this.fleet_guid = this.$route.params.fleet_guid
      }
    },
    methods: {
      
    }
  }
</script>