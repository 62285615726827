<template>
  <v-btn
    text
    small
    class="ma-1"
    @click="goBack"
  >
    <v-icon>mdi-arrow-left</v-icon> Back
  </v-btn>
</template>

<script>
  export default {
    name: 'BackButton',
    
    props: ['target'],
    
    data() {
      return {
        previousRoute: false
      }
    },
    
    mounted() {
      if (this.$router && this.$router.last_route) {
        this.previousRoute = this.$router.last_route
      }
    },
    
    methods: {
      goBack() {
        if (this.target) {
          this.$router.push(this.target)
        } else if (this.previousRoute) {
          this.$router.push(this.previousRoute)
        } else {
          this.$router.go(-1)
        }
      }
    }
  }
</script>