<template>
  <v-select
    v-model="input"
    :items="inputList"
    itemValue="id"
    :item-text="inputName"
    @change=valueChanged
    :disabled="!canEdit"
    label="Input"
    hide-details
    outlined
  >
  </v-select>
</template>

<script>
  export default {
    name: 'InputSelector',
    
    data() {
      return {
        input: [],
        inputList: []
      }
    },

    props: ['device_guid', 'value', 'canEdit'],

    watch: {
      value(value) {
        this.input = (value) ? value : false
      },
    },
    
    mounted() {
      this.input = (this.value) ? this.value : false
      this.getDeviceInputShadows()
    },
    
    computed: {
      
    },
    methods: {
      inputName(item) {
        if (item.config && item.config.name) {
          return item.config.name
        }
        return 'Input #' + item.id
      },
      
      getDeviceInputShadows() {
        var shadows = this.$deviceShadows.getDeviceShadow(this.device_guid, 'Inputs')
        var inputs = (shadows.reported.state) ? shadows.reported.state : []
        
        this.inputList = inputs
      },
      
      valueChanged() {
        this.$emit('input', this.input)
        this.$emit('change')
      },
    }
  }
</script>