<template>
  <videon-card
    heading="Organization Select"
    max-width="640"
  >
    <v-dialog
      v-model="showCreateOrg"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Organization</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="createOrg">
            <v-text-field
              v-model="newOrgName"
              label="Organization Name"
              required
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showCreateOrg = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="createOrg"
              >
                Create
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
      <v-alert dense outlined type="error" v-if="inviteError" class="ma-5">
        {{ inviteErrorText }}
      </v-alert>
      
      <v-row v-if="!invitesLoaded" class="justify-center ma-5">
        <v-progress-circular :size="100" :width="10" indeterminate color="primary"></v-progress-circular>
      </v-row>
      
      <div v-if="invites.length > 0">
        <v-card
          color="videon_purple"
          dark
          class="ma-2 mb-5"
        >
          <v-card-title>Pending Invitations</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="invites"
              :loading="loading"
              mobile-breakpoint="0"
              hide-default-header
              hide-default-footer
              class="videon_purple"
            >
              <template v-slot:item.org_name="{ item }">
                <div class="ma-1" v-if="item.org_guid">
                  <v-icon class="mr-2">house</v-icon>
                  <span v-if="item.org_name" class="subtitle-1">{{ $helpers.htmlDecode(item.org_name) }}</span>
                  <span v-else class="subtitle-1"><i>No Name</i></span>
                </div>
                <div class="ma-1" v-else-if="item.fleet_guid">
                  <v-icon class="mr-2">folder</v-icon>
                  <span v-if="item.fleet_name" class="subtitle-1">{{ $helpers.htmlDecode(item.fleet_name) }}</span>
                  <span v-else class="subtitle-1"><i>No Name</i></span>
                </div>
                <div class="ma-1" v-else-if="item.device_guid">
                  <v-icon class="mr-2">storage</v-icon>
                  <span v-if="item.device_name" class="subtitle-1">{{ $helpers.htmlDecode(item.device_name) }}</span>
                  <span v-else class="subtitle-1"><i>No Name</i></span>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <div class="text-right">
                  <v-btn
                    small
                    color="black"
                    @click="declineInvite(item)"
                    class="ma-1"
                  >
                    Decline
                  </v-btn>
                  <v-btn
                    small
                    color="primary"
                    @click="acceptInvite(item)"
                    class="ma-1"
                  >
                    Accept
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
      
      <v-container class="pa-0 pa-md-3">
        <v-row>
          <v-col cols="12">
            <div v-if="!error && organizations.length == 0 && invites.length == 0" class="text-center">
              <h2 class="mb-5">Awaiting Invitation</h2>
              <p>
                Please have an organization admin invite you to their organization. If this is your first time signing into LiveEdge Cloud, please navigate to <a class="primary--text" href="https://support.videonlabs.com/hc/en-us/articles/12883463391251-How-do-I-get-invited-to-an-organization-in-LiveEdge-Cloud-" target="_blank">this Help Center article</a>.
                <br/><br/>Ensure <span class="primary--text">{{ email }}</span> is used for the invitation as invites are bound to email addresses for security reasons.<br/><br/>
                <i>Once you have received your invitation please reload this page.</i>
              </p>
            </div>
          
            <div v-if="organizations.length > 0">
              <v-row class="mx-2 my-1">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="headers"
                    hide-default-header
                    mobile-breakpoint="0"
                    :items="organizations"
                    :search="search"
                    :loading="!isReady"
                    :items-per-page="10"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, -1]
                    }"
                    sort-by="org_name"
                    :hide-default-footer="organizations.length <= 10"
                  >
                    <template v-slot:item.org_name="{ item }">
                      <span :class="isLastOrganizationGuid(item) ? 'primary--text' : ''">
                        <v-icon class="mr-2" :color="isLastOrganizationGuid(item) ? 'primary' : ''">house</v-icon>
                        <span class="subtitle-1">
                          {{ item.org_name }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn
                        small
                        disabled
                        v-if="organization && (item.org_guid == organization.org_guid)"
                      >
                        Current
                      </v-btn>
                      <v-btn
                        small
                        color="primary"
                        @click="selectOrg(item)"
                        v-if="!organization || (item.org_guid != organization.org_guid)"
                      >
                        Select
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </videon-card>
</template>


<script>
  import { mapState, mapGetters } from "vuex"

  export default {
    name: 'OrgSelect',
    
    data() {
      return {
        inviteError: false,
        inviteErrorText: '',
        
        search: '',
        headers: [
          { text: 'Organization Name', align: 'start', filterable: true, value: 'org_name', sortable: true},
          { text: '', align: 'end', value: 'actions', sortable: false },
        ],
      
        loading: false,
      
        showCreateOrg: false,
        newOrgName: '',
      }
    },
    
    computed: {
      ...mapState('user', ['error', 'errorText']),
      ...mapGetters('user', ['isReady', 'email', 'organizations', 'organization', 'invites', 'invitesLoaded']),
    },
    mounted() {
      this.$store.dispatch('user/getUserOrganizations')
      this.$store.dispatch('user/getUserInvites')
    },
    methods: {
      createOrg() {
        var that = this
        
        if (this.newOrgName) {
          this.loading = true
          this.showCreateOrg = false
          
          this.axios.post('/orgs', {'org_name': this.newOrgName})
            .then((response) => {
              console.log('OrgSelect createOrg response', response)
              that.$store.dispatch('user/getUserOrganizations').then(() => {
                that.newOrgName = ''
                that.loading = false
              })
            }).catch((error) => {
              console.log('OrgSelect createOrg error', error)
              that.loading = false
          })
        }
      },
      
      selectOrg(org) {
        if (org) {
          this.$emit('submit', org)
        }
      },
      
      isLastOrganizationGuid(item) {
        if (item.org_guid == localStorage.getItem('last_organization_guid')) {
          return true
        }
        return false
      },
      
      acceptInvite(invite) {
        console.log('OrgSelect acceptInvite', invite)
        this.loading = true
        
        this.axios.post('/invites/accept', {'invite_guid': invite.invite_guid})
        .then((response) => {
          console.log('OrgSelect acceptInvite response', response)
          
          if (invite.org_guid) {
            // NOTE: Fetch the rest of the organization data
            this.$organizations.getOrg(invite.org_guid, {'org_name': invite.org_name})
            
            // TODO: I would prefer if we only responded with the guid and had the caller
            // do a lookup through $organizations. However, currently the object is stored
            // in the user store.
            this.selectOrg({'org_guid': invite.org_guid, 'org_name': invite.org_name})
          }
          
          this.$store.dispatch('user/getUserInvites', true).then(() => {
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
          
        }).catch((error) => {
          console.log('OrgSelect acceptInvite error', error)
          this.inviteError = true
          this.inviteErrorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      declineInvite(invite) {
        console.log('OrgSelect declineInvite', invite)
        this.loading = true
        
        this.axios.post('/invites/decline', {'invite_guid': invite.invite_guid})
        .then((response) => {
          console.log('OrgSelect declineInvite response', response)
        
          this.$store.dispatch('user/getUserInvites', true).then(() => {
            this.loading = false
          }).catch (() => {
            this.loading = false
          })
        }).catch((error) => {
          console.log('OrgSelect declineInvite error', error)
          this.inviteError = true
          this.inviteErrorText = this.$helpers.parseError(error)
          this.loading = false
        })
      }
    }
  }
</script>
