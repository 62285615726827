<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <adoption-box v-if="device_guid == 'adopt'" />
        
        <forget-box v-else-if="device_guid == 'forget'" />
        
        <device-box v-else-if="device_guid && device_guid != 'adopt' && device_guid != 'forget'" :device_guid="device_guid" />
        
        <device-listing-box v-else-if="!device_guid"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import DeviceListingBox from '../components/device/DeviceListing.vue'
  import DeviceBox from '../components/device/Device.vue'
  import AdoptionBox from '../components/device/DeviceAdoption.vue'
  import ForgetBox from '../components/device/ForgetDevice.vue'
  
  export default {
    name: "Devices",
    components: {
      DeviceListingBox,
      DeviceBox,
      AdoptionBox,
      ForgetBox
    },
    data() {
      return {
        device_guid: (this.$route.params.device_guid) ? this.$route.params.device_guid : false,
      }
    },
    watch: {
      $route() {
        this.device_guid = (this.$route.params.device_guid) ? this.$route.params.device_guid : false
      }
    }
  }
</script>