<template>
  <div>
    <span>
      <v-alert outlined dense rounded class="state-chip ma-1 text--disabled" v-if="dockerStateChanges.containers.length == 0 && dockerStateChanges.images.length == 0">
        No Visible Changes
      </v-alert>
    </span>
    <span>
      <v-alert outlined dense rounded class="state-chip ma-1"
        v-for="(container, index) in dockerStateChanges.containers" v-bind:key="index"
      >
        <span class="font-weight-medium">Container "{{ container.name }}"</span>:&nbsp;
        <span v-if="container.changes">
          <span v-for="(change, changeIndex) in container.changes" v-bind:key="changeIndex">
            <span class="text-capitalize font-weight-medium">{{ nameLabel(change.key) }}</span>:&nbsp;
            <span v-if="$helpers.isTimestamp(change.key)">{{ change.from | moment("YYYY/MM/DD hh:mm:ss a") }} <v-icon x-small>mdi-arrow-right</v-icon>{{ change.to | moment("YYYY/MM/DD hh:mm:ss a") }}</span>
            <span v-else>{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}</span>
          &nbsp;</span>
        </span>
      </v-alert>
    </span>
    <span>
      <v-alert outlined dense rounded class="state-chip ma-1"
        v-for="(image, index) in dockerStateChanges.images" v-bind:key="index"
      >
        <span class="font-weight-medium">Image "{{ dockerImageName(image) }}"</span>:&nbsp;
        <span v-if="image.changes">
          <span v-for="(imageChange, imageChangeIndex) in image.changes" v-bind:key="imageChangeIndex">
            <span class="text-capitalize font-weight-medium">{{ nameLabel(imageChange.key) }}</span>:&nbsp;
            
            <span v-if="$helpers.isTimestamp(imageChange.key) && !imageChange.array">{{ imageChange.from | moment("YYYY/MM/DD hh:mm:ss a") }} <v-icon x-small>mdi-arrow-right</v-icon>{{ imageChange.to | moment("YYYY/MM/DD hh:mm:ss a") }}</span>
            <span v-else-if="!imageChange.array">{{ parseValue(imageChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(imageChange.to) }}</span>
            <span v-else-if="imageChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
            <span v-else>
              [&nbsp;<span v-for="(arrayChange, index) in imageChange.array" v-bind:key="index">
                <span class="text-capitalize font-weight-medium">{{ nameLabel(arrayChange.key) }}</span>:
                <span v-if="!arrayChange.array">{{ parseValue(arrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(arrayChange.to) }}&nbsp;</span>
                <span v-else-if="arrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Changes ]&nbsp;</span>
                <span v-else class="font-weight-regular text--disabled">[ Has Changes ]&nbsp;</span>
              </span>]
            </span>
          &nbsp;</span>
        </span>
      </v-alert>
    </span>
  </div>
</template>

<script>
export default {
    name: 'DockerStateChangeDiff',
    props: {
      eventData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      dockerStateChanges() {
        if (this.eventData.from_state && this.eventData.to_state) {
          return this.$helpers.dockerDiff(this.eventData.from_state, this.eventData.to_state)
        }
        return false
      }
    },
    methods: {
      nameLabel(stateName) {
        return stateName.replace(/_/g, ' ')
      },
      dockerImageName(item) {
        if (item && item.tags && item.tags[0]) {
          return item.tags[0]
        }
        return '<none>'
      },
      parseValue(value) {
        if (value === undefined) {
          return 'undefined'
        } else if (typeof(value) == 'object') {
          return 'Object (' + JSON.stringify(value) + ')'
        }
        return this.$helpers.getLabel(value)
      },
    }
  }
</script>
<style lang="scss" scoped>
  .state-chip {
     font-size: 12px;
     line-height: 20px;
     padding: 3px 12px;
     min-height: 24px;
     align-items: center;
     display: inline-flex;
  }
  .theme--light.state-chip {
      border-color: rgba(0, 0, 0, 0.12) !important;
      color: rgba(0, 0, 0, 0.87) !important;
  }
  .theme--dark.state-chip {
    border-color: rgba(255, 255, 255, 0.12) !important;
    color: #FFFFFF !important;
  }
</style>