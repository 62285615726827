<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
      
        <div v-if="!user_guid">
          <user-listing-box />
        </div>
        
        <div v-if="user_guid">
          <edit-user-box :user_guid="user_guid"/>
        </div>
      
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import UserListingBox from '../components/user/UserListing.vue'
  import EditUserBox from '../components/user/EditUser.vue'
  
  export default {
    name: "Users",
    components: {
      UserListingBox,
      EditUserBox
    },
    data() {
      return {
        user_guid: this.$route.params.user_guid,
      }
    },
    watch: {
      $route() {
        this.user_guid = this.$route.params.user_guid
      }
    },
    methods: {
      
    }
  }
</script>
