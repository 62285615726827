<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col>
        <div v-if="processing">
          <processing-box></processing-box>
        </div>
        
        <div v-else-if="!processing">
          
          <div v-if="authStage == 'capture_email'">
            <email-box @submit="capturedEmail"></email-box>
          </div>
          
          <div v-else-if="authStage == 'sso'">
            <SSOBox :providers="providers" @submit="authSSOLogin" />
          </div>
          
          <div v-else-if="authStage == 'auth_password' || authStage == 'user_exists'">
            <div v-if="action == 'login'">
              <password-box :email="email" @submit="authPasswordLogin" @forgotPassword="forgotPassword"></password-box>
            </div>
            <div v-if="action == 'signup'">
              <signup-box @submit="authPasswordSignup" @SSOSubmit="authSSOLogin" :providers="providers"></signup-box>
            </div>
          </div>
          
          <div v-else-if="authStage == 'totp_confirmation'">
            <TOTPBox :error="TOTPError" :errorText="TOTPErrorText" @submit="TOTPComplete"/>
          </div>
          
          <div v-else-if="authStage == 'email_confirmation'">
            <email-confirmation-box @sendConfirmation="resendConfirmationEmail"></email-confirmation-box>
          </div>
          
          <div v-else-if="authStage == 'organization_selection'">
            <div v-if="isReady">
              <org-select-box @submit="orgSelected"></org-select-box>
            </div>
            <div v-if="!isReady">
              <processing-box></processing-box>
            </div>
          </div>
          
          <div v-else-if="authStage == 'forgot_password'">
            <div >
              <forgot-password-box @submit="forgotPasswordSubmit"></forgot-password-box>
            </div>
          </div>
          
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import ProcessingBox from '../components/auth/Processing.vue'
  import EmailBox from '../components/auth/CaptureEmail.vue'
  import PasswordBox from '../components/auth/AuthPassword.vue'
  import ForgotPasswordBox from '../components/auth/ForgotPassword.vue'
  import SignupBox from '../components/auth/AuthSignup.vue'
  import EmailConfirmationBox from '../components/auth/EmailConfirmation.vue'
  import OrgSelectBox from '../components/auth/OrgSelect.vue'
  import SSOBox from '../components/auth/AuthSSO.vue'
  import TOTPBox from '../components/auth/AuthTOTP.vue'
  
  export default {
    name: 'Auth',
    
    components: {
      ProcessingBox,
      EmailBox,
      PasswordBox,
      ForgotPasswordBox,
      SignupBox,
      EmailConfirmationBox,
      OrgSelectBox,
      SSOBox,
      TOTPBox
    },
    
    computed: {
      ...mapGetters('user', ['isReady', 'isLoggedIn', 'authStatus', 'authStage', 'email', 'user', 'organization', 'organizations', 'error', 'errorText']),
    },
    data() {
      return {
        action: this.$route.params.action,
        
        processing: false,
        verified: true,
        credentials: {},
        redirect: false,
        
        SSOProviders: [],
        providers: [],
        
        currentUser: null, // Because the user isn't technically 'authenticated' on TOTP challenge, it isn't accessible in currentAuthenticatedUser
        
        TOTPError: false,
        TOTPErrorText: '',
      }
    },
    watch: {
      $route() {
        this.action = this.$route.params.action
      },
      
      authStage() {
        console.log('Auth auth stage changed to', this.authStage)
        
        // wait for auth_complete then handle org selection process
        if (this.authStage == 'auth_complete') {
          // check if the user is part of an org
          if (!this.organization) {
            // show org select
            this.processing = true
            this.$store.dispatch('user/setStage', 'organization_selection')
          } else {
            if (this.redirect) {
              this.$router.push(this.redirect)
            } else {
              this.$router.push('/dashboard')
            }
          }
        }
      },
      
      organization() {
        // if we have a valid org selection but the org list takes a while to come in, redirect to the dashboard once everything resolves...
        if (this.action == 'login' && this.organization) {
          this.processing = false
          
          if (this.redirect) {
            this.$router.push(this.redirect)
          } else {
            this.$router.push('/dashboard')
          }
        }
      },
      
      organizations() {
        if (this.isReady) {
          this.processing = false
        }
      }
      
    },
    
    mounted() {
      // if we end back at the beginning, but have an email address stored, lets just resume signup
      console.log('Auth action', this.action)
      
      // if we have redirect in the url and its not auth related...
      if (this.$route.query && this.$route.query.redirect && this.$route.query.redirect.indexOf('auth') == -1) {
        console.log('Auth setting redirect', this.$route.query.redirect)
        this.redirect = this.$route.query.redirect
      }
      
      if (!this.action) {
        this.$router.push('/auth/login')
      } else if (this.action == 'oauth_complete') {
        this.SSOComplete()
      } else if (this.action == 'signup' && this.authStage == 'capture_email' && this.email) {
        this.capturedEmail(this.email, 'signup')
      } else if (this.action == 'organizations' && this.authStage == 'capture_email') {
        this.$store.dispatch('user/setStage', 'organization_selection').then(() => {
          this.processing = false
        })
      }
    },
    
    methods: {
      // Login & Signp Flow methods
      capturedEmail(email) {
        this.processing = true
        
        this.$store.dispatch('user/resetError').then(() => {
          this.$store.dispatch('user/setEmail', email.toLowerCase()).then(() => {
            this.axios.get('/auth-info?email=' + encodeURIComponent(email.toLowerCase())).then(response => {
              console.log('Auth capturedEmail response', response)
              
              this.SSOProviders = response.data.sso_providers || []
              
              var amplifyConfig = {
                AWSRegion: response.data.cognito_region,
                AWSUserPoolId: response.data.cognito_user_pool_id,
                AWSUserPoolDomain: response.data.cognito_user_pool_domain,
                AWSUserPoolWebClientId: response.data.client_id,
                AWSIdentityPoolId: response.data.cognito_identity_pool_id,
                AWSOAuthScope: response.data.oauth_scope,
                AWSOAuthResponseType: response.data.oauth_response_type,
                AWSS3ProfileBucket: response.data.profile_picture_bucket,
                AWSIOTEndpoint: response.data.iot_endpoint
              }
              
              var isLoggingIn = response.data.user_exists || false
              
              if (response.data.identity_provider == 'COGNITO') {
                // update the amplifyConfig with the values returned by the API
                this.$store.dispatch('config/setAmplifyConfig', amplifyConfig).then(() => {
                  if (isLoggingIn) {
                    this.$store.dispatch('user/setStage', 'auth_password')
                    this.processing = false
                  } else {
                    this.$store.dispatch('user/setStage', 'auth_password').then(() => {
                      if (this.action == 'login') {
                        this.$router.push('/auth/signup')
                        this.providers = []
                        this.processing = false
                      }
                    })
                  }
                })
              } else if (this.SSOProviders.includes(response.data.identity_provider)) {
                this.$store.dispatch('config/setAmplifyConfig', amplifyConfig).then(() => {
                  this.$store.dispatch('user/setStage', 'sso')

                  // Only one provider is an option, so only offer one provider
                  this.providers = [response.data.identity_provider]

                  this.processing = false
                })
              } else {
                this.$store.dispatch('config/setAmplifyConfig', amplifyConfig).then(() => {
                  this.$store.dispatch('user/setStage', 'auth_password').then(() => {
                    // Offer all providers, because we don't care
                    this.providers = this.SSOProviders
                    this.$router.push('/auth/signup')
                    this.processing = false
                  })
                })
              }
            }).catch((error) => {
              this.$store.dispatch('user/authError', this.$helpers.parseError(error))
              this.processing = false
            })
          })
        })
      },
      
      resendConfirmationEmail(email) {
        console.log('Auth resendConfirmationEmail', email)
        this.$Amplify.Auth.resendSignUp(email)
      },
      
      // Login Flow methods
      authPasswordLogin(credentials) {
        // dont set processing if we're in the email_confirmation phase
        if (this.authStage != 'email_confirmation') this.processing = true
        
        this.credentials = credentials
        
        this.$store.dispatch('user/resetError').then(() => {
          console.log('Auth authPasswordLogin')
        
          this.$Amplify.Auth.signIn(this.credentials.email, this.credentials.password).then(async (response) => {
            console.log('Auth Cognito response', response)
            
            if (response.challengeName) {
              if (response.challengeName == 'SOFTWARE_TOKEN_MFA') {
                this.currentUser = response

                this.$store.dispatch('user/setStage', 'totp_confirmation')
                
                this.processing = false
              } else {
                this.$store.dispatch('user/authError', 'Unhandled Cognito Challenge: ' + response.challengeName)
              }
            } else {
              await this.completeAuth(response)
            }
          }).catch((error) => {
            // handle auth error
            console.log('Auth authPasswordLogin error', error.message)
            if (error.code == 'UserNotConfirmedException') {
              var that = this
              this.$store.dispatch('user/setStage', 'email_confirmation')
              this.processing = false
              // automatically check verified status
              setTimeout(function() {
                that.checkVerified()
              }, 5000)
            } else {
              this.$store.dispatch('user/authError', error.message)
              this.processing = false
            }
          })
        })
      },
      
      async completeAuth(user) {
        this.credentials.preferredMFA = user.preferredMFA
        console.log('Auth prefferredMFA', this.credentials.preferredMFA)
        
        var currentSession = await this.$Amplify.Auth.currentSession()
        if (currentSession) {
          // handle setting the token here
          this.$store.dispatch('user/validateAuth').then(() => {
            this.$store.dispatch('user/setStage', 'auth_complete')
            
            this.authVerify()
            
            this.processing = false
          })
        }
      },
      
      authSSOLogin(provider) {
        console.log('Auth authSSOLogin', provider)
        
        this.processing = true
        this.$store.dispatch('user/setStage', 'oauth_in_progress')
        this.$Amplify.Auth.federatedSignIn({ 'provider': provider })
      },
      
      SSOComplete() {
        this.processing = true
        
        var { code, state } = this.$route.query // I believe we'll need to use these for tokens
        if (!code || !state) {
          console.log('Auth invalidReturnUrl logging out')
          this.$store.dispatch('user/logout')
        }
        
        this.$Amplify.Auth.currentSession().then(currentSession => {
          console.log('Auth currentSession:', currentSession)
          if (currentSession) {
            // handle setting the token here
            this.$store.dispatch('user/validateAuth').then(() => {
              this.$store.dispatch('user/setStage', 'auth_complete')
              this.authVerify()
            })
          }
        })
      },
      
      // this function is to allow attaching a policy to IOT core using our cognito identity id
      async authVerify() {
        var { identityId } = await this.$Amplify.Auth.currentCredentials()
        console.log('Auth authVerify identityId', identityId)
        
        var postBody = { 'identityId':identityId }
        
        this.axios.post('/auth-verify', postBody).then(response => {
          console.log('Auth auth-verify response', response)
        }).catch(error => {
          console.log('Auth auth-verify error', error)
        })
      },
      
      TOTPComplete(code) {
        this.$Amplify.Auth.confirmSignIn(this.currentUser, code, 'SOFTWARE_TOKEN_MFA').then((user) => {
          this.completeAuth(user)
        }).catch((error) => {
          if (error.message == 'Invalid session for the user, session is expired.') {
            // NOTE: The reason for this is that the session will expire after 3 minutes (because its not
            // really an authenticated session). Currently, we'll just go back a step, but we might be able
            // to re-call signIn to get a new session, and then just re-do the code (assuming that the code
            // doesn't update in that time)
            
            this.$store.dispatch('user/setStage', 'capture_email')
          }
          this.TOTPError = true
          this.TOTPErrorText = error.message
        })
      },
      
      orgSelected(org) {
        this.$store.dispatch('user/setOrganization', org).then(() => {
          this.$store.dispatch('user/setStage', 'auth_complete')
        })
      },
      
      
      // Signup Flow methods
      authPasswordSignup(credentials) {
        this.processing = true
        this.credentials = credentials
        
        console.log('Auth authPasswordSignup')
        this.$store.dispatch('user/setEmail', this.credentials.email.toLowerCase())
        
        this.$store.dispatch('user/resetError').then(() => {
          this.$Amplify.Auth.signUp({
            username: this.credentials.email.toLowerCase(),
            password: this.credentials.password,
            attributes: {
              name: this.credentials.name,
              email: this.credentials.email.toLowerCase(),
              phone_number: this.credentials.phone,
              locale: this.credentials.locale,
              zoneinfo: this.credentials.timezone
            }
          }).then(async (response) => {
            console.log('Auth Cognito response', response)
            if (response.userConfirmed == false) {
              var that = this
              this.$store.dispatch('user/setStage', 'email_confirmation')
              // automatically check verified status
              setTimeout(function() {
                that.checkVerified()
              }, 5000)
            } else {
              // TODO already confirmed user?
            }
            this.processing = false
          }).catch((error) => {
            // handle auth error
            console.log('Auth authPasswordSignup error', error.message)
            
            if (error.code == 'UsernameExistsException') {
              error.message += ' Please try logging in instead'
              this.$store.dispatch('user/authError', error.message)
              this.processing = false
              
              this.$store.dispatch('user/setStage', 'user_exists')
              this.$router.push('/auth/login')
            } else {
              // nothing yet
              this.$store.dispatch('user/authError', error.message)
              this.processing = false
            }
          })
        })
      },
      
      checkVerified() {
        console.log('Auth checkVerified')
        if (this.credentials.password) {
          this.authPasswordLogin(this.credentials)
        } else {
          this.$store.dispatch('user/authError', 'verification check error, credintials missing')
          this.processing = false
        } 
      },
      
      forgotPassword() {
        console.log('Auth forgotPassword')
        if (this.email) {
          this.processing = true
          this.$Amplify.Auth.forgotPassword(this.email).then(() => {
            this.$store.dispatch('user/setStage', 'forgot_password').then(() => {
              this.processing = false
            })
          })
        }
      },
      
      forgotPasswordSubmit(credentials) {
        console.log('Auth forgotPasswordSubmit')
        this.processing = true
        
        this.$Amplify.Auth.forgotPasswordSubmit(credentials.email, credentials.code, credentials.password).then(() => {
          this.authPasswordLogin(credentials)
        }).catch((error) => {
          console.log('Auth forgotPasswordSubmit error', error)
        })
      }
    }
  }
</script>
