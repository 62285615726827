<template>
  <v-container class="pa-0 my-3">
    <v-dialog
      v-model="showSelectDevices"
      max-width="900px"
    >
      <videon-card heading="Select Devices" showClose="true" @close="showSelectDevices = false">
        <v-card-text>
          <v-form @submit.prevent="selectionChanged">
            
            <device-selector v-model="selectedDevices" class="mb-3"/>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showSelectDevices = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="selectionChanged(true)"
              >
                Select
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <h4>Device Selection</h4>
    <v-card outlined flat>
      <v-row class="mx-2 my-1" align="center" style="min-height: 48px">
        <span v-if="items.length == 0" class="ml-2 text--secondary">Please make a selection</span>
        <v-chip 
          v-for="(device, index) in items" 
          v-bind:key="device.device_guid" 
          outlined
          close
          close-icon="mdi-close"
          @click:close="removeItem(device)"
          class="ma-1"
        >
          <v-icon
            small
            v-if="colorCode && items.length > 1"
            :color="$helpers.getGraphColorByIndex(index)"
            class="mr-1"
          >mdi-square</v-icon>
          {{ device.deviceName() }}
        </v-chip>
        <v-spacer />
        <v-btn color="primary" @click="showSelectDevices = true" outlined small class="ma-1">Select Devices</v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import DeviceSelector from './DeviceSelector.vue'
  
  export default {
    name: 'DeviceSelection',
    components: {
      DeviceSelector
    },
    data() {
      return {
        error: false,
        errorText: '',
      
        showSelectDevices: false,
        selectedDevices: [],
        
        items: []
      }
    },
    props: {
      value: {
        type: Array
      },
      colorCode: {
        type: Boolean,
        default: false
      }
    },
    watch: {
      value() {
        this.items = this.value
        this.selectedDevices = this.value
      }
    },
    mounted() {
      if (this.value) {
        this.items = this.value
        this.selectedDevices = this.value
      }
    },
    
    methods: {
      removeItem(item) {
        for (var x in this.items) {
          if (this.items[x].device_guid == item.device_guid) {
            this.items.splice(x, 1)
            this.selectionChanged()
            return
          }
        }
      },
      selectionChanged(updateFromSelectDevices = false) {
        if (updateFromSelectDevices) {
          this.items = this.selectedDevices
        }
        this.showSelectDevices = false
        this.$emit('input', this.items)
      }
    }
  }
</script>