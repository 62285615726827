import Vue from 'vue'
import Router from 'vue-router'

import store from './store'

import NotFound from '@/views/NotFound.vue'
import NotImplemented from '@/views/NotImplemented.vue'

import Lander from '@/views/Lander.vue'

import Auth from '@/views/Auth.vue'

import Dashboard from '@/views/Dashboard.vue'

import Devices from '@/views/Devices.vue'
import Search from '@/views/Search.vue'

import AdminDevices from '@/views/AdminDevices.vue'

import Fleets from '@/views/Fleets.vue'
import Events from '@/views/Events.vue'

import Account from "./views/Account.vue"

import Organizations from "./views/Organizations.vue"
import Users from "./views/Users.vue"

import Alerts from "./views/Alerts.vue"
import Metrics from "./views/Metrics.vue"

import Entitlements from "./views/Entitlements.vue"
import Licenses from "./views/Licenses.vue" 

import Legal from "./views/Legal.vue"

import Containers from './views/Containers.vue'


// silence NavigationDuplicated 
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

Vue.use(Router)

var router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Lander',
      component: Lander,
      meta: {
        requiresAuth: false,
        requiresOrg: false,
        title: 'Homepage'
      }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Dashboard'
      }
    },
    {
      path: '/not_implemented',
      name: 'Not Implemented',
      component: NotImplemented,
      meta: {
        requiresAuth: false,
        title: 'Not Implemented'
      }
    },

    {
      path: '/auth',
      name: 'Authentication Lander',
      component: Auth,
      meta: {
        title: 'Authentication'
      }
    },
    {
      path: '/login',
      name: 'Login Lander',
      component: Auth,
      meta: {
        title: 'Authentication'
      }
    },
    {
      path: '/signup',
      name: 'Signup Lander',
      component: Auth,
      meta: {
        title: 'Authentication'
      }
    },
    {
      path: '/auth/:action',
      name: 'Authentication',
      component: Auth,
      meta: {
        title: 'Authentication'
      }
    },

    {
      path: "/account",
      name: "Manage Account",
      component: Account,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orgs",
      name: "Organization Management",
      component: Organizations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/orgs/:org_guid",
      name: "Edit Organization",
      component: Organizations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/users",
      name: "User Management",
      component: Users,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/admin/users/:user_guid",
      name: "Edit User",
      component: Users,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/fleets',
      name: 'Fleet Management',
      component: Fleets,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Fleet Management'
      }
    },
    {
      path: "/fleets/:fleet_guid/:section?",
      name: "View Fleet",
      component: Fleets,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
      },
    },
    {
      path: '/devices',
      name: 'All Devices',
      component: Devices,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'All Devices'
      }
    },
    {
      path: "/devices/:device_guid/:section?",
      name: "View Device",
      component: Devices,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
      },
    },
    {
      path: '/search',
      name: 'Device Search',
      component: Search,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Device Search'
      }
    },
    {
      path: '/licenses',
      name: 'Licenses',
      component: Licenses,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Licenses'
      }
    },
    {
      path: '/admin/containers',
      name: 'Containers',
      component: Containers,
      meta: {
        requiresAuth: true,
        title: 'Containers'
      }
    },
    {
      path: '/admin/devices',
      name: 'Provisioned Devices',
      component: AdminDevices,
      meta: {
        requiresAuth: true,
        title: 'All Devices'
      }
    },
    {
      path: '/alerts',
      name: 'Alerts',
      component: Alerts,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Alerts'
      }
    },
    {
      path: '/alerts/:alert_guid',
      name: 'Alert',
      component: Alerts,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Alerts'
      }
    },
    {
      path: '/config/alerts',
      name: 'Alert Config',
      component: Alerts,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Alerts Settings',
        config: true,
      }
    },
    {
      path: '/events',
      name: 'Events',
      component: Events,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Events'
      }
    },
    {
      path: '/metrics',
      name: 'Metrics',
      component: Metrics,
      meta: {
        requiresAuth: true,
        requiresOrg: true,
        title: 'Metrics Explorer'
      }
    },
    {
      path: '/entitlements/:section/:id?',
      name: 'Entitlements',
      component: Entitlements,
      meta: {
        requiresAuth: true,
        title: 'Entitlement Management'
      }
    },
    {
      path: '/backend_error',
      name: 'Backend Error',
      component: NotImplemented,
      meta: {
        title: 'Backend Error'
      }
    },
    {
      path: '/legal/:document',
      name: 'Legal Documents',
      component: Legal,
      meta: {
        title: 'Legal Documents'
      }
    },
    {
      path: '*',
      name: 'Not Found',
      component: NotFound,
      meta: {
        title: 'Page Not Found'
      }
    },
  ]
})

function validateAccess(to) {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    if (store.getters['user/isLoggedIn'] && store.getters['user/isAdmin']) {
      return true
    } else {
      return false
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['user/isLoggedIn']) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

function validateOrg(to) {
  if (to.matched.some(record => record.meta.requiresOrg)) {
    if (store.getters['user/organization']) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

router.beforeResolve(async (to, from, next) => {
  router.last_route = from
  
  if (!store.getters['config/isConfigured']) {
    console.log('VueRouter !isConfigured')
  
    await store.dispatch('config/setInitialConfiguration')
    await store.dispatch('user/quickValidateAuth')

    if (store.getters['user/isLoggedIn']) {
      await store.dispatch('user/getUserOrganizations')
    }
  }
  
  if (validateAccess(to) == true) {
    if (validateOrg(to) == true) {
      next()
    } else {
      next({
        path: '/auth/organizations',
        query: { redirect: to.fullPath }
      })
    }
  } else {
    console.log('VueRouter failed validateAccess')
    await store.dispatch('user/quickValidateAuth').then(() => {
      // dont create circular auth redirects
      var redirectURL = to.fullPath
      if (redirectURL.includes('/auth')) {
        redirectURL = '/dashboard'
      }
      
      if (validateAccess(to) == true) {
        if (validateOrg(to) == true) {
          next()
        } else {
          next({
            path: '/auth/organizations',
            query: { redirect: redirectURL }
          })
        }
        
      } else {
        next({
          path: '/auth/login',
          query: { redirect: redirectURL }
        })
      }
    })
  }
  
})

// build page title
router.afterEach((to) => {
  Vue.nextTick(() => {
    var title = store.getters['config/deploymentName']

    if (to.meta.title) {
      title += ' - ' + to.meta.title
    }

    document.title = title
  })
})

export default router
