<template>
  <v-card 
    class="mx-auto"
    :max-width="maxWidth"
    :min-height="minHeight"
    :flat="flat"
  >
    <div v-if="heading" :class="(showClose && !ignoreHeaderMargin) ? 'mb-5' : 'mb-0'">
      <v-toolbar flat>
        <v-toolbar-title>
          <v-avatar
            height="32px"
            class="mx-auto"
          >
            <videon-logo />
          </v-avatar>
          {{ heading }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon small @click="close" class="mr-0 pl-1" v-if="showClose"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-divider/>
    </div>
    
    <v-row v-if="showLogo">
      <v-col cols="12" class="text-center mt-5">
        <v-avatar
          size="100"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
      </v-col>
    </v-row>
    
    <slot/>
  </v-card>
</template>

<script>
  
  export default {
    name: 'VideonCard',
    props: {
      heading: {
        type: String,
        default: '',
      },
      maxWidth: {
        type: String,
        default: '',
      },
      showLogo: {
        type: Boolean,
        default: false,
      },
      minHeight: {
        type: String,
        default: ''
      },
      flat: {
        type: Boolean
      },
      showClose: {
        default: false
      },
      ignoreHeaderMargin: {
        default: false
      }
    },
    computed: {
      
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>