<template>
  <v-select
    label="Locale"
    v-model="locale"
    :items="locales"
    outlined
  />
</template>

<script>
  export default {
    name: 'LocaleSelector',

    data() {
      return {
        locale: '',
        locales: []
      }
    },

    mounted() {
      this.locale = this.value

      var browserLocale = this.browserLocale()
      this.locales = [
        {
          text: 'Default (en-US)',
          value: 'en-US'
        },
        { // If the browser locale is also en-US, this won't show
          text: 'Browser Default (' + browserLocale + ')',
          value: browserLocale
        }
      ]

      if (this.locale) { // If there is a locale presented by Cognito, provide it
        this.locales.push({
          text: 'Previous Locale (' + this.locale + ')',
          value: this.locale
        })
      }
    },

    props: {
      value: {
        type: String,
        default: ''
      }
    },

    watch: {
      value(value) {
        this.locale = value
      },

      locale() {
        this.$emit('input', this.locale)
      }
    },

    methods: {
      browserLocale() {
        var localeRegex = /^[a-z]{2}-[A-Z]{2}$/;
        
        if (navigator.languages) {
          var locale = navigator.languages.filter(language => localeRegex.test(language))[0]
          return locale || this.locale
        }

        // Technically, navigator.language is defined as navigator.languages[0], but in case of strange support
        if (navigator.language && localeRegex.test(navigator.language)) {
          return navigator.language || this.locale;
        }

        return this.locale
      }
    }
  }
</script>