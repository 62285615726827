<template>
  <videon-card
    heading="Standard Login"
    max-width="440"
  >
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
      
      <v-row class="ma-1">
        <v-col cols="12">
          <v-form @submit.prevent="submitCreds">
            <h4>Login as:</h4>
            
            <v-text-field
              name="email"
              :value="email"
              type="email"
              solo
              flat
              dense
            ></v-text-field>
            
            <v-text-field
              v-model="password"
              ref="password"
              name="password"
              autocomplete="current-password"
              :error-messages="passwordErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              @keyup.native.enter="submitCreds"
              outlined
            ></v-text-field>
            
            <v-btn
              color="primary"
              @click="submitCreds"
              class="mx-auto ma-3"
              width="100%"
            >
              Login
            </v-btn>
          </v-form>
          
          <v-row align="center" class="my-3">
            <v-col cols="5"><v-divider /></v-col>
            <v-col cols="2" class="text-center overline justify-start">or</v-col>
            <v-col cols="5"><v-divider /></v-col>
          </v-row>
          
          <v-btn
            color="secondary"
            @click="forgotPassword"
            class="mx-auto ma-2"
            width="100%"
            text
          >
            I Forgot My Password
          </v-btn>
          
          <v-btn
            color="primary"
            @click="cancel"
            class="mx-auto ma-2"
            width="100%"
            text
          >
            Use different email
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </videon-card>
</template>

<script>
  import { mapState, mapGetters } from "vuex"
  
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],

    validations: {
      password: { required, minLength: minLength(8) },
    },

    data() {
      return {
        password: '',
        showPass: false,
      }
    },
    
    computed: {
      ...mapState('user', ['error', 'errorText']),
      ...mapGetters('user', ['email', 'organizations', 'organization']),
      
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password needs to be longer')
        !this.$v.password.required && errors.push('Password is required')
        return errors
      },
    },
    mounted() {
      //this.$refs.password.$el.focus()
    },
    methods: {
      submitCreds() {
        this.$v.$touch()
        if (this.email && this.password && this.passwordErrors.length < 1) {
          this.$emit('submit', {email: this.email, password: this.password})
        }
      },
      cancel() {
        this.$store.dispatch('user/resetEmail')
      },
      forgotPassword() {
        this.$emit('forgotPassword')
      }
    },
  }
</script>
