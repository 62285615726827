<template>
  <div>
    <device-updater-edgecaster v-if="device.product_name == 'edgecaster'" :device="device" @close="close"/>
    <device-updater-edgecaster-max v-if="device.product_name == 'edgecaster_max'" :device="device" @close="close"/>
    <cloud-updater :device="device" />
  </div>
</template>

<script> 
  import CloudUpdater from './firmware_updater/Cloud.vue'
  import DeviceUpdaterEdgecaster from './firmware_updater/Edgecaster.vue'
  import DeviceUpdaterEdgecasterMax from './firmware_updater/Edgecaster_Max.vue'
  
  export default {
    name: 'DeviceFirmwareUpdater',
    props: ['device'],
    components: {
      CloudUpdater,
      DeviceUpdaterEdgecaster,
      DeviceUpdaterEdgecasterMax
    },
    methods: {
      close() {
        this.$emit('close')
      }
    }
  }
</script>