<template>
  <videon-card
    heading="Create New Account"
    max-width="600px"
  >
    <v-dialog
      v-model="showCSA"
      max-width="80%"
    >
        <CSA @close="showCSA = false"/>
    </v-dialog>
    <v-dialog
      v-model="showPrivacyPolicy"
      max-width="80%"
    >
        <privacy-policy @close="showPrivacyPolicy = false"/>
    </v-dialog>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
      
      <v-row class="ma-1">
        <v-col cols="12">
          <p class="subtitle-1">
            Before you continue, please review our <a @click="showCSA = true" class="primary--text text-decoration-underline">Cloud Services Agreement</a>.
          </p>
          
          <v-checkbox
            v-model="checkbox"
            :error-messages="checkboxErrors"
            label="I agree to the Terms of the Cloud Services Agreement as defined."
            required
            @change="$v.checkbox.$touch()"
            @blur="$v.checkbox.$touch()"
          ></v-checkbox>
        </v-col>
      </v-row>
      
      <div v-if="providers.length > 0" class="mt-2">
        <v-row>
          <v-col cols="12" class="text-center mx-auto">
            <SSOButton
              v-for="(provider, index) in providers"
              @submit="SSOContinue(provider)"
              :key="index"
              :provider="provider"
              :disabled="!checkbox"
              class="mx-1"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5"><v-divider /></v-col>
          <v-col cols="2" class="text-center overline justify-start">or</v-col>
          <v-col cols="5"><v-divider /></v-col>
        </v-row>
      </div>
      <v-row class="ma-1">
        <v-col cols="12">
          <v-form @submit.prevent="submit">
            <v-text-field
              name="name"
              autocomplete="name"
              v-model="name"
              :error-messages="nameErrors"
              label="Full Name"
              required
              @input="$v.name.$touch()"
              @blur="$v.name.$touch()"
              outlined
            ></v-text-field>
            <v-text-field
              name="email"
              autocomplete="email"
              v-model="email"
              :error-messages="emailErrors"
              label="E-mail"
              type="email"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              outlined
            ></v-text-field>
            
            <v-text-field
              name="password"
              autocomplete="new-password"
              v-model="password"
              :error-messages="passwordErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              outlined
            ></v-text-field>
            <v-text-field
              name="password-confirm"
              autocomplete="new-password"
              v-model="passwordConfirm"
              :error-messages="passwordConfirmErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Confirm Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.passwordConfirm.$touch()"
              @blur="$v.passwordConfirm.$touch()"
              outlined
            ></v-text-field>

            <locale-selector v-model="locale" />

            <timezone-selector v-model="timezone" />
          </v-form>
          
          <v-btn
            color="primary"
            @click="submit"
            class="mx-auto ma-2"
            width="100%"
            :disabled="!checkbox"
          >
            Create Account
          </v-btn>
          
          <v-btn
            color="secondary"
            @click="cancel"
            class="mx-auto ma-2"
            width="100%"
            text
          >
            Cancel
          </v-btn>
          
        </v-col>
      </v-row>
    </v-card-text>
  </videon-card>
</template>


<script>
  import { mapState } from "vuex"
    
  import { validationMixin } from 'vuelidate'
  import { required, minLength, email } from 'vuelidate/lib/validators'
  
  import SSOButton from './AuthSSOButton.vue'
  import LocaleSelector from '../user/LocaleSelector.vue'
  
  import CSA from '../legal/CloudServicesAgreement.vue'
  import PrivacyPolicy from '../legal/PrivacyPolicy.vue'
  
  export default {
    name: 'AuthSignup',
    
    components: {
      SSOButton,
      LocaleSelector,
      CSA,
      PrivacyPolicy
    },
    
    mixins: [validationMixin],
    
    validations: {
      name: { required },
      email: { required, email },
      phone: { },
      password: { required, minLength: minLength(8) },
      passwordConfirm: { required, minLength: minLength(8) },
      checkbox: {
        checked (val) {
          return val
        },
      },
    },

    data() {
      return {
        name: '',
        email: '',
        phone: '',
      
        password: '',
        passwordConfirm: '',
        showPass: false,
      
        locale: 'en-US',
        timezone: 'America/New_York',

        checkbox: false,
      
        showCSA: false,
        showPrivacyPolicy: false
      }
    },

    props: {
      providers: Array
    },

    computed: {
      ...mapState('user', ['error', 'errorText']),
      
      checkboxErrors () {
        const errors = []
        if (!this.$v.checkbox.$dirty) return errors
        !this.$v.checkbox.checked && errors.push('You must agree to continue!')
        return errors
      },
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.phone.$dirty) return errors
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password needs to be longer')
        !this.$v.password.required && errors.push('Password is required')
        return errors
      },
      passwordConfirmErrors () {
        const errors = []
        if (!this.$v.passwordConfirm.$dirty) return errors
        !this.$v.passwordConfirm.minLength && errors.push('Password needs to be longer')
        !this.$v.passwordConfirm.required && errors.push('Password is required')
        this.passwordConfirm !== this.password && errors.push('Passwords don`t match')
        return errors
      },
    },
    
    mounted() {
      this.email = this.$store.getters['user/email']
    },
    
    methods: {
      SSOContinue(provider) {
        console.log('AuthSignup SSOContinue', provider)
        this.$emit('SSOSubmit', provider)
      },
      submit() {
        this.$v.$touch()
        // TODO validate actual errors before submitting
        
        var phone_number = (this.phone) ? this.phone.replace(/[\s-]+/g, '').toLowerCase() : ''
        
        if (this.checkbox && this.name && this.email && this.password) {
          this.$emit('submit', {'name': this.name, 'email': this.email, 'password': this.password, 'phone': phone_number, 'locale': this.locale, 'timezone': this.timezone})
        }
      },
      cancel() {
        // If a user cancels signin, they should be sent back to login (not dashboard)
        // and the entered email should be reset.
        this.$router.push('/auth/login')
        this.$store.dispatch('user/resetEmail')
      },
      clear() {
        this.$v.$reset()
        this.name = ''
        this.email = ''
        this.password = ''
        this.checkbox = false
      },
    },
  }
</script>
