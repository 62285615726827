export default [
  {
    icon: "home",
    title: "Dashboard",
    to: "/dashboard"
  },
  {
    group: "alert_group",
    icon: "notifications",
    title: "Alerts",
    model: false,
    children: [
      {
        title: "Alerts",
        icon: "notifications_active",
        to: "/alerts",
      },
      {
        title: "Settings",
        icon: "settings",
        to: "/config/alerts",
      },
    ]
  },
  {
    group: "reporting_group",
    icon: "list",
    title: "Reporting",
    model: false,
    children: [
      {
        title: "Events",
        icon: "list",
        to: "/events",
      },
      {
        title: "Metrics",
        icon: "assessment",
        to: "/metrics",
      },
    ]
  },
  {
    group: "device",
    icon: "storage",
    title: "Devices",
    model: false,
    children: [
      {
        title: "All Devices",
        icon: "storage",
        to: "/devices",
      },
      {
        title: "Search",
        icon: "search",
        to: "/search",
      },
      {
        title: "Fleets",
        icon: "folder",
        to: "/fleets",
      },
      
    ]
  },
  {
    icon: "local_offer",
    title: "Licenses",
    to: "/licenses"
  },
  {
    group: "admin",
    icon: "admin_panel_settings",
    title: "Videon Admin",
    model: false,
    requiresAdmin: true,
    children: [
      {
        title: "Provisioned Devices",
        icon: "storage",
        to: "/admin/devices",
      },
      {
        title: "Organizations",
        icon: "location_city",
        to: "/orgs",
      },
      {
        title: "Users",
        icon: "group",
        to: "/admin/users",
      },
      {
        group: "licensing",
        icon: "storefront",
        title: "Licensing & Entitlements",
        model: false,
        requiresGroup: 'EntitlementManagement',
        children: [
          {
            title: "Licensing",
            icon: "local_offer",
            to: "/entitlements/licenses",
          },
          {
            title: "Entitlements",
            icon: "collections_bookmark",
            to: "/entitlements/profiles",
          },
          {
            title: "Product Capabilities",
            icon: "menu_book",
            to: "/entitlements/capabilities",
          },
        ]
      },
      {
        title: "Container Directory",
        icon: "mdi-table",
        to: "/admin/containers"
      },
    ]
  },
]
