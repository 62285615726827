<template>
  <div>
    <div v-if="!loading">
      <v-row align="center" style="flex-wrap: nowrap">
        <v-icon class="mr-2" color="red" v-if="hasAlerts">notifications_active</v-icon>
        <v-icon class="mr-2" style="filter: drop-shadow( 2px 2px 3px rgba(150, 150, 150, .2))" :color="(error) ? 'error' : ''" v-else>{{ icon }}</v-icon>
        
        <a class="subtitle-1 mr-2 text-no-wrap" :class="color" v-on:click.prevent="manageDevice" :href="href" v-if="clickable">{{ displayName }}</a>
        
        <span class="subtitle-1 mr-2 text-no-wrap" :class="color" v-if="!clickable">{{ displayName }}</span>
      </v-row>
    </div>
    
    <v-progress-linear
      class="ma-2"
      color="primary"
      indeterminate
      buffer-value="0"
      v-if="clickable && loading"
      v-on:click.prevent="manageDevice" 
      :href="href"
    ></v-progress-linear>
    
    <v-progress-linear
      class="ma-2"
      color="primary"
      indeterminate
      buffer-value="0"
      v-else-if="loading"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
    name: 'DeviceLabel',
    props: {
      device: {
        type: Object,
        required: true
      },
      clickable: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      color() {
        if (this.error) {
          return 'error--text'
        } else if (this.disabled) {
          return 'text--disabled'
        }
        return ''
      },
      loading() {
        return !this.device.ready
      },
      hasAlerts() {
        return (this.$alerts.alertsForDevice(this.device.device_guid).length > 0) ? true : false
      },
      error() {
        return (this.hasAlerts) ? true : this.device.error
      },
      icon() {
        return this.device.icon()
      },
      displayName() {
        return this.device.deviceName()
      },
      href() {
        return '/devices/' + this.device.device_guid
      }
    },
    methods: {
      manageDevice() {
        this.$router.push('/devices/' + this.device.device_guid)
      }
      
    }
  }
</script>
<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
</style>