<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon size="32px">list</v-icon>
              Recent Organization Events
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          
          <v-divider/>
          
          <v-card-text>
            <v-card
              flat
            >
              <v-card-text>
                <v-row class="mx-2">
                  <period-selection v-model="period" :initialRange="periodKey" />
                </v-row>
              </v-card-text>
            </v-card>
            
            <v-row>
              <v-col cols="12">
                <device-events v-if="organization && period" heading="Events" @loaded="loaded"  :showDevice="true" targetObject="orgs" :targetGUID="organization.org_guid" :endTime="period.end" :startTime="period.start"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import PeriodSelection from '../components/common/PeriodSelection.vue'
  
  import DeviceEvents from '../components/device/components/DeviceEvents.vue'
  
  export default {
    name: "Events",
    
    components: {
      PeriodSelection,
      DeviceEvents
    },
    computed: {
      ...mapGetters('user', ['organization']),
      
      queryParameters() {
        return this.$route.query
      },
      
      orgName() {
        if (this.organization.org_name) {
          return this.organization.org_name
        }
        return 'Unknown Org'
      },
    },
    watch: {
      period() {
        if (!this.loading) {
          this.updateQueryParameters()
        }
      },
    },
    data() {
      return {
        loading: true,
        error: false,
        errorText: '',
        
        period: false,
        periodKey: false,
      }
    },
    created() {
      if (this.$route.query) {
        this.periodKey = (this.$route.query.period_key) ? this.$route.query.period_key : false
      }
    },
    methods: {
      loaded() {
        this.loading = false
      },
      updateQueryParameters() {
        this.$router.push({ path: '/events', query: {
          'period_key': this.period.key,
          'timestamp': new Date().getTime(),
        }}).catch((error) => {
          console.log('updateQueryParameters error', error)
        })
      }
    }
  }
</script>