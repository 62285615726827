<template>
  <v-container class="pa-0 my-3">
    <v-dialog
      v-model="showSelectUsers"
      max-width="640px"
    >
      <videon-card heading="Select Users" showClose="true" @close="showSelectUsers = false">
        <v-card-text>
          <v-form @submit.prevent="selectionChanged">
            
            <user-selector v-model="selectedUsers" class="mb-3"/>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showSelectUsers = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="selectionChanged(true)"
              >
                Select
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <h4>User Selection</h4>
    <v-card outlined flat>
      <v-row class="mx-2 my-1" align="center" style="min-height: 48px">
        <span v-if="items.length == 0" class="ml-2 text--secondary">Please make a selection</span>
        <v-chip 
          v-for="(user) in items" 
          v-bind:key="user.user_guid" 
          outlined
          close
          close-icon="mdi-close"
          @click:close="removeItem(user)"
          class="ma-1"
        >
          {{ user.displayName() }}
        </v-chip>
        <v-spacer />
        <v-btn color="primary" @click="showSelectUsers = true" outlined small class="ma-1" v-if="!disabled">Select Users</v-btn>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import UserSelector from './OrgUserSelector.vue'
  
  export default {
    name: 'OrgUserSelection',
    components: {
      UserSelector
    },
    data() {
      return {
        error: false,
        errorText: '',
      
        showSelectUsers: false,
        selectedUsers: [],
        
        items: []
      }
    },
    props: ['value', 'disabled'],
    watch: {
      value() {
        this.items = this.value
        this.selectedUsers = this.value
      }
    },
    mounted() {
      if (this.value) {
        this.items = this.value
        this.selectedUsers = this.value
      }
    },
    
    methods: {
      removeItem(item) {
        console.log('OrgUserSelection removeItem', item)
        
        for (var x in this.items) {
          if (this.items[x].user_guid == item.user_guid) {
            this.items.splice(x, 1)
            this.selectionChanged()
            return
          }
        }
      },
      selectionChanged(updateFromSelectedUsers = false) {
        if (updateFromSelectedUsers) {
          this.items = this.selectedUsers
        }
        this.showSelectUsers = false
        this.$emit('input', this.items)
      }
    }
  }
</script>