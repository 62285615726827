<template>
  <div class="d-inline-block text-center">
    <v-dialog
      v-if="device"
      v-model="showState"
      max-width="640px"
    >
      <videon-card :heading="device.deviceName() + ' Entitlements'" showClose="true" @close="showState = false">
        <v-card-text>
          <pre>{{ entitlements }}</pre>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-chip color="blue" label outlined small @click="showState = true">
      Entitlements
    </v-chip>
  </div>
</template>

<script>
  export default {
    name: 'EntitlementsLabel',
    
    props: ['device'],
    
    data() {
      return {
        showState: false,
      }
    },
    
    computed: {
      entitlements() {
        return this.device.entitlements()
      }
    }
  }
</script>