<template>
  <v-card
    flat
    width="100%"
  >
    <v-card-title>
      {{ heading }}
      <v-spacer />
      <v-btn v-if="explorerButton" color="secondary" @click="metricExplorer" x-small outlined class="mr-3"><v-icon small>assessment</v-icon> Modify Query</v-btn>
      <v-btn icon small @click="close" class="mr-0 pl-1" v-if="showClose"><v-icon>close</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <line-chart :chart-data="chartData" :chart-options="chartOptions()" :class="(small) ? 'small-chart' : 'full-chart'"/>
    </v-card-text>
  </v-card>
</template>

<script>
  import DeviceMetrics from './DeviceMetrics.js'  
  
  export default {
    name: 'VideonChart',
    
    props: {
      heading: {
        type: String
      },
      fill: {
        type: Boolean,
        default: false
      },
      devices: {
        type: Array,
      },
      metrics: {
        type: Array,
      },
      unit: {
        type: String,
        default: ''
      },
      deviceMetrics: {
        type: Object,
        default: () => new DeviceMetrics(),
      },
      explorerButton: {
        type: Boolean,
        default: false
      },
      small: {
        type: Boolean,
        default: false
      },
      showClose: {
        type: Boolean,
        default: false
      },
      colorByDevice: {
        type: Boolean,
        default: false
      },
      pointStyles: {
        type: Array,
        default: () => ['circle']
      },
      pointStyleInLegend: {
        type: Boolean,
        default: false
      },
      pointStyleInTooltip: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      chartData() {
        return {
          datasets: this.deviceMetrics.chartData().map((data, i) => {
            var color
            if (this.colorByDevice && data.device_index >=0) {
              color = this.$helpers.getGraphColorByIndex(data.device_index)
            } else {
              color = this.$helpers.getGraphColorByIndex(i)
            }

            var pointStyle
            if (data.metric_index >= 0) {
              pointStyle = this.pointStyles[data.metric_index % this.pointStyles.length]
            } else {
              pointStyle = 'circle'
            }

            return {
              label: data.label,
              data: data.data,
              spanGaps: false,
              pointStyle,
              pointRadius: 3,
              lineTension: 0.1,
              fill: this.fill,
              borderWidth: 2,
              borderColor: color,
              backgroundColor: color,
              pointBackgroundColor: color,
            }
          }),
        };
      },
    },
    data() {
      return {
        
      }
    },
    
    methods: {
      chartOptions() {
        const chartOptions = {
          showLines: true,
          scales: {
            x: {
              type: 'time',
              time: {
                tooltipFormat: 'YYYY/MM/DD HH:mm',
                displayFormats: {
                  second: 'YYYY/MM/DD HH:mm',
                  minute: 'YYYY/MM/DD HH:mm',
                  hour: 'YYYY/MM/DD HH:mm',
                  day: 'YYYY/MM/DD HH:mm',
                }
              },
              ticks: {
                display: true,
                autoSkip: true,
                source: 'data'
              }
            },
            y: {
              ticks: {
                display: true,
                callback: (value) => {
                  if (this.unit == 'bytes') {
                    return this.$helpers.byteFormat(value)
                  } else {
                    value = Number.isInteger(value) ? value : value.toFixed(2)
                    return '' + value + this.unit
                  }
                }
              }
            }
          },
          plugins: {
            legend: {
              display: false,
              align: 'start',
              position: 'bottom',
              labels: {
                padding: 15,
                usePointStyle: this.pointStyleInLegend
              }
            },
            tooltip: {
              enabled: true,
              usePointStyle: this.pointStyleInTooltip,
              callbacks: {
                label: (item) => {
                  var itemValue
                  if (this.unit == 'bytes') {
                    itemValue = this.$helpers.byteFormat(item.parsed.y)
                  } else {
                    itemValue = '' + item.formattedValue + this.unit
                  }
                  return item.dataset.label + ': ' + itemValue
                }
              }
            }
          }
        }
        
        if (this.unit == '%') {
          chartOptions.scales.y.min = 0
          chartOptions.scales.y.max = 100
        }
        
        return chartOptions
      },
      
      metricExplorer() {
        this.$router.push({ path: '/metrics', query: {
          'device_guids': this.devices.map((device) => { return device.device_guid }),
          'metrics': this.metrics
        }})
      },
      
      close() {
        this.$emit('close')
      }
    }
  }
</script>
<style scoped lang="scss">
  .small-chart {
    width: 100% !important;
    height: 300px !important;
  }
  .full-chart {
    
  }
</style>