<template>
  <container-list />
</template>

<script>
  import ContainerList from '../components/container/ContainerList.vue'
  
  export default {
    name: 'Containers',
    
    components: {
      ContainerList
    }
  }
</script>
