<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">HTTP Push Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="6">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
      
      <v-col cols="6" v-if="config.enable_retry !== undefined">
        <div class="d-flex align-center">
          <v-checkbox
            v-model="config.enable_retry"
            label="Retry on Error"
            @change="configChanged"
            :disabled="!canEdit"
            hide-details
            class="mt-0"
          ></v-checkbox>
          <v-tooltip bottom max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                x-small
                class="ma-1"
              >
                <v-icon small>question_mark</v-icon>
              </v-btn>
            </template>
            <span style="line-height: 1.5em">
              Retrying causes the stream to restart which will overwrite existing manifests
              at the destination URL. This may be undesireable for Live->VOD workflows.
            </span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" :includeSyncGroups="true" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
      <v-col v-if="config.sources.data !== undefined" cols="12">
        <encoder-selector :device_guid='device_guid' type="data" :canEdit="canEdit" multiple v-model="config.sources.data" :filter="supportedDataEncoders" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.service_name"
          :items="generateSelectItems(service_nameValues, 'daemon.output.http_push.integrations')"
          label="Streaming Provider"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <div v-if="config.service_name == 'generic'">
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="parsedServiceData.push_url"
            label="Primary URL"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <v-col cols="12">
          <v-text-field
            v-model="parsedServiceData.push_url_secondary"
            label="Secondary URL (optional)"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-if="config.service_name != 'generic'">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
          >
            Management of this Streaming Provider is not yet implemented in LiveEdge Cloud, please configure this on the device directly.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Manifest Types</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="6">
        <v-checkbox
          v-model="config.protocols"
          value="HLS"
          multiple
          label="Generate HLS Manifest"
          @change="configChanged"
          :disabled="!canEdit || !featureValueIncludes('daemon.output.http_push.protocols', 'hls')"
          :rules="manifestValidation"
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox
          v-model="config.protocols"
          value="DASH"
          multiple
          label="Generate DASH Manifest"
          @change="configChanged"
          :disabled="!canEdit || !featureValueIncludes('daemon.output.http_push.protocols', 'dash')"
          :rules="manifestValidation"
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="config.media_container"
          :items="generateSelectItems(media_containerValues, 'daemon.output.http_push.formats', { 'ts': 'TS', 'fmp4': 'FMP4' })"
          label="Media Type"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col lg="3" cols="6">
        <v-text-field
          v-model.number="config.segment_length"
          label="Segment Length (seconds)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col lg="3" cols="6">
        <v-text-field
          v-model.number="config.number_of_segments"
          label="Number of Segments"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="config.save_segment_window"
          label="Save Segment Window"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable_synchronization"
          label="Enable Synchronization"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.enable_synchronization">
      <v-row class="mt-3">
        <v-col cols="5">
          <v-text-field
            v-model.number="config.synchronized_dash_presentation_delay"
            label="DASH Presentation Delay - SPD (seconds)"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="7" v-if="config.synchronized_dash_time_service !== undefined">
          <div class="d-flex align-center">
            <v-text-field
              v-model="config.synchronized_dash_time_service"
              label="DASH Time Service"
              @input="configChanged"
              :disabled="!canEdit"
              outlined
              hide-details="auto"
            ></v-text-field>
            <v-tooltip bottom max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  v-bind="attrs"
                  v-on="on"
                  small
                  class="ma-1"
                >
                  <v-icon>question_mark</v-icon>
                </v-btn>
              </template>
              <span style="line-height: 1.5em">
                In order to playback a DASH low latency stream, the DASH player needs to synchronize its clock with the encoder/packager.
                A DASH time service is an HTTP server that serves the current time, so that the player can use it to synchronize.
                The time service must be synchronized to the same NTP server that the encoder/packager is synchronized to.
                The time service is also required to serve the time in ISO/IEC 8601 format.
              </span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>
    </div>
    
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.ULL_enable"
          label="Ultra Low Latency"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.ULL_enable">
      <v-row class="mb-3">
        <v-col cols="3">
          <v-text-field
            v-model.number="config.ULL_chunk_interval"
            label="Chunk Duration"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-select
            v-model="config.ULL_chunk_unit"
            :items="ULL_chunk_unitValues"
            label="Chunk Unit"
            @change="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="4" v-if="config.ULL_target_latency_ms !== undefined">
          <v-text-field
            v-model.number="config.ULL_target_latency_ms"
            label="Target Latency (ms)"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-alert v-if="isNew && outputLimitReached" type="error" outlined>Unable to add another HTTP output. This device is licensed for {{ featureValue('daemon.output.http_push.limit') }} instance(s).</v-alert>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'HTTP_PushOutputDocument',
    
    props: ['output', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        additionalSettingsVersion: this.$helpers.parseVersion('device', 'v9.5.0'),
        enableRetryVersion: this.$helpers.parseVersion('device', 'v10.4.0'),
        
        valid: true,
        
        id: false,
        type: false,
        
        parsedServiceData: {'push_url': '', 'push_url_secondary': ''},
        
        ULL_chunk_unitValues: [
          { 'value': 'FRAMES', 'text': 'Frames' },
          { 'value': 'MILLISECONDS', 'text': 'Milliseconds' },
        ],
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        supportedDataEncoders: [
          'scte35'
        ],
        
        
        config: {
          enable: false,
          
          number_of_segments: 10,
          segment_length: 2,
          
          protocols: [
            'DASH',
            'HLS'
          ],
          
          media_container: 'FMP4',
          
          service_name: 'generic',
          service_data: '',
          
          save_segment_window: false,
          
          enable_synchronization: false,
          synchronized_dash_presentation_delay: 2,
          //synchronized_dash_time_service: 'http://time.akamai.com/?iso', # this property will get set if its available in the shadow
          
          ULL_enable: false,
          ULL_chunk_unit: 'FRAMES',
          ULL_chunk_interval: 3,
          //ULL_target_latency_ms: 4000, # this property will get set if its available in the shadow
          
          //enable_retry: false, # this property will get set if its available in the shadow
          
          sources: {
            audio: [],
            video: []
          }
        }
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      service_nameValues() {
        return [
          { 'value': 'generic', 'text': 'Generic HTTP' },
          { 'value': 'akamai', 'text': 'Akamai' },
        ]
      },
      
      media_containerValues() {
        return [
          { 'value': 'FMP4', 'text': 'fMP4' },
          { 'value': 'TS', 'text': 'Transport Stream' },
        ]
      },
      
      outputLimitReached() {
        return !this.hasFeature('daemon.output.http_push.limit', this.instanceCountByType('http_push'))
      },
      
      manifestValidation() {
        if (this.config.protocols.length == 0) {
          return ['You need at least one manifest']
        }
        return []
      },
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        // update the config.data string
        if (this.parsedServiceData) {
          this.config.service_data = JSON.stringify(this.parsedServiceData)
        }
        
        var validationErrors = false
        
        if (this.config.enable == true) {
          if (this.config.sources && this.config.sources.video.length == 0) {
            console.log('HTTP_PushOutputDocument ' + this.device_guid + ' validation error: Video encoder must be specified', this.config)
            validationErrors = true
          }
          
          if (this.config.protocols.length == 0) {
            console.log('HTTP_PushOutputDocument ' + this.device_guid + ' validation error: You need at least one manifest', this.config)
            validationErrors = true
          }
          
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        if (this.isNew && !this.hasFeature('daemon.output.http_push.limit', this.instanceCountByType('http_push'))) {
          console.log('HTTP_PushOutputDocument ' + this.device_guid + ' validation error: Output instance limit reached', this.config)
          validationErrors = true
        }
        
        console.log('HTTP_PushOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // parse data in to an actual object for use...
          if (this.config.service_data) {
            this.parsedServiceData = JSON.parse(this.config.service_data)
          }
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // populate transient values
        if (this.isNew && this.$helpers.versionCheck(this.additionalSettingsVersion, this.device.daemon_version)) {
          console.log('HTTP_PushOutputDocument populating transient properties')
          if (!this.config.ULL_target_latency_ms) {
            this.$set(this.config, 'ULL_target_latency_ms', 4000)
          }
          if (!this.config.synchronized_dash_time_service) {
            this.$set(this.config, 'synchronized_dash_time_service', 'http://time.akamai.com/?iso')
          }
        } 
        
        if (this.isNew && this.$helpers.versionCheck(this.enableRetryVersion, this.device.daemon_version)) {
          console.log('HTTP_PushOutputDocument populating enable_retry')
          if (!this.config.enable_retry) {
            this.$set(this.config, 'enable_retry', false)
          }
        }
        
        // show data encoder if creating new
        if (this.isNew) {
          if (this.config.sources.data === undefined) {
            this.$set(this.config.sources, 'data', [])
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('HTTP_PushOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>