<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Input Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <input-selector :device_guid='device_guid' :canEdit="canEdit" v-model="config.in_channel_id" @change="configChanged" />
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Video Scaling</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="">
      <v-col cols="12">
        <v-checkbox
          v-model="config.limit_to_30_fps"
          label="Limit to Maximum 30 FPS"
          @change="configChanged"
          :disabled="!canEdit"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="6">
        <v-text-field
          v-if="config.keyframe_unit == 'FRAMES'"
          v-model.number="config.keyframe_interval"
          label="Keyframe Interval (frames)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="keyframe_unitFramesValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
        <v-text-field
          v-else
          v-model.number="config.keyframe_interval"
          label="Keyframe Interval (milliseconds)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="keyframe_unitMSValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="config.keyframe_unit"
          :items="keyframe_unitValues"
          label="Keyframe Unit"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.latency_mode"
          :items="generateSelectItems(latency_modeValues, 'daemon.encoders.video.latency_modes', {'high': 'HIGH', 'normal': 'NORMAL', 'low': 'LOW', 'lowest': 'LOWEST'})"
          label="Quality / Latency"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Sync Group</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ encoder }}</pre>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import InputSelector from '../components/InputSelector.vue'

  export default {
    name: 'SyncGroupDocument',
    
    props: ['encoder', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      InputSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        keyframe_unitValues: [
          { 'value': 'MILLISECONDS', 'text': 'Milliseconds' },
          { 'value': 'FRAMES', 'text': 'Frames' },
        ],
        
        config: {
          "video_encoder_ids": [],
          
          "keyframe_interval": 2000,
          "keyframe_unit": "MILLISECONDS",
          
          "latency_mode": "NORMAL",
          
          "name": "MBR Group",
          
          "limit_to_30_fps": false,
          
          "in_channel_id": -1
        },
        
        
        // validations
        keyframe_unitFramesValidation:  [
          v => (!isNaN(v)) || 'Value must be a number',
          v => (v >= this.featureValueObjectKey('daemon.encoders.video.keyframe', 'min', 1)) || 'Value must be >= ' + this.featureValueObjectKey('daemon.encoders.video.keyframe', 'min', 1),
          v => (v <= this.featureValueObjectKey('daemon.encoders.video.keyframe', 'max', 1000)) || 'Value must be <= ' + this.featureValueObjectKey('daemon.encoders.video.keyframe', 'max', 1000)
        ],
        
        keyframe_unitMSValidation:  [
          v => (!isNaN(v)) || 'Value must be a number',
          v => (v >= 41) || 'Value must be >= 41',
          v => (v <= 16666) || 'Value must be <= 16666'
        ],
        
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      latency_modeValues() {
        if (this.device.product_name == 'edgecaster_max') {
          return [
            { 'value': 'NORMAL', 'text': 'Normal' },
            { 'value': 'LOWEST', 'text': 'Lowest' },
          ]
        } else {
          return [
            { 'value': 'HIGH', 'text': 'High' },
            { 'value': 'NORMAL', 'text': 'Normal' },
            { 'value': 'LOW', 'text': 'Low' },
            { 'value': 'LOWEST', 'text': 'Lowest' },
          ]
        }
      },
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        console.log('SyncGroupDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config)
      },
      
      updateValues() {
        if (this.encoder && this.encoder.config) {
          Object.keys(this.encoder).map((key) => {
            this[key] = this.encoder[key]
          })
          
          // htmlDecode name properties
          if (this.config.name) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteEncoder() {
        console.log('SyncGroupDocument ' + this.device_guid + ' deleteEncoder')
        this.$emit('deleteEncoder')
      }
    }
  }
</script>