<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <capabilities-box v-if="section == 'capabilities'"/>
        <entitlements-box v-if="section == 'profiles'"/>
        <license-box v-if="section == 'licenses'"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  // THIS IS AN ADMIN THING....
  import CapabilitiesBox from '../components/entitlements/Capabilities.vue'
  import EntitlementsBox from '../components/entitlements/EntitlementProfiles.vue'
  import LicenseBox from '../components/entitlements/AdminLicenseManager.vue'
  
  export default {
    name: "Entitlements",
    components: {
      CapabilitiesBox,
      EntitlementsBox,
      LicenseBox
    },
    data() {
      return {
        section: this.$route.params.section,
        id: this.$route.params.id
      }
    },
    watch: {
      $route() {
        this.section = this.$route.params.section
        this.id = this.$route.params.id
      }
    },
    methods: {
      
    }
  }
</script>
