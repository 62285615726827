<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <admin-device-listing-box />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AdminDeviceListingBox from '../components/device/AdminDeviceListing.vue'
  
  export default {
    name: "AdminDevices",
    components: {
      AdminDeviceListingBox,
    },
  }
</script>