<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <license-manager />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import LicenseManager from '../components/entitlements/OrgLicenseManager.vue'
  
  export default {
    name: "Licenses",
    
    components: {
      LicenseManager
    },
  }
</script>