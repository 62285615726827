<template>
  <div class="d-inline-block text-center">
    <v-icon v-if="status.warning" color="red">warning</v-icon> <span>{{ status.label }}</span>
  </div>
</template>

<script>
  
  export default {
    name: 'ProcessorsStatusLabel',
    props: ['device'],
    computed: {
      status() {
        if (this.device.state) {
          if (this.device.state.gpu_throttling) {
            return {'label': 'GPU throttling', 'warning': true}
          } 
          return {'label': 'Good'}
        }
        return {'label': 'Unknown'}
      },
    },
    data() {
      return {
        showState: false,
      }
    }
  }
</script>