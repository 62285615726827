<template>
  <div>
    <h3>Reboot Devices</h3>
    <p>
      Attempt to reboot the selected devices that are currently online.
    </p>
    
    <v-row align="center">
      <v-col cols="12" align="center" class="mt-5">
        <v-btn
          class="my-3"
          color="secondary"
          outlined
          @click="reboot"
          :disabled="actioned"
        >
          Reboot Now
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  export default {
    name: 'BulkActionReboot',
    props: ['devices'],
    
    data() {
      return {
        actioned: false,
      }
    },
    
    methods: {
      reboot() {
        this.actioned = true
        
        for (var device of this.devices) {
          if (!device.hasFeature('services.cloud.bulk_actions.enabled')) {
            device.command_message = 'Not Licensed'
          } else if (device.isOnline()) {
            device.runCommand('reboot_device')
          }
        }
      }
    }
  }
</script>