<template>
  <v-autocomplete
    v-model="selectedOrgGuid"
    itemText="org_name"
    itemValue="org_guid"
    :loading="loading"
    :items="organizations"
    :filter="filter"
    label="Organization"
    hide-details
    outlined
  />
</template>

<script>
  export default {
    name: 'OrgSelector',
    
    data() {
      return {
        loading: false,
        organizations: [],
        selectedOrgGuid: ''
      }
    },

    props: {
      value: {
        type: String,
        default: ''
      }
    },

    watch: {
      value(value) {
        this.selectedOrgGuid = value
      },
      
      selectedOrgGuid() {
        this.$emit('input', this.selectedOrgGuid || '')
      }
    },

    mounted() {
      this.loading = true
      this.$organizations.allOrgs().then(() => {
        this.organizations = this.$organizations.orgs
        this.loading = false
      })
      this.selectedOrgGuid = this.value
    },

    methods: {
      filter(item, queryText, itemText) {
        queryText = queryText.toLocaleLowerCase().replace(' ', '_')
        return itemText.toLocaleLowerCase().indexOf(queryText) > -1
      },
      // TODO need to make the search filter do live querying.. right now its expecting all orgs to load...
    }
  }
</script>