export default {
  computed: { 
    device() {
      return this.$devices.getDevice(this.device_guid)
    },
    
    instanceCount() {
      return this.documents.length
    },
    
    selectedVideoEncoder() {
      if (this.config && this.config.sources.video.length > 0 && this.config.sources.video[0]) {
        var videoEncoderID = this.config.sources.video[0]
        if (videoEncoderID > 0) {
          return this.device.encoderByID(videoEncoderID)
        }
      }
      return false
    },
    
    videoEncoderHasBackpressureControl() {
      var videoEncoder = this.selectedVideoEncoder
      
      var outputsShadow = this.$deviceShadows.getDeviceShadow(this.device_guid, 'Outputs') 
      var outputs = (outputsShadow.reported) ? outputsShadow.reported.state : []
      
      var backpressureControlledOutputs = outputs.find(x => (
        x.config 
        && x.config.sources.video.length > 0
        && x.config.sources.video[0] == videoEncoder.id
        && x.type == 'zixi'
        && x.id != this.id
        && x.config.enable_back_pressure_bitrate_adjustment
        && x.config.enable_back_pressure_bitrate_adjustment == true
      ))
      
      return (backpressureControlledOutputs) ? true : false
    },
    
    videoEncoderValidation() {
      if (this.videoEncoderHasBackpressureControl) {
        return ['The Video Encoder you’re trying to connect to has its bitrate managed by another output. Using this video encoder for both outputs could have undesirable behavior.']
      }
      return false
    },
  },
  methods: {
    instanceCountByType(type) {
      if (this.documents && this.documents.length > 0) {
        var count = this.documents.filter(item => item.type === type).length
        return count
      }
      return -1
    },
    
    instanceCountByDataType(data_type) {
      if (this.documents && this.documents.length > 0) {
        var count = this.documents.filter((item) => {
          if (item.config && item.config.data_type && item.config.data_type === data_type) {
            return item
          }
        }).length
        return count
      }
      return -1
    },
    
    generateSelectItems(values = [], key = false, translation = false) {
      var entitlements = this.device.entitlements()
      
      // if an entitlement key is specified, overwrite values with entitlement values
      if (key && entitlements[key]) {
        if (translation && typeof translation === 'object') {
          values = entitlements[key].map(item => {
            if (translation[item]) {
              return translation[item]
            }
            return item
          })
        } else {
          values = entitlements[key]
        }
      }
      
      var items = values.map(item => {
        return {'value': item, 'text': this.$helpers.getLabel(item)}
      })
      
      return items
    },
    
    hasFeature(key, currentInstances = 0) {
      return this.device.hasFeature(key, currentInstances)
    },
    
    featureValue(key, defaultValue = false) {
      return this.device.featureValue(key, defaultValue)
    },
    
    featureValueIncludes(key, value) {
      return this.device.featureValueIncludes(key, value)
    },
    
    featureValueObjectKey(key, objectKey, defaultValue = false) {
      return this.device.featureValueObjectKey(key, objectKey, defaultValue)
    }
  }
}