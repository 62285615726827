import Vue from 'vue'

class VideonCapabilityProfile {
  constructor(product, product_attributes = false) {
    this.product = product
    this.ready = false
    
    this.product_name = product

    this.capabilities = []
    
    if (product_attributes) {
      for (var property in product_attributes) {
        this[property] = product_attributes[property]
      }
      this.ready = true
    }
    
    this.error = false
    this.errorText = ''
    
    if (!this.ready) {
      this.fetchProfile()
    }
  }
  
  displayName() {
    return Vue.helpers.getLabel(this.product_name)
  }
  
  fetchProfile() {
    Vue.axios.get('/entitlements/capabilities/' + this.product)
    .then((response) => {
      console.log('VideonCapabilityProfile ' + this.product + ' fetchProfile response', response)

      for (var property in response.data.capabilities) {
        this[property] = response.data.capabilities[property]
      }

      this.ready = true
    }).catch((error) => {
      console.log('VideonCapabilityProfile ' + this.product + ' fetchProfile error', error)
    })
  }

  getFeatures() {
    return this.capabilities.map(capability => capability.feature)
  }
  
}

export default new class Capabilities {
  constructor() {
    this.profiles = []
  }

  allProducts() {
    return ['edgecaster', 'edgecaster_max', 'cloud_ui']
  }
  
  allProfiles() {
    // rather than enumerating this.profiles, lets just get each to ensure its loaded

    var profiles = []
    for (var profile of this.allProducts()) {
      profiles.push(this.getCapabilityProfile(profile))
    }
    
    return profiles
  }
  
  getCapabilityProfile(product, product_attributes = false) {
    if (!product) {
      console.log('Capabilities getCapabilityProfile missing product', product, product_attributes)
      return false
    }
    
    var profile = this.profiles.find(x => x.product === product)
    if (profile) {
      return profile
    }
    
    profile = Vue.observable(new VideonCapabilityProfile(product, product_attributes))
    this.profiles.push(profile)
    
    return profile
  }
}