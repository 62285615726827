<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
      
        <div v-if="!org_guid">
          <org-listing-box />
        </div>
        
        <div v-if="org_guid">
          <edit-org-box :org_guid="org_guid"/>
        </div>
      
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import OrgListingBox from '../components/organization/OrgListing.vue'
  import EditOrgBox from '../components/organization/EditOrg.vue'
  
  export default {
    name: "Organizations",
    components: {
      OrgListingBox,
      EditOrgBox
    },
    data() {
      return {
        org_guid: this.$route.params.org_guid,
      }
    },
    watch: {
      $route() {
        this.org_guid = this.$route.params.org_guid
      }
    },
    methods: {
      
    }
  }
</script>
