<template>
  <videon-card heading="SSO Login" max-width="440">
    <v-card-text>
      <div class="mt-5 mb-3">
        <v-row>
          <v-col cols="12" class="text-center mx-auto">
            <SSOButton
              v-for="(provider, index) in providers"
              @submit="SSOContinue(provider)"
              :key="index"
              :provider="provider"
            />
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="5"><v-divider /></v-col>
          <v-col cols="2" class="text-center overline justify-start">or</v-col>
          <v-col cols="5"><v-divider /></v-col>
        </v-row>
      </div>
      <v-row class="ma-1">
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="cancel"
            class="mx-auto ma-2"
            width="100%"
            text
          >
            Cancel SSO Login
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </videon-card>
</template>

<script>
  import SSOButton from './AuthSSOButton.vue'

  export default {
    name: 'AuthSSO',
    
    props: ['providers'],
    
    components: {
      SSOButton
    },

    methods: {
      SSOContinue(provider) {
        this.$emit('submit', provider);
      },
      cancel() {
        this.$store.dispatch('user/setStage', 'capture_email')
        this.$store.dispatch('user/resetEmail')
      }
    }
  }
</script>