<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <search-box />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import SearchBox from '../components/search/DeviceSearch.vue'
  
  export default {
    name: "Search",
    components: {
      SearchBox,
    },
    data() {
      return {
        device_guid: (this.$route.params.device_guid) ? this.$route.params.device_guid : false,
      }
    },
    watch: {
      $route() {
        this.device_guid = (this.$route.params.device_guid) ? this.$route.params.device_guid : false
      }
    }
  }
</script>