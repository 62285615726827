<template>
  <v-select
    v-model="encoders"
    :items="encoderList"
    itemValue="id"
    :item-text="encoderName"
    @change=valueChanged
    :disabled="!canEdit"
    :label="encoderLabel"
    :clearable="type == 'audio'"
    :multiple="multiple"
    :rules="selectorRules"
    hide-details="auto"
    outlined
  >
  </v-select>
</template>

<script>
  export default {
    name: 'EncoderSelector',
    
    data() {
      return {
        encoders: [],
        encoderList: [],
        
        requiredRule: [
          v => !!v || 'Encoder selection is required'
        ],
      }
    },
    
    inject: ['form'],
    
    props: ['device_guid', 'type', 'value', 'canEdit', 'multiple', 'includeSyncGroups', 'filter', 'required', 'rules'],
    
    watch: {
      value(value) {
        if (this.multiple !== undefined) {
          this.encoders = (value) ? value : []
        } else {
          this.encoders = (value[0]) ? value[0] : false
        }
      },
    },
    
    created() {
      this.form.register(this)
    },
    
    mounted() {
      if (this.multiple !== undefined) {
        this.encoders = (this.value) ? this.value : []
      } else {
        this.encoders = (this.value[0]) ? this.value[0] : false
      }
      
      this.getDeviceEncoderShadows()
    },
    
    computed: {
      encoderLabel() {
        if (this.type == 'video') {
          return 'Video Encoder'
        } else if (this.type == 'audio') {
          return 'Audio Encoder(s)'
        } else if (this.type == 'data') {
          return 'Data Encoder(s)'
        }
        return 'Unknown Encoder'
      },
      
      selectorRules() {
        if (this.rules) {
          return this.rules
        } else if (this.required) {
          return this.requiredRule
        }
        return []
      }
    },
    methods: {
      validate() {
        // do nothing
      },
      
      resetValidation() {
        // do nothing
      },
      
      encoderName(item) {
        if (item.config && item.config.name) {
          return item.config.name
        }
        return 'Encoder #' + item.id
      },
      
      getDeviceEncoderShadows() {
        var shadows = this.$deviceShadows.getDeviceShadow(this.device_guid, 'Encoders')
        var encoders = (shadows.reported.state) ? shadows.reported.state : []
        
        this.encoderList = encoders.filter(encoder => {
          if (this.filter) {
            if (this.type == 'video') {
              var videoCodec = (encoder.config && encoder.config.selected_codec) ? encoder.config.selected_codec : false
              if (encoder.type == this.type && videoCodec && this.filter.includes(videoCodec)) {
                return encoder
              }
            } else if (this.type == 'audio') {
              var audioCodec = (encoder.config && encoder.config.codec) ? encoder.config.codec : false
              if (encoder.type == this.type && audioCodec && this.filter.includes(audioCodec)) {
                return encoder
              }
            } else if (this.type == 'data') {
              var dataType = (encoder.config && encoder.config.data_type) ? encoder.config.data_type : false
              if (encoder.type == this.type && dataType && this.filter.includes(dataType)) {
                return encoder
              }
            }
          } else {
            if (encoder.type == this.type) {
              return encoder
            } else if (this.includeSyncGroups && this.type == 'video' && encoder.type == 'sync_group') {
              return encoder
            }
          }
        })
      },
      
      valueChanged() {
        if (this.multiple !== undefined) {
          this.$emit('input', this.encoders)
        } else {
          this.$emit('input', [this.encoders])
        }
        this.$emit('change')
      },
    }
  }
</script>