<template>
  <div>EDIT</div>
</template>

<script>
  export default {
    name: 'EditAlert',
    
    data() {
      return {
        error: false,
        errorText: '',
        
        loading: false,
        
      }
    },
    computed: {
      
    },
    props: {
      alert_guid: {
        type: String,
        required: true
      }
    },
    watch: {
      
    },
    mounted() {
      this.loadAlert()
    },
    methods: {
      loadAlert() {
        this.loading = true
        
        if (!this.alert_guid) {
          this.error = true
          this.errorText = 'alert_guid not supplied'
          return
        }
        /*
        this.axios.get('/users/' + this.user_guid).then((response) => {
          console.log('loadAlert', response)

          
          this.loading = false
          
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
        */
      },

        
      
    }
  }
</script>