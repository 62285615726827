import Vue from 'vue'
import store from '../store'
import moment from 'moment'

class VideonDevice {
  constructor(device_guid, device_attributes = false) {
    this.device_guid = device_guid
    
    this.device_name = this.device_guid
    
    this.ready = false
    this.error = false
    this.errorText = ''
    
    this.controllable = false
    this.editable = false
    
    this.state = false
    this.state_timestamp = false
    
    this.metadata = false
    
    this.usersFetched = false
    this.users = []
    this.userInvites = []
    
    this.licenses = []
    
    this.updatesAvailable = { 'daemon': false, 'system': false, 'cloud': false }
    
    this.documentSyncing = false
    
    this.commandResponseCheckInterval = 2000
    this.commandResponseCheckMaxAttempts = 100
    this.commandResponseCheckAttempt = 0
    this.commandResponseTimer = false
    this.commandGUID = ''
    
    this.inputPreviewDuration = 30
    this.inputPreviewInterval = 1
    this.lastInputPreviewRequestAt = false
    
    this.command = ''
    this.command_status = false
    this.command_message = ''
    
    this.last_api_request_id = ''
    
    this.streaming_status = 5
    
    this.firmware_version = 'Unknown'
    this.system_version = false
    this.daemon_version = false
    this.cloud_version = false
    
    this.device_ip = ''
    this.external_ip = ''
    this.ip_scheme = ''
    
    // object properties
    this.serial_number = (device_attributes) ? device_attributes.serial_number : 'Unknown'
    this.mac_address = (device_attributes) ? device_attributes.mac_address : '00:00:00:00:00:00'
    this.partner_id = (device_attributes && device_attributes.partner_id) ? device_attributes.partner_id : ''
    
    this.product_name = (device_attributes.product_name) ? device_attributes.product_name : false
    this.manufacture_date = (device_attributes && device_attributes.manufacture_date) ? device_attributes.manufacture_date : ''
    
    this.org_guid = (device_attributes && device_attributes.org_guid) ? device_attributes.org_guid : false
    
    if (!device_attributes || !this.product_name) { // we need to ensure we have a product_name
      this.fetchDevice()
    }
    
    if (!this.state) {
      this.fetchState()
    }
    
    setTimeout(() => {
      this.fetchMetadata()
      Vue.deviceShadows.getDeviceShadow(this.device_guid, 'System')
      Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Outputs')
    }, Vue.helpers.getRefreshInterval(1, 10))
    
    setTimeout(() => {
      this.syncDocuments()
    }, Vue.helpers.getRefreshInterval(10, 250))
    
    setTimeout(() => {
      this.fetchLicenses()
    }, Vue.helpers.getRefreshInterval(1, 100))
  }
  
  refresh() {
    this.fetchDevice()
    this.fetchMetadata()
    this.syncDocuments()
    this.fetchUsers()
    this.fetchLicenses()
  }
  
  refreshProperties() {
    this.deviceName(true)
    this.deviceIP(true)
    this.IPScheme(true)
    this.updateStreamingStatus()
    this.firmwareVersion()
  }
  
  delete() {
    clearTimeout(this.commandResponseTimer)
    
    this.device_guid = false
    this.device_name = 'deleted'
    this.autoRefresh = false
    this.state = false
    
    Vue.delete(this) // this shouldnt work.. but it does...
  }
  
  // accessibility methods
  icon() {
    if (this.product_name == 'edgecaster') {
      return '$edgecaster'
    } else if (this.product_name == 'edgecaster_max') {
        return '$edgecaster_max'
    }
    return '$videon_logo'
  }
  
  deviceName(refresh = false) {
    if (this.device_name && !refresh) {
      return this.device_name
    }
    
    if (this.metadata && this.hasMetadataKey('device_alias')) {
      this.device_name = Vue.helpers.htmlDecode(this.metadataValue('device_alias'))
      return this.device_name
    }
    
    var systemState = this.systemState()
    if (systemState && systemState.config && systemState.config.device_name) {
      this.device_name = Vue.helpers.htmlDecode(systemState.config.device_name)
      return this.device_name
    }
    
    this.device_name = this.serial_number + ' [' + this.mac_address + ']'
    return this.device_name
  }
  
  isOnline() {
    if (this.state) {
      return this.state.online
    }
    return false
  }
  
  deviceIP(refresh = false) {
    if (this.device_ip && !refresh) {
      return this.device_ip
    }
    
    if (this.state) {
      if (this.state.device_ip) {
        this.device_ip = this.state.device_ip
      }
      if (this.state.external_ip) {
        this.external_ip = this.state.external_ip
      }
    }
    
    var systemState = this.systemState()
    if (systemState && systemState.network) {
      this.device_ip = systemState.network.ip_address 
    }
    
    return this.device_ip
  }
  
  IPScheme(refresh = false) {
    if (this.ip_scheme && !refresh) {
      return this.ip_scheme
    }
    
    if (this.state && this.state.ip_scheme) {
      this.ip_scheme = this.state.ip_scheme
      return this.ip_scheme
    }
    
    var systemState = this.systemState()
    if (systemState && systemState.config) {
      this.ip_scheme = systemState.config.ip_scheme
      return this.ip_scheme
    }
    
    return false
  }
  
  hasXMLConfig() {
    var systemState = this.systemState()
    if (systemState && systemState.config && systemState.config.xml) {
      return systemState.config.xml.enabled
    }
    return false
  }
  
  firmwareVersion() {
    if (this.state && this.state.daemon_version) {
      if (this.state.daemon_version != 'Unknown') {
        
        this.firmware_version = 'v' + this.state.daemon_version
        
        if (this.state.system_version != 'Unknown' && this.state.firmware_version != 'Unknown') {
          this.firmware_version += '.' + this.state.system_version + '.' + this.state.firmware_version
        }
        
        this.daemon_version = Vue.helpers.parseVersion('device', this.firmware_version)
        this.system_version = this.state.system_version
        
        return this.firmware_version
      } else if (this.product_name == 'edgecaster_max' && this.state.daemon_version == 'Unknown') {
        // hardcode all unknown versions to 10.0 as thats what Max released with
        this.firmware_version = 'v10.0.0'
        this.daemon_version = Vue.helpers.parseVersion('device', this.firmware_version)
        this.system_version = this.state.system_version
        
        return this.firmware_version
      }
    } 
    
    return 'Unknown'
  }
  
  hasUpdates() {
    for (var key in this.updatesAvailable) {
      if (this.updatesAvailable[key]) {
        return true
      }
    }
    return false
  }
  
  isUpdating() {
    if (this.commandGUID && (this.command == 'update_cloud' || this.command == 'update_device') ) {
      return true
    } else if (this.product_name == 'edgecaster_max' && this.state && this.state.update_state) {
      var systemState = this.state.update_state.find(x => (x.module == 'system'))
      
      if (systemState && systemState.status.toLowerCase() == 'downloading update') {
        return true
      } else if (systemState && (systemState.action.toLowerCase() == 'apply' && systemState.state.toLowerCase() == 'in_progress')) {
        return true
      }
    }
    return false
  }
  
  isCommandProcessing() {
    if (
      this.command_status 
      && this.command_status != 'complete' 
      && this.command_status != 'error'
      && this.command_status != 'rejected'
      && this.command_status != 'failed' 
    ) {
      return true
    }
    
    return false 
  }
  
  canControl() {
    // if we dont have any state, the device likely cant be interacted with
    if (!this.state) {
      return false
    }
    
    if (!this.usersFetched) {
      this.fetchUsers()
    }
    return this.controllable
  }
  
  canEdit() {
    // if we dont have any state, the device likely cant be interacted with
    if (!this.state) {
      return false
    }
    
    if (!this.usersFetched) {
      this.fetchUsers()
    }
    return this.editable
  }
  
  
  // API queries
  fetchDevice() {
    if (!this.device_guid) {
      return
    }
    
    Vue.axios.get('/devices/' + this.device_guid)
    .then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchDevice response', response)
      this.last_api_request_id = response.data.api_request_id
      
      this.serial_number = response.data.device.serial_number
      this.mac_address = response.data.device.mac_address
      
      this.partner_id = response.data.device.partner_id
      
      if (response.data.device.product_name) {
        this.product_name = response.data.device.product_name
      } else {
        this.product_name = 'edgecaster' // fallback to edgecaster
      }
      
      this.manufacture_date = response.data.device.manufacture_date
      this.org_guid = response.data.device.org_guid
      
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
    })
  }
  
  fetchMetadata() {
    if (!this.device_guid) {
      return
    }
    
    Vue.axios.get('/devices/' + this.device_guid + '/metadata')
    .then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchMetadata response', response)
      this.last_api_request_id = response.data.api_request_id
      if (response.data.metadata) {
        if (response.data.metadata.length > 0) {
          this.metadata = response.data.metadata
        } else {
          this.metadata = false
        }
        
        // refresh device name
        this.deviceName(true)
      }
    }).catch((error) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchMetadata error', error)
    })
  }
  
  syncDocuments(force = false) {
    if (!this.device_guid) {
      return
    }
    
    // stop multiple concurrent requests
    if (this.documentSyncing == true && !force) {
      console.log('VideonDevice ' + this.device_guid + ' syncDocuments already running')
      return
    }
    
    if (force || !this.ready || this.isUpdating() || document.hasFocus()) {
      this.documentSyncing = true
      
      Vue.axios.get('/devices/' + this.device_guid + '/documents')
      .then((response) => {
        // device has since been deleted
        if (!this.device_guid) {
          return
        }
        
        this.documentSyncing = false
        
        console.log('VideonDevice ' + this.device_guid + ' syncDocuments response', response)
        this.last_api_request_id = response.data.api_request_id
      
        if (!response.data.documents) {
          // fallback and fetch just state if we dont have it
          if (!this.state) {
            this.fetchState()
          }
          return
        }
      
        // compare document last_modified...
        var documents = response.data.documents
        
        for (var key in documents) {
          if (documents[key].last_modified) {
            var newDocumentTimestamp = moment(documents[key].last_modified)
            
            if (key == 'state') {
              if (this.error || !this.state_timestamp || newDocumentTimestamp.isAfter(this.state_timestamp)) {
                console.log('VideonDevice ' + this.device_guid + ' syncDocuments updated State')
                this.state_timestamp = documents[key].last_modified
                this.fetchState()
              }
            
            } else if (key == 'shadow/Inputs') {
              var inputShadowDocument = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Inputs')
              if ((!inputShadowDocument.reported && this.isOnline()) || newDocumentTimestamp.isAfter(inputShadowDocument.reported.timestamp)) {
                console.log('VideonDevice ' + this.device_guid + ' syncDocuments updated Inputs shadow')
                inputShadowDocument.fetchShadow()
              }
            
            } else if (key == 'shadow/Encoders') {
              var encoderShadowDocument = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Encoders')
              if ((!encoderShadowDocument.reported && this.isOnline()) || newDocumentTimestamp.isAfter(encoderShadowDocument.reported.timestamp)) {
                console.log('VideonDevice ' + this.device_guid + ' syncDocuments updated Encoders shadow')
                encoderShadowDocument.fetchShadow()
              }
            
            } else if (key == 'shadow/Outputs') {
              var outputShadowDocument = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Outputs')
              if ((!outputShadowDocument.reported && this.isOnline()) || newDocumentTimestamp.isAfter(outputShadowDocument.reported.timestamp)) {
                console.log('VideonDevice ' + this.device_guid + ' syncDocuments updated Outputs shadow')
                outputShadowDocument.fetchShadow()
              }
            
            } else if (key == 'shadow/System') {
              var systemShadowDocument = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'System')
              if ((!systemShadowDocument.reported && this.isOnline()) || newDocumentTimestamp.isAfter(systemShadowDocument.reported.timestamp)) {
                console.log('VideonDevice ' + this.device_guid + ' syncDocuments updated System shadow')
                systemShadowDocument.fetchShadow()
              }
            
            }
          }
        }
      }).catch((error) => {
        console.log('VideonDevice ' + this.device_guid + ' syncDocuments error', Vue.helpers.parseError(error))
        
        this.error = true
        this.errorText = Vue.helpers.parseError(error)
      
        // fallback and fetch just state if we dont have it
        if (!this.state) {
          this.fetchState()
        }
      })
    }
  }
  
  fetchState() {
    if (!this.device_guid) {
      return
    }
    
    Vue.axios.get('/devices/' + this.device_guid + '/state')
    .then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchState response', response)
      this.last_api_request_id = response.data.api_request_id
      
      this.state = response.data.state
      
      Object.keys(this.state).map((key) => {
        if (this.state[key] == 'false') {
          this.state[key] = false
        } else if (this.state[key] == 'true') {
          this.state[key] = true
        }
      })
      
      // update the state_timestamp to now so that we dont fetch it twice on load
      this.state_timestamp = moment()
      
      if (this.state.cloud_version) {
        // current version & check for updates
        this.cloud_version = Vue.helpers.parseVersion('cloud', this.state.cloud_version)
      }
      
      setTimeout(() => {
        this.checkForUpdates()
      }, 2000)
      
      this.refreshProperties()
      
      this.error = false
      this.errorText = ''
      
      this.ready = true
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
      
      this.updateStreamingStatus()
      
      this.ready = true
    })
  }
  
  hasCompleteState() {
    if (
      this.state
      && this.state.last_boot
      && (this.state.ready_for_commands !== undefined)
      && this.state.last_state_update
    ) {
      return true
    }
    return false
  }
  
  fetchUsers() {
    if (!this.device_guid) {
      return
    }
    
    Vue.axios.get('/devices/' + this.device_guid + '/users').then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchUsers response', response)
      this.last_api_request_id = response.data.api_request_id
      
      this.usersFetched = true
      this.users = response.data.users
      
      this.resolveUserPermissions()
    }).catch((error) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchUsers error', Vue.helpers.parseError(error))
      if (error.status == 404) {
        this.usersFetched = true
        this.users = []
        this.resolveUserPermissions()
      }
    })
  }
  
  fetchUserInvites() {
    if (!this.device_guid) {
      return
    }
    
    Vue.axios.get('/invites', {params: {'device_guid': this.device_guid}}).then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchUserInvites response', response)
      this.last_api_request_id = response.data.api_request_id
      
      this.userInvites = response.data.invites
    }).catch(() => {
      // do nothing...
    })
  }
  
  fetchLicenses() {
    if (!this.device_guid) {
      return
    }
    
    Vue.axios.get('/entitlements/devices/' + this.device_guid + '/licenses').then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchLicenses response', response)
      this.last_api_request_id = response.data.api_request_id
      
      if (response.data.licenses) {
        this.licenses = response.data.licenses
        
        // warm the cache...
        for (var license of this.licenses) {
          Vue.entitlementProfiles.getEntitlementProfile(license.profile_guid)
        }
      }
      
      // test entitlements
      setTimeout(() => {
        console.log('VideonDevice ' + this.device_guid + ' fetchLicenses entitlements', this.entitlements())
      }, 500)
    }).catch((error) => {
      console.log('VideonDevice ' + this.device_guid + ' fetchLicenses error', Vue.helpers.parseError(error))
    })
  }
  
  
  // cross class/object logic
  capabilities() {
    return Vue.capabilities.getCapabilityProfile(this.product_name)
  }
  
  entitlements() {
    var entitlements = {}
    
    var entitlementProfileGUIDs = this.licenses.filter(license => !license.expired).map(license => { return license.profile_guid })
    
    // Add base entitlement
    if (this.product_name == 'edgecaster_max') {
      entitlementProfileGUIDs.unshift('BASE_EDGECASTER_MAX')
    } else {
      entitlementProfileGUIDs.unshift('BASE_EDGECASTER')
    }
    
    for (var profile_guid of entitlementProfileGUIDs) {
      var entitlement = Vue.entitlementProfiles.getEntitlementProfile(profile_guid)
      
      if (entitlement.ready) {
        for (var propertyKey in entitlement.properties) {
          var propertyValue = entitlement.properties[propertyKey]
          
          try {
            if (entitlements[propertyKey]) {
              if (typeof propertyValue === 'boolean') {
                entitlements[propertyKey] = propertyValue || entitlements[propertyKey]
              } else if (typeof propertyValue === 'number') {
                entitlements[propertyKey] = Math.max(propertyValue, entitlements[propertyKey])
              } else if (typeof propertyValue === 'object') {
                if (propertyValue instanceof Array) {
                  entitlements[propertyKey] = [...entitlements[propertyKey], ...propertyValue]
                } else if (propertyValue.min && propertyValue.max) {
                  var { min: oldMin, max: oldMax } = entitlements[propertyKey]
                  var { min: newMin, max: newMax } = propertyValue
                  
                  entitlements[propertyKey] = {
                    'min': Math.min(newMin, oldMin),
                    'max': Math.max(newMax, oldMax)
                  }
                }
              }
            } else {
              entitlements[propertyKey] = propertyValue
            }
          } catch (error) {
            console.log('VideonDevice ' + this.device_guid + ' entitlements error', Vue.helpers.parseError(error))
          }
        }
      }
    }
    
    return entitlements
  }
  
  hasFeature(key, currentInstances = 0) {
    var entitlements = this.entitlements()
  
    if (key && entitlements[key]) {
      var propertyValue = entitlements[key]
      
      if (typeof propertyValue === 'boolean') {
        return propertyValue
      } else if (typeof propertyValue === 'number') {
        return (currentInstances < propertyValue) ? true : false
      } else if (typeof propertyValue === 'object') {
        return true
      } else if (Array.isArray(propertyValue)) {
        if (propertyValue.length > 0) {
          return true
        }
      }
    }
    
    return false
  }
  
  featureValue(key, defaultValue = false) {
    var entitlements = this.entitlements()
    
    if (key && entitlements[key]) {
      return entitlements[key]
    }
    
    return defaultValue
  }
  
  featureValueIncludes(key, value) {
    var featureValues = this.featureValue(key)
    return featureValues.includes(value)
  }
  
  featureValueObjectKey(key, objectKey, defaultValue = false) {
    var featureValues = this.featureValue(key)
    
    if (featureValues[objectKey]) {
      return featureValues[objectKey]
    }
    
    return defaultValue
  }
  
  resolveUserPermissions() {
    if (store.getters['user/isDeviceAdmin']) {
      this.editable = true
      this.controllable = true
      return
    }
    
    let myGUID = store.getters['user/guid']
    
    for (var i in this.users) {
      var user = this.users[i]
      // if this user has appropriate permissions enable editable flag
      if (myGUID == user.user_guid && user.access > 200) {
        console.log('VideonDevice ' + this.device_guid + ' resolveUserPermissions user can edit device')
        this.editable = true
        this.controllable = true
        return
      } else if (myGUID == user.user_guid && user.access > 100) {
        console.log('VideonDevice ' + this.device_guid + ' resolveUserPermissions user can control device')
        this.controllable = true
        return
      }
    }
  }
  
  checkForUpdates() {
    if (!this.product_name) {
      console.log('VideonDevice ' + this.device_guid + ' checkForUpdates product_name is unknown, skipping')
      return
    }
    
    this.updatesAvailable = { 'daemon': false, 'system': false, 'cloud': false }
    
    // update logic is separated by platform
    if (this.product_name == 'edgecaster') {
      // cloud agent check
      var latestCloudVersion = Vue.latestVersions.getLatestVersion('cloud')
      if (this.cloud_version && latestCloudVersion.ready) {
        var parsedLatestCloudVersion = Vue.helpers.parseVersion('cloud', latestCloudVersion.version)
        
        // Don't allow cloud updates from the following versions
        var forbiddenVersions = [
          'V1.0.0b44',
          'V1.0.0b44-BB-PROD'
        ]
      
        if (!forbiddenVersions.includes(this.cloud_version.original) && Vue.helpers.isNewerVersion(this.cloud_version, parsedLatestCloudVersion)) {
          this.updatesAvailable['cloud'] = parsedLatestCloudVersion
        }
      }
      
      // edgecaster daemon version check
      if (this.state && this.state.update_state) {
        var latestDaemonVersion = this.state.update_state.find(x => (x.module == 'daemon' || x.module == 'Daemon'))
        if (latestDaemonVersion) {
          var parsedLatestDaemonVersion = Vue.helpers.parseVersion('device', latestDaemonVersion.target_version)
          
          if (!this.daemon_version || Vue.helpers.isNewerVersion(this.daemon_version, parsedLatestDaemonVersion)) {
            this.updatesAvailable['device'] = parsedLatestDaemonVersion
          }
        }
      }
    } else if (this.product_name == 'edgecaster_max') {
      // max system version check
      if (this.state && this.state.update_state) {
        var latestSystemVersion = this.state.update_state.find(x => (x.module == 'system'))
        if (latestSystemVersion) {
          var parsedLatestSystemVersion = Vue.helpers.parseVersion('device', '' + latestSystemVersion.target_version)
          
          if (!this.firmware_version || latestSystemVersion.target_version > this.system_version) {
            this.updatesAvailable['device'] = parsedLatestSystemVersion
          }
        }
      }
      
    }
  }
  
  runCommand(command, data) {
    if (this.commandGUID || !command || !this.device_guid) {
      return
    }
    console.log('VideonDevice ' + this.device_guid + ' runCommand', command, data)
    
    this.command = command
    this.command_status = 'issued'
    this.command_message = 'Command Issued'
    
    // validSessionCheck
    store.dispatch('user/validSessionCheck').then(() => {
      var commandObject = {'command': command}
      
      // append data if passed in
      commandObject.data = data
      
      Vue.axios.post('/devices/' + this.device_guid + '/commands', commandObject).then((response) => {
        console.log('VideonDevice ' + this.device_guid + ' runCommand response', response)
        this.last_api_request_id = response.data.api_request_id
        
        this.command_status = 'accepted'
        this.command_message = 'Command Accepted'
        this.commandGUID = response.data.command_guid
        
        if (this.commandGUID) {
          this.commandResponseTimer = setTimeout(() => {
            this.commandResponseCheckAttempt++
            this.checkDeviceCommand(this.commandGUID)
          }, this.commandResponseCheckInterval)
        }
      }).catch((error) => {
        console.log('VideonDevice ' + this.device_guid + ' runCommand error', Vue.helpers.parseError(error))
        this.command_status = 'error'
        this.command_message = Vue.helpers.parseError(error)
      })
    }).catch((error) => {
      this.command_status = 'error'
      this.command_message = Vue.helpers.parseError(error)
    })
  }
  
  checkDeviceCommand(command_guid) {
    if (!this.device_guid || !command_guid) {
      return
    }
    
    Vue.axios.get('/devices/' + this.device_guid + '/commands/' + command_guid)
    .then((response) => {
      console.log('VideonDevice ' + this.device_guid + ' checkDeviceCommand response', response)
      this.last_api_request_id = response.data.api_request_id
      
      if (response.data.command.finished === true) {
        console.log('VideonDevice ' + this.device_guid + ' checkDeviceCommand finished', response.data.command)
        this.commandGUID = false
        
        if (response.data.command.response && response.data.command.response.result) {
          var result = response.data.command.response.result
          console.log('VideonDevice ' + this.device_guid + ' checkDeviceCommand result', result)
          
          this.command_status = result.state.toLowerCase()
          this.command_message = result.message
          
          if (this.command_status == 'complete') {
            // sync state after 1s
            setTimeout(() => {
              this.syncDocuments(true)
            }, 1000)
            
            if (this.command == 'update_cloud' || this.command == 'update_device') {
              // update the updates object
              this.checkForUpdates()
            }
          }
        }
      } else {
        // poll until we get updated data, or we timeout...
        if (this.commandResponseCheckAttempt < this.commandResponseCheckMaxAttempts) {
          this.commandResponseTimer = setTimeout(() => {
            this.commandResponseCheckAttempt++
            this.checkDeviceCommand(command_guid)
          }, this.commandResponseCheckInterval)
        } else {
          console.log('VideonDevice ' + this.device_guid + ' checkDeviceCommand did not complete')
          this.command_status = 'error'
          this.command_message = 'No command response in ' + ((this.commandResponseCheckAttempt * this.commandResponseCheckInterval) / 1000) + ' seconds...'
          this.commandGUID = false
        }
      }
    }).catch((error) => {
      console.log('VideonDevice ' + this.device_guid + ' checkDeviceCommand error', Vue.helpers.parseError(error))
    }) 
  }
  
  resetCommandState() {
    if (!this.isCommandProcessing() && !this.commandGUID) {
      console.log('VideonDevice ' + this.device_guid + ' resetCommandState')
      this.command_status = ''
      this.command_message = ''
    }
  }
  
  async requestInputPreview(force = false) {
    if (!this.device_guid) {
      return true
    }
    
    // if the window doesnt have focus, dont request...
    if (!force && !document.hasFocus()) {
      return true
    }
    
    // we need to filter requestInputPreview through the device as to not send too many concurrent commands
    if (this.lastInputPreviewRequestAt && new Date().getTime() < (this.lastInputPreviewRequestAt + (this.inputPreviewDuration * 1000))) {
      return true
    }
    
    this.lastInputPreviewRequestAt = new Date().getTime()
    
    var commandObject = {'command': 'send_input_preview', 'data': {'duration': this.inputPreviewDuration, 'sample_interval': this.inputPreviewInterval}}
    
    try {
      let response = await Vue.axios.post('/devices/' + this.device_guid + '/commands', commandObject)
      console.log('VideonDevice ' + this.device_guid + ' requestInputPreview response', response)
      return true
    } catch (error) {
      console.log('VideonDevice ' + this.device_guid + ' requestInputPreview error', Vue.helpers.parseError(error))
    }
    
    return false
  }
  
  hasMetadataKey(key) {
    if (this.metadata && this.metadata.length > 0) {
      return (this.metadata.find(data => data.key === key && data.value)) ? true : false
    }
    return false
  }
  
  metadataValue(key) {
    if (this.metadata && this.metadata.length > 0) {
      var data = this.metadata.find(data => data.key === key)
      if (data) {
        return data.value
      }
    }
    return false
  }
  
  inputsState() {
    var inputsShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Inputs')
    if (inputsShadow.reported) {
      return inputsShadow.reported.state
    } 
    return []
  }
  
  outputsState() {
    var outputsShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Outputs') 
    if (outputsShadow.reported) {
      return outputsShadow.reported.state.filter(output => (Vue.helpers.outputStatus(output).configured === true && output.type != 'thumbnail'))
    } 
    return []
  }
  
  encodersState() {
    var encodersShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Encoders') 
    if (encodersShadow.reported) {
      return encodersShadow.reported.state
    } 
    return []
  }
  
  encoderByID(id) {
    var encoders = this.encodersState()
    return encoders.find(x => (x.id == id))
  }
  
  systemState() {
    var systemShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'System') 
    if (systemShadow.reported) {
      return systemShadow.reported.state
    } 
    return false
  }
  
  outputsChain() {
    var outputs = []
    var inputs = []
    var encoders = []
    
    // we need to work on editableState, not the actual objects as to avoid messing with cache...
    var inputsShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Inputs')
    if (inputsShadow.reported) {
      inputs = inputsShadow.editableStateCopy()
    }
    
    var encodersShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Encoders')
    if (encodersShadow.reported) {
      encoders = encodersShadow.editableStateCopy()
    }
    
    var outputsShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Outputs') 
    if (outputsShadow.reported) {
      var outputState = outputsShadow.editableStateCopy().filter(output => (Vue.helpers.outputStatus(output).configured === true && output.type != 'thumbnail'))
      
      outputs = outputState.map((output) => {
        output.encoders = []
        
        if (output.config.sources.video) {
          output.encoders = output.encoders.concat(encoders.filter(encoder => output.config.sources.video.indexOf(encoder.id) !== -1))
        }
        
        if (output.config.sources.audio) {
          output.encoders = output.encoders.concat(encoders.filter(encoder => output.config.sources.audio.indexOf(encoder.id) !== -1))
        }
        
        if (output.config.sources.data) {
          output.encoders = output.encoders.concat(encoders.filter(encoder => output.config.sources.data.indexOf(encoder.id) !== -1))
        }
        
        output.inputs = inputs.filter(input => output.encoders.findIndex(encoder => encoder.config.in_channel_id == input.id) !== -1)
        
        return output
      })
    }
    
    return outputs
  }
  
  
  outputColumnView() {
    var outputsChain = this.outputsChain()
    
    var rows = {}
    
    for (var output of outputsChain) {
      if (output.inputs && output.inputs.length > 0 && output.inputs[0].id) {
        if (output.inputs.length > 0 && !rows[output.inputs[0].id]) {
          rows[output.inputs[0].id] = {'inputs': [], 'encoders': [], 'outputs': []}
        }
      
        for (var input of output.inputs) {
          if (!rows[output.inputs[0].id].inputs.find(x => x.id == input.id)) {
            rows[output.inputs[0].id].inputs.push(input)
          }
        }
        
        for (var encoder of output.encoders) {
          if (!rows[output.inputs[0].id].encoders[encoder.type]) {
            rows[output.inputs[0].id].encoders[encoder.type] = []
          }
          
          if (!rows[output.inputs[0].id].encoders[encoder.type].find(x => x.id == encoder.id)) {
            rows[output.inputs[0].id].encoders[encoder.type].push(encoder)
          }
        }
        
        rows[output.inputs[0].id].outputs.push(output)
      }
      
    }
    
    return Object.keys(rows).map((id) => rows[id])
  }
  
  
  inputsChain() {
    var outputsChain = this.outputsChain()
    
    var inputs = {}
    
    for (var output of outputsChain) {
      for (var encoder of output.encoders) {
        inputs[encoder.config.in_channel_id] = output.inputs.find(input => input.id == encoder.config.in_channel_id)
        
        if (!encoder.outputs) {
          encoder.outputs = {}
        }
        encoder.outputs[output.id] = output
        
        if (!inputs[encoder.config.in_channel_id].encoders) {
          inputs[encoder.config.in_channel_id].encoders = {}
        }
        inputs[encoder.config.in_channel_id].encoders[encoder.id] = encoder
        
      }
      
      // delete these arrays to avoid circular references
      delete output.encoders
      delete output.inputs
    }
    
    return Object.keys(inputs).map((id) => inputs[id])
  }
  
  
  
  updateStreamingStatus() {
    if (!this.state) {
      this.streaming_status = 5
    } else if (!this.state.online) {
      this.streaming_status =  4
    } else if (this.outputsState().filter(output => Vue.helpers.outputStatus(output).streaming === false && Vue.helpers.outputStatus(output).enabled === true).length > 0) {
      this.streaming_status =  0
    } else if (this.outputsState().filter(output => Vue.helpers.outputStatus(output).streaming === true).length > 0) {
      this.streaming_status =  1
    } else if (this.outputsState().filter(output => Vue.helpers.outputStatus(output).streaming === false).length > 0) {
      this.streaming_status =  2
    } else if (this.outputsState().filter(output => Vue.helpers.outputStatus(output).streaming === false).length == 0) {
      this.streaming_status =  3
    }
    return this.streaming_status
  }
  
  thumbnailEnabled(index = 0) {
    if (index < 0) {
      return false
    }
    
    var outputsShadow = Vue.deviceShadows.getDeviceShadow(this.device_guid, 'Outputs') 
    
    if (outputsShadow.reported) {
      // sort thumbnail outputs by id
      var thumbnailOutputs = outputsShadow.reported.state.filter(output => (output.type == 'thumbnail')).sort(function(a, b) {return a.id - b.id})
      
      if (thumbnailOutputs.length > 0 && thumbnailOutputs[index]) {
        if (Vue.helpers.outputStatus(thumbnailOutputs[index]).configured === true && Vue.helpers.outputStatus(thumbnailOutputs[index]).enabled === true) {
          return true
        }
      }
    }
    
    return false
  }
}

export default new class VideonDevices {
  constructor() {
    this.devices = []
  }
  
  reset() {
    console.log('VideonDevices reset')
    
    // invalidate device_guids
    for (var x in this.devices) {
      console.log('VideonDevices reset invalidating', this.devices[x].device_guid)
      this.devices[x].delete()
    }
    
    // reset array
    this.devices.splice(0)
  }
  
  invalidateDevice(device_guid) {
    for (var i = this.devices.length - 1; i >= 0; --i) {
      if (this.devices[i].device_guid == device_guid) {
        console.log('VideonDevices invalidateDevice', device_guid)
        this.devices[i].delete()
        this.devices.splice(i,1)
        return
      }
    }
  }
  
  deviceBySerial(serial_number) {
    var device = this.devices.find(x => x.serial_number === serial_number)
    if (device) {
      return device
    }
    
    return false
  }
  
  getDevice(device_guid, device_attributes = false) {
    if (!device_guid) {
      console.log('Devices getDevice missing device_guid', device_guid, device_attributes)
      return false
    }
    
    var device = this.devices.find(x => x.device_guid === device_guid)
    if (device) {
      // set the org_guid if we dont have one already
      if (!device.org_guid && device_attributes.org_guid) {
        device.org_guid = device_attributes.org_guid
      }
      return device
    }
    
    device = Vue.observable(new VideonDevice(device_guid, device_attributes))
    this.devices.push(device)
    
    return device
  }
}