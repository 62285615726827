<template>
  <div>
    <v-dialog
      v-model="showCropImage"
      max-width="400px">
    
      <videon-card heading="Edit Profile Picture" showClose="true" @close="showCropImage = false">
        <v-card-text>
          
          <v-progress-linear v-if="loading" indeterminate />
          
          <cropper
            class="cropper"
            ref="cropper"
            :src="profileImage"
            :stencil-props="{
              aspectRatio: 1
            }"
            stencil-component="circle-stencil"
          />
          
          <div class="text-right mt-3">
            <v-btn color="primary" @click="cropImage($event)" :disabled="loading">
              Crop Picture
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <div class="text-center">
      <div class="mb-2">
        <v-avatar size="64">
          <user-avatar :size="64" :src="avatar" />
        </v-avatar>
      </div>

      <v-btn x-small outlined color="secondary" class="ma-1" @click="$refs.file.click()">
        <input type="file" ref="file" @change="loadImage($event)" accept="image/**" v-show="false" />
        Upload Picture
      </v-btn>
    </div>
  </div>
</template>

<script>
  import 'vue-advanced-cropper/dist/style.css'
  
  import { mapGetters } from 'vuex'
  import { Cropper, CircleStencil } from 'vue-advanced-cropper'

  import Vue from 'vue'
  Vue.use('circle-stencil', CircleStencil)

  export default {
    name: "EditAvatar",

    data() {
      return {
        loading: false,
        
        showCropImage: false,
        
        profileImage: require("../../assets/icons/icon-On-site Client-48.svg")
      }
    },

    components: {
      Cropper
    },

    computed: {
      ...mapGetters('user', ['avatar']),
      ...mapGetters('config', ['AmplifyConfig'])
    },

    methods: {
      loadImage(e) {
        var { files } = e.target;
      
        if (files && files[0]) {
          if (this.profileImage) {
            URL.revokeObjectURL(this.profileImage)
          }
          
          this.profileImage = URL.createObjectURL(files[0])
          
          this.showCropImage = true
        }
      },

      async cropImage() {
        var { canvas } = this.$refs.cropper.getResult()
        
        if (canvas) {
          this.loading = true
          
          var dataURI = canvas.toDataURL("image/png")
          var blobData = this.$helpers.dataURItoBlob(dataURI)
          
          await this.$Amplify.Storage.put("profile.png", blobData, {
            level: "protected"
          })
          
          var { identityId } = await this.$Amplify.Auth.currentUserCredentials()
          
          var objectURL = 'https://' + this.AmplifyConfig.AWSS3.bucket + '.s3.' + this.AmplifyConfig.AWSS3.region + '.amazonaws.com/protected/' + identityId + '/profile.png?ts=' + new Date().getTime()
          
          this.$emit("image-uploaded", objectURL)
        }
        
        this.loading = false
        this.showCropImage = false
      }
    }
  }
</script>

<style scoped>
  .cropper {
    height: 300px;
  }
</style>