<template>
  <span>
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="640px"
    >
      <videon-card :heading="device.deviceName() + ' Metadata'" showClose="true" @close="showControl = false">
        <v-card-text>
          <div class="my-5 mx-5 mt-8">
            <v-row v-for="(data, index) in metadata" v-bind:key="index" no-gutters align="center" class="my-3">
              <v-col cols="5">
                <div
                  v-if="!data.isNew"
                  class="text-right mr-3 subtitle-1"
                >
                  {{ $helpers.getLabel(data.key) }}
                </div>
                
                <v-combobox
                  v-else
                  class="ma-1"
                  v-model="data.key"
                  :items="metadataKeyValues"
                  :return-object="false"
                  @change="data.value = ''"
                  label="Key"
                  outlined
                  hide-details
                  :disabled="!canEdit"
                ></v-combobox>
              </v-col>
              <v-col cols="7">
                <v-select
                  class="ma-1"
                  v-if="data.key == 'deployment_status'"
                  v-model="data.value"
                  :items="deploymentStatusValues"
                  outlined
                  hide-details
                  :disabled="!canEdit"
                ></v-select>
                
                <v-textarea
                  v-else-if="data.key == 'note'"
                  class="ma-1"
                  v-model="data.value"
                  :rules="[v => (v || '' ).length <= 200 || 'Description must be 200 characters or less']"
                  outlined
                  counter="200"
                  hide-details="auto"
                  :disabled="!canEdit"
                ></v-textarea>
                
                <v-text-field
                  v-else
                  class="ma-1"
                  v-model="data.value"
                  outlined
                  hide-details
                  :disabled="!canEdit"
                ></v-text-field>
                
              </v-col>
            </v-row>
            <div class="text-right mt-3">
              <v-btn
                x-small
                outlined
                @click="metadata.push({'key': null, 'value': null, 'isNew': true})"
                :disabled="!canEdit"
              >
                Add Data
              </v-btn>
            </div>
            
          </div>
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showControl = false"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ma-1"
              @click="updateMetadata"
              :disabled="loading || !canEdit"
              :loading="loading"
            >
              Save
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-btn
      text
      small
      class="ma-1"
       @click="showDialog()"
    >
      <v-icon>list</v-icon> Metadata
    </v-btn>
  </span>
</template>

<script>
 export default {
    name: 'DeviceMetadataEditor',
    
    props: ['device'],
    
    data() {
      return {
        showControl: false,
        
        loading: false,
        
        metadata: [],
        
        deploymentStatusValues: [
          { 'value': 'in_transit', 'text': 'In Transit' },
          { 'value': 'not_deployed', 'text': 'Not Deployed' },
          { 'value': 'testing', 'text': 'Testing' },
          { 'value': 'production', 'text': 'Production' },
          { 'value': '', 'text': 'Not Defined'}
        ]
      }
    },
    
    mounted() {
      
    },
    
    computed: {
      canEdit() {
        return this.device.canEdit()
      },
      
      metadataKeyValues() {
        var keyValues = [
          { 'value': 'deployment_status', 'text': 'Deployment Status' },
          { 'value': 'device_alias', 'text': 'Device Alias' },
          { 'value': 'location', 'text': 'Location' },
          { 'value': 'note', 'text': 'Note' },
        ]
        // dont return items that exist already
        return keyValues.filter(item => !this.metadata.find(data => data.key == item.value))
      }
    },
    
    watch: {
      
    },
    
    methods: {
      showDialog() {
        if (this.device.metadata) {
          this.metadata = JSON.parse(JSON.stringify(this.device.metadata))
        } else {
          this.metadata = []
        }
        
        this.showControl = true
      },
      
      updateMetadata() {
        var invalidData = false
        for (var data of this.metadata) {
          if (data.key == 'note' && data.value.length > 200) {
            invalidData = true
          }
        }
        
        if (invalidData) {
          return
        }
        
        this.loading = true
        
        var metadata = this.metadata.filter((data) => {
          if (data.key) {
            return data
          }
        }).map((data) => {
          delete data.isNew
          return data
        })
        
        console.log('DeviceMetadataEditor updateMetadata ' + this.device.device_guid, metadata)
        
        // post to outputs endpoint
        this.axios.post('/devices/' + this.device.device_guid + '/metadata', metadata)
        .then((response) => {
          console.log('DeviceMetadataEditor updateMetadata ' + this.device.device_guid + ' response', response)
          
          // fetch device state after waiting 5s
          setTimeout(() => {
            this.loading = false
            this.showControl = false
            
            this.device.fetchMetadata()
          }, 500)
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          console.log('DeviceMetadataEditor updateMetadata ' + this.device.device_guid + ' error', error)
        })
      }
    }
  }
</script>