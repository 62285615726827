<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">SRT Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" multiple v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
      <v-col v-if="config.sources.data !== undefined" cols="12">
        <encoder-selector :device_guid='device_guid' type="data" :canEdit="canEdit" multiple v-model="config.sources.data" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="6">
        <v-select
          v-model="config.call_mode"
          :items="call_modeValues"
          label="Call Mode"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <div v-if="config.call_mode == 'CALLER'">
      <v-row class="mb-5">
        <v-col cols="9">
          <v-text-field
            v-model="config.destination_ip"
            label="Destination Address"
            @input="configChanged"
            :disabled="!canEdit"
            :rules="$validations.destinationAddressValidation"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model.number="config.destination_port"
            label="Port"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            :rules="$validations.portValidation"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <div v-if="config.call_mode == 'LISTENER'">
      <v-row class="mb-5">
        <v-col cols="3">
          <v-text-field
            v-model.number="config.destination_port"
            label="Listen Port"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            :rules="$validations.portValidation"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col lg="3" cols="6">
        <v-text-field
          v-model.number="config.latency"
          label="Latency (ms)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col lg="4" cols="6">
        <v-text-field
          v-model.number="config.bw_overhead"
          label="Bandwidth Overhead (%)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="$validations.percent"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col lg="7" cols="12">
        <v-text-field
          v-model="config.stream_id"
          label="Stream ID"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row :class="(!config.encryption_enabled) ? 'mb-3' : ''">
      <v-col cols="12">
        <v-checkbox
          v-model="config.encryption_enabled"
          label="Enable Encryption"
          @change="configChanged"
          hide-details
          class="mt-0"
          :disabled="!canEdit"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.encryption_enabled">
      <v-row class="mb-3">
        <v-col cols="3">
          <v-select
            v-model="config.key_size"
            :items="key_sizeValues"
            label="Key Size (bits)"
            @change="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-select>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="config.passphrase"
            autocomplete="new-password"
            type="password"
            label="Passphrase"
            :rules="$validations.passphraseValidation"
            @input="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-alert v-if="isNew && outputLimitReached" type="error" outlined>Unable to add another SRT output. This device is licensed for {{ featureValue('daemon.output.srt.limit') }} instance(s).</v-alert>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'SRTOutputDocument',
    
    props: ['output', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        key_sizeValues: [
          { 'value': 'AES128', 'text': '128' },
          { 'value': 'AES192', 'text': '192' },
          { 'value': 'AES256', 'text': '256' },
        ],
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        config: {
          enable: false,
          
          call_mode: 'CALLER',
          
          stream_id: '',
          
          destination_ip: '',
          destination_port: 1000,
          
          latency: 120,
          bw_overhead: 25,
          
          sources: {
            audio: [],
            video: []
          },
          
          encryption_enabled: false,
          key_size: 'AES128',
          passphrase: '',
        },
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      call_modeValues() {
        var callModes = []
        
        if (this.hasFeature('daemon.output.srt.caller.enabled')) {
          callModes.push({ 'value': 'CALLER', 'text': 'Caller' })
        }
        if (this.hasFeature('daemon.output.srt.listener.enabled')) {
          callModes.push({ 'value': 'LISTENER', 'text': 'Listener' })
        }
        
        return callModes
      },
      
      outputLimitReached() {
        return !this.hasFeature('daemon.output.srt.limit', this.instanceCountByType('srt'))
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        if (this.config.enable == true) {
          var ipValidation = this.$validations.isValidIpAddress(this.config.destination_ip)
          var hostnameValidation = this.$validations.isValidHostname(this.config.destination_ip)
          
          // check for validation errors
          if (this.config.call_mode == 'CALLER' && !ipValidation) {
            if (!hostnameValidation) {
              console.log('SRTOutputDocument ' + this.device_guid + ' validation error: Destination Address must be a valid IP address or hostname', this.config)
              validationErrors = true
            }
          }
          
          if (this.config.destination_port < 1000 || this.config.destination_port > 65000) {
            console.log('SRTOutputDocument ' + this.device_guid + ' validation error: Port must be between 1000 and 65000', this.config)
            validationErrors = true
          }
        
          if (this.config.bw_overhead < 0 || this.config.bw_overhead > 100) {
            console.log('SRTOutputDocument ' + this.device_guid + ' validation error: Percentages must be between 0 and 100', this.config)
            validationErrors = true
          }
          
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        if (this.isNew && !this.hasFeature('daemon.output.srt.limit', this.instanceCountByType('srt'))) {
          console.log('SRTOutputDocument ' + this.device_guid + ' validation error: Output instance limit reached', this.config)
          validationErrors = true
        }
        
        console.log('SRTOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // show data encoder if creating new
        if (this.isNew) {
          if (this.config.sources.data === undefined) {
            this.$set(this.config.sources, 'data', [])
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('SRTOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>