<template>
  <v-card flat>
    <v-data-table
      v-model="selection"
      item-key="device_guid"
      :show-select="showSelect"
      :headers="headers"
      :items="items"
      :loading="loading"
      :loadingText="loadingText"
      no-data-text="No devices"
      :items-per-page="1000"
      :search="filter"
      sort-by="device_name"
      must-sort
      hide-default-footer
    >
      <template v-slot:item.device_name="{ item }">
        <device-label :device="item" />
      </template>
      <template v-slot:item.streaming_status="{ item }">
        <streaming-status-label :device="item" />
      </template>
      <template v-slot:item.device_ip="{ item }">
        <v-tooltip 
          open-on-hover
          open-delay="500"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
            >
              {{ item.device_ip }}
            </span>
          </template>
          <span v-if="item.state.online">External IP: {{ item.external_ip }}</span>
          <span v-else>Device Offline</span>
        </v-tooltip>
      </template>
      <template v-slot:item.firmware_version="{ item }">
        <firmware-version-label :device="item" />
      </template>
      <template v-slot:item.metadata="{ header, item }">
        <span v-if="header.key && item.hasMetadataKey(header.key)">{{  $helpers.getLabel(item.metadataValue(header.key)) }}</span>
        <span v-else-if="!header.key">
          <v-chip
            small
            class="mr-1"
            label
            v-for="(data, index) in item.metadata"
            v-bind:key="index"
            outlined
          >
            <device-note v-if="data.key == 'note'" :device="item" />
            <span v-else>
              <strong>{{ $helpers.getLabel(data.key) }}</strong>: {{ $helpers.getLabel(data.value) }}
            </span>
          </v-chip>
        </span>
      </template>
      <template v-slot:item.state="{ header, item }">
        <span v-if="header.key && item.state && item.state[header.key]">{{  $helpers.getLabel(item.state[header.key]) }}</span>
      </template>
    </v-data-table>
    <v-card-actions v-if="selection && selection.length > 0" class="px-0">
      <v-sheet
        rounded
        elevation="2"
        class="py-1 px-3"
        width="100%"
      >
        <span class="subtitle-2 mr-2">Actions</span>
        
        <span v-if="actionGroup == 'devices'">
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="deleteSelection()"
            :disabled="!canEdit"
          >
            Forget Device(s)
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="outputControl()"
            :disabled="!canEdit"
          >
            Output Control
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="firmwareUpdate()"
            :disabled="!canEdit"
          >
            Update Firmware
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="containerInstall()"
            :disabled="!canEdit"
          >
            Install Container
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1 red--text"
            outlined
            @click="rebootDevices()"
            :disabled="!canEdit"
          >
            Reboot Device(s)
          </v-btn>
        </span>
        <span v-if="actionGroup == 'fleet'">
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="deleteSelection()"
            :disabled="!canEdit"
          >
            Remove Device(s)
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="outputControl()"
            :disabled="!canEdit"
          >
            Output Control
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="firmwareUpdate()"
            :disabled="!canEdit"
          >
            Update Firmware
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1"
            outlined
            @click="containerInstall()"
            :disabled="!canEdit"
          >
            Install Container
          </v-btn>
          
          <v-btn
            x-small
            class="ma-1 red--text"
            outlined
            @click="rebootDevices()"
            :disabled="!canEdit"
          >
            Reboot Device(s)
          </v-btn>
        </span>
      </v-sheet>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceList',
    
    props: ['loading', 'loadingText', 'value', 'filter', 'actionGroup', 'canEdit'],
    
    data() {
      return {
        error: false,
        errorText: '',
        
        items: [],
        selection: [],
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'organization']),
      ...mapGetters('userPreferences', ['deviceListLayout']),
      
      showSelect() {
        if (this.actionGroup && this.canEdit) {
          return true
        }
        return false
      },
      
      headers() {
        var sortableItems = [
          'streaming_status',
          'device_ip',
          'external_ip',
          'firmware_version',
          'serial_number',
          'mac_address'
        ]
        
        var headers = [
          { text: 'Device', align: 'start', value: 'device_name', sortable: true }
        ]
        
        for (var index in this.deviceListLayout) {
          var layout = this.deviceListLayout[index]
          
          var thisHeader = {
            align: layout.class,
            value: layout.component,
            sortable: sortableItems.includes(layout.component) ? true : false
          }
          
          if (layout.component == 'metadata' && layout.key) {
            thisHeader.text = this.$helpers.getLabel(layout.key)
          } else {
            thisHeader.text = this.$helpers.getLabel(layout.component)
          }
          
          if (layout.key) {
            thisHeader.key = layout.key
          }
          
          headers.push(thisHeader)
        }
        
        return headers
      }
    },
    
    watch: {
      value() {
        this.items = this.value
        this.selection = []
      },
      selection() {
        this.selectionChanged()
      },
    },
    
    mounted() {
      if (this.value) {
        this.items = this.value
      }
      this.selection = []
    },
    
    methods: {
      selectionChanged() {
        this.$emit('selectionChanged', this.selection)
      },
      deleteSelection() {
        this.$emit('deleteSelection', this.selection)
      },
      outputControl() {
        this.$emit('outputControl', this.selection)
      },
      firmwareUpdate() {
        this.$emit('firmwareUpdate', this.selection)
      },
      containerInstall() {
        this.$emit('containerInstall', this.selection)
      },
      rebootDevices() {
        this.$emit('rebootDevices', this.selection)
      }
    } 
  }
</script>