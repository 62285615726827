<template>
  <v-card 
    class="mx-auto"
  > 
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        Product Capabilities
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-tabs
      v-model="section"
      color="secondary"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="(profile, index) in profiles"
        :key="index"
        :href="'#' + profile.product_name"
      >
        {{ profile.displayName() }}
      </v-tab>
    </v-tabs>
    
    <v-divider/>
    
    <v-card-text>
      <v-tabs-items v-model="section">
        <v-tab-item 
          v-for="(profile, index) in profiles"
          :key="index"
          :value="profile.product_name"
        >
          <v-card 
            v-if="profile.hardware"
            flat
          >
            <v-card-title>Hardware</v-card-title>
            <v-card-text>
              <hardware-capabilities :capabilities="profile" />
            </v-card-text>
          </v-card>
          <software-capabilities :capabilities="profile" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script> 
  import HardwareCapabilities from '../common/capabilities/HardwareCapabilities.vue'
  import SoftwareCapabilities from '../common/capabilities/SoftwareCapabilities.vue'
  
  export default {
    name: 'Capabilities',
    components: {
      HardwareCapabilities,
      SoftwareCapabilities
    },
    data() {
      return {
        section: false,
        
        error: false,
        errorText: '',
        
        profiles: []
      }
    },
    mounted() {
      this.profiles = this.$capabilities.allProfiles()
    },
    watch: {

    },
    computed: {
      
    },
    methods: {
      
    }
  }
</script>