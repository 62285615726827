<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Audio Encoder</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Encoder Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <div v-if="isDynamicSupported">
      <v-row><h5 class="text--disabled">Input Selection</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-5">
        <v-col lg="12" cols="12">
          <input-selector :device_guid='device_guid' :canEdit="canEdit" v-model="config.in_channel_id" @change="configChanged" />
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Encoder Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-select
          v-model="config.codec"
          :items="codecValues"
          label="Audio Encoding"
          @change="configChanged"
          :disabled="!canChangeCodec"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row class="mb-3">
      <v-col cols="6">
        <v-select
          v-model="config.bitrate_mode"
          :items="bitrate_modeValues"
          label="Encoding Mode"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model.number="config.bitrate"
          :items="bitrateValues"
          label="Audio Bitrate (kbps)"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Audio Channel Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    
    
    <v-row>
      <v-col 
        cols="6" 
        lg="3"
        v-for="(index) of featureValue('daemon.input.audio.max_channels', 8)"
        v-bind:key="index"
      >
        <v-checkbox
          v-model="config.selected_channels"
          :label="'Channel ' + index"
          :value="index"
          @change="configChanged"
          :disabled="!canEdit"
          :rules="channelSelectionValidation"
          multiple
          :hide-details="(index == featureValue('daemon.input.audio.max_channels', 8)) ? 'auto' : 'true'"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <div class="mb-3">&nbsp;</div>
    
    <v-alert v-if="isNew && encoderLimitReached" type="error" outlined>Unable to add another audio encoder. This device is licensed for {{ featureValue('daemon.encoders.audio.limit') }} audio encoder instance(s).</v-alert>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Encoder Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ encoder }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteEncoder()"
      >
        Delete Encoder
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import InputSelector from '../components/InputSelector.vue'
  
  export default {
    name: 'AudioEncoderDocument',
    
    props: ['encoder', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      InputSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        opusDaemonVersion: this.$helpers.parseVersion('device', 'v10.1.0'),
        
        valid: true,
        
        id: false,
        seconds_in_running: 0,
        
        config: {
          active: false,
          in_channel_id: -1,
          
          mix_mode: 'STEREO',
          sample: 'SAMPLE_48_khz',
          
          name: '',
          
          codec: 'mpeg4_aac',
          bitrate_mode: 'variable',
          bitrate: 128,
        
          selected_channels: [1, 2]
        },

        // validations
        channelSelectionValidation: [
          v => (v.length <= this.featureValue('daemon.encoders.audio.max_channels', 6)) || 'Only ' + this.featureValue('daemon.encoders.audio.max_channels', 6) + ' channels are allowed per Audio Profile'
        ]
      }
    },
    
    watch: {
      encoder() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      isDynamicSupported() {
        return this.$versioning.isDynamicSupported(this.device)
      },
      
      canChangeCodec() {
        if (!this.canEdit || (this.isDynamicSupported && !this.isNew)) {
          return false
        }
        return true
      },
      
      codecValues() {
        var entitledValues = this.featureValue('daemon.encoders.audio.supported_codecs')
        
        var codecValues = []
        
        if (entitledValues && entitledValues.includes('MPEG4_AAC_ADTS')) {
          codecValues.push({ 'value': 'mpeg4_aac', 'text': 'MPEG-4 AAC-LC' })
        }
          
        if (this.$helpers.versionCheck(this.opusDaemonVersion, this.device.daemon_version) && entitledValues && entitledValues.includes('Opus')) {
          codecValues.push({ 'value': 'opus', 'text': 'Opus' })
        }
        
        return codecValues
      },
      
      bitrateValues() {
        var bitrateValues = [
          { 'value': 64, 'text': '64' },
          { 'value': 96, 'text': '96' },
          { 'value': 128, 'text': '128' },
          { 'value': 192, 'text': '192' },
          { 'value': 256, 'text': '256' },
          { 'value': 320, 'text': '320' },
          { 'value': 384, 'text': '384' },
          { 'value': 512, 'text': '512' }
        ]
        
        if (this.config.codec == 'mpeg4_aac') {
          bitrateValues.push({ 'value': 576, 'text': '576' })
        }
        
        return bitrateValues
      },
      
      bitrate_modeValues() {
        return  [
          { 'value': 'constant', 'text': 'Constant Bitrate' },
          { 'value': 'variable', 'text': 'Variable Bitrate' },
        ]
      },
      
      encoderLimitReached() {
        return !this.hasFeature('daemon.encoders.audio.limit', this.instanceCountByType('audio'))
      }
      
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        // check for validation errors
        if (this.config.selected_channels.length > this.featureValue('daemon.encoders.audio.max_channels', 6)) {
          console.log('AudioEncoderDocument ' + this.device_guid + ' validation error: Only ' + this.featureValue('daemon.encoders.audio.max_channels', 6) + ' channels are allowed per Audio Profile', this.config)
          validationErrors = true
        }
        
        if (this.isNew && !this.hasFeature('daemon.encoders.audio.limit', this.instanceCountByType('audio'))) {
          console.log('AudioEncoderDocument ' + this.device_guid + ' validation error: Encoder instance limit reached', this.config)
          validationErrors = true
        }
        
        console.log('AudioEncoderDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.encoder && this.encoder.config) {
          Object.keys(this.encoder).map((key) => {
            this[key] = this.encoder[key]
          })
          
          // htmlDecode name properties
          if (this.config.name) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteEncoder() {
        console.log('AudioEncoderDocument ' + this.device_guid + ' deleteEncoder')
        this.$emit('deleteEncoder')
      }
    }
  }
</script>