<template>
  <v-list-item 
    @click="showDialog()"
    :disabled="!device.isOnline()"
  >
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="600px"
    >
      <videon-card heading="Update Docker Container" showClose="true" :ignoreHeaderMargin="!isVersionSupported" @close="showControl = false">
        <v-alert
          text
          color="orange"
          icon="mdi-alert-outline"
          v-if="!isVersionSupported"
        >
          This feature is not supported on {{ this.device.cloud_version.original }}, please update your cloud agent.
        </v-alert>
        <v-card-text>
          <p>
            <strong>This is a Videon Device Admin command and is not available to regular users.</strong><br/>
            This command also requires that the docker python dependency is installed via firmware release, or "<b class="primary--text">pip install docker</b>" on the device.
          </p>
          <p class="red--text">
            <b>This function has been implemented for the Nimbra Edge container only.</b>
          </p>
          <div v-if="processing">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
              class="mb-3"
            ></v-progress-linear>
          </div>
          <v-form @submit.prevent="updateContainer">
            <div v-if="device.command_status" class="mb-5">
              <h3>Command Progress</h3>
              <div dark>
                <pre style="white-space: pre-wrap;">Command Status: {{ device.command_status }}</pre>
                <pre style="white-space: pre-wrap;">Command Message: {{ device.command_message }}</pre>
              </div>
            </div>
            
            <h4 class="mb-2">Update Parameters</h4>
            <v-text-field
              v-model="username"
              label="DockerHub Username"
              name="dockerhub-username"
              autocomplete="username"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="password"
              label="DockerHub Password"
              name="dockerhub-password"
              autocomplete="new-password"
              type="password"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="version_tag"
              label="Version Tag"
              autocomplete="off"
              required
              outlined
            ></v-text-field>
            <v-text-field
              v-model="container_name"
              label="Container Name"
              autocomplete="off"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showControl = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="updateContainer"
                :disabled="processing || !isVersionSupported"
              >
                Update
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="pr-2">
      <v-icon class="material-icons-outlined">cloud_download</v-icon> 
      Update Container
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    name: 'UpdateContainer',
    
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b16'), // must be 1 revision less than target
        
        error: false,
        errorText: '',
        
        showControl: false,
        
        username: '',
        password: '',
        
        version_tag: 'R3.12.0',
        container_name: 'videon-gui',
      }
    },
    mounted() {
      
    },
    computed: {
      processing() {
        if (this.device.isCommandProcessing()) {
          return true
        }
        return false
      },
      isVersionSupported() {
        if (this.device.cloud_version.dev || this.$helpers.isNewerVersion(this.minimumAgentVersion, this.device.cloud_version)) {
          return true
        }
        return false
      }
    },

    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      updateContainer() {
        this.device.runCommand('update_nimbraedge_container', {
          'username': this.username,
          'password': this.password,
          'version_tag': this.version_tag,
          'container_name': this.container_name
        })
      },
    }
  }
</script>