<template>
  <div class="d-inline-block text-center">
    <v-dialog
      v-model="showState"
      max-width="640px"
    >
      <videon-card :heading="containerName" showClose="true" @close="showState = false">
        <v-card-text class="">
          <v-row class="">
            <videon-state-tile
              heading="Description"
            >
              {{ container.description }}
            </videon-state-tile>
          </v-row>
          
          <v-row class="mt-2" v-if="container.requirements">
            <videon-state-tile
              heading="Requirements"
            >
              {{ container.requirements }}
            </videon-state-tile>
          </v-row>
          
          <v-row class="mt-2">
            <videon-state-tile
              heading="Platforms"
            >
              <ul>
                <li
                  v-for="(platform, index) in container.platforms"
                  :key="index"
                >
                  {{ $helpers.getLabel(platform) }}
                </li>
              </ul>
            </videon-state-tile>
          </v-row>
          
          <v-row v-if="container.companyName || container.companyDescription"><v-divider /></v-row>
          
          <v-row class="mt-2" v-if="container.companyName">
            <videon-state-tile
             heading="Made By"
             class="text-capitalize"
            >
             {{ container.companyName }}
            </videon-state-tile>
            <videon-state-tile
             heading="Company URL"
             v-if="container.companyUrl"
            >
             <a :href="container.companyUrl" target="_blank">{{ container.companyUrl }}</a>
            </videon-state-tile>
          </v-row>
          
          <v-row class="mt-2" v-if="container.companyDescription">
            <videon-state-tile
              heading="Company Description"
            >
              {{ container.companyDescription }}
            </videon-state-tile>
          </v-row>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-btn
      color="secondary"
      text
      small
      @click="showState = true"
    >
      Read More
    </v-btn>
  </div>
</template>

<script>
  
  export default {
    name: 'ContainerInfo',
    
    props: ['container'],
    
    computed: {
      containerName() {
        if (this.container.name) {
          return this.container.name
        }
        return 'Container'
      }
    },
    data() {
      return {
        showState: false,
      }
    }
  }
</script>