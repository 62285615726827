import Vue from 'vue'

import store from '../store'

class VideonUser {
  constructor(user_guid, user_attributes = false) {
    this.user_guid = user_guid
    this.ready = false
    
    this.name = (user_attributes) ? user_attributes.name : ''
    this.picture = (user_attributes && user_attributes.picture) ? user_attributes.picture : ''
    
    this.email = (user_attributes && user_attributes.email) ? user_attributes.email : ''
    this.email_verified = (user_attributes && user_attributes.email_verified) ? user_attributes.email_verified : false
    this.phone = (user_attributes && user_attributes.phone_number) ? user_attributes.phone_number : ''
    this.phone_verified = (user_attributes && user_attributes.phone_number_verified) ? user_attributes.phone_number_verified : false

    this.locale = (user_attributes && user_attributes.locale) ? user_attributes.locale : ''
    this.timezone = (user_attributes && user_attributes.zoneinfo) ? user_attributes.zoneinfo : ''
    this.status = (user_attributes && user_attributes.status) ? user_attributes.status : ''
    
    this.error = false
    this.errorText = ''
    
    if (user_attributes) {
      this.ready = true
      
      let myGUID = store.getters['user/guid']
      if (this.user_guid == myGUID) {
        this.me = true
      }
    } else {
      this.fetchUser()
    }
  }
  
  displayName() {
    return (this.name) ? Vue.helpers.htmlDecode(this.name) : 'loading...'
  }
  
  fetchUser() {
    // is this my amplify user?
    let myGUID = store.getters['user/guid']
    if (myGUID == this.user_guid) {
      this.me = true
      
      Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((user) => {
        console.log('VideonUser ' + this.user_guid + ' fetchUser from Amplify', user)
        
        this.name = user.attributes.name || ''
        this.email = user.attributes.email || ''
        this.email_verified = user.attributes.email_verified || false

        this.picture = user.attributes.picture || ''
        
        this.phone = user.attributes.phone_number || ''
        this.phone_verified = user.attributes.phone_number_verified || false
        
        this.locale = user.attributes.locale || ''
        this.timezone = user.attributes.zoneinfo || ''
        
        this.ready = true
      })
      
      this.ready = false
      return
    }
    
    // fetch user from API
    Vue.axios.get('/users/' + this.user_guid)
    .then((response) => {
      console.log('VideonUser ' + this.user_guid + ' fetchUser response', response.data)
      
      this.name = response.data.user.name
      this.picture = response.data.user.picture
      
      this.email = response.data.user.email
      this.email_verified = response.data.user.email_verified
      this.phone_number = response.data.user.phone_number
      this.email_verified = response.data.user.phone_number_verified
    
      this.locale = response.data.user.locale
      this.timezone = response.data.user.zoneinfo
      this.status = response.data.user.status
      
      this.ready = true
    }).catch((error) => {
      if (!this.name) {
        this.name = 'error fetching user...'
      }
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
      this.ready = true
    })
  }
  
  refresh() {
    this.fetchUser()
  }
}

export default new class VideonUsers {
  constructor() {
    this.users = []
  }
  
  reset() {
    console.log('VideonUsers reset')
    this.users.splice(0)
  }
  
  invalidateUser(user_guid) {
    for (var i = this.users.length - 1; i >= 0; --i) {
      if (this.users[i].user_guid == user_guid) {
        console.log('VideonUsers invalidateUser', user_guid)
        this.users.splice(i,1)
        return
      }
    }
  }
  
  getUser(user_guid, user_attributes = false) {
    if (!user_guid) {
      console.log('Users getUser missing user_guid', user_guid, user_attributes)
      return false
    }
    
    var user = this.users.find(x => x.user_guid === user_guid)
    if (user) {
      return user
    }
    
    user = Vue.observable(new VideonUser(user_guid, user_attributes))
    this.users.push(user)
    
    return user
  }

  getMe() {
    return this.getUser(store.getters['user/guid'])
  }
}