import Vue from 'vue'

class VideonOrganization {
  constructor(org_guid, org_attributes = false) {
    this.org_guid = org_guid
    this.ready = false

    // NOTE: Not evoking updateOrg here because we need sane
    // defaults if org_attributes isn't initialized
    
    // QUESTION: We may want to note that we assume the name
    // and access to be defined by org_attributes.
    // Or, do something like org_attributes && org_attributes.org_name

    this.org_name = (org_attributes && org_attributes.org_name)
      ? org_attributes.org_name : ''
    this.access = (org_attributes && org_attributes.access)
      ? org_attributes.access : 100
    
    this.error = false
    this.errorText = ''
    
    if (org_attributes && org_attributes.org_name && org_attributes.access) {
      this.ready = true
    } else {
      this.fetchOrg()
    }
  }
  
  displayName() {
    return (this.org_name) ? Vue.helpers.htmlDecode(this.org_name) : 'loading...'
  }
  
  fetchOrg() {
    Vue.axios.get('/orgs/' + this.org_guid)
    .then((response) => {
      console.log('VideonOrganization ' + this.org_guid + ' fetchOrg response', response.data)

      this.updateOrg(response.data.org)

      this.ready = true
    }).catch((error) => {
      if (!this.org_name) {
        this.org_name = 'error fetching org...'
      }
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
      this.ready = true
    })
  }

  updateOrg(org) {
    // NOTE: Style-wise, I'm not sure which of the following would be best.
    // 1. if (org.org_name) { this.org_name = org.org_name; }
    // 2. this.org_name = org.org_name || this.org_name;
    // 3. this.org_name = "org_name" in org ? org.org_name : this.org_name
    // Number 3 is technically different than the others, if org.org_name
    // is falsey (aka an empty string) but that shouldn't be possible.

    this.org_name = org.org_name || this.org_name
    this.access = org.access || this.access
  }
  
  refresh() {
    this.fetchOrg()
  }
  
  canEdit() {
    return (this.access > 200) ? true : false
  }
}

export default new class VideonOrganizations {
  constructor() {
    this.orgs = []
  }
  
  reset() {
    console.log('VideonOrganizations reset')
    this.orgs.splice(0)
  }
  
  invalidateOrg(org_guid) {
    for (var i = this.orgs.length - 1; i >= 0; --i) {
      if (this.orgs[i].org_guid == org_guid) {
        console.log('VideonOrganizations invalidateOrg', org_guid)
        this.orgs.splice(i,1)
        return
      }
    }
  }
  
  async allOrgs(user_guid = false) {
    var queryParams = {
      'pagination_size': 50
    }
    
    if (user_guid) {
      this.orgs = [] // reset existing org list
      queryParams.user_guid = user_guid // filter org results on user_guid
    }
    
    var paginationToken = false
    
    try {
      do {
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        let response = await Vue.axios.get('/orgs', {'params': queryParams})
        
        console.log('VideonOrganizations allOrgs response', response)
        
        if (response.data.orgs.length > 0) {
          for (var i in response.data.orgs) {
            var org = response.data.orgs[i]
            var existingOrg = this.orgs.find(x => x.org_guid === org.org_guid)

            if (existingOrg) {
              existingOrg.updateOrg(org)
            } else {
              this.orgs.push(Vue.observable(new VideonOrganization(org.org_guid, org)))
            }
          }

          // TODO: What happens if an org was removed?
          
          if (response.data.pagination_token) {
            paginationToken = response.data.pagination_token
          } else {
            paginationToken = false
          }
        }
      } while (paginationToken)
    } catch (error) {
      console.log('VideonOrganizations allOrgs error', error)
    }
    
    return this.orgs
  }
  
  getOrg(org_guid, org_attributes = false) {
    if (!org_guid) {
      console.log('Organizations getOrg missing org_guid')
      return false
    }
    
    var org = this.orgs.find(x => x.org_guid === org_guid)
    if (org && org_attributes) {
      // overwrite the access level
      if (org_attributes.access > org.access) {
        org.access = org_attributes.access
      }
      return org
    } else if (org) {
      return org
    }
    
    org = Vue.observable(new VideonOrganization(org_guid, org_attributes))
    this.orgs.push(org)
    
    return org
  }
}