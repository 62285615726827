import Vue from 'vue'

class VideonLatestVersion {
  constructor(update_type) {
    this.update_type = update_type
    this.ready = false
    
    this.version = ''
    
    this.fetchVersion()
  }
  
  fetchVersion() {
    Vue.axios.get('/devices/updates/' + this.update_type)
    .then((response) => {
      console.log('VideonLatestVersion fetchVersion response', response)
      
      this.version = response.data.version
      
      this.ready = true
    }).catch((error) => {
      console.log('VideonLatestVersion fetchVersion error', error)
    }) 
  }
}

export default new class LatestVersions {
  constructor() {
    this.versions = []
  }
  
  latestVersions() {
    var updateTypes = ['cloud']
    
    var latestVersions = []
    for (var update_type of updateTypes) {
      latestVersions.push(this.getLatestVersion(update_type))
    }
    
    return latestVersions
  }
  
  getLatestVersion(update_type) {
    if (!update_type) {
      console.log('LatestVersions getLatestVersion missing update_type')
      return false
    }
    
    var latestVersion = this.versions.find(x => x.update_type === update_type)
    if (latestVersion) {
      return latestVersion
    }
    
    latestVersion = Vue.observable(new VideonLatestVersion(update_type))
    this.versions.push(latestVersion)
    
    return latestVersion
  }
}