import Vue from 'vue'
import store from '../store'


class VideonFleet {
  constructor(fleet_guid, fleet_attributes = false) {
    this.fleet_guid = fleet_guid
    this.ready = false
    
    this.editable = false
        
    this.fleet_name = (fleet_attributes) ? fleet_attributes.fleet_name : ''
    
    this.status = false
    this.fetching = false
    
    this.devices = []
    this.itemsPerPage = 50
    
    this.users = []
    this.userInvites = []
    
    this.error = false
    this.errorText = ''
    
    if (!fleet_attributes) {
      this.fetchFleet()
    }
    
    //this.fetchDevices()
    //this.fetchStatus()
  }
  
  refresh() {
    this.fetchFleet()
    this.fetchStatus()
  }
  
  delete() {
    this.fleet_guid = false
    Vue.delete(this) // this shouldnt work.. but it does...
  }
  
  displayName() {
    return (this.fleet_name) ? Vue.helpers.htmlDecode(this.fleet_name) : 'loading...'
  }
  
  canEdit() {
    return this.editable
  }
  
  fetchFleet() {
    if (!this.fleet_guid) {
      return
    }
    Vue.axios.get('/fleets/' + this.fleet_guid)
    .then((response) => {
      console.log('VideonFleet ' + this.fleet_guid + ' fetchFleet response', response.data)
      
      this.fleet_name = response.data.fleet.fleet_name
      
    }).catch((error) => {
      this.error = true
      this.errorText = Vue.helpers.parseError(error)
    })
  }
  
  async fetchDevices() {
    if (!this.fleet_guid) {
      return
    }
    
    var newDevices = []
    
    var queryParams = {
      'pagination_size': this.itemsPerPage
    }
  
    var paginationToken = false
    
    try {
      do {
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        let response = await Vue.axios.get('/fleets/' + this.fleet_guid + '/devices', {'params': queryParams})
        console.log('VideonFleet fetchDevices response', response)
        
        if (response.data.devices.length > 0) {
          newDevices = newDevices.concat(response.data.devices.map((device) => Vue.devices.getDevice(device.device_guid)))
          
          if (response.data.pagination_token) {
            paginationToken = response.data.pagination_token
          } else {
            paginationToken = false
          }
        }
      } while (paginationToken)
      
      this.ready = true
    } catch (error) {
      console.log('VideonFleet fetchDevices error', error)
    }
    
    this.devices = newDevices
  }
  
  fetchUsers() {
    if (!this.fleet_guid) {
      return
    }
    Vue.axios.get('/fleets/' + this.fleet_guid + '/users').then((response) => {
      console.log('VideonFleet ' + this.fleet_guid + ' fetchUsers response', response)
      
      this.users = response.data.users
      
      this.resolveUserPermissions()
    }).catch(() => {
      // do nothing
    })
  }
  
  fetchUserInvites() {
    if (!this.fleet_guid) {
      return
    }
    Vue.axios.get('/invites', {params: {'fleet_guid': this.fleet_guid}}).then((response) => {
      console.log('VideonFleet ' + this.fleet_guid + ' fetchUserInvites response', response)
      
      this.userInvites = response.data.invites
    }).catch(() => {
      // do nothing...
    })
  }
  
  resolveUserPermissions() {
    if (store.getters['user/isOrgAdmin']) {
      this.editable = true
      return
    }
    
    let myGUID = store.getters['user/guid']
    
    for (var i in this.users) {
      var user = this.users[i]
      // if this user has appropriate permissions enable editable flag
      if (myGUID == user.user_guid && user.access > 200) {
        console.log('VideonFleet ' + this.fleet_guid + ' resolveUserPermissions user can edit device')
        this.editable = true
        this.controllable = true
        return
      } else if (myGUID == user.user_guid && user.access > 100) {
        console.log('VideonFleet ' + this.fleet_guid + ' resolveUserPermissions user can control device')
        this.controllable = true
        return
      }
    }
  }
  
  fetchStatus() {
    if (!this.fleet_guid) {
      return
    }
    
    if (this.fetching) {
      return
    }
    
    if (!this.ready || document.hasFocus()) {
      this.fetching = true
      Vue.axios.get('/fleets/' + this.fleet_guid + '/devices/status')
      .then((response) => {
        this.fetching = false
        
        console.log('VideonFleet ' + this.fleet_guid + ' fetchStatus response', response)
        
        this.status = response.data.status
        
        this.error = false
        this.errorText = ''
      }).catch((error) => {
        this.error = true
        this.errorText = Vue.helpers.parseError(error)
      })
    }
  }
}

export default new class VideonFleets {
  constructor() {
    this.fleets = []
  }
  
  reset() {
    console.log('VideonFleets reset')
    
    // invalidate device_guids
    for (var x in this.fleets) {
      console.log('VideonFleets reset invalidating', this.fleets[x].fleet_guid)
      this.fleets[x].delete()
    }
    
    // reset array
    this.fleets.splice(0)
  }
  
  invalidateFleet(fleet_guid) {
    for (var i = this.fleets.length - 1; i >= 0; --i) {
      if (this.fleets[i].fleet_guid == fleet_guid) {
        console.log('VideonFleets invalidateFleet', fleet_guid)
        this.fleets[i].delete()
        this.fleets.splice(i,1)
        return
      }
    }
  }
  
  getFleet(fleet_guid, fleet_attributes = false) {
    if (!fleet_guid) {
      console.log('Fleets getFleet missing fleet_guid', fleet_guid, fleet_attributes)
      return false
    }
    
    var fleet = this.fleets.find(x => x.fleet_guid === fleet_guid)
    if (fleet) {
      return fleet
    }
    
    fleet = Vue.observable(new VideonFleet(fleet_guid, fleet_attributes))
    this.fleets.push(fleet)
    
    return fleet
  }
}