<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selection"
          item-key="user_guid"
          show-select
          :headers="headers"
          :items="items"
          :items-per-page="itemsPerPage"
          :loading="loading"
           mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:item.name="{ item }">
            <user-label :user="item" />
          </template>
        </v-data-table>
        <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'OrgUserSelector',
    
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
        
        items: [],
        selection: [],
      
        headers: [
          { text: 'Name', align: 'start', value: 'name', sortable: true },
        ],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
        paginationTokens: []
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'organization']),
    },
    props: {
      value: {
        type: Array,
        required: true
      },
    },
    watch: {
      page() {
        this.searchUsers(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchUsers(this.page - 1)
      },
      selection() {
        this.selectionChanged()
      }
    },
    mounted() {
      if (this.value) {
        this.selection = this.value
      }
      
      this.searchUsers(0)
    },
    
    methods: {
      searchUsers(paginationIndex) {
        this.loading = true
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'pagination_size': this.itemsPerPage
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        this.axios.get('/orgs/' + this.organization.org_guid + '/users', {'params': queryParams}).then((response) => {
          console.log('OrgUserSelector searchUsers response', response)
          
          if (response.data.users.length > 0) {
            this.items = response.data.users.map((user) => {
              var thisUser = this.$users.getUser(user.user_guid)
              thisUser.isSelectable = true
              
              var selectedUser = this.value.find(x => x.user_guid === user.user_guid)
              if (selectedUser) {
                thisUser.selected = true
              }
              
              return thisUser
            })
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      selectionChanged() {
        this.$emit('input', this.selection);
      }
    }
  }
</script>