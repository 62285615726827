<template>
  <div>
    <v-row align="center" no-gutters>
      <span :class="(input.video_input_format == 'FORMAT_UNDEFINED') ? 'error--text' : ''">{{ this.$helpers.getLabel(input.video_input_format) }}</span>
      &nbsp;
      <v-tooltip 
        open-on-hover
        open-delay="500"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
          >
            <v-icon v-if="input.audio_input_detected" class="black--text">volume_up</v-icon>
            <v-icon v-else class="red--text">volume_off</v-icon>
          </span>
        </template>
        <strong>Audio Detected:</strong> {{ input.audio_input_detected }}
      </v-tooltip>
    
    </v-row>
  </div>
</template>

<script>
export default {
    name: 'InputStatusLabel',
    props:['input']
  }
</script>