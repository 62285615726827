<template>
  <v-btn
    @click="signin"
    class="mb-3 pl-1 py-5"
    :class="buttonClass"
    :color="buttonColor"
    :disabled="disabled"
  >
    <v-icon large>
      {{ buttonIcon }}
    </v-icon>
    Sign in with {{ providerName }}
  </v-btn>
</template>

<script>
  export default {
    name: 'AuthSSOButton',
    
    data() {
      return {
        ProviderData: {
          Google: {
            name: 'Google',
            id: 'Google',
            icon: '$google_icon',
            color: '#4285F4',
            class: 'white--text'
          },
          Okta: {
            name: 'Okta',
            id: 'Okta',
            icon: '$okta_icon',
            color: '#ffffff',
            class: 'black--text'
          },
        }
      }
    },

    props: {
      provider: String,
      disabled: Boolean
    },
    
    computed: {
      providerId() {
        if (this.provider.includes('-')) {
          return this.provider.split('-')[0]
        }
        return this.provider
      },
      
      buttonColor() {
        if (this.provider && this.ProviderData[this.providerId]) {
          return this.ProviderData[this.providerId].color
        }
        return ''
      },
      buttonIcon() {
        if (this.provider && this.ProviderData[this.providerId]) {
          return this.ProviderData[this.providerId].icon
        }
        return 'lock'
      },
      buttonClass() {
        if (this.provider && this.ProviderData[this.providerId]) {
          return this.ProviderData[this.providerId].class
        }
        return ''
      },
      providerName() {
        if (this.provider && this.ProviderData[this.providerId]) {
          return this.ProviderData[this.providerId].name
        }
        return this.provider
      },
    },
    
    methods: {
      signin() {
        this.$emit('submit', this.provider)
      }
    },
  }
</script>