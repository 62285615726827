import Vue from 'vue'

class VideonEntitlementProfile {
  constructor(profile_guid, profile_attributes = false) {
    this.profile_guid = profile_guid
    this.ready = false
    
    this.profile_name = (profile_attributes.profile_name) ? profile_attributes.profile_name : ''
    this.profile_platform = (profile_attributes.profile_platform) ? profile_attributes.profile_platform: 'edgecaster'
    this.properties = (profile_attributes.properties) ? profile_attributes.properties : []
    
    this.error = false
    this.errorText = ''
    
    if (profile_attributes) {
      this.ready = true
    } else {
      this.fetchProfile()
    }
  }
  
  displayName() {
    if (this.error) {
      return this.errorText
    }
    return (this.profile_name) ? Vue.helpers.htmlDecode(this.profile_name) : 'loading...'
  }

  isBaseProfile() {
    return this.profile_guid.startsWith('BASE_')
  }
  
  fetchProfile() {
    Vue.axios.get('/entitlements/profiles/' + this.profile_guid)
    .then((response) => {
      console.log('VideonEntitlementProfile ' + this.profile_guid + ' fetchProfile response', response)
      
      this.profile_name = response.data.profile.profile_name
      this.properties = response.data.profile.properties
    }).catch((err) => {
      this.error = true
      this.errorText = err.data.message
    }).finally(() => {
      this.ready = true
    })
  }
}

export default new class EntitlementProfiles {
  constructor() {
    this.profiles = []
  }
  
  async allEntitlementProfiles() {
    console.log('EntitlementProfiles allEntitlementProfiles()')
    
    var newProfileList = []
    
    var queryParams = {
      'pagination_size': 50
    }
    
    var paginationToken = false
    
    try {
      do {
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }
        
        let response = await Vue.axios.get('/entitlements/profiles', {'params': queryParams})
        console.log('EntitlementProfiles allEntitlementProfiles response', response)
        
        if (response.data.profiles.length > 0) {
          newProfileList = newProfileList.concat(response.data.profiles.map((profile) => {
            return Vue.observable(new VideonEntitlementProfile(profile.profile_guid, profile))
          }))
          
          if (response.data.pagination_token) {
            paginationToken = response.data.pagination_token
          } else {
            paginationToken = false
          }
        }
      } while (paginationToken)
    } catch (error) {
      console.log('EntitlementProfiles allEntitlementProfiles error', error)
    }
    
    this.profiles = newProfileList
  }
  
  getEntitlementProfile(profile_guid, profile_attributes = false) {
    if (!profile_guid) {
      console.log('EntitlementProfiles getEntitlementProfile missing profile_guid', profile_guid, profile_attributes)
      return false
    }
    
    var profile = this.profiles.find(x => x.profile_guid === profile_guid)
    if (profile) {
      return profile
    }
    
    profile = Vue.observable(new VideonEntitlementProfile(profile_guid, profile_attributes))
    this.profiles.push(profile)
    
    return profile
  }
}