<template>
  <v-list-item 
    @click="showDialog()"
    :disabled="!device.isOnline() || (!device.hasFeature('services.cloud.reset_settings.enabled') && !isDeviceAdmin)"
  >
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="640px"
    >
      <videon-card :heading="'Reset settings on ' + device.deviceName()" showClose="true" @close="showControl = false">
        <v-card-text>
          <p class="subtitle-1 my-5">
            Are you sure you want to reset settings on <span class="primary--text">{{ device.deviceName() }}</span>?
          </p>
          <v-alert dense outlined type="error" class="mb-5" v-if="commandStatus == 'rejected' || commandStatus == 'failed'" >
            {{ commandMessage }}
          </v-alert>
          <v-alert dense outlined type="info" class="mb-5" v-if="commandStatus == 'complete'" >
            {{ commandMessage }} Device may reboot during this process...
          </v-alert>
          <div v-if="processing">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
              class="mb-8"
            ></v-progress-linear>
          </div>
          <v-form @submit.prevent="resetSettings">
            <v-text-field
              v-model="resetSettingsConfirm"
              label="Reset Confirmation"
              messages="Please type RESET above to proceed"
              required
              outlined
            ></v-text-field>
            <div class="mt-3 d-flex align-end">
              <v-spacer />
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showControl = false"
                text
              >
                Cancel
              </v-btn>
              
              <v-btn
                v-if="commandStatus == 'complete'"
                class="ma-1"
                color="primary"
                @click="showControl = false"
                :disabled="processing"
              >
                Close
              </v-btn>
              <v-btn
                v-else
                class="ma-1"
                color="primary"
                @click="resetSettings"
                :disabled="processing"
              >
                Reset Settings
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="red--text">
      <v-progress-circular
        v-if="processing"
        class="mr-1"
        indeterminate
        color="primary"
        size="23"
        width="2"
      ></v-progress-circular>
      <v-icon color="red" v-else>delete_forever</v-icon> 
      Reset Settings
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'ResetSettings',
    
    props: ['device'],
    
    data() {
      return {
        error: false,
        errorText: '',
        
        showControl: false,
        
        resetSettingsConfirm: ''
      }
    },
    mounted() {
      
    },
    watch: {
      
    },
    computed: {
      ...mapGetters('user', ['isDeviceAdmin']),
      
      processing() {
        if (this.device.isCommandProcessing()) {
          return true
        }
        return false
      },
      commandStatus() {
        return this.device.command_status
      },
      commandMessage() {
        return this.device.command_message
      },
    },

    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      resetSettings() {
        if (this.resetSettingsConfirm == 'RESET') {
          this.device.runCommand('reset_settings')
        }
      }
    }
  }
</script>