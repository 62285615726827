<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showDeleteUser"
      max-width="400px"
    >
      <videon-card heading="Delete Your Account" showClose="true" @close="showDeleteUser = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete your account?
          </p>
          <v-form @submit.prevent="confirmDeleteUser">
            <v-text-field
              v-model="deleteUserReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteUser = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteUser"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showDisableMFA"
      max-width="440px"
    >
      <videon-card heading="Disable MFA" showClose="true" @close="showDisableMFA = false">
        <v-card-text>
          <v-form @submit.prevent="disableMFA">
            <p class="my-3 body-2">
              Disabling MFA will make your account less-secure. Are you sure you want to do this?
            </p>
            
            <div class="text-right mt-5">
              <v-btn
                class="ma-1"
                color="primary"
                @click="disableMFA"
              >
                Yes, Disable MFA
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>

    <v-dialog
      v-model="showEnableTOTP"
      max-width="440px"
    >
      <videon-card heading="Enable TOTP" showClose="true" @close="showEnableTOTP = false">
        <v-card-text>
          <v-form @submit.prevent="handleTOTPCode">
            
            <div class="my-10 text-center" v-if="!TOTPQrCode">
              <v-progress-circular :size="150" :width="10" indeterminate color="primary" ></v-progress-circular>
            </div>
            <vue-qrcode align="center" :value="TOTPQrCode" size="300" class="ma-3" v-if="TOTPQrCode"/>
            
            <v-divider />
            
            <p class="my-3 body-2">
              Please scan the QR code using an authentication app such as Authy or Google Authenticator,
              then enter the provided code below to enable.
            </p>
            
            <v-text-field
              v-model="TOTPVerificationCode"
              name="TOTPCode"
              label="Code"
              @input="$v.TOTPVerificationCode.$touch()"
              @blur="$v.TOTPVerificationCode.$touch()"
              :error="$v.TOTPVerificationCode.$invalid"
              autocomplete="off"
              single-line
              outlined
              hide-details
            ></v-text-field>
            
            <div class="text-right mt-5">
              <v-btn
                class="ma-1"
                color="primary"
                @click="handleTOTPCode"
              >
                Enable TOTP
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showChangeEmail"
      persistent
      max-width="480px"
    >
      <videon-card heading="Verify Email Change">
        <v-card-text class="">
          <v-alert outlined type="error" v-if="emailError">
            {{ emailErrorText }}
          </v-alert>
          
          <v-form @submit.prevent="handleEmailCode">
            <p class="my-3 body-1">
              A confirmation code was emailed to: <br/>
              <span class="primary--text">{{ email }}</span>
            </p>
            
            <v-btn
              @click="showChangeEmail = false"
              color="secondary"
              outlined
              small
            >
              Change this email address
            </v-btn>
            
            <v-divider class="my-5" />
            
            <p class="my-3 subtitle-1">
              To continue, please find the confirmation email and enter the verification code provided.
              <br/><br/>
              Lost the verification code? 
              <v-btn
                class="ml-1"
                @click="resendEmailCode"
                :disabled="emailResendDisabled"
                color="accent"
                outlined
                small
              >
                Resend Code
              </v-btn>
            </p>
            
            <v-text-field
              class="mt-5"
              v-model="emailVerificationCode"
              label="Verification Code"
              @input="$v.emailVerificationCode.$touch()"
              @blur="$v.emailVerificationCode.$touch()"
              :error="$v.emailVerificationCode.$invalid"
              single-line
              required
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                @click="handleEmailCode"
                :disabled="$v.emailVerificationCode.$invalid"
                color="primary"
              >
                Verify
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>

    <v-dialog
      v-model="showChangePhoneNumber"
      persistent
      max-width="400px"
    >
      <videon-card heading="Verify Phone Number Change">
        <v-card-text>
          <v-alert outlined type="error" v-if="SMSError">
            {{ SMSErrorText }}
          </v-alert>
          
          <v-form @submit.prevent="handleSMSCode">
            <p class="subtitle-1">
              An text message was sent to <span class="primary--text">{{ phone }}</span> to validate your phone number change.
              <br/><br/>
              To continue, please find the confirmation text message and enter the verification code provided.
              <br/><br/>
              Lost the verification code?
              <v-btn
                @click="resendSMSCode"
                :disabled="SMSResendDisabled"
                color="primary"
                small
                text
              >Resend</v-btn>
            </p>
            <v-text-field
              v-model="SMSVerificationCode"
              label="Verification Code"
              @input="$v.SMSVerificationCode.$touch()"
              @blur="$v.SMSVerificationCode.$touch()"
              :error="$v.SMSVerificationCode.$invalid"
              single-line
              required
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                @click="handleSMSCode"
                :disabled="$v.SMSVerificationCode.$invalid"
                color="primary"
              >
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showChangePassword"
      max-width="400px"
    >
      <videon-card heading="Change Password" showClose="true" @close="showChangePassword = false">
        <v-card-text>
          <v-form @submit.prevent="changePassword">
            <v-text-field
              class="mt-3"
              name="current-password"
              autocomplete="current-password"
              v-model="currentPassword"
              :error-messages="currentPasswordErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Current Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.currentPassword.$touch()"
              @blur="$v.currentPassword.$touch()"
              outlined
            ></v-text-field>
            
            <v-divider class="mb-5 mt-2"></v-divider>
            
            <v-text-field
              name="password"
              autocomplete="new-password"
              v-model="password"
              :error-messages="passwordErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="New Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              outlined
            ></v-text-field>
            <v-text-field
              name="password-confirm"
              autocomplete="new-password"
              v-model="passwordConfirm"
              :error-messages="passwordConfirmErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Confirm New Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.passwordConfirm.$touch()"
              @blur="$v.passwordConfirm.$touch()"
              outlined
            ></v-text-field>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showChangePassword = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="changePassword"
              >
                Update
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        My Account
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-none d-md-inline-flex">
        <v-btn
          v-if="!isSSO && mfaMethod && mfaMethod != 'NOMFA'"
          class="ma-1"
          small
          text
          @click="showDisableMFA = true"
        >
          <v-icon>key_off</v-icon> Disable MFA
        </v-btn>
        <v-btn
          v-if="!isSSO && mfaMethod && mfaMethod != 'SOFTWARE_TOKEN_MFA'"
          class="ma-1"
          small
          text
          @click="setupTOTP"
        >
          <v-icon>mdi-qrcode</v-icon> Enable TOTP
        </v-btn>
        <v-btn
          v-if="!isSSO"
          class="ma-1"
          small
          text
          color="secondary"
          @click="showChangePassword = true"
        >
          <v-icon>lock</v-icon> Change Password
        </v-btn>
        <v-btn
          class="ma-1"
          small
          text
          @click="showDeleteUser = true"
        >
          <v-icon>delete</v-icon> Delete Account
        </v-btn>
      </div>
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      
      <v-alert dense outlined type="info" v-if="infoText">
        {{ infoText }}
      </v-alert>
      
      <v-container class="ml-0">
        <v-row v-if="loading">
          <v-col cols="12">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
            ></v-progress-linear>
          </v-col>
        </v-row>
        <v-row v-if="!loading">
          <v-col md="3">
            <edit-avatar @image-uploaded="handleImageUpload"/>
          </v-col>
          <v-col cols="12" md="8">
            <v-form @submit.prevent="saveChanges">
              <v-text-field
                name="name"
                autocomplete="name"
                v-model="name"
                :error-messages="nameErrors"
                label="Full Name"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                :disabled="isSSO"
                outlined
              ></v-text-field>
              <v-text-field
                name="email"
                autocomplete="email"
                v-model="email"
                :error-messages="emailErrors"
                label="E-mail"
                type="email"
                required
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
                :disabled="isSSO"
                outlined
              ></v-text-field>
              
              <vue-tel-input-vuetify
                name="phone"
                autocomplete="tel"
                v-model="phone"
                :error-messages="phoneErrors"
                label="Phone Number"
                hint="Only the United States and Canada are supported at the moment."
                @input="$v.phone.$touch()"
                @blur="$v.phone.$touch()"
                default-country="US"
                :onlyCountries="['US', 'CA']"
                :disabledFetchingCountry="true"
                mode="international"
                outlined
                full-width
              ></vue-tel-input-vuetify>
              
              <locale-selector v-model="locale" />
              
              <timezone-selector v-model="timezone" />
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-row
         align="center"
         justify="end"
         class="ma-2 mt-0"
       >
         <v-btn
           color="primary"
           @click="saveChanges"
           class="ma-1"
         >
           Update
         </v-btn>
       </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import { validationMixin } from 'vuelidate'
  import { required, minLength, email } from 'vuelidate/lib/validators'

  import VueQrcode from 'qrcode.vue'

  import EditAvatar from './EditAvatar.vue'
  import LocaleSelector from './LocaleSelector.vue'
  
  export default {
    mixins: [validationMixin],
    
    name: 'MyUser',
    
    validations: {
      name: { required },
      email: { required, email },
      phone: { },

      emailVerificationCode: {
        required,
        enforceLength: (value) => value.length == 6,
        enforceCode: (value) => (/[0-9]{6}/).test(value)
      },

      SMSVerificationCode: {
        required,
        enforceLength: (value) => value.length == 6,
        enforceCode: (value) => (/[0-9]{6}/).test(value)
      },

      TOTPVerificationCode: {
        required,
        enforceLength: (value) => value.length == 6,
        enforceCode: (value) => (/[0-9]{6}/).test(value)
      },
      
      currentPassword: { required, minLength: minLength(8) },
      password: { required, minLength: minLength(8) },
      passwordConfirm: { required, minLength: minLength(8) },
    },

    components: {
      VueQrcode,
      EditAvatar,
      LocaleSelector
    },
    
    data() {
      return {
        error: false,
        errorText: '',
      
        infoText: false,
      
        showDeleteUser: false,
        deleteUserReason: '',

        showEnableTOTP: false,
        TOTPQrCode: false,
        TOTPVerificationCode: '',

        showDisableMFA: false,

        showChangeEmail: false,
        emailVerificationCode: '',
        emailResendWaitTime: 30, // Seconds
        emailResendTimestamp: 0,
        emailResendDisabled: false,
        emailError: false,
        emailErrorText: '',

        showChangePhoneNumber: false,
        SMSVerificationCode: '',
        SMSResendWaitTime: 30, // Seconds
        SMSResendTimestamp: 0,
        SMSResendDisabled: false,
        SMSError: false,
        SMSErrorText: '',
      
        showChangePassword: false,
      
        loading: false,
      
        name: 'User',
        email: '',
        phone: '',
        mfaMethod: '',

        picture: '',

        locale: 'en-US',
        timezone: 'America/New_York',
      
        isSSO: false,
        
        showPass: false,
        currentPassword: '',
        password: '',
        passwordConfirm: '',
      }
    },
    computed: {
      ...mapGetters('user', ['guid', 'isAdmin']),
      
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.phone.$dirty) return errors
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
      currentPasswordErrors () {
        const errors = []
        if (!this.$v.currentPassword.$dirty) return errors
        !this.$v.currentPassword.minLength && errors.push('Password needs to be longer')
        !this.$v.currentPassword.required && errors.push('Password is required')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password needs to be longer')
        !this.$v.password.required && errors.push('Password is required')
        return errors
      },
      passwordConfirmErrors () {
        const errors = []
        if (!this.$v.passwordConfirm.$dirty) return errors
        !this.$v.passwordConfirm.minLength && errors.push('Password needs to be longer')
        !this.$v.passwordConfirm.required && errors.push('Password is required')
        this.passwordConfirm !== this.password && errors.push('Passwords don`t match')
        return errors
      },
    },
    watch: {

    },
    mounted() {
      this.loadUser()
    },
    methods: {
      loadUser() {
        this.loading = true
        
        // Directly getting the user from Amplify to solve CP-355
        // Not using Users object, because we need the user object
        this.$users.getMe() // get the cache ready
        
        this.$Amplify.Auth.currentAuthenticatedUser({ bypassCache: true }).then((user) => {
          this.isSSO = (user.attributes.identities) ? true : false
          
          this.name = user.attributes.name || ''
          this.email = user.attributes.email || ''
        
          this.picture = user.attributes.picture || ''
          
          this.phone = user.attributes.phone_number || ''
          this.locale = user.attributes.locale || this.locale
          this.timezone = user.attributes.zoneinfo || this.timezone
          
          if (user.attributes.email_verified != undefined) {
            this.showChangeEmail = !user.attributes.email_verified
          }
          
          // Because a phone number isn't required, and no phone number is consitered
          // unverified, we need to make sure that the user gave us a phone number
          if (this.phone && user.attributes.phone_number_verified != undefined) {
            // TODO: Uncomment when SMS is implemented
            // this.showChangePhoneNumber = !user.attributes.phone_number_verified
          }
          
          this.$Amplify.Auth.getPreferredMFA(user).then((method) => {
            this.mfaMethod = method
            
            this.loading = false
          })
        })
      },
      
      confirmDeleteUser() {
        console.log('MyUser confirmDeleteUser')
        
        if (this.deleteUserReason) {
          var that = this
          this.loading = true
          this.showDeleteUser = false
        
          this.axios.delete('/users/' + this.guid, {'data': {'reason': this.deleteUserReason}})
          .then((response) => {
            console.log('MyUser confirmDeleteUser response', response)
            
            that.$store.dispatch('user/logout').then(() => {
              that.$router.push('/auth/login')
            })
          }).catch((error) => {
            console.log('MyUser confirmDeleteUser error', error)
          
            that.error = true
            that.errorText = this.$helpers.parseError(error)
          
            that.loading = false
          
          })
        }
      },

      resendEmailCode() {
        var currentTimestamp = Date.now() / 1000 // Seconds since epoch
        if (currentTimestamp < this.emailResendTimestamp + this.emailResendWaitTime) {
          if (!this.emailError) {
            this.emailError = true
            this.emailErrorText = 'Please Wait Before Resending the Verification Code'
            // TODO: Maybe add a progress bar?
          }
          return
        }
        
        console.log('MyUser resendEmailCode')
        
        this.emailResendTimestamp = currentTimestamp
        this.emailResendDisabled = true
        this.$Amplify.Auth.verifyUserAttribute(this.$Amplify.Auth.user, 'email').catch((err) => {
          // TODO: Use friendlier error messages, we don't want customers to know that we use AWS
          this.emailError = true
          this.emailErrorText = err
        }).finally(() => {
          this.emailResendDisabled = false
        })
      },

      resendSMSCode() {
        var currentTimestamp = Date.now() / 1000 // Seconds since epoch
        if (currentTimestamp < this.SMSResendTimestamp + this.SMSResendWaitTime) {
          if (!this.SMSError) {
            this.SMSError = true
            this.SMSErrorText = 'Please Wait Before Resending the Verification Code'
            // TODO: Maybe add a progress bar?
          }
          return
        }
        
        console.log('MyUser resendSMSCode')
        
        this.SMSResendTimestamp = currentTimestamp
        this.SMSResendDisabled = true
        this.$Amplify.Auth.verifyUserAttribute(this.$Amplify.Auth.user, 'phone_number').catch((err) => {
          // TODO: Use friendlier error messages, we don't want customers to know that we use AWS
          this.SMSError = true
          this.SMSErrorText = err
        }).finally(() => {
          this.SMSResendDisabled = false
        })
      },

      handleEmailCode() {
        this.$v.$touch()
        
        this.emailError = false
        
        console.log('MyUser handleEmailCode', this.emailVerificationCode)
        
        this.$Amplify.Auth.verifyUserAttributeSubmit(this.$Amplify.Auth.user, 'email', this.emailVerificationCode).then((response) => {
          console.log('MyUser handleEmailCode response', response)
          
          this.showChangeEmail = false
          this.emailVerificationCode = ''
        }).catch((err) => {
          // TODO: Use friendlier error messages, we don't want customers to know that we use AWS
          this.emailError = true
          this.emailErrorText = err
        })
      },

      handleSMSCode() {
        // TODO: Handle this once SMS is setup
        // When you implement, uncomment the lines that starts with this.showChangePhoneNumber
        //   One is in saveChanges() and the other is in loadUser()
      },

      handleTOTPCode() {
        this.$v.$touch()
        
        if (this.TOTPVerificationCode) {
          this.loading = true
          this.showEnableTOTP = false
          
          console.log('MyUser handleTOTPCode', this.TOTPVerificationCode)
          
          this.$Amplify.Auth.verifyTotpToken(this.$Amplify.Auth.user, this.TOTPVerificationCode).then(() => {
            this.$Amplify.Auth.setPreferredMFA(this.$Amplify.Auth.user, 'TOTP').then(() => {
              this.infoText = 'Enabled TOTP successfully.'
              
              this.mfaMethod = 'SOFTWARE_TOKEN_MFA'
              
              this.loading = false
            })
          }).catch((error) => {
            this.error = true
            this.errorText = error.message
            this.loading = false
          })
        } 
       
      },

      handleImageUpload(url) {
        this.picture = url
        
        this.$Amplify.Auth.updateUserAttributes(this.$Amplify.Auth.user, {
          picture: url
        }).then(() => {
          this.$store.dispatch('user/setAvatar', url)
          this.$users.getMe().refresh()
        }).catch((error) => {
          console.log('MyUser handleImageUpload Error:', this.$helpers.parseError(error))
        })
      },
      
      saveChanges() {
        console.log('MyUser saveChanges')
        this.loading = true
        
        var updateBody = {}
        
        var myUser = this.$users.getMe()
        console.log('MyUser saveChanges user', myUser)
        
        if (this.name) {
          updateBody.name = this.name
        }
        
        var emailChanged = false
        if (this.email) {
          updateBody.email = this.email.toLowerCase()
          if (myUser.email && myUser.email != updateBody.email) {
            emailChanged = true
          }
        }
        
        var phoneChanged = false
        if (this.phone) {
          updateBody.phone_number = this.phone.replace(/[\s-]+/g, '').toLowerCase()
          
          if (myUser.phone != updateBody.phone_number) {
            phoneChanged = true
          }
        }
        
        if (this.locale) {
          updateBody.locale = this.locale
        }
        
        if (this.timezone) {
          updateBody.zoneinfo = this.timezone
        }
        
        this.$Amplify.Auth.updateUserAttributes(this.$Amplify.Auth.user, updateBody).then(() => {
          if (emailChanged) {
            this.$store.dispatch('user/setEmail', updateBody.email)
            console.log('MyUser email changed', updateBody.email)
            this.showChangeEmail = true
          }
          
          if (phoneChanged) {
            console.log('MyUser phone number changed')
            // TODO: Uncomment when SMS is implemented
            // this.showChangePhoneNumber = true
          }
          
          // We need to call currentAuthenticatedUser(), because updateUserAttributes doesn't update
          // the Auth.user object. In addition, calling refresh will set off the Vue observable and
          // will cause Navigation (and other components) to update.
          myUser.refresh()
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = error.message
          
          this.loading = false
        })
      },
      
      changePassword() {
        console.log('MyUser changePassword')
        this.showChangePassword = false
        this.loading = true
        
        if (this.currentPassword && this.password && this.passwordConfirm && (this.password == this.passwordConfirm)) {
          this.$Amplify.Auth.changePassword(this.$Amplify.Auth.user, this.currentPassword, this.password).then(() => {
            this.infoText = 'Password changed successfully.'
            this.loading = false
          })
        }
      },

      disableMFA() {
        console.log('MyUser disableMFA')
        
        this.loading = true
        this.showDisableMFA = false
        
        this.$Amplify.Auth.setPreferredMFA(this.$Amplify.Auth.user, 'NOMFA').then(() => {
          this.infoText = 'Disabled MFA successfully.'
          this.mfaMethod = 'NOMFA'
        }).catch((error) => {
          this.error = true
          this.errorText = error.message
        }).finally(() => {
          this.loading = false
        })
      },

      setupTOTP() {
        this.showEnableTOTP = true
        
        this.$Amplify.Auth.setupTOTP(this.$Amplify.Auth.user).then((code) => {
          this.TOTPQrCode = "otpauth://totp/Videon:"+ this.guid + "?secret=" + code + "&issuer=Cloud+Platform";
        }).catch((error) => {
          this.error = true
          this.errorText = error.message
        })
      },
    }
  }
</script>