<template>
  <v-btn text color="secondary" v-if="device.state && reachable" :href="deviceURL" target="_blank"><v-icon>input</v-icon>Manage Device</v-btn>
</template>

<script>
  
  export default {
    name: 'ManageDeviceButton',
    props: {
      device_guid: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        error: false,
        errorText: '',
 
        device: false,
        
        reachable: false,
      }
    },
    watch: {
      
    },
    computed: {
      deviceURL() {
        if (this.device.state && this.device.state.device_ip) {
          return 'http://' + this.device.state.device_ip
        }
        return ''
      }
    },
    mounted() {
      this.loadDevice()
    },
    methods: {
      loadDevice() {
        this.device = this.$devices.getDevice(this.device_guid)
        
        setTimeout(() => {
          this.checkReachability()
        }, 1000)
        
      },
      
      checkReachability() {
        if (this.device.state && this.device.state.device_ip) {
          // this doesnt work because of https -> http being blocked.. sigh
          // must remove the global headers for this to pass CORS
          this.axios.get('https://' + this.device.state.device_ip + ':2020/v2/system', { transformRequest: (data, headers) => {
            delete headers.common['Authorization']
            delete headers.common['Org-Guid']
            return data
          }})
          .then((response) => {
            console.log('ManageDeviceButton checkReachability', response)
            this.reachable = true
          }).catch((error) => {
            console.log('ManageDeviceButton checkReachability error', error)

            this.error = true
            this.errorText = this.$helpers.parseError(error)
          })
        }
      }
    }

  }
</script>