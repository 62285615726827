<template>
  <base-line
    :chart-options="options"
    :chart-data="chartData"
  />
</template>

<script>
import { Line as BaseLine } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  Filler,
  LineElement,
  LinearScale,
  TimeScale,
  PointElement
} from 'chart.js'

import 'chartjs-adapter-moment'

ChartJS.register(
  Tooltip,
  Legend,
  Filler,
  LineElement,
  LinearScale,
  TimeScale,
  PointElement
)

import objectAssignDeep from 'object-assign-deep';

export default {
  name: 'LineChart',
  components: {
    BaseLine
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({ datasets: [] })
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    options() {
      const defaultOptions = {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
      
        scaleStartValue: 0,
        scales: {
          x: {
            type: 'time',
            gridLines: {
              zeroLineColor: 'transparent',
              drawBorder: false,
              color: 'rgba(0, 0, 0, 0.02)'
            },
            ticks: {
              display: true,
              autoSkip: false,
              source: 'data'
            }
          },
          y: {
            id: 'primary',
            position: 'left',
            gridLines: {
              zeroLineColor: 'transparent',
              drawBorder: false,
              color: 'rgba(0, 0, 0, 0.02)'
            },
            ticks: {
              beginAtZero: true,
              callback: value => (Number.isInteger(value) ? value : null)
            }
          }
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      }

      const options = objectAssignDeep(defaultOptions, this.chartOptions);
      return options
    },
  },
}
</script>