<template>
  <div>
    <h3>Firmware Update</h3>
    <p>
      Attempt to apply the latest firmware or update the cloud agent on the selected devices that are currently online.
    </p>
    
    <v-row align="center">
      <v-col cols="12" align="center" class="mt-5">
        <v-btn
          class="my-3"
          color="secondary"
          outlined
          @click="firmwareUpdate"
          :disabled="actioned"
        >
          Update Firmware
        </v-btn>
        
        <br />
        
        <v-btn
          small
          class="my-3"
          outlined
          @click="agentUpdate"
          :disabled="actioned"
        >
          Update Cloud Agent
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  export default {
    name: 'BulkActionFirmwareUpdate',
    props: ['devices'],
    
    data() {
      return {
        actioned: false,
      }
    },
    
    computed: {
      
    },
    
    methods: {
      firmwareUpdate() {
        this.actioned = true
        
        for (var device of this.devices) {
          if (!device.hasFeature('services.cloud.bulk_actions.enabled')) {
            device.command_message = 'Not Licensed'
          } else if (device.isOnline()) {
            device.runCommand('download_update')
          }
        }
      },
      
      async agentUpdate() {
        this.actioned = true
        
        for (var device of this.devices) {
          if (!device.hasFeature('services.cloud.bulk_actions.enabled')) {
            device.command_message = 'Not Licensed'
          } else if (device.isOnline()) {
            if (device.product_name == 'edgecaster_max') {
              device.command_message = 'Unsupported Device'
            } else {
              try {
                let response = await this.axios.post('/devices/' + device.device_guid + '/updates/cloud', {'update': true})
                
                console.log('BulkActionFirmwareUpdate agentUpdate response', response)
                
                device.command_status = 'accepted'
                device.command_message = 'Command Accepted'
                device.commandGUID = response.data.command_guid
                
                if (device.commandGUID) {
                  device.checkDeviceCommand(device.commandGUID)
                }
              } catch (error) {
                console.log('BulkActionFirmwareUpdate fetchContainers error', error)
              }
            }
          }
        }
      },
      
    }
  }
</script>