export default {
    "FORMAT_UNDEFINED": "Format Unsupported",

    "FORMAT_2160p2398": "2160p23.98", "FORMAT_2160p24":   "2160p24",    "FORMAT_2160p25":   "2160p25",
    "FORMAT_2160p2997": "2160p29.97", "FORMAT_2160p30":   "2160p30",    "FORMAT_2160p50":   "2160p50",
    "FORMAT_2160p5994": "2160p59.94", "FORMAT_2160p60":   "2160p60",

    "FORMAT_1080p2398": "1080p23.98", "FORMAT_1080p24":   "1080p24",    "FORMAT_1080p25":   "1080p25",
    "FORMAT_1080p2997": "1080p29.97", "FORMAT_1080p30":   "1080p30",    "FORMAT_1080p50":   "1080p50",
    "FORMAT_1080p5994": "1080p59.94", "FORMAT_1080p60":   "1080p60",

    "FORMAT_1080i50":   "1080i50",    "FORMAT_1080i5994": "1080i59.94", "FORMAT_1080i60":   "1080i60",

    "FORMAT_720p2398":  "720p23.98",  "FORMAT_720p24":    "720p24",     "FORMAT_720p25":    "720p25",
    "FORMAT_720p2997":  "720p29.97",  "FORMAT_720p30":    "720p30",     "FORMAT_720p50":    "720p50",
    "FORMAT_720p5994":  "720p59.94",  "FORMAT_720p60":    "720p60",

    "FORMAT_576p2398":  "576p23.98",  "FORMAT_576p24":    "576p24",     "FORMAT_576p25":    "576p25",
    "FORMAT_576p2997":  "576p29.97",  "FORMAT_576p30":    "576p30",     "FORMAT_576p50":    "576p50",
    "FORMAT_576p5994":  "576p59.94",  "FORMAT_576p60":    "576p60",

    "FORMAT_576i50":    "576i50",

    "FORMAT_540p2398":  "540p23.98",  "FORMAT_540p24":    "540p24",     "FORMAT_540p25":    "540p25",
    "FORMAT_540p2997":  "540p29.97",  "FORMAT_540p30":    "540p30",     "FORMAT_540p50":    "540p50",
    "FORMAT_540p5994":  "540p59.94",  "FORMAT_540p60":    "540p60",

    "FORMAT_480p2398":  "480p23.98",  "FORMAT_480p24":    "480p24",     "FORMAT_480p25":    "480p25",
    "FORMAT_480p2997":  "480p29.97",  "FORMAT_480p30":    "480p30",     "FORMAT_480p50":    "480p50",
    "FORMAT_480p5994":  "480p59.94",  "FORMAT_480p60":    "480p60",

    "FORMAT_480i5994":  "480i59.94",  "FORMAT_480i60":    "480i60",

    "FORMAT_360p2398":  "360p23.98",  "FORMAT_360p24":    "360p24",     "FORMAT_360p25":    "360p25",
    "FORMAT_360p2997":  "360p29.97",  "FORMAT_360p30":    "360p30",     "FORMAT_360p50":    "360p50",
    "FORMAT_360p5994":  "360p59.94",  "FORMAT_360p60":    "360p60",

    "FORMAT_270p2398":  "270p23.98",  "FORMAT_270p24":    "270p24",     "FORMAT_270p25":    "270p25",
    "FORMAT_270p2997":  "270p29.97",  "FORMAT_270p30":    "270p30",     "FORMAT_270p50":    "270p50",
    "FORMAT_270p5994":  "270p59.94",  "FORMAT_270p60":    "270p60",

    "FORMAT_180p2398":  "180p23.98",  "FORMAT_180p24":    "180p24",     "FORMAT_180p25":    "180p25",
    "FORMAT_180p2997":  "180p29.97",  "FORMAT_180p30":    "180p30",     "FORMAT_180p50":    "180p50",
    "FORMAT_180p5994":  "180p59.94",  "FORMAT_180p60":    "180p60"
}