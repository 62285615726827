<template>
    <div>
        <strong>{{ $helpers.getLabel(feature.feature) }}</strong>

        <span v-if="feature.type === 'number'">: [{{ feature.min }}...{{ feature.max }}]</span>

        <span
          v-else-if="feature.type === 'array'"
        >
          <span>:&nbsp;</span>
          <v-chip
            v-for="(possible, index) in feature.possible"
            :key="index"
            outlined
            small
          >
            {{ possible }}
          </v-chip>
        </span>

        <span v-else-if="feature.type === 'range'">: [{{ feature.min }}...{{ feature.max }}]</span>
    </div>
</template>

<script>
  export default {
    name: 'FeatureCapability',
    props: ['feature'],

    data() {
      return {}
    }
  }
</script>