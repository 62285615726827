<template>
  <videon-card
    heading="User Login"
    max-width="480px"
  >
    <v-dialog
      v-model="showCSA"
      max-width="80%"
    >
      <CSA @close="showCSA = false"/>
    </v-dialog>
    <v-dialog
      v-model="showPrivacyPolicy"
      max-width="80%"
    >
      <privacy-policy @close="showPrivacyPolicy = false"/>
    </v-dialog>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
    
      <v-row class="ma-1">
        <v-col cols="12">
          <p>
            Welcome, please enter your company email address to continue.
          </p>
          
          <v-form @submit.prevent="captureEmail">
            <v-text-field
              class="mt-6"
              v-model="email"
              name="email"
              autocomplete="email"
              type="email"
              ref="email"
              :error-messages="emailErrors"
              label="E-mail"
              required
              @input="$v.email.$touch()"
              @blur="$v.email.$touch()"
              outlined
            ></v-text-field>
            
            <v-btn
              color="primary"
              class="mx-auto"
              @click="captureEmail"
              width="100%"
            >
              Continue
            </v-btn>
          </v-form>

          
        </v-col>
      </v-row>
      <div class="mt-4 text-center">
        <v-btn small text @click="showPrivacyPolicy = true" class="mx-2">View Privacy Policy</v-btn>
        <v-btn small text @click="showCSA = true" class="mx-2">View CSA</v-btn>
      </div>
    </v-card-text>
    
  </videon-card>
</template>

<script>
  import { mapState, mapGetters } from "vuex"
  
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  
  import CSA from '../legal/CloudServicesAgreement.vue'
  import PrivacyPolicy from '../legal/PrivacyPolicy.vue'
  
  export default {
    name: 'CaptureEmail',
    
    components: {
      CSA,
      PrivacyPolicy
    },
    mixins: [validationMixin],

    validations: {
      email: { required, email },
    },

    computed: {
      ...mapState('user', ['error', 'errorText']),
      ...mapGetters('config', ['deploymentName']),
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
    },
    
    data() {
      return {
        email: '',
        showCSA: false,
        showPrivacyPolicy: false
      }
    },
    
    mounted() {
      this.email = this.$store.getters['user/email']
    },
    
    methods: {
      captureEmail() {
        this.$v.$touch()
        
        if (this.email && this.emailErrors.length < 1) {
          this.$emit('submit', this.email)
        }
      },
    },
  }
</script>
