<template>
  <v-alert dark dense rounded class="alert-chip ma-1 darken-1" outlined color="red">
    <span class="text-no-wrap">
      <span class="text-capitalize font-weight-medium">{{ message }}:&nbsp;</span>
      <span v-if="eventData.thread_name || (eventData.agent && eventData.agent.thread_name)">Thread '{{ eventData.thread_name || eventData.agent.thread_name }}'&nbsp;</span>
      <span v-if="eventData.death_count || (eventData.agent && eventData.agent.death_count)">died {{ eventData.death_count || eventData.agent.death_count }} times&nbsp;</span>
      <span v-if="eventData.last_death_time || (eventData.agent && eventData.agent.last_death_time)"><span class="font-weight-medium">Last Death:</span> {{ eventData.last_death_time || eventData.agent.last_death_time | moment("YYYY/MM/DD hh:mm:ss a") }}&nbsp;</span>
    </span>
  </v-alert>
</template>

<script>
  
  export default {
    name: 'AgentEvent',
    props: {
      eventData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      message() {
        return (this.eventData.message) ? this.eventData.message : 'Unknown'
      }
    },
    methods: {
      
    }
  }
</script>
<style lang="css" scoped>
  .alert-chip {
     font-size: 12px;
     line-height: 20px;
     padding: 3px 12px;
     min-height: 24px;
     align-items: center;
     display: inline-flex;
  }
</style>