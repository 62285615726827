<template>
  <div>
    <span class="subtitle-2">{{ accessLevel }}</span>
  </div>
</template>

<script>
  
  export default {
    name: 'AccessLevelLabel',
    props: {
      access_level: {
        type: Number,
        default: 100,
      },
    },
    computed: {
      accessLevel() {
        if (this.access_level == 300) {
          return 'Admin'
        } else if (this.access_level == 200) {
          return 'User'
        } else if (this.access_level == 100) {
          return 'Read Only'
        } else {
          return 'Unknown Access Level'
        }
      }
    }
  }
</script>