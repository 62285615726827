<template>
  <div>
    <h3>Output Control</h3>
    <p>
      Enable/disable all configured outputs for the selected devices that are currently online.
    </p>
    
    <v-row align="center">
      <v-col cols="12" align="center" class="mt-5">
        <v-btn
          small
          class="my-3"
          color="secondary"
          outlined
          @click="toggleOutputs(true)"
          :disabled="actioned"
        >
          Enable configured outputs
        </v-btn>
        <br />
        <v-btn
          small
          class="my-3"
          outlined
          @click="toggleOutputs(false)"
          :disabled="actioned"
        >
          Disable configured outputs
        </v-btn>
      </v-col>
    </v-row>
    
  </div>
</template>

<script>
  export default {
    name: 'BulkActionOutputControl',
    props: ['devices'],
    
    data() {
      return {
        actioned: false,
      }
    },
    
    methods: {
      async toggleOutputs(enable = true) {
        this.actioned = true
        
        for (var device of this.devices) {
          if (!device.hasFeature('services.cloud.bulk_actions.enabled')) {
            device.command_message = 'Not Licensed'
          } else if (device.isOnline()) {
            var outputShadow = this.$deviceShadows.getDeviceShadow(device.device_guid, 'Outputs')
            var configuredOutputs = outputShadow.editableStateCopy().filter(output => (this.$helpers.outputStatus(output).configured === true && output.type != 'thumbnail'))
            
            var outputs = configuredOutputs.map((output) => {
              return {'output_id': output.id, 'enable': enable}
            })
            
            try {
              let response = await this.axios.post('/devices/' + device.device_guid + '/outputs', outputs)
              
              console.log('BulkActionOutputControl toggleOutputs response', response)
              
              device.command_message = 'Command Accepted'
              
              setTimeout(() => {
                device.syncDocuments()
              }, 5000)
            } catch (error) {
              console.log('BulkActionOutputControl toggleOutputs error', error)
            }
          }
        }
      }
    }
  }
</script>