<template>
  <v-icon>{{ icon }}</v-icon>
</template>

<script>
  export default {
    computed: {
      icon() {
        if (this.$vuetify.theme.dark) {
          return '$videon_logo_dark'
        }
        return '$videon_logo'
      }
    }
  }
</script>