<template>
  <v-container>
    <v-row align="center" class="mt-1">
      <v-spacer />
      <v-icon
        :disabled="!enablePrevious"
        @click="firstPage()"
        color="primary"
      >
        keyboard_double_arrow_left
      </v-icon>
      <v-icon
        class="mr-1"
        :disabled="!enablePrevious"
        @click="previousPage()"
        color="primary"
      >
        keyboard_arrow_left
      </v-icon>
      
      {{ currentPage }}
      
      <v-icon
        class="ml-1"
        :disabled="!enableNext"
        @click="nextPage()"
        color="primary"
      >
        keyboard_arrow_right
      </v-icon>
    </v-row>
  </v-container>
</template>

<script>
  
  export default {
    name: 'DataTablePagination',
    data() {
      return {
        currentPage: 1
      }
    },
    props: ['value', 'paginationTokens'],
    
    watch: {
      currentPage(to, from) {
        if (to != from) {
          this.pageChanged()
        }
      },
      value() {
        this.currentPage = this.value
      }
    },
    computed: {
      enableNext() {
        if (this.paginationTokens[this.currentPage - 1]) {
          return true
        }
        return false
      },
      enablePrevious() {
        if (this.currentPage == 1) {
          return false
        }
        if (!this.paginationTokens[this.currentPage - 2]) {
          return false
        }
        
        return true
      }
    },
    mounted() {
      if (this.value) {
        this.currentPage = this.value
      }
    },
    methods: {
      firstPage() {
        this.currentPage = 1
      },
      nextPage() {
        this.currentPage++
      },
      previousPage() {
        this.currentPage--
      },
      pageChanged() {
        this.$emit('input', this.currentPage)
      }
    }
  }
</script>