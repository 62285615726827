<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">File Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" multiple v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
      <v-col v-if="config.sources.data !== undefined" cols="12">
        <encoder-selector :device_guid='device_guid' type="data" :canEdit="canEdit" multiple v-model="config.sources.data" :filter="supportedDataEncoders" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row v-if="available_drives.length == 0 || isNew">
      <v-alert
        type="info"
        text
      >
        Target selection for this output will be available once you have saved this configuration.
      </v-alert>
    </v-row>
    <v-row v-else class="mb-3">
      <v-list dense width="100%">
        <v-list-item-group
          v-model="config.device_selection_policy_data"
          @change="configChanged"
          color="primary"
        >      
          <v-list-item
            v-for="(drive, index) in available_drives"
            :key="index"
            :value="drive.uuid"
            :disabled="!canEdit"
            two-line
          >
            <v-list-item-icon class="mr-1">
              <v-icon v-if="drive.type == 'MMC'">sd_card</v-icon>
              <v-icon v-else-if="drive.type == 'USB'">mdi-usb-flash-drive</v-icon>
              <v-icon v-else>folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="drive.type == 'OTHER'">Internal Storage</v-list-item-title>
              <v-list-item-title v-else-if="drive.type == 'USB'">USB Storage</v-list-item-title>
              <v-list-item-title v-else-if="drive.type == 'MMC'">SD Card</v-list-item-title>
              <v-list-item-title v-else v-text="drive.type || drive.uuid"></v-list-item-title>
              <v-list-item-subtitle>{{ $helpers.byteFormat(drive.size) }} total, {{ $helpers.byteFormat(drive.free_space) }} free</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col lg="4" cols="6">
        <v-select
          v-model="config.file_format"
          :items="generateSelectItems(file_formatValues, 'daemon.output.file_record.formats', { 'ts': 'TS', 'mp4': 'MP4' })"
          label="File Record Format"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col lg="4" cols="6">
        <v-text-field
          v-model="config.filename_base"
          label="Filename Prefix"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
      <v-col lg="4" cols="6">
        <v-select
          v-model="config.filename_timezone"
          :items="filename_timezoneValues"
          label="Filename Time Format"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-checkbox
          v-model="config.enable_max_file_size"
          label="Limit Max File Size"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.enable_max_file_size">
      <v-row class="mt-3">
        <v-col cols="6">
          <v-text-field
            v-model.number="config.max_file_size"
            label="Max File Size (bytes)"
            @input="configChanged"
            :disabled="!canEdit"
            type="number"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-row class="mb-3">
      <v-col cols="6">
        <v-checkbox
          v-model="config.delete_oldest_when_full"
          label="Delete Oldest File When Full"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">FTP Configuration</h5></v-row>
    <v-row><v-divider /></v-row>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="config.ftp_config.enable"
          label="Upload recordings to FTP server"
          @change="configChanged"
          hide-details
          class="mt-0 mb-3"
          :disabled="!canEdit"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.ftp_config.enable">
      <v-row>
        <v-col cols="9">
          <v-text-field
            v-model="config.ftp_config.server"
            label="FTP Server"
            @input="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            v-model.number="config.ftp_config.port"
            label="Port"
            type="number"
            @input="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="config.ftp_config.file_path"
            label="FTP File Path"
            @input="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col lg="6" cols="12">
          <v-text-field
            v-model="config.ftp_config.username"
            label="FTP Username"
            autocomplete="new-password"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col lg="6" cols="12">
          <v-text-field
            v-model="config.ftp_config.password"
            label="FTP Password"
            type="password"
            autocomplete="new-password"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-3" no-gutters>
        <v-col cols="12">
          <v-checkbox
            v-model="config.ftp_config.delete_file_after_upload"
            label="Delete file from storage after upload"
            @change="configChanged"
            hide-details
            class="mt-0 mb-3"
            :disabled="!canEdit"
          ></v-checkbox>
        </v-col>
      </v-row>
    </div>
    
    <v-alert v-if="isNew && outputLimitReached" type="error" outlined>Unable to add another file output. This device is licensed for {{ featureValue('daemon.output.file_record.limit') }} instance(s).</v-alert>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'FileOutputDocument',
    
    props: ['output', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        ftp_state: {
          current_state: '',
          total_bytes: 0,
          files_remaining: 0,
          bytes_uploaded: 0,
          seconds_elapsed: 0
        },
        
        available_drives: [],
        selected_device_name: '',
        
        filename_timezoneValues: [
          { 'value': 'UTC', 'text': 'UTC' },
          { 'value': 'LOCALTIME', 'text': 'Local Time' },
        ],
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        supportedDataEncoders: [
          'smpte2038'
        ],
        
        config: {
          enable: false,
          
          filename_base: 'VID',
          max_file_size: 2000000000,
                    
          enable_max_file_size: true,
          
          device_selection_policy: 'ATTACH_TIME_FIRST',
          device_selection_policy_data: '',
          
          delete_oldest_when_full: false,
          
          file_format: 'MP4',
          filename_timezone: 'UTC',
          
          sources: {
            audio: [],
            video: []
          },
          
          ftp_config: {
            server: '',
            file_path: '',
            password: '',
            sftp_key: '',
            port: 21,
            enable: false,
            delete_file_after_upload: false,
            username: ''
          },
        }
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      file_formatValues() {
        return  [
          { 'value': 'MP4', 'text': 'MP4 (.mp4)' },
          { 'value': 'TS', 'text': 'Transport Stream (.ts)' },
        ]
      },
      
      outputLimitReached() {
        return !this.hasFeature('daemon.output.file_record.limit', this.instanceCountByType('file'))
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        if (this.config.enable == true) {
          if (this.config.sources && this.config.sources.video.length == 0) {
            console.log('FileOutputDocument ' + this.device_guid + ' validation error: Video encoder must be specified', this.config)
            validationErrors = true
          }
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        if (this.isNew && !this.hasFeature('daemon.output.file_record.limit', this.instanceCountByType('file'))) {
          console.log('FileOutputDocument ' + this.device_guid + ' validation error: Output instance limit reached', this.config)
          validationErrors = true
        }
        
        console.log('FileOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // show data encoder if creating new
        if (this.isNew) {
          if (this.config.sources.data === undefined) {
            this.$set(this.config.sources, 'data', [])
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('FileOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>