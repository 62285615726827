<template>
  <videon-card heading="Adopt a device" max-width="440px">
    <div v-if="loading" class="mx-3 mt-5">
      <v-progress-linear
        color="primary"
        indeterminate
        buffer-value="0"
      ></v-progress-linear>
    </div>
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      <p class="body-2 mb-5">
        You are adopting a device into the <span class="primary--text">{{ orgName }}</span> organization. Please confirm the details and continue.
      </p>
      <v-form @submit.prevent="adoptDevice">
        
        <v-text-field
          v-model="deviceSerial"
          label="Serial Number"
          hint="example: 1234-1234"
          required
          outlined
          :disabled="preparedURL"
        ></v-text-field>
        
        <div class="text-right">
          <v-btn
            class="ma-1"
            color="secondary"
            @click="cancelAdopt"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            class="ma-1"
            color="primary"
            @click="adoptDevice"
          >
            Adopt
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </videon-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceAdoption',
    data() {
      return {
        error: false,
        errorText: '',
        
        loading: false,
        
        preparedURL: false,
        deviceSerial: '',
        deviceGUID: '',
      }
    },
    mounted() {
      this.validatePreparedURL()
    },
    computed: {
      ...mapGetters('user', ['guid', 'organization']),
      ...mapGetters('config', ['deploymentName']),
      orgName() {
        if (this.organization) {
          return this.organization.org_name
        }
        return ''
      },
    },
    methods: {
      validatePreparedURL() {
        console.log('DeviceAdoption validatePreparedURL', this.$route)
        if (this.$route.query && this.$route.query.serial) {
          this.deviceSerial = this.$route.query.serial
          this.deviceGUID = (this.$route.query) ? this.$route.query.device_guid : ''
          this.preparedURL = true
        }
      },
      adoptDevice() {
        this.loading = true
        this.showAdoptDevice = false
        
        this.error = false
        this.errorText = ''
        
        console.log('DeviceAdoption adoptDevice', this.deviceSerial)
        
        this.axios.post('/devices/adopt?serial_number=' + encodeURIComponent(this.deviceSerial) + '&device_guid=' + encodeURIComponent(this.deviceGUID)).then((response) => {
          console.log('DeviceAdoption adoptDevice response', response)
          
          this.deviceSerial = ''
          this.deviceGUID = ''
          
          this.$router.push('/devices')
          
          this.loading = false
        }).catch((error) => {
          console.log('DeviceAdoption adoptDevice error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      cancelAdopt() {
        this.$router.push('/devices')
      }
    }
  }
</script>