<template>
  <div>
    <v-card flat>
      <v-card-title>
        Device Licenses
        <v-spacer/>
        
        <v-btn
          small
          outlined
          class="mr-1"
          color="secondary"
          @click="reloadLicenses"
          tooltip="Reload Licenses"
        >
          Reload
        </v-btn>
        
        <entitlement-debug :device="device" v-if="device.canControl() && isDeviceAdmin" class="mr-1" />
        
        <v-btn
          small
          outlined
          color="primary"
          @click="downloadLicense"
          tooltip="Download Licenses"
        >
          <v-icon>mdi-file-outline</v-icon> Download Licenses
        </v-btn>
      </v-card-title>
      
      <v-card-text>
        <v-alert dense outlined type="error" v-if="error" >
          {{ errorText }}
        </v-alert>
        
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="device.licenses"
              :items-per-page="itemsPerPage"
              hide-default-footer
            >
              <template v-slot:item.profile_guid="{ item }">
                <v-row class="align-center">
                  <license-label :license="item"></license-label>
                </v-row>
              </template>
              <template v-slot:item.license_type="{ item }">
                {{ licenseTypeLabel(item.license_type) }}
              </template>
              <template v-slot:item.created="{ item }">
                <span>{{ item.created | moment("YYYY/MM/DD") }}</span>
              </template>
              <template v-slot:item.expires="{ item }">
                <span v-if="item.expires" :class="(item.expired) ? 'red--text' : ''">{{ item.expires | moment("YYYY/MM/DD") }}</span>
                <span v-else>Never</span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'DeviceLicenses',
    
    props: ['device_guid', 'device'],
        
    data() {
      return {
        error: false,
        errorText: '',
        
        headers: [
          { text: 'License Name', align: '', value: 'profile_guid', sortable: false },
          { text: 'License Type', align: '', value: 'license_type', sortable: false },
          { text: 'Issue Date', align: 'center', value: 'created', sortable: false },
          { text: 'Expiration Date', align: 'center', value: 'expires', sortable: false },
        ],
        
        itemsPerPage: 50,
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'organization', 'isDeviceAdmin']),
    },
    methods: {
      licenseTypeLabel(license_type) {
        if (license_type == 'base') {
          return 'Device Base License'
        } else if (license_type == 'per-device') {
          return 'Device License'
        } else if (license_type == 'all-devices') {
          return 'Organization License'
        }
        return 'Unknown'
      },
      
      reloadLicenses() {
        this.device.fetchLicenses()
      },
      
      downloadLicense() {
        // hardcoded version to ensure version >10.0 for API sake
        this.axios.get('/entitlements/devices/' + this.device.device_guid + '?version=10.3.0')
        .then((response) => {
          console.log('DeviceLicenses ' + this.device.device_guid + ' downloadLicense response', response)
          
          var jsonText = JSON.stringify(response.data)
          var jsonBlob = new Blob([jsonText], { type: "application/json" })
          
          const link = document.createElement('a')
          link.href = URL.createObjectURL(jsonBlob)
          
          link.download = this.device.deviceName().replace(' ', '_') + '.lic'
          
          document.body.appendChild(link)
          
          link.click()
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        })
      }
    }
  }
</script>
