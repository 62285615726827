<template>
  <div class="text-no-wrap">
    <v-row v-if="!loading" :class="(error) ? 'error--text align-center' : 'align-center'">
      
      <v-avatar size="32" class="mr-4">
        <img 
          referrerpolicy="no-referrer"
          v-if="displayPicture" 
          :src="displayPicture" 
          @error="imageError"
        >
        <v-icon v-else :color="(error) ? 'error' : ''">person</v-icon>
      </v-avatar>
      
      <span class="subtitle-1" >{{ displayName }}</span>
      <v-chip
        class="ml-3"
        color="accent"
        small
        pill
        v-if="user.me"
      >
        Me
      </v-chip>
    </v-row>
    <v-progress-linear
      class="ma-2"
      color="primary"
      indeterminate
      buffer-value="0"
      v-if="loading"
    ></v-progress-linear>
  </div>
</template>

<script>
  
  export default {
    name: 'UserLabel',
    props: {
      user: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        noImage: false
      }
    },
    computed: {
      loading() {
        return !this.user.ready
      },
      error() {
        return this.user.error
      },
      displayName() {
        return this.user.displayName()
      },
      displayPicture() {
        if (!this.noImage && this.user.picture) {
          return this.user.picture
        }
        return false
      }
    },
    methods: {
      imageError() {
        this.noImage = true
      }
    }
  }
</script>