<template>
  <div class="d-inline-block text-center">
    <div v-if="fleet.status">
      <v-chip :color="online.color" label outlined small class="ma-1">
        <span class="text-capitalize font-weight-medium">Online</span>: {{ online.label }}
      </v-chip>
      <v-chip :color="streaming.color" label outlined small class="ma-1 d-none d-lg-inline-block">
        <span class="text-capitalize font-weight-medium">Streaming</span>: {{ streaming.label }}
      </v-chip>
    </div>
    <v-progress-linear
      class="ma-2"
      color="primary"
      indeterminate
      buffer-value="0"
      v-if="!fleet.status && !fleet.error"
      style="min-width: 100px"
    ></v-progress-linear>
    <v-chip v-if="fleet.error" color="error" label outlined small class="ma-1">
      Error fetching status
    </v-chip>
  </div>
</template>

<script>
  
  export default {
    name: 'FleetStatusLabel',
    props: ['fleet'],
    computed: {
      online() {
        if (this.fleet.status && this.fleet.status.total_devices) {
          var color = 'info' //(this.fleet.status.total_online < this.fleet.status.total_devices) ? 'red' : 'green'
          if (this.fleet.status.total_devices == 0) {
            color = 'grey'
          }
          return {'label': '' + parseInt(this.fleet.status.total_online) + '/' + parseInt(this.fleet.status.total_devices), 'color': color}
        }
        return {'label': '0', 'color': 'grey'}
      },
      streaming() {
        if (this.fleet.status && this.fleet.status.total_devices) {
          var color = 'info' //(this.fleet.status.total_streaming < this.fleet.status.total_devices) ? 'red' : 'green'
          return {'label': '' + parseInt(this.fleet.status.total_streaming) + '/' + parseInt(this.fleet.status.total_devices), 'color': color}
        }
        return {'label': '0', 'color': 'grey'}
      }
    },
    mounted() {
      this.fleet.fetchStatus()
    } 
  }
</script>