<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Zixi Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Encoder Selection</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="video" :canEdit="canEdit" v-model="config.sources.video" @change="configChanged" :required="config.enable" :rules="videoEncoderValidation" />
      </v-col>
      <v-col lg="6" cols="12">
        <encoder-selector :device_guid='device_guid' type="audio" :canEdit="canEdit" multiple v-model="config.sources.audio" :filter="supportedAudioEncoders" @change="configChanged"/>
      </v-col>
      <v-col v-if="config.sources.data !== undefined" cols="12">
        <encoder-selector :device_guid='device_guid' type="data" :canEdit="canEdit" multiple v-model="config.sources.data" @change="configChanged"/>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Target</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    
    <v-row class="mb-3">
      <v-col cols="9">
        <v-text-field
          v-model="config.destination_ip"
          label="Destination Address"
          @input="configChanged"
          :disabled="!canEdit"
          :rules="$validations.destinationAddressValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="config.destination_port"
          label="Port"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="$validations.portValidation"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col lg="3" cols="6">
        <v-text-field
          v-model.number="config.max_latency_ms"
          label="Max Latency (ms)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col lg="4" cols="6">
        <v-text-field
          v-model.number="config.fec_percentage"
          label="FEC Overhead (%)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          :rules="$validations.percent"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row class="mb-3" v-if="config.enable_back_pressure_bitrate_adjustment !== undefined">
      <v-col cols="12">
        <div class="d-flex align-center">
          <v-checkbox
            v-model="config.enable_back_pressure_bitrate_adjustment"
            label="Enable Encoder Back Pressure Bitrate Adjustment"
            @change="configChanged"
            :disabled="!canEdit"
            :rules="enable_back_pressure_bitrate_adjustmentValidation"
            class="mt-0"
            hide-details="auto"
          ></v-checkbox>
          <v-tooltip bottom max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                v-bind="attrs"
                v-on="on"
                small
                class="ma-1"
              >
                <v-icon>question_mark</v-icon>
              </v-btn>
            </template>
            <span style="line-height: 1.5em">
              Enabling the Back Pressure Bitrate Adjustment allows the encoder bitrate to be controlled by the endpoint, 
              this may cause unintended consequences if the encoder is in use by multiple outputs.
            </span>
          </v-tooltip>
        </div>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col lg="7" cols="12">
        <v-text-field
          v-model="config.stream_id"
          label="Stream ID"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row>
      <v-col lg="7" cols="12">
        <v-text-field
          v-model="config.passphrase"
          autocomplete="new-password"
          type="password"
          label="Passphrase (optional)"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row :class="(!config.encryption_enabled) ? 'mb-3' : ''">
      <v-col cols="12">
        <v-checkbox
          v-model="config.encryption_enabled"
          label="Enable Encryption"
          @change="configChanged"
          hide-details
          class="mt-0"
          :disabled="!canEdit"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.encryption_enabled">
      <v-row class="mb-3">
        <v-col cols="3">
          <v-select
            v-model="config.key_size"
            :items="key_sizeValues"
            label="Key Size (bits)"
            @change="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-select>
        </v-col>
        <v-col cols="9">
          <v-text-field
            v-model="config.encryption_key"
            autocomplete="new-password"
            type="password"
            label="Encryption Key"
            @input="configChanged"
            outlined
            hide-details="auto"
            :disabled="!canEdit"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-alert v-if="isNew && outputLimitReached" type="error" outlined>Unable to add another Zixi output. This device is licensed for {{ featureValue('daemon.output.zixi.limit') }} instance(s).</v-alert>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteOutput()"
      >
        Delete Output
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import EncoderSelector from '../components/EncoderSelector.vue'
  
  export default {
    name: 'ZixiOutputDocument',
    
    props: ['output', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      EncoderSelector
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        backpressureSettingsVersion: this.$helpers.parseVersion('cloud', 'v1.11.0'),
        
        valid: true,
        
        id: false,
        type: false,
        
        key_sizeValues: [
          { 'value': 'AES128', 'text': '128' },
          { 'value': 'AES192', 'text': '192' },
          { 'value': 'AES256', 'text': '256' },
        ],
        
        supportedAudioEncoders: [
          'mpeg4_aac'
        ],
        
        config: {
          enable: false,
          
          destination_ip: '',
          destination_port: 2088,
          
          max_latency_ms: 3000,
          fec_percentage: 30,
          
          stream_id: '',
          
          passphrase: '',
          
          sources: {
            audio: [],
            video: []
          },
          
          encryption_enabled: false,
          encryption_key: '',
          key_size: 'AES128',
        }
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      enable_back_pressure_bitrate_adjustmentValidation() {
        var videoEncoder = this.selectedVideoEncoder
        if (this.config.enable_back_pressure_bitrate_adjustment == true && !videoEncoder.config.allow_outputs_to_adjust_bitrate) {
          return ['The selected Video Encoder does not allow its bitrate to be adjusted by Zixi back pressure.']
        } else if (this.config.enable_back_pressure_bitrate_adjustment == true && this.videoEncoderHasBackpressureControl) {
          return ['Only one Zixi output can have back pressure bitrate adjustment enabled for each video encoder.']
        }
        return []
      },
      
      outputLimitReached() {
        return !this.hasFeature('daemon.output.zixi.limit', this.instanceCountByType('zixi'))
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        if (this.config.enable == true) {
          if (this.config.sources && this.config.sources.video.length == 0) {
            console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: Video encoder must be specified', this.config)
            validationErrors = true
          }
          
          var ipValidation = this.$validations.isValidIpAddress(this.config.destination_ip)
          var hostnameValidation = this.$validations.isValidHostname(this.config.destination_ip)
        
          // check for validation errors
          if (!ipValidation) {
            if (!hostnameValidation) {
              console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: Destination Address must be a valid IP address or hostname', this.config)
              validationErrors = true
            }
          }
        
          if (this.config.destination_port < 1000 || this.config.destination_port > 65000) {
            console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: Port must be between 1000 and 65000', this.config)
            validationErrors = true
          }
        
          if (this.config.fec_percentage < 0 || this.config.fec_percentage > 100) {
            console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: Percentages must be between 0 and 100', this.config)
            validationErrors = true
          }
          
          var videoEncoder = this.selectedVideoEncoder
          if (this.config.enable_back_pressure_bitrate_adjustment == true && !videoEncoder.config.allow_outputs_to_adjust_bitrate) {
            console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: enable_back_pressure_bitrate_adjustment enabled, but video encoder does not support allow_outputs_to_adjust_bitrate', this.config)
            validationErrors = true
          } else if (this.config.enable_back_pressure_bitrate_adjustment == true && this.videoEncoderHasBackpressureControl) {
            console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: enable_back_pressure_bitrate_adjustment enabled, another zixi output has this video encoder', this.config)
            validationErrors = true
          }
          
          this.$refs.form.validate()
        } else {
          this.$refs.form.resetValidation()
        }
        
        if (this.isNew && !this.hasFeature('daemon.output.zixi.limit', this.instanceCountByType('zixi'))) {
          console.log('ZixiOutputDocument ' + this.device_guid + ' validation error: Output instance limit reached', this.config)
          validationErrors = true
        }
        
        console.log('ZixiOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // show data encoder if creating new
        if (this.isNew) {
          if (this.config.sources.data === undefined) {
            this.$set(this.config.sources, 'data', [])
          }
        }
        
        // populate transient values
        if (this.isNew && (this.device.cloud_version.dev || this.$helpers.versionCheck(this.backpressureSettingsVersion, this.device.cloud_version))) {
          console.log('ZixiOutputDocument populating transient properties')
          if (!this.config.enable_back_pressure_bitrate_adjustment) {
            this.$set(this.config, 'enable_back_pressure_bitrate_adjustment', false)
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('ZixiOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>