<template>
  <v-container class="pa-0 my-3">
    <h4>E-mail Selection</h4>
    <v-card outlined flat>
      <v-row class="mx-0 my-1" align="center" style="min-height: 48px">
        <v-combobox
          class="ma-0 pa-0"
          v-model="items"
          label="Please make a selection"
          hide-selected
          hide-details
          disable-lookup
          multiple
          flat
          solo
          append-icon=""
          :disabled="disabled"
        >
          <template v-slot:label>
            <span class="pa-2 v-card__text text--secondary">Please make a selection</span>
          </template>
          <template v-slot:selection="{ attrs, item, _parent, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              outlined
              close
              close-icon="mdi-close"
              @click:close="removeItem(item)"
              class="ma-1"
            >
              {{ item }}
            </v-chip>
          </template>
        </v-combobox>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>  
  export default {
    name: 'EmailListSelectionComboBox',
    
    data() {
      return {
        error: false,
        errorText: '',
        
        items: []
      }
    },
    props: ['value', 'disabled'],
    watch: {
      value() {
        this.items = this.value
      },
      items() {
        this.selectionChanged()
      }
    },
    mounted() {
      if (this.value) {
        this.items = this.value
      }
    },
    
    methods: {
      removeItem(item) {
        for (var x in this.items) {
          if (this.items[x] == item) {
            this.items.splice(x, 1)
            return
          }
        }
      },
      selectionChanged() {
        this.$emit('input', this.items)
      }
    }
  }
</script>