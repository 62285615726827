<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Data Encoder</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="config.data_type"
          :items="data_typeValues"
          label="Data Type"
          @change="dataTypeChanged"
          :disabled="!canEdit"
          :readonly="!isNew"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Encoder Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <div v-if="config.in_channel_id !== undefined">
      <v-row><h5 class="text--disabled">Input Selection</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-5">
        <v-col lg="12" cols="12">
          <input-selector :device_guid='device_guid' :canEdit="canEdit" v-model="config.in_channel_id" @change="configChanged" />
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Encoder Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    
    <div v-if="config.data_type == 'klv_sync'">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            class="mt-3"
          >
            When enabled KLV (Key-Length-Value) data is stored in a metadata stream and included in connected transport stream-based outputs.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="config.data_type == 'scte35'">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            class="mt-3"
          >
            When enabled this feature allows for SCTE-35 splice marker insertion via REST API that will send SCTE-35 markers to the connected transport stream-based outputs..
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <div v-else-if="config.data_type == 'smpte2038'">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            class="mt-3"
          >
            When enabled ANC data in the SDI input will be converted to SMPTE-2038 data for delivery in connected transport stream-based outputs..
          </v-alert>
        </v-col>
      </v-row>
    </div>
    
    <v-alert v-if="isNew && encoderLimitReached" type="error" outlined>Unable to add another data encoder. This device is licensed for {{ featureValue(entitlementKey) }} {{ $helpers.getLabel(config.data_type) }} encoder instance(s).</v-alert>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Encoder Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ encoder }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteEncoder()"
      >
        Delete Encoder
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import InputSelector from '../components/InputSelector.vue'

  export default {
    name: 'DataEncoderDocument',
    
    props: ['encoder', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    mixins:[ShadowMixins],
    
    components: {
      InputSelector
    },
    
    data() {
      return {
        valid: true,
        
        id: false,
        type: false,
        
        config: {
          name: '',
          
          data_type: 'scte35',
        }
      }
    },
    
    watch: {
      encoder() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      data_typeValues() {
        return [
          { 'value': 'klv_sync', 'text': 'KLV SMPTE-336' },
          { 'value': 'scte35', 'text': 'SCTE-35' },
          { 'value': 'smpte2038', 'text': 'SMPTE-2038' },
        ]
      },
      
      entitlementKey() {
        if (this.config.data_type == 'scte35') {
          return 'daemon.encoders.data.scte_35.limit'
        } else {
          return 'daemon.encoders.data.' + this.config.data_type + '.limit'
        }
      },
      
      encoderLimitReached() {
        return !this.hasFeature(this.entitlementKey, this.instanceCountByDataType(this.config.data_type)) 
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      dataTypeChanged() {
        console.log('DataEncoderDocument ' + this.device_guid + ' configChanged', this.config)
        this.$delete(this.config, 'splice_duration')
        this.$delete(this.config, 'in_channel_id')
        this.updateValues()
      },
      
      configChanged() {
        var validationErrors = false
        
        if (this.isNew && !this.hasFeature(this.entitlementKey, this.instanceCountByDataType(this.config.data_type))) {
          console.log('DataEncoderDocument ' + this.device_guid + ' validation error: Encoder instance limit reached', this.config)
          validationErrors = true
        }
        
        console.log('DataEncoderDocument ' + this.device_guid + ' configChanged', this.config, validationErrors)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.encoder && this.encoder.config) {
          Object.keys(this.encoder).map((key) => {
            this[key] = this.encoder[key]
          })
          
          // htmlDecode name properties
          if (this.config.name) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // populate defaults for creation
        if (this.isNew) {
          if (this.config.data_type == 'klv_sync') {
            // do nothing
          } else if (this.config.data_type == 'scte35') {
            // do nothing
          } else if (this.config.data_type == 'smpte2038') {
            if (!this.config.in_channel_id) {
              this.config.in_channel_id = -1
            }
          }
        }
        
        this.configChanged()
      },
      
      deleteEncoder() {
        console.log('DataEncoderDocument ' + this.device_guid + ' deleteEncoder')
        this.$emit('deleteEncoder')
      }
    }
  }
</script>