<template>
  <v-list-group
    :group="group"
    :sub-group="subGroup"
    :prepend-icon="prependIcon"
  >
    <template v-slot:activator >
      <v-list-item-icon v-if="item.icon && !subGroup" class="icon">
        <v-icon>{{ item.icon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </template>

    <template v-for="(child, i) in children">
      <nav-item-group
        v-if="child.children && !filterNavItem(child)"
        :key="`sub-group-${i}`"
        :item="child"
        sub-group
      />
      <nav-item
        v-else-if="!filterNavItem(child)"
        :key="`item-${i}`"
        :item="child"
        text
      />
    </template>
  </v-list-group>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'NavItemGroup',

    inheritAttrs: false,

    props: {
      item: {
        type: Object,
        default: () => ({
          avatar: undefined,
          group: undefined,
          title: undefined,
          children: [],
        }),
      },
      subGroup: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
    },
    
    computed: {
      ...mapGetters('user', ['groups']),
      
      children () {
        return this.item.children.map(item => ({
          ...item,
          to: !item.to ? undefined : item.to,
        }))
      },
      group () {
        return this.genGroup(this.item.children)
      },
      prependIcon() {
        if (this.subGroup) {
          return this.item.icon
        }
        return ''
      }
    },

    methods: {
      genGroup (children) {
        return children.map(item => {
            if (item.children) {
              return this.genGroup(item.children)
            } else {
              return '^' + item.to + '$'
            }
          }).join('|')
      },
      
      filterNavItem(item) {
        if ((!item.requiresAdmin && !item.requiresGroup) 
          || (item.requiresAdmin && this.isAdmin)
          || (item.requiresGroup && this.groups.includes(item.requiresGroup))
        ) {
          return false
        }
        return true
      }
    },
  }
</script>
