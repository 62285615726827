<template>
  <v-card 
    class="mx-auto"
  >
    <v-toolbar flat>
      <v-toolbar-title>
        <v-icon size="32px">search</v-icon>
        Device Search
      </v-toolbar-title>
      <v-spacer></v-spacer>
      
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-tabs
        v-model="section"
        color="secondary"
        :show-arrows="true"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab tab-value="metadata">
          Metadata Search
        </v-tab>
        <!-- v-tab tab-value="shadow">
          Device Shadow
        </v-tab -->
      </v-tabs>
      <v-divider />
      <v-tabs-items v-model="section">
        <v-tab-item value="metadata">
          <v-alert dense outlined type="error" v-if="error">
            {{ errorText }}
          </v-alert>
          <v-container>
            <v-row class="my-1" align="end">
              <v-col cols="4">
                <v-select
                  v-model="searchKey"
                  :items="searchKeys"
                  @change="searchMetadata"
                  hide-details
                ></v-select>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="mr-5"
                  v-model="searchFilter"
                  label="Search"
                  append-icon="mdi-magnify"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="searchKey != 'device_alias' && filteredMetadata.length > 0">
              <v-toolbar
                flat
                dense
              >
                <v-chip
                  class="mr-1"
                  label
                  v-for="(data, index) in filteredMetadata"
                  v-bind:key="index"
                  outlined
                >
                  <strong>{{ $helpers.getLabel(data.key) }}</strong>: {{ $helpers.getLabel(data.value) }}
                </v-chip>
              </v-toolbar>
            </v-row>
            <v-row>
              <v-col cols="12">
                <device-list
                  v-model="metadataDevices"
                  :loading="metadataLoading"
                  loadingText="Building searchable index..."
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        
        <v-tab-item value="shadow">
          <v-alert dense outlined type="error" v-if="error">
            {{ errorText }}
          </v-alert>
          <v-container>
            <v-row class="my-1" align="end">
              <v-col cols="12">
                <v-text-field
                  class="mr-5"
                  v-model="searchFilter"
                  label="Search"
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <device-list
                  v-model="shadowDevices"
                  :loading="shadowLoading"
                  loadingText="Building searchable index..."
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DeviceList from '../device/components/DeviceList.vue'
  
  export default {
    name: 'DeviceSearch',
    
    components: {
      DeviceList,
    },
    
    data() {
      return {
        error: false,
        errorText: '',
        
        searchKeys: [
          { 'value': 'device_alias', 'text': 'Device Alias' },
          { 'value': 'location', 'text': 'Location' },
          { 'value': 'note', 'text': 'Note' },
        ],
        
        section: 'metadata',
        
        searchKey: 'device_alias',
        searchFilter: '',
        awaitingSearch: false,
        
        metadataLoading: false,
        metadataIndex: false,
        filteredMetadata: [],
        metadataDevices: [],
        
        shadowLoading: false,
        shadowIndex: false,
        shadowDevices: [],
        
        itemsPerPage: 50,
      }
    },
    
    created() {
      this.section = this.$route.query.section || 'metadata'
      this.searchKey = this.$route.query.searchKey || 'device_alias'
      this.searchFilter = this.$route.query.searchFilter || ''
      
      if (this.section == 'metadata') {
        this.searchMetadata()
      } else {
        this.buildShadowIndex()
      }
    },
    
    watch: {
      section() {
        if (this.section == 'metadata' && !this.metadataIndex) {
          this.searchMetadata()
        } else if (this.section == 'shadow' && !this.shadowIndex) {
          this.buildShadowIndex()
        }
        
        this.updateQueryParameters()
      },
      
      searchFilter() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            
            if (this.section == 'metadata') {
              this.filterMetadata()
            } else {
              this.filterShadowIndex()
            }
            
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      },
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'organization']),
    },
    methods: {
      async searchMetadata() {
        this.metadataLoading = true
        
        var metadataIndex = []
        
        var queryParams = {
          'pagination_size': this.itemsPerPage,
          'search_keys': [this.searchKey]
        }
        
        var paginationToken = false
        
        try {
          do {
            if (paginationToken) {
              queryParams.pagination_token = paginationToken
            }
            
            let response = await this.axios.post('/orgs/' + this.organization.org_guid + '/device_metadata', queryParams)
            console.log('DeviceSearch searchMetadata response', response)
            
            if (response.data.metadata.length > 0) {
              metadataIndex = metadataIndex.concat(response.data.metadata)
              
              if (response.data.pagination_token) {
                paginationToken = response.data.pagination_token
              } else {
                paginationToken = false
              }
            }
          } while (paginationToken)
        } catch (error) {
          console.log('DeviceSearch searchMetadata error', error)
        }
        
        this.metadataIndex = metadataIndex
        this.metadataLoading = false
        
        this.filterMetadata()
        
        this.updateQueryParameters()
      },
      
      filterMetadata() {
        if (this.searchFilter && this.metadataIndex) {
          var filteredMetadata = this.metadataIndex.filter((data) => {
            if (data.value.toLowerCase().indexOf(this.searchFilter.toLowerCase()) >= 0) {
              return data
            }
          })
          
          this.filteredMetadata = [...new Map(filteredMetadata.map(item => [item['value'], item])).values()]
          
          this.metadataDevices = filteredMetadata.map((data) => {
            return this.$devices.getDevice(data.device_guid)
          })
          
        } else {
          this.filteredMetadata = []
          this.metadataDevices = []
        }
        
        this.updateQueryParameters()
      },
      
      async buildShadowIndex() {
        this.shadowLoading = true
        
        var deviceIndex = []
        var shadowIndex = []
        
        var queryParams = {
          'pagination_size': this.itemsPerPage,
          'org_guid': this.organization.org_guid
        }
        
        var paginationToken = false
        
        try {
          do {
            if (paginationToken) {
              queryParams.pagination_token = paginationToken
            }
            
            let response = await this.axios.get('/devices', queryParams)
            console.log('DeviceSearch buildShadowIndex device response', response)
            
            if (response.data.devices.length > 0) {
              deviceIndex = deviceIndex.concat(response.data.devices)
              
              if (response.data.pagination_token) {
                paginationToken = response.data.pagination_token
              } else {
                paginationToken = false
              }
            }
          } while (paginationToken)
        } catch (error) {
          console.log('DeviceSearch buildShadowIndex error', error)
        }
        
        for (var device of deviceIndex) {
          let response = await this.axios.get('/devices/' + device.device_guid + '/shadows', {
            'params': {
              'shadow_names': 'System',
              'skip_requested_state': true
            }
          })
        
          if (response.data.shadows && response.data.shadows[0] && response.data.shadows[0].reported) {
            var systemShadow = response.data.shadows[0].reported.state
            
            if (systemShadow.config && systemShadow.config.device_name) {
              // we have a device name!
              shadowIndex.push({'device_name': systemShadow.config.device_name, 'device_guid': device.device_guid})
            }
          }
        }
        
        this.shadowIndex = shadowIndex
        this.shadowLoading = false
        
        this.filterShadowIndex()
        
        this.updateQueryParameters()
      },
      
      filterShadowIndex() {
        if (this.searchFilter && this.shadowIndex) {
          var filteredShadows = this.shadowIndex.filter((device) => {
            if (device.device_name.toLowerCase().indexOf(this.searchFilter.toLowerCase()) >= 0) {
              return device
            }
          })
          
          this.shadowDevices = filteredShadows.map((data) => {
            return this.$devices.getDevice(data.device_guid)
          })
          
        } else {
          this.filteredMetadata = []
          this.metadataDevices = []
        }
        
        this.updateQueryParameters()
      },
      
      updateQueryParameters() {
        this.$router.push({ path: '/search', query: {
          'section': this.section,
          'searchKey': this.searchKey,
          'searchFilter': this.searchFilter,
          'timestamp': new Date().getTime(),
        }}).catch((error) => {
          console.log('Metrics updateQueryParameters error', error)
        })
      },
    }
  }
</script>