<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">System Status</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <videon-state-tile
        heading="MAC Address"
      >
        {{ mac_address }}
      </videon-state-tile>
      <videon-state-tile
        heading="Network Connected"
        class="text-capitalize"
      >
        {{ net_connected }}
      </videon-state-tile>
      <videon-state-tile
        heading="IP Address"
      >
        {{ network.ip_address }}
      </videon-state-tile>
      <videon-state-tile
        heading="DNS Servers"
      >
        {{ dnsServers }}
      </videon-state-tile>
    </v-row>
    
    <v-row><h5 class="text--disabled">System Settings</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.device_name"
          label="Device Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Network Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-btn-toggle
          mandatory
          v-model="config.ip_scheme"
          @change="configChanged"
          :disabled="!canEdit"
        >
          <v-btn 
            v-for="(item) in ip_schemeValues" 
            :key="item.value"
            :value="item.value"
            :disabled="!canEdit"
            outlined
            small
          >
            {{ item.text }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <div v-if="config.ip_scheme == 'static'">
      <v-row class="mb-3">
        <v-col cols="6">
          <v-text-field
            v-model="config.static_network.ip_address"
            label="IP Address"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="config.static_network.netmask"
            label="Subnet Netmask"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="6">
          <v-text-field
            v-model="config.static_network.gateway"
            label="Gateway IP"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          
        </v-col>
      </v-row>
      
      <v-row class="mb-3" v-if="config.static_network.dns_addresses">
        <v-col cols="6">
          <v-text-field
            v-model="config.static_network.dns_addresses[0]"
            label="DNS Server 1"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="config.static_network.dns_addresses[1]"
            label="DNS Server 2"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">NTP Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.ntp_server"
          label="NTP Server"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <timezone-selector v-model="config.timezone" @change="configChanged" :disabled="!canEdit" :product_name="device.product_name"/>
      </v-col>
    </v-row>
    
    <div v-if="config.xml">
      <v-row><h5 class="text--disabled">XML Configuration</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <v-checkbox
            v-model="config.xml.enabled"
            label="Remote XML Document Configuration"
            @change="configChanged"
            :disabled="!canEdit || !hasFeature('services.xml.enabled')"
            hide-details
            class="mt-0"
          ></v-checkbox>
        </v-col>
      </v-row>
      <div v-if="config.xml.enabled">
        <v-row>
          <v-col cols="9">
            <v-text-field
              v-model="config.xml.url"
              label="XML Document URL"
              @input="configChanged"
              :disabled="!canEdit"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="config.xml.poll_interval"
              label="Poll Interval"
              @input="configChanged"
              :disabled="!canEdit"
              type="number"
              outlined
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        
        <v-row class="mb-3">
          <v-col lg="6" cols="12">
            <v-text-field
              v-model="config.xml.username"
              label="HTTP Username"
              autocomplete="new-password"
              @input="configChanged"
              :disabled="!canEdit"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col lg="6" cols="12">
            <v-text-field
              v-model="config.xml.password"
              label="HTTP Password"
              type="password"
              autocomplete="new-password"
              @input="configChanged"
              :disabled="!canEdit"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    
    <div v-if="shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">System Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ system }}</pre>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>  
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'

  export default {
    name: 'SystemDocument',
    
    props: ['system', 'device_guid', 'canEdit'],
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        // info
        device_id: '',
        version: '',
        
        mac_address: '',
        net_connected: false,
        
        network: {
          ip_address: '',
          netmask: '',
          gateway: '',
          dns_addresses: []
        },
        
        ip_schemeValues: [
          { 'value': 'dhcp', 'text': 'DHCP' },
          { 'value': 'static', 'text': 'Manual (Static)' },
        ],
        
        config: {
          device_name: '',
        
          ip_scheme: 'dhcp',
        
          static_network: {
            ip_address: '',
            netmask: '',
            gateway: '',
            dns_addresses: ['', '']
          },
          
          ntp_server: '',
          timezone: '',
          
          xml: {
            enabled: false,
            poll_interval: 60,
            url: '',
            username: '',
            password: ''
          }
        }
        
      }
    },
    
    computed: {
      dnsServers() {
        if (Array.isArray(this.network.dns_addresses)) {
          return this.network.dns_addresses.join(', ')
        }
        return this.network.dns_addresses
      },
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      ...mapGetters('userPreferences', ['shadowDebug']),
    },
    
    watch: {
      system() {
        this.updateValues()
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        // apply data cleanup
        
        // if we were static, and moving to dhcp, reset static ip 
        if (this.config.ip_scheme == 'dhcp' && this.config.static_network.ip_address) {
          this.config.static_network = {
            ip_address: '',
            netmask: '',
            gateway: '',
            dns_addresses: ['', '']
          }
        }
        
        // if we were dhcp, and moving to static, pre-populate the static config with the current dhcp values
        if (this.config.ip_scheme == 'static' && !this.config.static_network.ip_address) {
          this.config.static_network = {
            ip_address: this.network.ip_address,
            netmask: this.network.netmask,
            gateway: this.network.gateway,
            dns_addresses: this.network.dns_addresses
          }
        }
        
        if (this.device.product_name == 'edgecaster_max' && this.config.static_network.dns_addresses) {
          if (this.config.static_network.dns_addresses[1] == '') {
            this.config.static_network.dns_addresses.splice(1, 1)
          }
          if (this.config.static_network.dns_addresses[0] == '') {
            this.config.static_network.dns_addresses.splice(0, 1)
          }
        }
        
        this.$emit('configUpdate', this.config)
      },
      
      updateValues() {
        if (this.system && this.system.config) {
          Object.keys(this.system).map((key) => {
            this[key] = this.system[key]
          })
        }
        
        // apply data cleanup
        if (this.device.product_name == 'edgecaster') {
          // ensure 2 dns addresses...
          this.config.static_network.dns_addresses = [
            (this.config.static_network.dns_addresses[0]) ? this.config.static_network.dns_addresses[0] : '',
            (this.config.static_network.dns_addresses[1]) ? this.config.static_network.dns_addresses[1] : ''
          ]
        }
        
        // htmlDecode name properties
        this.config.device_name = this.$helpers.htmlDecode(this.config.device_name)
        
        this.configChanged()
      }
    }
  }
</script>