import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

// custom icons
import SVGIcon from '../components/common/SVGIcon.vue'

import VideonLogo from '@/assets/videon-logo-icon-dark-orange-64.svg'
import VideonLogoDark from '@/assets/videon-logo-icon-white-orange-64.svg'

import GoogleIcon from '@/assets/google/btn_google_light_normal_ios.svg'
import OktaIcon from '@/assets/okta/okta-o.svg'

import Croissant from '@/assets/icons/croissant.svg'

import EdgeCaster from '@/assets/videon/EZ.svg'
import EdgeCasterMax from '@/assets/videon/LE-Max-23.svg'

Vue.use(Vuetify)

export default new Vuetify({
  // Vuetify Videon Theme
  theme: {
    dark: false,
    
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        background: '#F6F6F6',
        
        dark_background: '#24222A',
        nav_background: '#2b2432',
        
        primary: '#FF6900',
        secondary: '#82B1FF',
        accent: '#9E00FF',
        
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        
        videon_orange: '#FF6900',
        videon_dark: '#24222A',
        videon_light: '#FFFFFF',
        videon_purple: '#9E00FF'
      },
      dark: {
        background: '#222028',
        
        dark_background: '#24222A',
        nav_background: '#2b2432',
        
        primary: '#FF6900',
        secondary: '#82B1FF',
        accent: '#9E00FF',
        
        error: '#F88686',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        
        videon_orange: '#FF6900',
        videon_dark: '#24222A',
        videon_light: '#FFFFFF',
        videon_purple: '#9E00FF'
      }
    },
  },
  icons: {
    values: {
      videon_logo: {
        component: SVGIcon,
        props: {
          icon: VideonLogo
        }
      },
      videon_logo_dark: {
        component: SVGIcon,
        props: {
          icon: VideonLogoDark
        }
      },
      google_icon: {
        component: SVGIcon,
        props: {
          icon: GoogleIcon
        }
      },
      okta_icon: {
        component: SVGIcon,
        props: {
          icon: OktaIcon
        }
      },
      croissant: {
        component: SVGIcon,
        props: {
          icon: Croissant
        }
      }, 
      edgecaster: {
        component: SVGIcon,
        props: {
          icon: EdgeCaster
        }
      }, 
      edgecaster_max: {
        component: SVGIcon,
        props: {
          icon: EdgeCasterMax
        }
      }, 
    }
  }
})
