<template>
  <v-card 
    class="mx-1 my-1"
    :min-width="minWidth"
    flat
  >
    <v-row class="ma-1">
      <div class="col pa-2">
        <h4 class="text-truncate text-uppercase">{{ heading }}</h4>
        <div class="ma-0">
          <slot />
        </div>
      </div>
    </v-row>
  </v-card>
</template>

<script>
  
  export default {
    name: 'VideonStateTile',
    props: {
      heading: {
        type: String,
        default: '',
      },
      minWidth: {
        type: String,
        default: '200px'
      }
    }
  }
</script>