export default {
  // metrics labels
  'cpu_load1': { label: '1min Load Average', unit: ''},
  'cpu_load5': { label: '5min Load Average', unit: ''},
  'cpu_load15': { label: '15min Load Average', unit: ''},
  
  'cpu_percent_used': { label: 'CPU Utilization (ALL)', unit: '%'},
  
  'cpu_CPU0_percent_used': { label: 'CPU0 Utilization', unit: '%'},
  'cpu_CPU1_percent_used': { label: 'CPU1 Utilization', unit: '%'},
  'cpu_CPU2_percent_used': { label: 'CPU2 Utilization', unit: '%'},
  'cpu_CPU3_percent_used': { label: 'CPU3 Utilization', unit: '%'},
  'cpu_CPU4_percent_used': { label: 'CPU4 Utilization', unit: '%'},
  'cpu_CPU5_percent_used': { label: 'CPU5 Utilization', unit: '%'},
  'cpu_CPU6_percent_used': { label: 'CPU6 Utilization', unit: '%'},
  'cpu_CPU7_percent_used': { label: 'CPU7 Utilization', unit: '%'},
  
  'cpu_CPU0_MHZ_min': { label: 'CPU0 MHz (Min)', unit: 'MHz'},
  'cpu_CPU0_MHZ_max': { label: 'CPU0 MHz (Max)', unit: 'MHz'},
  'cpu_CPU0_MHZ_current': { label: 'CPU0 MHz', unit: 'MHz'},
  
  'cpu_CPU1_MHZ_min': { label: 'CPU1 MHz (Min)', unit: 'MHz'},
  'cpu_CPU1_MHZ_max': { label: 'CPU1 MHz (Max)', unit: 'MHz'},
  'cpu_CPU1_MHZ_current': { label: 'CPU1 MHz', unit: 'MHz'},
  
  'cpu_CPU2_MHZ_min': { label: 'CPU2 MHz (Min)', unit: 'MHz'},
  'cpu_CPU2_MHZ_max': { label: 'CPU2 MHz (Max)', unit: 'MHz'},
  'cpu_CPU2_MHZ_current': { label: 'CPU2 MHz', unit: 'MHz'},
  
  'cpu_CPU3_MHZ_min': { label: 'CPU3 MHz (Min)', unit: 'MHz'},
  'cpu_CPU3_MHZ_max': { label: 'CPU3 MHz (Max)', unit: 'MHz'},
  'cpu_CPU3_MHZ_current': { label: 'CPU3 MHz', unit: 'MHz'},
  
  'cpu_CPU4_MHZ_min': { label: 'CPU4 MHz (Min)', unit: 'MHz'},
  'cpu_CPU4_MHZ_max': { label: 'CPU4 MHz (Max)', unit: 'MHz'},
  'cpu_CPU4_MHZ_current': { label: 'CPU4 MHz', unit: 'MHz'},
  
  'cpu_CPU5_MHZ_min': { label: 'CPU5 MHz (Min)', unit: 'MHz'},
  'cpu_CPU5_MHZ_max': { label: 'CPU5 MHz (Max)', unit: 'MHz'},
  'cpu_CPU5_MHZ_current': { label: 'CPU5 MHz', unit: 'MHz'},
  
  'cpu_CPU6_MHZ_min': { label: 'CPU6 MHz (Min)', unit: 'MHz'},
  'cpu_CPU6_MHZ_max': { label: 'CPU6 MHz (Max)', unit: 'MHz'},
  'cpu_CPU6_MHZ_current': { label: 'CPU6 MHz', unit: 'MHz'},
  
  'cpu_CPU7_MHZ_min': { label: 'CPU7 MHz (Min)', unit: 'MHz'},
  'cpu_CPU7_MHZ_max': { label: 'CPU7 MHz (Max)', unit: 'MHz'},
  'cpu_CPU7_MHZ_current': { label: 'CPU7 MHz', unit: 'MHz'},
  
  'gpu_MHZ_min': { label: 'GPU MHz (Min)', unit: 'MHz'},
  'gpu_MHZ_max': { label: 'GPU MHz (Max)', unit: 'MHz'},
  'gpu_MHZ_current': { label: 'GPU MHz', unit: 'MHz'},
  
  'memory_system_percent_used': { label: 'Memory Utilization', unit: '%'},
  'memory_system_bytes_total': { label: 'Memory Total', unit: 'bytes'},
  'memory_system_bytes_free': { label: 'Memory Free', unit: 'bytes'},
  
  'filesystem_/data_percent_used': { label: 'Data Utilization', unit: '%'},
  'filesystem_/data_bytes_total': { label: 'Data Total Bytes', unit: 'bytes'},
  'filesystem_/data_bytes_free': { label: 'Data Free Bytes', unit: 'bytes'},
  
  'filesystem_/cache_percent_used': { label: 'Cache Utilization', unit: '%'},
  'filesystem_/cache_bytes_total': { label: 'Cache Total Bytes', unit: 'bytes'},
  'filesystem_/cache_bytes_free': { label: 'Cache Free Bytes', unit: 'bytes'},
  
  'filesystem_/persist_percent_used': { label: 'Persist Utilization', unit: '%'},
  'filesystem_/persist_bytes_total': { label: 'Persist Total Bytes', unit: 'bytes'},
  'filesystem_/persist_bytes_free': { label: 'Persist Free Bytes', unit: 'bytes'},
  
  'network_eth0_active': { label: 'Ethernet Active', unit: ''},
  'network_eth0_speed': { label: 'Ethernet Link Speed', unit: ''},
  'network_eth0_mtu': { label: 'Ethernet MTU', unit: ''},
  'network_eth0_bytes_sent': { label: 'Ethernet Bytes Sent', unit: 'bytes'},
  'network_eth0_bytes_received': { label: 'Ethernet Bytes Received', unit: 'bytes'},
  'network_eth0_bytes_sent_per_second': { label: 'Ethernet Bytes Sent / Second', unit: 'bytes'},
  'network_eth0_bytes_received_per_second': { label: 'Ethernet Bytes Received / Second', unit: 'bytes'},
  
  'network_lo_active': { label: 'localhost Active', unit: ''},
  'network_lo_speed': { label: 'localhost Link Speed', unit: ''},
  'network_lo_mtu': { label: 'localhost MTU', unit: ''},
  'network_lo_bytes_sent': { label: 'localhost Bytes Sent', unit: ''},
  'network_lo_bytes_received': { label: 'localhost Bytes Received', unit: ''},
  'network_lo_bytes_sent_per_second': { label: 'localhost Bytes Sent / Second', unit: 'bytes'},
  'network_lo_bytes_received_per_second': { label: 'localhost Bytes Received / Second', unit: 'bytes'},
  
  'thermal_som_temp_current': { label: 'System Temperature', unit: '°C'},
  'thermal_som_temp_high': {label: 'System Temperature (High)', unit: '°C'},
  'thermal_som_temp_critical': {label: 'System Temperature (Critical)', unit: '°C'},
  
  'thermal_video-usr_temp_current': { label: 'System Temperature', unit: '°C'},
  'thermal_video-usr_temp_high': {label: 'System Temperature (High)', unit: '°C'},
  'thermal_video-usr_temp_critical': {label: 'System Temperature (Critical)', unit: '°C'},
  
  // metadata labels
  'location':  {label: 'Location', unit: ''},
  'deployment_status':  {label: 'Deployment Status', unit: ''},
  'device_alias':  {label: 'Device Alias', unit: ''},
  'note':  {label: 'Note', unit: ''},
  
  'in_transit':  {label: 'In Transit', unit: ''},
  'not_deployed':  {label: 'Not Deployed', unit: ''},
  'testing':  {label: 'Testing', unit: ''},
  'production':  {label: 'Production', unit: ''},
  
  // table header labels
  'streaming_status':  {label: 'Streaming Status', unit: ''},
  'device_ip':  {label: 'Device IP', unit: ''},
  'external_ip':  {label: 'External IP', unit: ''},
  'firmware_version':  {label: 'Firmware Version', unit: ''},
  'serial_number':  {label: 'Serial Number', unit: ''},
  'mac_address':  {label: 'MAC Address', unit: ''},
  'metadata':  {label: 'Metadata', unit: ''},
  
  // state labels
  'online': {label: 'Device Online', unit: ''},
  'input_video_format': {label: 'Input Video Format', unit: ''},
  'video_input_format': {label: 'Input Video Format', unit: ''},
  
  // output type labels
  'multicast': {label: 'Multicast', unit: ''},
  'unicast': {label: 'Unicast', unit: ''},
  'rtmp': {label: 'RTMP', unit: ''},
  'file': {label: 'File Record', unit: ''},
  'rtsp': {label: 'RTSP', unit: ''},
  'http_pull': {label: 'HTTP Pull', unit: ''},
  'http_push': {label: 'HTTP Push', unit: ''},
  'srt': {label: 'SRT', unit: ''},
  'zixi': {label: 'Zixi', unit: ''},
  'whip': {label: 'WHIP', unit: ''},
  'thumbnail': {label: 'Thumbnail / Preview', unit: ''},
  
  // shadow value labels
  'input_auto': {label: 'Auto Detect', unit: ''},
  'input_sdi': {label: 'SDI Video', unit: ''},
  'input_hdmi': {label: 'HDMI Video', unit: ''},
  'input_embedded': {label: 'Embedded Audio', unit: ''},
  'input_3p5mm': {label: '3.5mm Audio', unit: ''},
  'input_tpg': {label: 'Test Pattern Generator', unit: ''},
  
  // codecs & data types
  'mpeg4_aac': {label: 'MPEG-4 AAC-LC', unit: ''},
  'opus': {label: 'Opus', unit: ''},
  'klv_sync': {label: 'KLV SMPTE-336', unit: ''},
  'scte35': {label: 'SCTE-35', unit: ''},
  'smpte2038': {label: 'SMPTE-2038', unit: ''},
  
  'constant': {label: 'Constant Bitrate', unit: ''},
  'variable': {label: 'Variable Bitrate', unit: ''},
  
  'UTC': {label: 'UTC', unit: ''},
  'LOCALTIME': {label: 'Local Time', unit: ''},
  
  'MP4': {label: 'MP4 (.mp4)', unit: ''},
  'FMP4': {label: 'FMP4 (.mp4)', unit: ''},
  'TS': {label: 'Transport Stream (.ts)', unit: ''},
  
  'RTP': {label: 'RTP', unit: ''},
  'RAW_UDP': {label: 'UDP', unit: ''},
  
  'FRAMES': {label: 'Frames', unit: ''},
  'MILLISECONDS': {label: 'Milliseconds', unit: ''},
  
  'CALLER': {label: 'Caller', unit: ''},
  'LISTENER': {label: 'Listener', unit: ''},
  
  'AES128': {label: '128', unit: ''},
  'AES192': {label: '192', unit: ''},
  'AES256': {label: '256', unit: ''},
  
  'dhcp': {label: 'DHCP', unit: ''},
  'static': {label: 'Manual (Static)', unit: ''},
  
  // resolutions
  'RES_PASSTHROUGH': {label: 'Passthrough', unit: ''},
  'RES_320X180': {label: '320x180p (16:9)', unit: ''},
  'RES_480X270': {label: '480x270p (16:9)', unit: ''},
  'RES_640X360': {label: '640x360p (16:9)', unit: ''},
  'RES_854X480': {label: '854x480p (16:9)', unit: ''},
  'RES_720X576': {label: '720x576p (4:3)', unit: ''},
  'RES_960X540': {label: '960x540p (16:9)', unit: ''},
  'RES_1280X720': {label: '1280x720p (16:9)', unit: ''},
  'RES_1920X1080': {label: '1920x1080p (16:9)', unit: ''},
  'RES_1920X1200': {label: '1920x1200p (16:10)', unit: ''},
  'RES_2560X1440': {label: '2560x1440p (16:9)', unit: ''},
  'RES_3840X2160': {label: '3840x2160p (16:9)', unit: ''},
  'RES_180X320': {label: '180x320p (9:16)', unit: ''},
  'RES_270X480': {label: '270x480p (9:16)', unit: ''},
  'RES_360X640': {label: '360x640p (9:16)', unit: ''},
  'RES_480X854': {label: '480x854p (9:16)', unit: ''},
  'RES_576X720': {label: '576x720p (3:4)', unit: ''},
  'RES_540X960': {label: '540x960p (9:16)', unit: ''},
  'RES_720X1280': {label: '720x1280p (9:16)', unit: ''},
  'RES_1080X1920': {label: '1080x1920p (9:16)', unit: ''},
  'RES_1200X1920': {label: '1200x1920p (10:16)', unit: ''},
  'RES_1440X2560': {label: '1440x2560p (9:16)', unit: ''},
  'RES_2160X3840': {label: '2160x3840p (9:16)', unit: ''},
  
  'ROTATE_0': {label: 'None', unit: ''},
  'ROTATE_90': {label: '90°', unit: ''},
  'ROTATE_180': {label: '180°', unit: ''},
  'ROTATE_270': {label: '270°', unit: ''},
  
  'NO_LOCK': {label: 'No Lock', unit: ''},
  'LOCK_TO_WIDTH': {label: 'Lock to Image Width', unit: ''},
  'LOCK_TO_HEIGHT': {label: 'Lock to Image Height', unit: ''},
  
  'MANUAL': {label: 'Manual', unit: ''},
  'FULLSCREEN': {label: 'Full Screen', unit: ''},
  'LOWER_THIRD': {label: 'Lower 1/3', unit: ''},
  'TOP_LEFT': {label: 'Top Left Corner', unit: ''},
  'BOTTOM_LEFT': {label: 'Bottom Left Corner', unit: ''},
  'TOP_CENTER': {label: 'Center Top', unit: ''},
  'CENTER': {label: 'Center', unit: ''},
  'BOTTOM_CENTER': {label: 'Center Bottom', unit: ''},
  'TOP_RIGHT': {label: 'Top Right Corner', unit: ''},
  'BOTTOM_RIGHT': {label: 'Bottom Right Corner', unit: ''},

  'H264': {label: 'H.264 (AVC)', unit: ''},
  'H265': {label: 'H.265 (HEVC)', unit: ''},
  
  'PROFILE_HIGH': {label: 'High Profile', unit: ''},
  'PROFILE_MAIN': {label: 'Main Profile', unit: ''},
  'PROFILE_BASELINE': {label: 'Baseline Profile', unit: ''},

  'HIGH': {label: 'High', unit: ''},
  'NORMAL': {label: 'Normal', unit: ''},
  'LOW': {label: 'Low', unit: ''},
  'LOWEST': {label: 'Lowest', unit: ''},

  // product labels - refer to the values in the capabilities endpoint
  'edgecaster': { label: 'EdgeCaster', unit: '' },
  'edgecaster_max': { label: 'EdgeCaster Max', unit: '' },
  'cloud_ui': { label: 'Videon Cloud Platform', unit: '' },
  
  'generic': { label: 'Generic RTMP', unit: '' },
  'akamai': { label: 'Akamai', unit: '' },
  'aws_ivs': { label: 'Amazon IVS', unit: '' },
  'wowza': { label: 'Wowza Streaming Engine', unit: '' },
  'wowza_cloud': { label: 'Wowza Cloud', unit: '' },
  'youtube': { label: 'YouTube', unit: '' },
  'facebook': { label: 'Facebook', unit: '' },
  'mux': { label: 'Mux', unit: '' },
  'dolby_io': { label: 'Dolby IO', unit: '' },
  
  // input + encoder + output statuses
  'FORMAT_UNDEFINED': {label: 'Format Unsupported', unit: ''},
  
  'RUNNING': {label: 'Running', unit: ''},
  'DISABLED': {label: 'Disabled', unit: ''},
  'NO_INPUT': {label: 'No input', unit: ''},
  'UNABLE_TO_ENCODE': {label: 'Unable to encode', unit: ''},
  'FAILED_TO_START': {label: 'Failed to start', unit: ''},
 
  'OFF': {label: 'Off', unit: ''},
  'PENDING': {label: 'Pending', unit: ''},
  'RETRYING': {label: 'Retrying', unit: ''},
  'CONNECTED': {label: 'Connected to server', unit: ''},
  'REMOTE_CLOSED': {label: 'Server is closed', unit: ''},
  
  'STREAM_RUNNING': {label: 'Running', unit: ''},
  'STREAM_OFF': {label: 'Off', unit: ''},
  'STREAM_CONNECT_ERROR': {label: 'Connection Error', unit: ''},
  'STREAM_PENDING': {label: 'Pending', unit: ''},
  
  'ERROR': {label: 'Error', unit: ''},
  
  'ERROR_INTERNAL': {label: 'Error occurred internally', unit: ''},
  'ERROR_AVINPUT_MISMATCH': {label: 'AV is from unrelated inputs', unit: ''},
  'ERROR_SOURCE_AV_MISSING': {label: 'Signal from multiple connected sources is missing', unit: ''},
  'ERROR_SOURCE_VIDEO_MISSING': {label: 'Signal from connected video source is missing', unit: ''},
  'ERROR_SOURCE_AUDIO_MISSING': {label: 'Signal from connected audio source is missing', unit: ''},
  'ERROR_UNSUPPORTED_CODEC': {label: 'Video format error', unit: ''},
  'ERROR_UNSUPPORTED_BITRATE': {label: 'Bitrate not supported with selected service', unit: ''},
  'ERROR_UNSUPPORTED_BITRATE_MODE': {label: 'Encoding mode not supported with selected service', unit: ''},
  'ERROR_UNSUPPORTED_RESOLUTION': {label: 'Resolution not supported with selected service', unit: ''},
  
  'ERROR_REMOTE': {label: 'Remote closed the connection', unit: ''},
  'ERROR_REMOTE_UNREACHABLE': {label: 'Can not connect to URL', unit: ''},
  'ERROR_REMOTE_ACCESS_DENIED': {label: 'Server access denied', unit: ''},
  
  'ERROR_STORAGE_NO_DEVICE': {label: 'Selected storage device not connected. Please reinsert', unit: ''},
  'ERROR_STORAGE_UNSUPPORTED_FILESYSTEM': {label: 'Incompatible file system on storage device', unit: ''},
  'ERROR_STORAGE_DEVICE_FULL': {label: 'Storage device full', unit: ''},
  
  'FILE_NO_ERROR': {label: 'No error', unit: ''},
  'FILE_NO_STORAGE_DEVICE': {label: 'Selected storage device not connected. Please reinsert', unit: ''},
  'FILE_UNKNOWN_FILESYSTEM': {label: 'Incompatible file system on storage device', unit: ''},
  'FILE_STORAGE_DEVICE_FULL': {label: 'Storage device full', unit: ''},
  'FILE_UNMOUNTING': {label: 'Unmounting', unit: ''},
  'FILE_UNMOUNT_ERROR': {label: 'Unmount error', unit: ''},
  'FILE_STORAGE_DEVICE_TOO_SLOW': {label: 'Storage device too slow', unit: ''},
  'FILE_STORAGE_UNMOUNTABLE': {label: 'Storage unmountable', unit: ''},
  'FILE_NO_VIDEO_SOURCE': {label: 'Video source signal lost', unit: ''},
  'FILE_NO_AUDIO_SOURCE': {label: 'Audio source signal lost', unit: ''},
  'FILE_NO_AV_SOURCE': {label: 'Video and audio signal lost', unit: ''},
  'FILE_CODEC_ERROR': {label: 'Unknown Recording error has occurred', unit: ''},
  'FILE_UNKNOWN_FAILURE': {label: 'Unknown Recording error has occurred', unit: ''},
  'FILE_AVINPUT_MISMATCH_ERROR': {label: 'Selected audio and video profiles must have the same input source', unit: ''},
  
  'FTP_NO_ERROR': {label: 'No errors', unit: ''},
  'FTP_SUCCESS': {label: 'Success', unit: ''},
  'FTP_SUCCESS_UPLOAD_IN_PROGRESS': {label: 'Upload in progress', unit: ''},
  'FTP_FAILURE': {label: 'Failure', unit: ''},
  'FTP_FAILURE_SERVER_UNREACHABLE': {label: 'Server unreachable', unit: ''},
  'FTP_FAILURE_CONNECTION_TIMED_OUT': {label: 'Connection timed out', unit: ''},
  'FTP_FAILURE_AUTHENTICATION': {label: 'Authentication failure', unit: ''},
  'FTP_FAILURE_SFTP_KEY': {label: 'Authentication failure (SFTP key)', unit: ''},
  'FTP_FAILURE_AUTHORIZATION': {label: 'Authorization failure', unit: ''},
  'FTP_FAILURE_SERVER_DISK_FULL': {label: 'Disk full', unit: ''},
  'FTP_FAILURE_CANCELLED': {label: 'Cancelled', unit: ''},
  'FTP_FAILURE_STORAGE_DEVICE_TOO_SLOW': {label: 'Storage device too slow', unit: ''},
  
  
  'download': {label: 'Download', unit: ''},
  'apply': {label: 'Apply', unit: ''},
  'pending': {label: 'Pending', unit: ''},
  'in_progress': {label: 'In Progress', unit: ''},
  'complete': {label: 'Complete', unit: ''},
  
  
  
  // entitlement features
  'daemon.input.video.resolutions': { label: 'Supported Input Resolutions', unit: '' },

  'daemon.input.video.fractional_framerate': { label: 'Allow Fractional Framerate', unit: '' },
  'daemon.input.video.interlaced': { label: 'Allow Interlaced Video', unit: '' },
  'daemon.input.video.failover': { label: 'Allow Input Failover', unit: '' },
  'daemon.input.video.cef': { label: 'HTML5 Overlay', unit: '' },
  
  'daemon.input.video.hdmi.limit': { label: 'HDMI Input Instance Limit', unit: '' },
  'daemon.input.video.sdi.limit': { label: 'SDI Input Instance Limit', unit: '' },
  'daemon.input.video.limit': { label: 'Video Input Instance Limit', unit: '' },
    
  'daemon.input.audio.embedded.enabled': { label: 'Allow Embedded Audio', unit: '' },
  'daemon.input.audio.analog.enabled': { label: 'Allow Analog Audio', unit: '' },
  'daemon.input.audio.max_channels': { label: 'Max Input Audio Channels', unit: '' },

  'daemon.output.rtmp.limit': { label: 'RTMP Output Instance Limit', unit: '' },
  'daemon.output.rtmp.integrations': { label: 'RTMP Output Integrations', unit: '' },
  
  'daemon.output.srt.limit': { label: 'SRT Output Instance Limit', unit: '' },
  'daemon.output.srt.caller.enabled': { label: 'SRT Output Caller Enabled', unit: '' },
  'daemon.output.srt.listener.enabled': { label: 'SRT Output Listener Enabled', unit: '' },
  
  'daemon.output.rtsp.limit': { label: 'RTSP Output Instance Limit', unit: '' },
  
  'daemon.output.unicast.limit': { label: 'Unicast Output Instance Limit', unit: '' },
  
  'daemon.output.multicast.limit': { label: 'Multicast Output Instance Limit', unit: '' },
  
  'daemon.output.file_record.limit': { label: 'File Recording Output Instance Limit', unit: '' },
  'daemon.output.file_record.formats': { label: 'File Recording Output Formats', unit: '' },
  
  'daemon.output.http_push.limit': { label: 'HTTP Output Instance Limit', unit: ''},
  'daemon.output.http_push.formats': { label: 'HTTP Output Formats', unit: ''},
  'daemon.output.http_push.protocols': { label: 'HTTP Output Protocols', unit: ''},
  'daemon.output.http_push.integrations': { label: 'HTTP Output Integrations', unit: ''},
  
  'daemon.output.zixi.limit': { label: 'Zixi Output Instance Limit', unit: ''},
  
  'daemon.output.whip.limit': { label: 'WHIP Output Instance Limit', unit: ''},
  'daemon.output.whip.integrations': { label: 'WHIP Output Integrations', unit: ''},
  
  'daemon.encoders.video.scaling_resolutions': { label: 'Supported Scaling Resolutions', unit: '' },
  'daemon.encoders.video.resolutions': { label: 'Supported Resolutions', unit: '' },
  'daemon.encoders.video.latency_modes': { label: 'Latency Modes', unit: '' },

  'daemon.encoders.video.overlay.enabled': { label: 'Allow Overlay Support', unit: '' },
  'daemon.encoders.video.input_preview.enabled': { label: 'Allow Input Preview', unit: '' },
  'daemon.encoders.video.source_rotations': { label: 'Source Rotations', unit: '' },

  'daemon.encoders.video.h264.enabled': { label: 'Allow H264', unit: '' },
  'daemon.encoders.video.h264.profiles': { label: 'H264 Profiles', unit: '' },
  'daemon.encoders.video.h265.enabled': { label: 'Allow H265', unit: '' },

  'daemon.encoders.video.bitrate': { label: 'Video Encoder Bitrate Range', unit: '' },
  'daemon.encoders.video.keyframe': { label: 'Video Encoder Keyframe Range', unit: '' },
  'daemon.encoders.video.limit': { label: 'Video Encoder Instance Limit', unit: '' },

  'daemon.encoders.audio.limit': { label: 'Audio Encoder Instance Limit', unit: '' },
  'daemon.encoders.audio.max_channels': { label: 'Max Audio Encoder Channels', unit: '' },
  'daemon.encoders.audio.supported_codecs': { label: 'Audio Encoder Codecs', unit: '' },
  'daemon.encoders.audio.bitrate': { label: 'Audio Encoder Bitrate Range', unit: '' },
  
  'daemon.encoders.sync_groups.limit': { label: 'Sync Group Instance Limit', unit: '' },
  
  'daemon.encoders.data.klv_sync.limit': { label: 'KLV Encoder Instance Limit', unit: '' },
  'daemon.encoders.data.smpte2038.limit': { label: 'SMPTE-2038 Encoder Instance Limit', unit: '' },
  'daemon.encoders.data.scte_35.limit': { label: 'SCTE-35 Encoder Instance Limit', unit: '' },
  
  'services.xml.enabled': { label: 'XML Enabled', unit: '' },
  'services.adb.enabled': { label: 'ADB Enabled', unit: '' },
  'services.docker.enabled': { label: 'Docker Enabled', unit: '' },
  'services.docker.privileged': { label: 'Docker Privileged Mode', unit: '' },
  'services.docker.containers': { label: 'Docker Containers', unit: '' },
  'services.cloud.enabled': { label: 'Cloud Enabled', unit: '' },

  'services.cloud.commands_interface.enabled': { label: 'COMMANDS Interface', unit: '' },
  'services.cloud.output_control.enabled': { label: 'Output Control', unit: '' },
  'services.cloud.download_logs.enabled': { label: 'Download Logs', unit: '' },
  'services.cloud.local_ui_password.enabled': { label: 'Local UI Password', unit: '' },
  'services.cloud.reset_settings.enabled': { label: 'Reset Settings', unit: '' },
  'services.cloud.reboot_device.enabled': { label: 'Reboot Device', unit: '' },
  'services.cloud.metrics.enabled': { label: 'Metrics', unit: '' },
  'services.cloud.metrics.send_rate': { label: 'Metrics Send Rate', unit: '' },
  'services.cloud.device_events.enabled': { label: 'Device Events', unit: '' },
  'services.cloud.device_info_tab.enabled': { label: 'Device Info Tab', unit: '' },
  'services.cloud.device_config_tab.enabled': { label: 'Device Configuration Tab', unit: '' },
  'services.cloud.user_management_tab.enabled': { label: 'User Management Tab', unit: '' },
  'services.cloud.bulk_actions.enabled': { label: 'Bulk Actions', unit: '' },
  'services.cloud.fleet_monitor.enabled': { label: 'Fleet Monitor', unit: '' },
  'services.cloud.fleet_multiview.enabled': { label: 'Fleet Multi-View', unit: '' },
}
