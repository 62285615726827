<template>
  <v-card>
    <v-dialog
      v-if="selectedAlert"
      v-model="showManageAlert"
      max-width="480px"
    >
      <videon-card :heading="selectedAlert.label" showClose="true" @close="showManageAlert = false">
        <v-card-text>
          <div class="ma-1">
            <v-row>
              <videon-state-tile
                heading="Device"
              >
                <device-label :device="$devices.getDevice(selectedAlert.device_guid)" class="ma-3" />
              </videon-state-tile>
              <videon-state-tile
                heading="Condition"
              >
                {{ $helpers.alertCondition(selectedAlert) }}
              </videon-state-tile>
            </v-row>
            <v-row class="mt-2">
              <videon-state-tile
                heading="Created"
              >
                {{ selectedAlert.timestamp | moment("YYYY/MM/DD hh:mm:ss a") }}
              </videon-state-tile>
              <videon-state-tile
                heading="Last Update"
              >
                {{ selectedAlert.last_status_change | moment("YYYY/MM/DD hh:mm:ss a") }}
              </videon-state-tile>
            </v-row>
          
            <v-row><h5 class="text--disabled">Threshold Condition</h5></v-row>
            <v-row><v-divider /></v-row>
            <v-row class="mt-2">
              <videon-state-tile
                heading="Type"
              >
                {{ selectedAlert.alert_type }}
              </videon-state-tile>
              <videon-state-tile
                heading="Name"
              >
                {{ selectedAlert.name }}
              </videon-state-tile>
              <videon-state-tile
                heading="Condition"
              >
                {{ selectedAlert.threshold_condition }}
              </videon-state-tile>
              <videon-state-tile
                heading="Value"
              >
                {{ selectedAlert.threshold_value }}
              </videon-state-tile>
            </v-row>
          
            <v-row><h5 class="text--disabled">State</h5></v-row>
            <v-row><v-divider /></v-row>
            <v-row>
              <videon-state-tile
                heading="Resolved"
              >
                {{ selectedAlert.resolved }}
              </videon-state-tile>
              <videon-state-tile
                heading="Silenced"
              >
                {{ selectedAlert.silenced }}
              </videon-state-tile>
            </v-row>
            <v-row v-if="selectedAlert.silenced_toggled_by">
              <videon-state-tile
                heading="Silenced At"
              >
                {{ selectedAlert.silenced_timestamp | moment("YYYY/MM/DD hh:mm:ss a") }}
              </videon-state-tile>
              <videon-state-tile
                heading="Silenced By"
              >
                <user-label :user="$users.getUser(selectedAlert.silenced_toggled_by)" />
              </videon-state-tile>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-row
             align="center"
             justify="end"
             class="ma-2 mt-0"
           >
             <v-tooltip top>
               <template #activator="{ on }">
                 <v-btn
                   v-on="on"
                   color="secondary"
                   @click="silenceAlert(selectedAlert)"
                   class="ma-1"
                   outlined
                   :disabled="selectedAlert.resolved || selectedAlert.silenced"
                 >
                   Silence
                 </v-btn>
                </template>
                <span>Silencing alerts stops them from notifying again until they are closed.</span>
             </v-tooltip>
             <v-tooltip top>
               <template #activator="{ on }">
                 <v-btn
                   v-on="on"
                   color="primary"
                   @click="deleteAlert(selectedAlert)"
                   class="ma-1"
                 >
                   Close Alert
                 </v-btn>
               </template>
               <span>Closing an alert will allow a new notification to be generated if the condition arises again.</span>
            </v-tooltip>
          </v-row>
        </v-card-actions>
      </videon-card>
    </v-dialog>
    
    <div>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon size="32px">notifications_active</v-icon>
          {{ organizationName }} Alerts
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-divider/>
    </div>
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      
      <v-card flat class="mb-5">
        <v-card-title>
          Current Alerts
        </v-card-title>
        <v-card-text>
          <v-alert dense outlined type="error" v-if="alertsError">
            {{ alertsErrorText }}
          </v-alert>
          
          <v-data-table
            v-if="!alertsError"
            :headers="headers"
            :items="items"
            loadingText="Loading alerts..."
            no-data-text="No alerts"
            :items-per-page="200"
            hide-default-footer
          >
            <template v-slot:item.label="{ item }">
              <span class="subtitle-2" @click="manageAlert(item)" style="cursor: pointer;">{{ item.label }}</span>
            </template>
            <template v-slot:item.resolved="{ item }">
              <span @click="manageAlert(item)" style="cursor: pointer;">
                <alert-state-label :alert="item" />
              </span>
            </template>
            <template v-slot:item.device_guid="{ item }">
              <device-label :device="$devices.getDevice(item.device_guid)" />
            </template>
            <template v-slot:item.timestamp="{ item }">
              <span @click="manageAlert(item)" style="cursor: pointer;">
                {{ item.timestamp | moment("YYYY/MM/DD hh:mm:ss a") }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      
      <device-events heading="Recent Organization Alerts" :showDevice="true" targetObject="orgs" :targetGUID="organization.org_guid" :startTime="eventStartTime" eventType="alert"/>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DeviceEvents from '../device/components/DeviceEvents.vue'
  
  import moment from 'moment'
  
  export default {
    name: 'AlertListing',
    components: {
      DeviceEvents
    },
    data() {
      return {
        error: false,
        errorText: '',
        
        headers: [
          { text: 'Alert', align: 'start', value: 'label', sortable: true, width: '25%' },
          { text: 'State', align: 'start', value: 'resolved', sortable: false},
          { text: 'Device', align: 'start text-no-wrap', value: 'device_guid', sortable: false },
          { text: 'Created', align: 'start', value: 'timestamp', sortable: false },
        ],
        
        itemsPerPage: 50,
        
        showManageAlert: false,
        selectedAlert: false,
        
        eventStartTime: moment().subtract('5', 'days').toISOString(),
      }
    },
    computed: {
      ...mapGetters('user', ['organization']),
      
      alertsError() {
        return this.$alerts.error
      },
      alertsErrorText() {
        return this.$alerts.errorText
      },
      
      organizationName() {
        if (this.organization) {
          return (this.organization.org_name) ? this.organization.org_name : this.organization.org_guid
        }
        return 'No Organization'
      },
      
      loading() {
        return !this.$alerts.ready
      },
      
      items() {
        return this.$alerts.alerts
      },
    },
    methods: {
      manageAlert(item) {
        console.log('AlertListing manageAlert', item)
        
        this.selectedAlert = item
        this.showManageAlert = true
      },
      
      silenceAlert(item) {
        console.log('AlertListing silenceAlert', item)
        this.showManageAlert = false
        
        this.axios.patch('/alerts/' + item.alert_guid, { 'silenced': true })
        .then((response) => {
          console.log('AlertListing silenceAlert', response)
          
          this.$alerts.getAlerts(this.organization.org_guid)
        }).catch((error) => {
          console.log('AlertListing silenceAlert error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        })
      },
      
      deleteAlert(item) {
        console.log('AlertListing deleteAlert', item)
        this.showManageAlert = false
        
        this.axios.delete('/alerts/' + item.alert_guid)
        .then((response) => {
          console.log('AlertListing deleteAlert', response)
          
          this.$alerts.getAlerts(this.organization.org_guid)
        }).catch((error) => {
          console.log('AlertListing deleteAlert error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        })
      }
    }
  }
</script>