import Vue from 'vue'

const state = {
  ready: false,
  
  stage: 'capture_email',

  token: null,
  tokenExpiration: false,

  groups: [],

  email: localStorage.getItem('email') || '',
  guid: false,
  
  avatarURL: false,

  organization: null,
  organizations: [],
  lastOrgsRequestAt: false,
  
  invites: [],
  lastInvitesRequestAt: false,
  
  error: false,
  errorText: ''
}

// getters
const getters = {
  isLoggedIn: () => {
    var currentTime = Math.round(+new Date() / 1000)
    
    if (state.tokenExpiration && (state.tokenExpiration - 60) > currentTime) {
      return true
    }
    
    return false
  },

  isReady: state => state.ready,

  authStatus: state => state.status,
  authStage: state => state.stage,

  token: state => state.token,
  
  groups: state => state.groups,

  isAdmin: () => {
    var adminGroups = ['UserManagement', 'OrganizationManagement', 'DeviceManagement', 'EntitlementManagement']
    if (state.groups.some(group => adminGroups.includes(group))) {
      return true
    }
    return false
  },

  isUserAdmin: () => {
    if (state.groups.includes('UserManagement')) {
      return true
    }
    return false
  },

  isOrgAdmin: () => {
    if (state.groups.includes('OrganizationManagement')) {
      return true
    }
    return false
  },
  
  isDeviceAdmin: () => {
    if (state.groups.includes('DeviceManagement')) {
      return true
    }
    return false
  },
  
  isEntitlementAdmin: () => {
    if (state.groups.includes('EntitlementManagement')) {
      return true
    }
    return false
  },
  
  isContainerAdmin: () => {
    if (state.groups.includes('ContainerManagement')) {
      return true
    }
    return false
  },
  
  email: state => state.email,
  guid: state => state.guid,
  
  avatar: state => {
    return state.avatarURL || require("../../assets/icons/icon-On-site Client-48.svg")
  },
  
  organization: state => state.organization,
  organizations: state => state.organizations,
  
  pendingInvites: state => state.invites.length,
  invites: state => state.invites,
  invitesLoaded: () => {
    return (state.lastInvitesRequestAt) ? true : false
  } 
}

// actions
const actions = {
  // this is where we let amplify do its magic and pull the refresh token if needed...
  async validateAuth({commit, dispatch, rootGetters}) {
    try {
      console.log('UserStore validateAuth')
      
      if (rootGetters['config/AmplifyConfig']) {
        await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
        
        var currentSession = await Vue.prototype.$Amplify.Auth.currentSession()
        
        if (currentSession.isValid()) {
          console.log('UserStore validateAuth valid session')
        
          var idToken = currentSession.getIdToken()
          var jwtToken = currentSession.getAccessToken().getJwtToken()
          var tokenExpiration = currentSession.getAccessToken().getExpiration()
        
          commit('set_token', {token: jwtToken, expiration: tokenExpiration})
        
          // if a user has cognito groups.. add them
          if (idToken.payload['cognito:groups'] && idToken.payload['cognito:groups'].length > 0) {
            console.log('UserStore validateAuth groups', idToken.payload['cognito:groups'])
            commit('set_groups', idToken.payload['cognito:groups'])
          }
          
          // set a default authorization token & Org-Guid for Axios globally
          Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwtToken
          //Vue.axios.defaults.headers.common['Access-Control-Expose-Headers'] = 'x-amzn-errortype' // TODO get this enabled...
          
          var myUser = Vue.prototype.$Amplify.Auth.user
          if (myUser && myUser.attributes) {
            dispatch('setGuid', myUser.username)
            if (myUser.attributes.sub) {
              dispatch('setGuid', myUser.attributes.sub)
            }
            if (myUser.attributes.picture) {
              dispatch('setAvatar', myUser.attributes.picture)
            }
          }
          
          var org_guid = localStorage.getItem('organization_guid')
          if (!state.organization && org_guid) {
            Vue.axios.defaults.headers.common['Org-Guid'] = org_guid
          }
          
          // get user organizations
          dispatch('getUserOrganizations')
          dispatch('getUserInvites')
          
          // prefetch latest versions
          Vue.latestVersions.latestVersions()
          
        } else {
          console.log('UserStore validateAuth invalid session')
        }
      } else {
        console.log('UserStore validateAuth Amplify not configured')
      }
    } catch (error) {
      console.log('UserStore validateAuth error', error)
      await dispatch('logout')
    }

  },
  
  // this quickly validates we're still logged in (tokenExpiration not expired) or it does the validateAuth logic
  async quickValidateAuth({dispatch}) {
    var currentTime = Math.round(+new Date() / 1000)
    
    if (!state.tokenExpiration || state.tokenExpiration <= currentTime) {
      console.log('UserStore quickValidateAuth expired token')
      await dispatch('validateAuth')
      return
    }
  },
  
  validSessionCheck({commit, rootGetters}) {
    return new Promise((resolve, reject) => {
      try {
        console.log('UserStore validSessionCheck')
        if (rootGetters['config/AmplifyConfig']) {
          Vue.prototype.$Amplify.Auth.currentSession().then((session) => {
            resolve(session)
          })
        }
      } catch (error) {
        commit('set_error', error)
        reject(error)
      }
    })
  },
  
  setStage({commit}, stage) {
    commit('set_stage', stage)
  },

  setEmail({commit}, email) {
    localStorage.setItem('email', email)
    commit('set_email', email)

    console.log('UserStore setEmail', email)
  },

  setGuid({commit}, guid) {
    commit('set_guid', guid)

    console.log('UserStore setGuid', guid)
  },

  setAvatar({commit}, avatarURL) {
    commit('set_avatar', avatarURL)
    
    console.log('UserStore setAvatar', avatarURL)
  },

  authError({commit}, error) {
    commit('set_error', error)
  },

  resetError({commit}) {
    commit('reset_error')
  },

  resetEmail({commit}) {
    localStorage.removeItem('email')
    commit('set_email', '')
    commit('reset')
  },

  setOrganization({commit}, org){
    if (org.org_guid) {
      console.log('UserStore setOrganization', org)
      
      localStorage.setItem('organization_guid', org.org_guid)
      localStorage.setItem('last_organization_guid', org.org_guid)
      
      // update axios default org_guid header
      Vue.axios.defaults.headers.common['Org-Guid'] = org.org_guid
      
      // reset caches
      Vue.helpers.resetCache()
      
      // fetch alerts
      Vue.alerts.getAlerts(org.org_guid)
    } else {
      localStorage.removeItem('organization_guid')
      delete Vue.axios.defaults.headers.common['Org-Guid']
    }
    
    commit('set_organization', org)
  },
  
  
  logout({commit, state}) {
    return new Promise((resolve, reject) => {
      try {
        if (!state.guid) {
          resolve()
        } else {
          console.log('UserStore logout')
          Vue.prototype.$Amplify.Auth.signOut().then(() => {
            commit('reset')
            
            delete Vue.axios.defaults.headers.common['Authorization']
            localStorage.removeItem('organization_guid')
            
            Vue.helpers.resetCache()
            
            resolve()
          })
        }
      } catch (error) {
        commit('set_error', error)
        reject(error)
      }
    })
  },
  
  getUserOrganizations({getters, commit, state, dispatch}, force = false){
    return new Promise((resolve, reject) => {
      // return existing orgs if not old
      var currentTime = Math.round(+new Date() / 1000)
      if (!force && state.lastOrgsRequestAt && (state.lastOrgsRequestAt + 60) >= currentTime && state.organization) {
        resolve(state.organizations)
        return
      }
      
      var guid = getters['guid']
      if (guid) {
        commit('set_org_request_at')
        
        Vue.organizations.allOrgs(guid).then((orgs) => {
          var userOrgs = (orgs) ? orgs.map((org) => Vue.organizations.getOrg(org.org_guid, org)) : []
          commit('set_organizations', userOrgs)
        
          // automatically set org if we can
          var org_guid = localStorage.getItem('organization_guid')
          if (!state.organization && org_guid) {
            var lastOrg = userOrgs.find(x => x.org_guid === org_guid)
            if (lastOrg) {
              dispatch('setOrganization', lastOrg)
            }
          } else if (!state.organization && userOrgs.length == 1) {
            dispatch('setOrganization', userOrgs[0])
          }
          
          commit('set_ready', true)
          resolve(userOrgs)
        }).catch((error) => {
          console.log('UserStore getUserOrganizations error', Vue.helpers.parseError(error))
          commit('set_error', Vue.helpers.parseError(error))
          commit('set_organizations', [])
          resolve([])
        })
        
      } else {
        reject('Cognito user is not set')
      }
    })
  },
  
  getUserInvites({getters, commit}, force = false){
    return new Promise((resolve, reject) => {
      // return existing invites if not old
      var currentTime = Math.round(+new Date() / 1000)
      if (!force && state.lastInvitesRequestAt && (state.lastInvitesRequestAt + 60) >= currentTime) {
        resolve(state.invites)
        return
      }
      
      if (getters['guid']) {
        commit('set_invites_request_at')
        
        Vue.axios.get('/invites', {noValidateAuth: true})
        .then((response) => {
          console.log('UserStore getUserInvites', response)
          var invites = response && response.data.invites ? response.data.invites : []
          
          commit('set_invites', invites)
          
          resolve(invites)
        }).catch((error) => {
          console.log('UserStore getUserInvites error', Vue.helpers.parseError(error))
          commit('set_error', Vue.helpers.parseError(error))
          commit('set_invites', [])
          resolve([])
        })
      } else {
        reject('Cognito user is not set')
      }
    })
  },
}

// mutations
const mutations = {
  set_ready(state, ready) {
    state.ready = ready
  },
  
  set_stage(state, stage) {
    state.stage = stage
  },

  set_error(state, error = '') {
    state.error = true
    state.errorText = error
  },

  reset(state) {
    state.ready = false
    state.stage = 'capture_email'
    state.token = null
    state.tokenExpiration = false
    state.groups = []
    //state.email = '' // dont reset email, it breaks auth flow
    state.guid = false
    state.avatarURL = false
    state.organization = null
    state.organizations = []
    state.invites = []
    state.error = false
    state.errorText = ''
  },

  reset_error(state) {
    state.error = false
    state.errorText = ''
  },

  set_token(state, token) {
    state.token = token.token
    state.tokenExpiration = token.expiration
  },

  set_email(state, email) {
    state.email = email
  },

  set_guid(state, guid) {
    state.guid = guid
  },

  set_avatar(state, avatarURL) {
    state.avatarURL = avatarURL
  },

  set_groups(state, groups) {
    state.groups = groups
  },
  
  set_organization(state, organization) {
    state.organization = organization
  },
  
  set_organizations(state, organizations) {
    state.organizations = organizations
    state.lastOrgsRequestAt = Math.round(+new Date() / 1000)
  },
  
  set_org_request_at(state) {
    state.lastOrgsRequestAt = Math.round(+new Date() / 1000)
  },
  
  set_invites(state, invites) {
    state.invites = invites
    state.lastInvitesRequestAt = Math.round(+new Date() / 1000)
  },
  
  set_invites_request_at(state) {
    state.lastInvitesRequestAt = Math.round(+new Date() / 1000)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
