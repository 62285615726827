<template>
  <videon-card :heading="title" showClose="true" @close="close()">
    <v-card-text>
      <v-row class="mb-3">
        <v-col cols="5">
          <v-list dense width="100%">
            <v-list-item
              v-for="(device, index) in devices"
              :key="index"
              :value="device.device_guid"
              :disabled="!device.isOnline()"
              
              two-line
            >
              <v-list-item-icon class="mr-1">
                <v-icon>{{ device.icon() }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ device.deviceName() }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-progress-linear
                    color="primary"
                    indeterminate
                    buffer-value="0"
                    class="my-2"
                    v-if="device.isCommandProcessing()"
                  ></v-progress-linear>
                  <span v-else>
                    {{ deviceStatus(device) }}&nbsp;
                  </span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="7">
          <output-control v-if="action == 'output_control'" :devices="devices" @close="close()" />
          <firmware-update v-if="action == 'firmware_update'" :devices="devices" @close="close()" />
          <container-install v-if="action == 'container_install'" :devices="devices" @close="close()" />
          <reboot-devices v-if="action == 'reboot'" :devices="devices" @close="close()" />
        </v-col>
      </v-row>
      
      <div class="text-right">
        <v-btn
          class="ma-1"
          color="primary"
          @click="close()"
          text
        >
          Close
        </v-btn>
      </div>
    </v-card-text>
  </videon-card>
</template>

<script>
  import OutputControl from './bulk_actions/OutputControl.vue'
  import FirmwareUpdate from './bulk_actions/FirmwareUpdate.vue'
  import ContainerInstall from './bulk_actions/ContainerInstall.vue'
  import RebootDevices from './bulk_actions/Reboot.vue'
  
  
  export default {
    name: 'DeviceBulkAction',
    
    props: ['action', 'devices'],
    
    components: {
      OutputControl,
      FirmwareUpdate,
      ContainerInstall,
      RebootDevices
    },
    
    mounted() {
      // reset command state of devices
      for (var device of this.devices) {
        device.resetCommandState()
      }
    },
    
    computed: {
      title() {
        if (this.action == 'output_control') {
          return 'Output Control'
        } else if (this.action == 'firmware_update') {
          return 'Firmware Update'
        } else if (this.action == 'container_install') {
          return 'Container Install'
        } else if (this.action == 'reboot') {
          return 'Reboot Devices'
        }
        return 'Bulk Action'
      }
    },
    
    watch: {
      
    },
    
    methods: {
      close() {
        this.$emit('close')
      },
      
      deviceStatus(device) {
        if (!device.isOnline()) {
          return 'Device Offline'
        } else {
          if (device.isCommandProcessing()) {
            return 'Processing Command...'
          } else if (device.command_message) {
            return device.command_message
          } else {
            return 'Device Online'
          }
        }
      }
    }
  }
</script>