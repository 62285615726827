<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        <CSA v-if="document == 'csa'"/>
        <AUP v-else-if="document == 'aup'"/>
        <privacy-policy v-else-if="document == 'privacy'"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import CSA from '../components/legal/CloudServicesAgreement.vue'
  import AUP from '../components/legal/AcceptableUsePolicy.vue'
  import PrivacyPolicy from '../components/legal/PrivacyPolicy.vue'
  
  export default {
    components: {
      CSA,
      AUP,
      PrivacyPolicy,
    },
    data() {
      return {
        document: this.$route.params.document
      }
    },
    watch: {
      $route() {
        this.document = this.$route.params.document
      }
    },
  }
</script>