<template>
  <v-container class="pa-0" fluid>
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
        
        <div v-if="config">
          <alert-config-box />
        </div>
        
        <div v-else-if="!alert_guid">
          <alert-listing-box />
        </div>
        
        <div v-else-if="alert_guid">
          <edit-alert-box :alert_guid="alert_guid"/>
        </div>
      
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import AlertListingBox from '../components/alerts/AlertListing.vue'
  import EditAlertBox from '../components/alerts/EditAlert.vue'
  
  import AlertConfigBox from '../components/alerts/AlertConfig.vue'
  
  export default {
    name: "Alerts",
    components: {
      AlertListingBox,
      EditAlertBox,
      AlertConfigBox
    },
    data() {
      return {
        config: this.$route.meta.config,
        alert_guid: this.$route.params.alert_guid,
      }
    },
    watch: {
      $route() {
        this.config = this.$route.meta.config,
        this.alert_guid = this.$route.params.alert_guid
      }
    },
    mounted() {
      
    },
    methods: {
      
    }
  }
</script>
