<template>
  <div>
    <v-dialog
      v-if="selectedDevice && showConfigureDevice"
      v-model="showConfigureDevice"
      max-width="80%"
    >
      <videon-card :heading="'Configure ' + selectedDevice.deviceName()" showClose="true" @close="showConfigureDevice = false">
        <v-card-text>
          <device-config :device_guid="selectedDevice.device_guid" :device="selectedDevice" />
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <div v-if="!fleet.ready" class="mx-5 my-10">
      <v-progress-linear
        color="primary"
        indeterminate
        buffer-value="0"
      ></v-progress-linear>
    </div>
    
    <v-col v-if="fleet.ready && sortedDevices.length == 0" cols="12" class="my-5 text-center">
      <span class="text--disabled">No configured devices</span>
    </v-col>
      
    <div v-else>
      <v-card
        v-for="(device, index) in sortedDevices"
        v-bind:key="index"
        class="ma-1 mb-3"
        outlined
      >
        <v-card-title class="ma-0 py-1 pl-6" :class="itemColor(device)">
          <device-label :device="device" />
          <v-spacer></v-spacer>
          <v-btn
            icon
            class="mr-1"
            @click="refreshShadows(device)"
            v-if="device.isOnline()"
            tooltip="Request config directly from the device"
            :disabled="shadowsLoading(device)"
          >
            <v-icon>refresh</v-icon>
          </v-btn>
          <streaming-status-label :device="device" />
        </v-card-title>
        <v-divider/>
        <v-card-text>
          {{ (rows=device.outputColumnView(), null) }}
          
          <v-row v-if="device.ready && !device.hasFeature('services.cloud.fleet_monitor.enabled')">
            <v-col cols="12" class="text-center">
              <span class="text--disabled">Not licensed</span>
            </v-col>
          </v-row>
          
          <v-row v-else-if="device.ready && rows.length == 0">
            <v-col cols="12" class="text-center">
              <span class="text--disabled">Device has no configured outputs</span>
            </v-col>
          </v-row>
          
          <div v-else-if="rows.length > 0">
            <v-row>
              <v-col cols="4">
                <h4>Inputs</h4>
              </v-col>
              <v-col cols="4">
                <h4>Encoders</h4>
              </v-col>
              <v-col cols="4">
                <h4>Outputs</h4>
              </v-col>
            </v-row>
          
            <v-row
              v-for="(row, index) in rows" 
              v-bind:key="index"
            >
              <v-col cols="4">
                <v-card
                  outlined
                  v-for="(input, inputIndex) in row.inputs" 
                  v-bind:key="inputIndex"
                  class="my-1"
                  :class="input.video_input_format == 'FORMAT_UNDEFINED' ? errorColor() : ''"
                >
                  <v-card-title class="text-subtitle-2 py-2">{{ inputName(input) }} <v-spacer /><v-chip v-if="input.type" x-small>{{ typeName(input.type) }}</v-chip></v-card-title>
                  <v-card-text class="my-0 pb-3">
                    <input-status-label :input="input" />
                    
                    <input-preview
                      v-if="device.isOnline() && isPreviewSupported(device, index) && input.video_input_format != 'FORMAT_UNDEFINED'"
                      :device_guid="device.device_guid" 
                      :input_id="input.id"  
                    />
                  </v-card-text>
                </v-card>
              </v-col>
          
              <v-col cols="4">
                <v-card 
                  outlined
                  v-for="(encoder, index) in row.encoders['video']" 
                  v-bind:key="index"
                  class="my-1"
                  :class="encoderFailed(encoder) ? errorColor() : ''"
                >
                  <v-card-title class="text-subtitle-2 py-2">{{ encoderName(encoder) }} <v-spacer /><v-chip x-small>{{ typeName(encoder.type) }}</v-chip></v-card-title>
                  <v-card-text class="my-0 pb-3">
                    <encoder-status-label :encoder="encoder" />
                  </v-card-text>
                </v-card>
            
                <div 
                  v-if="row.encoders['audio']"
                >
                  <v-card
                    outlined
                    v-for="(encoder, index) in row.encoders['audio']" 
                    v-bind:key="index"
                    class="my-1"
                    :class="encoderFailed(encoder) ? errorColor() : ''"
                  >
                    <v-card-title class="text-subtitle-2 py-2">{{ encoderName(encoder) }} <v-spacer /><v-chip x-small>{{ typeName(encoder.type) }}</v-chip></v-card-title>
                    <v-card-text class="my-0 pb-3">
                      <encoder-status-label :encoder="encoder" />
                    </v-card-text>
                  </v-card>
                </div>
                <div 
                  v-if="row.encoders['data']"
                >
                  <v-card
                    outlined
                    v-for="(encoder, index) in row.encoders['data']" 
                    v-bind:key="index"
                    class="my-1"
                    :class="encoderFailed(encoder) ? errorColor() : ''"
                  >
                    <v-card-title class="text-subtitle-2 py-2">{{ encoderName(encoder) }} <v-spacer /><v-chip x-small>{{ typeName(encoder.type) }}</v-chip></v-card-title>
                    <v-card-text class="my-0 pb-3">
                      <encoder-status-label :encoder="encoder" />
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
          
              <v-col cols="4">
                <v-card
                  outlined
                  v-for="(output, index) in row.outputs" 
                  v-bind:key="index"
                  class="my-1"
                  :class="outputFailed(output) ? errorColor() : ''"
                >
                  <v-card-title class="text-subtitle-2 py-2">{{ outputName(output) }} <v-spacer /><v-chip x-small>{{ typeName(output.type) }}</v-chip></v-card-title>
                  <v-card-text class="my-0 pb-3">
                    {{ (destination=$helpers.outputStatus(output).destination, null) }}
                    <output-status-label :output="output" />
                    <div v-if="destination" class="mt-1 caption">{{ destination }}</div>
                  </v-card-text>
                </v-card>
              </v-col>
          
              <v-divider 
                v-if="index < rows.length - 1"
                class="ma-5"
              />
            </v-row>
            <div class="mt-3 text-right" v-if="device.isOnline()">
              <v-btn
                small
                color="secondary"
                outlined
                class="ma-1"
                @click="configureDevice(device)"
                v-if="canEdit"
              >
                Configure Device
              </v-btn>
          
              <output-control :device="device" :button="true" class="ma-1" />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  import InputPreview from '../../device/components/InputPreview.vue'
  import DeviceConfig from '../../device/sections/DeviceConfig.vue'
  
  export default {
    name: 'FleetControl',
    
    props: ['fleet_guid', 'fleet'],
    
    components: {
      InputPreview,
      DeviceConfig
    },
    
    data() {
      return {
        inputPreviewSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.3.0'),
        
        showConfigureDevice: false,
        selectedDevice: false,
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isOrgAdmin']),
      
      
      sortedDevices() {
        var devices = this.fleet.devices
        
        devices.sort(function(a, b) {
          return a.streaming_status - b.streaming_status
        })
        
        return devices
      },
      
      canEdit() {
        return this.fleet.canEdit()
      }
    },
    
    mounted() {
      
    },
    
    methods: {
      errorColor() {
        if (this.$vuetify.theme.dark) {
          return 'red darken-4'
        }
        return 'red lighten-5'
      },
      
      itemColor(device) {
        if (this.$vuetify.theme.dark) {
          if (device.streaming_status == 1) {
            return 'green darken-4'
          } else if (device.streaming_status == 0) {
            return 'red darken-4'
          } else if (device.streaming_status == 2) {
            return 'orange darken-4'
          } else if (device.streaming_status == 3) {
            return 'grey darken-4'
          }
        } else {
          if (device.streaming_status == 1) {
            return 'green lighten-5'
          } else if (device.streaming_status == 0) {
            return 'red lighten-5'
          } else if (device.streaming_status == 2) {
            return 'orange lighten-5'
          } else if (device.streaming_status == 3) {
            return 'grey lighten-5'
          }
        }
        
        return 'disabled'
      },
      
      isPreviewSupported(device, index = 0) {
        if (device.thumbnailEnabled(index) && this.$helpers.versionCheck(this.inputPreviewSupportedVersion, device.cloud_version)) {
          return true
        }
        return false
      },
      
      typeName(type) {
        if (!type) {
          return
        }
        return type.replace(/_/g, ' ')
      },
      
      inputName(input) {
        if (input.config.name) {
          return this.$helpers.htmlDecode(input.config.name)
        }
        
        return 'Input #' + input.id
      },
      
      encoderName(encoder) {
        if (encoder.config.name) {
          return this.$helpers.htmlDecode(encoder.config.name)
        }
        
        return 'Encoder #' + encoder.id
      },
      
      outputName(output) {
        if (output.config.name) {
          return this.$helpers.htmlDecode(output.config.name)
        }
        
        var outputName = 'Output #' + output.id
        if (output.type) {
          outputName += ' (' + this.$helpers.getLabel(output.type) + ')'
        }
        return outputName
      },
      
      encoderFailed(encoder) {
        if (this.$helpers.encoderStatus(encoder).error === true) {
          return true
        }
        
        return false
      },
      
      outputFailed(output) {
        if (this.$helpers.outputStatus(output).streaming === false && this.$helpers.outputStatus(output).enabled === true) {
          return true
        }
        
        return false
      },
      
      configureDevice(device) {
        this.selectedDevice = device
        this.showConfigureDevice = true
      },
      
      shadowsLoading(device) {
        var inputsShadow = this.$deviceShadows.getDeviceShadow(device.device_guid, 'Inputs') 
        var outputsShadow = this.$deviceShadows.getDeviceShadow(device.device_guid, 'Outputs') 
        
        if (inputsShadow.ready && outputsShadow.ready) {
          return false
        }
        
        return true
      },
      
      refreshShadows(device) {
        this.$deviceShadows.getDeviceShadow(device.device_guid, 'Inputs').requestShadowUpdate()
        this.$deviceShadows.getDeviceShadow(device.device_guid, 'Encoders').requestShadowUpdate()
        this.$deviceShadows.getDeviceShadow(device.device_guid, 'Outputs').requestShadowUpdate()
      }
    }
  }
</script>