<template>
  <v-navigation-drawer
    id="main-sidebar"
    v-model="thisDrawer"
    color="nav_background"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :clipped="$vuetify.breakpoint.smAndUp"
    width="280px"
    app
    dark
  >
    <v-list-item two-line class="profile-bg">
      <v-list-item-avatar>
        <user-avatar :src="avatar"/>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>{{ name }}</v-list-item-title>
        <v-list-item-subtitle class="caption">{{ organizationName }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>

    <v-list expand nav class="mt-1">
      <template v-for="(item, i) in items">

        <v-row v-if="item.header" :key="item.header">
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">{{
              item.header
            }}</v-subheader>
          </v-col>
        </v-row>

        <nav-item-group
          v-else-if="item.children && !filterNavItem(item)"
          class="icon-size"
          :key="`group-${i}`"
          :item="item"
        ></nav-item-group>

        <nav-item v-else-if="!filterNavItem(item)" :key="`item-${i}`" :item="item" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import NavigationItems from "./NavigationItems"

  export default {
    name: "Navigation",
    props: {
      drawer: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        thisDrawer: true,
        items: NavigationItems,
        user: null
      }
    },
    mounted() {
      this.user = this.$users.getMe()
    },
    watch: {
      drawer() {
        this.thisDrawer = this.drawer
      },
    },
    computed: {
      ...mapGetters('user', ['groups', 'organization', 'isAdmin', 'avatar']),
    
      name() {
        return this.user ? this.user.name : 'Unknown'
      },
      organizationName() {
        if (this.organization) {
          return (this.organization.org_name) ? this.organization.org_name : this.organization.org_guid
        }
        return 'No Organization'
      }
    },

    methods: {
      filterNavItem(item) {
        if ((!item.requiresAdmin && !item.requiresGroup) 
          || (item.requiresAdmin && this.isAdmin)
          || (item.requiresGroup && this.groups.includes(item.requiresGroup))
        ) {
          return false
        }
        return true
      }
      
    },
  }
</script>
<style lang="scss" scoped>
  #main-sidebar a {
    color: var(--v-text-base) !important;
  }
</style>
<style lang="scss">
  #main-sidebar {
    box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
    
    background: linear-gradient(90deg,  transparent 60%, #24222A);
    
    .v-navigation-drawer__border {
      display: none;
    }
    .v-list {
      padding: 8px 15px;
    }
    .v-list-item {
      min-height: 35px;
      &__icon--text,
      &__icon:first-child {
        justify-content: center;
        text-align: center;
        width: 20px;
      }
    }
    .v-list-item__icon {
      margin-right: 10px;
    }
    .v-list-item__icon i {
      width: 20px;
    }
    .icon-size .v-list-group__items i {
      width: 16px;
      font-size: 16px;
    }
    .v-list-group__items {
      margin-left: 15px;
    }
    .v-list-group--sub-group .v-list-group__header {
      padding-left: 8px;
    }
    .v-list-group--sub-group .v-list-group__items .v-list-item {
      padding-left: 8px;
    }
    .profile-bg {
      background: linear-gradient(#24222A, #24232b, #2b2432);
      &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        opacity: 1;
      }
      .v-avatar {
        padding: 15px 0;
      }
    }
    .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
</style>