<template>
  <div>
    <div>
      <v-row align="center" class="font-weight-medium" style="flex-wrap: nowrap">
        <v-icon class="mr-1" :color="iconColor">{{ icon }}</v-icon>
        <span class="mr-1">{{ label }}</span>
      </v-row>
    </div>
  </div>
</template>

<script>
  
  export default {
    name: 'AlertStateLabel',
    props: {
      alert: {
        type: Object,
        required: true
      }
    },
    computed: {
      icon() {
        if (this.alert.resolved) {
          return 'check'
        }
        return 'warning'
      },
      iconColor() {
        if (this.alert.resolved) {
          return 'green'
        } else if (this.alert.silenced) {
          return 'grey'
        } else 
        
        return 'red'
      },
      label() {
        if (!this.alert.resolved && !this.alert.silenced) {
          return 'Unresolved'
        } else if (!this.alert.resolved && this.alert.silenced) {
          return 'Silenced'
        } else if (this.alert.resolved) {
          return 'Resolved'
        }
        return 'Unknown'
      }
    },
  }
</script>