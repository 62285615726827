<template>
  <v-container class="pa-0" fluid>
  
    <v-dialog
      v-model="showDeleteContainer"
      max-width="400px"
      v-if="this.selectedContainer"
    >
      <videon-card heading="Delete Container" showClose="true" @close="showDeleteContainer = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text text-no-wrap">{{ this.selectedContainer.name }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteContainer">
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteContainer = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteContainer"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-row class="ma-0 ma-md-2">
      <v-col cols="12" sm="12">
          <v-card class="mx-auto">
            
            <v-toolbar flat>
              <v-toolbar-title>
                <v-avatar
                  height="32px"
                  class="mx-auto"
                >
                  <videon-logo />
                </v-avatar>
                Container Directory
              </v-toolbar-title>
              
              <v-spacer />
              
              <v-btn
                @click="newContainer"
                color="primary"
                outlined
                small
              >
                <v-icon>mdi-plus</v-icon> Create Container
              </v-btn>
            </v-toolbar>
            
            <v-divider />
            
            <v-card-text>
              <div v-if="loading">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  buffer-value="0"
                ></v-progress-linear>
              </div>
              
              <v-row v-else>
                <v-col cols="3">
                  <v-list dense>
                    <v-list-item-group
                      v-model="selectedContainer"
                      color="primary"
                      @change="section = 'info'"
                    >
                      <v-list-item
                        v-for="(container, index) in containers"
                        :key="index"
                        :value="container"
                      >
                        <v-list-item-icon class="mr-1">
                          <v-icon>collections_bookmark</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title v-text="container.name"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-col>
                
                <v-col cols="9">
                  <v-alert dense outlined type="error" v-if="error">
                    {{ errorText }}
                  </v-alert>
                  
                  <v-card flat v-if="selectedContainer">
                    <v-form @submit.prevent="saveChanges">
                      <v-tabs v-model="section" color="secondary">
                        <v-tab tab-value="info">Info</v-tab>
                        <v-tab tab-value="manifest">Manifest</v-tab>
                      </v-tabs>
                      
                      <v-divider></v-divider>
                      
                      <v-tabs-items v-model="section">
                        <v-tab-item value="info">
                          <v-container class="pa-5 mt-5" fluid>
                            
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  label="Container Name"
                                  autocomplete="off"
                                  v-model="selectedContainer.name"
                                  required
                                  outlined
                                  :disabled="!canEdit"
                                ></v-text-field>
                              </v-col>
                              
                              <v-col cols="6">
                                <v-text-field
                                  label="Image Url"
                                  autocomplete="off"
                                  v-model="selectedContainer.image_url"
                                  required
                                  outlined
                                  :disabled="!canEdit"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            
                            <v-row no-gutters>
                              <v-textarea
                                label="Container Description"
                                autocomplete="off"
                                v-model="selectedContainer.description"
                                outlined
                                :disabled="!canEdit"
                              ></v-textarea>
                            </v-row>
                            
                            <v-row>
                              <v-col cols="6">
                                <v-text-field
                                  label="Company Name"
                                  autocomplete="off"
                                  v-model="selectedContainer.companyName"
                                  required
                                  outlined
                                  :disabled="!canEdit"
                                ></v-text-field>
                              </v-col>
                              
                              <v-col cols="6">
                                <v-text-field
                                  label="Company URL"
                                  autocomplete="off"
                                  v-model="selectedContainer.companyUrl"
                                  outlined
                                  :disabled="!canEdit"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            
                            <v-row no-gutters>
                              <v-textarea
                                label="Company Description"
                                autocomplete="off"
                                v-model="selectedContainer.companyDescription"
                                outlined
                                :disabled="!canEdit"
                              ></v-textarea>
                            </v-row>
                            
                            <v-row no-gutters>
                              <v-textarea
                                label="Requirements Description"
                                autocomplete="off"
                                v-model="selectedContainer.requirements"
                                outlined
                                :disabled="!canEdit"
                              ></v-textarea>
                            </v-row>
                            
                            <v-row no-gutters>
                              <v-select
                                label="Platforms"
                                autocomplete="off"
                                v-model="selectedContainer.platforms"
                                :items="productNameList"
                                item-text="label"
                                item-value="value"
                                multiple
                                required
                                outlined
                                :disabled="!canEdit"
                              ></v-select>
                            </v-row>
                            
                            <hr class="mb-5" />
                            
                            <v-row no-gutters>
                              <strong>Container GUID:</strong>&nbsp; <span class="primary--text">{{ selectedContainer.container_guid }}</span>
                            </v-row>
                            <v-row no-gutters>
                              <v-checkbox
                                v-model="selectedContainer.published"
                                label="Published"
                                :disabled="!canEdit"
                                hide-details
                                class="mr-5"
                              ></v-checkbox>
                              
                              <v-checkbox
                                v-model="selectedContainer.licensable"
                                label="Licensable"
                                :disabled="!canEdit"
                                hide-details
                                class="mr-5"
                              ></v-checkbox>
                              
                            </v-row>
                          </v-container>
                        </v-tab-item>
                        
                        <v-tab-item value="manifest">
                          <v-container class="pa-5" fluid>
                            <edit-manifest v-model="selectedContainer.manifest" :isAdmin="true" :canEdit="canEdit" v-if="section == 'manifest'"></edit-manifest>
                          </v-container>
                        </v-tab-item>
                      </v-tabs-items>
                      
                      <div class="text-right">
                        <v-btn
                          class="ma-1"
                          small
                          text
                          @click="showDeleteContainer = true"
                          v-if="selectedContainer.container_guid"
                          :disabled="!canEdit"
                        >
                          <v-icon>delete</v-icon> Delete Container
                        </v-btn>
                        <v-btn
                          small
                          class="ml-1"
                          color="primary" 
                          @click="saveChanges"
                          :loading="loading"
                          :disabled="!canEdit || !hasChanges || !selectedContainer.name || !selectedContainer.image_url"
                        >
                          Save Container
                        </v-btn>
                      </div>
                    </v-form>
                  </v-card>
                  
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  
  import EditManifest from './components/EditManifest.vue'
  
  export default {
    name: 'ContainerList',
    
    components: {
      EditManifest
    },
    
    data() {
      return {
        productNameList: [
          { label: 'Edgecaster', value: 'edgecaster' },
          { label: 'Edgecaster Max', value: 'edgecaster_max' },
        ],
        
        loading: false,
        
        error: false,
        errorText: false,
        
        selectedContainer: false,
        hasChanges: false,
        
        section: 'info',
        
        showDeleteContainer: false,
        
        showUnsavedChanges: false
      }
    },
    
    mounted() {
      this.loading = true
      this.$containers.allContainers().then(() => {
        this.loading = false
      }).catch((error) => {
        this.error = true
        this.errorText = this.$helpers.parseError(error)
        this.loading = false
      })
    },
    
    watch: {
      selectedContainer: {
        deep: true,
        handler(to, from) {
          if (to == from && this.selectedContainer && this.selectedContainer.name != '') {
            this.hasChanges = true
          } else {
            this.hasChanges = false
          }
        }
      }
    },
    
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isContainerAdmin']),
      
      containers() {
        return this.$containers.containers
      },
      
      canEdit() {
        return this.isContainerAdmin
      }
    },
    
    methods: {
      newContainer() {
        this.selectedContainer = Vue.observable({
          'name': '',
          'image_url': '',
          'description': '',
          
          'companyName': '',
          'companyUrl': '',
          'companyDescription': '',
          
          'requirements': '',
          'manifest': {},
          
          'platforms': ['edgecaster', 'edgecaster_max'],
          
          'published': false,
          'licensable': false
        })
        
        this.hasChanges = false
        this.error = false
      },
      
      saveChanges() {
        if (!this.selectedContainer) {
          return
        }
        
        if (!this.selectedContainer.name) {
          this.error = true
          this.errorText = 'Name is a required parameter.'
          return
        }
        
        if (!this.selectedContainer.image_url) {
          this.error = true
          this.errorText = 'Image Url is a required parameter.'
          return
        }
        
        this.loading = true
        
        this.error = false
        this.errorText = ''
        
        var container = {
          'name': this.selectedContainer.name,
          'image_url': this.selectedContainer.image_url,
          'description': this.selectedContainer.description,
          
          'company': {
            'name': this.selectedContainer.companyName,
            'url': this.selectedContainer.companyUrl,
            'description': this.selectedContainer.companyDescription
          },
          
          'requirements': this.selectedContainer.requirements,
          'manifest': this.selectedContainer.manifest,
          
          'platforms': this.selectedContainer.platforms,
          
          'published': this.selectedContainer.published,
          'licensable': this.selectedContainer.licensable,
        }
        
        if (!this.selectedContainer.container_guid) {
          console.log('ContainerList saveChanges new container', container)
          
          this.axios.post('/containers', container)
          .then((response) => {
            console.log('ContainerList saveChanges response', response)
            
            this.selectedContainer = false
            
            this.$containers.allContainers().finally(() => {
                // this.selectedContainer = this.$containers.getContainer(...);
                this.loading = false
                this.selectedContainer = false
            })
            
            this.hasChanges = false
          })
          .catch((error) => {
            console.log('ContainerList saveChanges error', error)
            
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
        } else {
          console.log('ContainerList saveChanges update container ' + this.selectedContainer.container_guid, container)
          
          this.axios.patch('/containers/' + this.selectedContainer.container_guid, container)
          .then((response) => {
            console.log('ContainerList saveChanges response', response)
            
            this.$containers.allContainers().finally(() => {
              this.loading = false
              this.selectedContainer = false
            })
            
            this.hasChanges = false
          })
          .catch((error) => {
            console.log('ContainerList saveChanges error', error)
            
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
        }
      },
      
      confirmDeleteContainer() {
        if (this.selectedContainer.container_guid) {
          this.showDeleteContainer = false
          
          this.loading = true
          
          this.error = false
          this.errorText = ''
          
          console.log('ContainerList confirmDeleteContainer', this.selectedContainer)
          
          this.axios.delete('/containers/' + this.selectedContainer.container_guid)
          .then((response) => {
            console.log('ContainerList confirmDeleteContainer response', response)
            
            this.selectedContainer = false
            
            this.$containers.allContainers().finally(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            console.log('ContainerList confirmDeleteContainer error', error)
            
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            
            this.loading = false
          })
        }
      }
    }
  }
</script>
