import Vue from 'vue'
import router from '../router'
import vuetify from './vuetify'
import colors from 'vuetify/lib/util/colors'

import { diff } from 'deep-object-diff'

import labels from './helpers/labels.js'
import shadow from './helpers/shadow.js'
import formats from './helpers/formats.js'
import edgecasterMaxTimezones from './helpers/timezones/edgecaster_max.js'
import edgecasterTimezones from './helpers/timezones/edgecaster.js'

export default {
  colorLookup: [
    'red',
    'indigo',
    'green',
    'yellow',
    'deepPurple',
    'lightBlue',
    'lightGreen',
    'orange',
    'purple',
    'blue',
    'amber',
    'lime',
    'pink',
    'cyan'
  ],

  timestampProperties: [
    'last_boot',
    'last_update',
    'last_connection_event',
    'last_state_update',
    'created'
  ],
  
  parseError(error) {
    if (error.response && error.response.data) {
      if (error.response.data.message) {
        return error.response.data.message
      } else {
        return error.response.data 
      }
    } else if (error.data && error.data.message) {
      return error.data.message
    } else if (error.message) {
      return error.message
    }
    return 'Unknown Error'
  },
  
  // the DEAlWITHITLATER state fixer!
  deferredReload() {
    setTimeout(() => {
      console.log('Helpers deferredReload')
      window.history.go() 
    }, 500)
  },
  
  forceLogin() {
    router.push('/auth/login')
  },
  
  resetCache() {
    Vue.devices.reset()
    Vue.fleets.reset()
    Vue.users.reset()
    Vue.alerts.reset()
  },
  
  getColorHex(color, variation = 0) {
    if (colors[color] && variation == 0 && colors[color]['base']) {
      return colors[color]['base']
    } else if (colors[color] && variation > 0 && variation % 2 == 0) {
      return colors[color]['darken' + variation]
    } else if (colors[color] && variation > 0 ) {
      return colors[color]['lighten' + variation]
    // variations dont work on theme colors
    } else if (vuetify.framework.theme.currentTheme && vuetify.framework.theme.currentTheme[color]) {
      return vuetify.framework.theme.currentTheme[color]
    }
  },
  
  getGraphColorByIndex(index) {
    if (this.colorLookup[index]) {
      return colors[this.colorLookup[index]]['base']
    }
    
    var multiplier = Math.ceil(index / this.colorLookup.length)
    var remainder = index % this.colorLookup.length
    var darken = (index % 2 == 0) ? true : false
    var colorVariant = (darken) ? 'darken' + multiplier : 'lighten' + multiplier
    
    if (colors[this.colorLookup[remainder]][colorVariant]) {
      return colors[this.colorLookup[remainder]][colorVariant]
    }
    
    return colors['blueGrey']['base']
  },
  
  getLabel(label) {
    var thisLabel = '' + label // weird string problem otherwise...
    
    if (thisLabel.indexOf('|')) {
      thisLabel = thisLabel.substr(thisLabel.indexOf('|') + 1)
    }
    
    if (labels[thisLabel]) {
      return labels[thisLabel].label
    } else if (formats[thisLabel]) {
      return formats[thisLabel]
    }
    
    return thisLabel
  },

  getTimezones(product_name) {
    if (product_name === 'edgecaster') {
      return edgecasterTimezones
    } if (product_name === 'edgecaster_max') {
      return edgecasterMaxTimezones
    }
    return edgecasterTimezones
  },
  
  getUnit(label) {
    var thisLabel = '' + label // weird string problem otherwise...
    
    if (thisLabel.indexOf('|') > 0) {
      thisLabel = thisLabel.substr(thisLabel.indexOf('|') + 1)
    }
    
    if (labels[thisLabel]) {
      return labels[thisLabel].unit
    }
  },
  
  getFormatLabel(format) {
    if (formats[format]) {
      return formats[format]
    }
    return format
  },
  
  byteFormat(value, includeFormat = true) {
    const const_term = 1024
    var format = ''
    
    if (value > const_term ** 4) {
      value = (value / const_term ** 4).toFixed(2)
      format = 'TB'
    } else if (value > const_term ** 3) {
      value = (value / const_term ** 3).toFixed(2)
      format = 'GB'
    } else if (value > const_term ** 2) {
      value = (value / const_term ** 2).toFixed(2)
      format = 'MB'
    } else {
      value = (value / const_term).toFixed(2)
      format = 'KB'
    }
    
    if (includeFormat) {
      return '' + value + format
    } else {
      return value
    }
  },

  titleCase(string) {
    // Convert a string like "this is a random string" -> "This Is A Random String"
    // It doesn't strictly uphold title case guidelines, but it should be enough for
    // displaying all-lowercase values as titles
    
    return string.split(' ').map(word => {
      if (word.length > 0) {
        word = word[0].toUpperCase() + word.slice(1)
      }

      return word
    }).join('')
  },
  
  
  alertCondition(thisAlert) {
    var condition = this.getLabel(thisAlert.name || thisAlert.alert_name)
    
    if (thisAlert.threshold_condition == 'GreaterThanThreshold') {
      condition += ' > '
    } else if (thisAlert.threshold_condition == 'LessThanThreshold') {
      condition += ' < '
    } else if (thisAlert.threshold_condition == 'EqualToThreshold') {
      condition += ' = '
    } else {
      condition += ' [' + thisAlert.threshold_condition + '] '
    }
    
    condition += this.getLabel(thisAlert.threshold_value)
    
    var unit = this.getUnit(thisAlert.name || thisAlert.alert_name)
    if (unit) {
      condition += unit
    }
    
    return condition
  },
  
  getRefreshInterval(minInterval, maxInterval) {
    if (!minInterval) {
      minInterval = Math.ceil(20000)
    }
    
    if (!maxInterval) {
      maxInterval = Math.floor(40000)
    }
    
    return Math.floor(Math.random() * (maxInterval - minInterval + 1) + minInterval)
  },
  
  dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
  },

  // https://stackoverflow.com/a/30832210
  downloadFile(name, blob) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, name)
    } else {
      var a = document.createElement('a')
      var url = URL.createObjectURL(blob)
      a.href = url
      a.download = name
      a.click()
    }
  },
  
  parseVersion(product, version) {
    var result = {'product': product, 'version': '', 'beta': false, 'dev': false, 'original': version}
    var cloudVersion = false
    var daemonVersion = false
    
    if (typeof version !== 'string') {
      console.log('parseVersion not string', version)
      return
    }
    
    if (product == 'cloud') {
      if (version.includes('LiveEdgeCloud-')) {
        cloudVersion = version.substring(15)
      } else if (version.includes('LiveEdge-')) {
        cloudVersion = version.substring(10)
        result.manual_update_required = true
      } else if (version.includes('V') || version.includes('v')) {
        cloudVersion = version.substring(1)
      } else {
        cloudVersion = version
      }
      
      if (cloudVersion) {
        // legacy stuff
        if (cloudVersion.includes('-beta')) {
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-beta'))
          result.beta = cloudVersion.substring(cloudVersion.indexOf('-beta') + 1)
        } else if (cloudVersion.includes('-BB-DEV')) {
          result.dev = true
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-BB-DEV'))
        } else if (cloudVersion.includes('-DEV')) {
          result.dev = true
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-DEV'))
        } else if (cloudVersion.includes('-BB')) {
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-BB'))
        }
        // bitbucket stuff
        if (cloudVersion.includes('-feature')) {
          result.dev = true
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-feature'))
        } else if (cloudVersion.includes('-bugfix')) {
          result.dev = true
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-bugfix'))
        } else if (cloudVersion.includes('-hotfix')) {
          result.dev = true
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-hotfix'))
        } else if (cloudVersion.includes('-master')) {
          result.dev = true
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-master'))
        } else if (cloudVersion.includes('-release')) {
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('-release'))
        }
        
        if (cloudVersion.includes('b')) {
          result.beta = cloudVersion.substring(cloudVersion.indexOf('b') + 1)
          cloudVersion = cloudVersion.substring(0, cloudVersion.indexOf('b'))
        }
        
        result.version = cloudVersion
      }
    } else if (product == 'device') {
      if (version.includes('V') || version.includes('v')) {
        daemonVersion = version.substring(1)
      } else {
        daemonVersion = version
      }
      
      // only take first 3 parts
      var versionParts = daemonVersion.split('.')
      result.version = versionParts[0] + '.' + versionParts[1] + '.' + versionParts[2]
      
      result.version = daemonVersion
    }
    
    return result
  },
  
  splitVersion(version) {
    var versionParts = version.split('.')
    
    var newVersion = {}
    newVersion.major = parseInt(versionParts[0])
    if (versionParts[1]) {
      newVersion.minor = parseInt(versionParts[1])
    } else {
      newVersion.minor = 0
    }
    if (versionParts[2]) {
      newVersion.patch = parseInt(versionParts[2])
    } else {
      newVersion.patch = 0
    }
    
    return newVersion
  },
  
  isNewerVersion(currentVersion, newVersion) {
    if (!currentVersion || !newVersion) {
      return false
    }
    
    var v1 = this.splitVersion(currentVersion.version)
    var v2 = this.splitVersion(newVersion.version)
    
    if (v2.major > v1.major) {
      return true
    } else if (v2.major == v1.major && v2.minor > v1.minor) {
      return true
    } else if (v2.major == v1.major && v2.minor == v1.minor && v2.patch > v1.patch) {
      return true
    } else if (v2.major == v1.major && v2.minor == v1.minor && v2.patch == v1.patch && currentVersion.beta) {
      if (!newVersion.beta || newVersion.beta && parseInt(newVersion.beta) > parseInt(currentVersion.beta)) {
        return true
      }
    } else if (v2.major == v1.major && v2.minor == v1.minor && v2.patch == v1.patch) {
      return false
    }
    
    return false
  },
  
  versionCheck(currentVersion, checkVersion) {
    if (!currentVersion || !checkVersion) {
      return false
    }
    
    var v1 = this.splitVersion(currentVersion.version)
    var v2 = this.splitVersion(checkVersion.version)
    
    if (v2.major > v1.major) {
      return true
    } else if (v2.major == v1.major && v2.minor > v1.minor) {
      return true
    } else if (v2.major == v1.major && v2.minor == v1.minor && v2.patch > v1.patch) {
      return true
    } else if (v2.major == v1.major && v2.minor == v1.minor && v2.patch == v1.patch && checkVersion.beta) {
      if (!currentVersion.beta || currentVersion.beta && parseInt(checkVersion.beta) >= parseInt(currentVersion.beta)) {
        return true
      }
    } else if (v2.major == v1.major && v2.minor == v1.minor && v2.patch == v1.patch) {
      return true
    }
    
    return false
  },
  
  outputStatus(output) {
    var statusFunction = '' + output.type + 'Status'
    
    if (shadow[statusFunction]) {
      return shadow[statusFunction](output)
    } else {
      return shadow['unknownStatus'](output)
    }
  },
  
  encoderStatus(encoder) {
    var encoderStatus = {'status_code': '', 'status_message': '', 'error': false}
    
    encoderStatus.status_code = encoder.status
    
    if (encoderStatus.status_code == 'NO_INPUT') {
      encoderStatus.error = true
    } else if (encoderStatus.status_code == 'UNABLE_TO_ENCODE') {
      encoderStatus.error = true
    }
    
    return encoderStatus
  },
  
  isTimestamp(propertyName) {
    if (this.timestampProperties.includes(propertyName)) {
      return true
    }
    return false
  },
  
  recursiveDiff(from, to, allKeys) {
    var diffs = []
    var thisDiff = diff(from, to)
    
    if (thisDiff) {
      var keys = Object.keys(thisDiff)
      for (var key of keys) {
        if (allKeys || !shadow.shadowIgnoreKeys.includes(key)) {
          if (typeof thisDiff[key] === 'object' || (from && typeof from[key] === 'object') || (to && typeof to[key] === 'object')) {
            var diffArray = this.recursiveDiff((from && from[key] !== undefined) ? from[key] : {} , (to && to[key] !== undefined) ? to[key] : {}, allKeys)
            if (diffArray.length > 0) {
              diffs.push({'key': key, 'array': diffArray})
            }
          } else {
            diffs.push({'key': key, 'to': (to && to[key] !== undefined) ? to[key] : undefined, 'from': (from && from[key] !== undefined) ? from[key] : undefined})
          }
        }
      }
    }
    
    return diffs
  },
  
  stateDiff(from, to) {
    var diffs = []    
    var thisDiff = diff(from, to)
    
    if (thisDiff) {
      var keys = Object.keys(thisDiff)
      for (var key of keys) {
        if (key != 'last_state_update') {
          if (typeof thisDiff[key] === 'object' || ((from && typeof from[key] === 'object') || (to && typeof to[key] === 'object'))) {
            var diffArray = this.stateDiff((from && from[key] !== undefined) ? from[key] : {} , (to && to[key] !== undefined) ? to[key] : {})
            if (diffArray.length > 0) {
              // remove the changes from the state array
              var unchanged_state = JSON.parse(JSON.stringify((to[key]) ? to[key] : from[key]))
              for (var diffObject of diffArray) {
                if (typeof unchanged_state[diffObject.key] === 'string') {
                  delete unchanged_state[diffObject.key]
                }
              }
              diffs.push({'key': key, 'array': diffArray, 'unchanged_state': unchanged_state})
            }
          } else {
            diffs.push({'key': key, 'to': (to && to[key] !== undefined) ? to[key] : undefined, 'from': (from && from[key] !== undefined) ? from[key] : undefined})
          }
        }
      }
    }
    
    return diffs
  },
  
  // probably a native way to do this, but too much to do...
  arrayToIndexedOject(items, key) {
    var newObject = {}
    if (items && items.length > 0) {
      for (var item of items) {
        newObject[item[key]] = item
      }
    }
    return newObject
  },
  
  dockerDiff(from, to) {
    var changedContainers = []
    var changedImages = []
    
    var fromContainers = this.arrayToIndexedOject(from.containers, 'id')
    var toContainers = this.arrayToIndexedOject(to.containers, 'id')
    var containerIDs = [...new Set([...Object.keys(fromContainers) ,...Object.keys(toContainers)])]
    
    for (var id of containerIDs) {
      var fromContainer = undefined
      var toContainer = undefined
      
      if (fromContainers[id]) {
        fromContainer = fromContainers[id]
      }
      
      if (toContainers[id]) {
        toContainer = toContainers[id]
      }
      
      var containerDiff = this.recursiveDiff(fromContainer, toContainer)
      if (containerDiff.length > 0) {
        var changedContainer = false
        
        if (fromContainer && fromContainer.name) {
          changedContainer = fromContainer
        } else if (toContainer && toContainer.name) {
          changedContainer = toContainer
        }
        
        if (changedContainer) {
          changedContainer.changes = containerDiff
          changedContainers.push(changedContainer)
        }
      }
    }
    
    var fromImages = this.arrayToIndexedOject(from.images, 'id')
    var toImages = this.arrayToIndexedOject(to.images, 'id')
    var imageIDs = [...new Set([...Object.keys(fromImages) ,...Object.keys(toImages)])]
    
    for (var imageID of imageIDs) {
      var fromImage = undefined
      var toImage = undefined
      
      if (fromImages[imageID]) {
        fromImage = fromImages[imageID]
      }
      
      if (toImages[imageID]) {
        toImage = toImages[imageID]
      }
      
      var imageDiff = this.recursiveDiff(fromImage, toImage)
      if (imageDiff.length > 0) {
        var changedImage = false
        
        if (fromImage && fromImage.tags) {
          changedImage = fromImage
        } else if (toImage && toImage.tags) {
          changedImage = toImage
        }
        
        if (changedImage) {
          changedImage.changes = imageDiff
          changedImages.push(changedImage)
        }
      }
    }
    
    //console.log('dockerDiff', changedContainers, changedImages)
    return {'containers': changedContainers, 'images': changedImages}
  },
  
  shadowStateDiff(from, to, allKeys = false) {
    if (shadow['shadowStateDiff']) {
      return shadow['shadowStateDiff'](from, to, allKeys)
    }
  },
  shadowConfigDiff(from, to, allKeys = false) {
    if (shadow['shadowConfigDiff']) {
      return shadow['shadowConfigDiff'](from, to, allKeys)
    }
  },
  
  htmlDecode(input) {
    var doc = new DOMParser().parseFromString(input, "text/html")
    return doc.documentElement.textContent
  },

  repositoryName(item) {
    if (item && item.tags && item.tags[0]) {
      var itemName = item.tags[0]
      
      if (itemName.includes('ecr')) {
        var splitName = itemName.split('/')
        return splitName[splitName.length - 1]
      }
      
      return itemName
    }
    return '<none>'
  }
}
