<template>
  <div @click="showFirmwareVersions = true" style="cursor: pointer; " >
    <v-dialog
      v-model="showFirmwareVersions"
      max-width="700px"
    >
      <videon-card :heading="device.deviceName() + ' Firmware Versions'" showClose="true" @close="showFirmwareVersions = false">
        <div v-if="!device.state" class="pa-5 pb-2">
          <v-alert outlined type="error">
            Device state is unknown
          </v-alert>
        </div>
        <v-card-text>
          <div v-if="device.state" class="ma-1 mb-5">
            <v-row>
              <videon-state-tile
                heading="Daemon"
                minWidth="140px"
              >
                {{ device.daemon_version.original }}
              </videon-state-tile>
              <videon-state-tile
                heading="System"
                minWidth="140px"
              >
                {{ device.system_version }}
              </videon-state-tile>
              <videon-state-tile
                heading="Cloud Agent"
                minWidth="140px"
              >
                {{ device.cloud_version.original }}
              </videon-state-tile>
            </v-row>
          </div>
          
          <device-firmware-updater :device="device" @close='showFirmwareVersions = false'/>
          
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-tooltip 
      open-on-hover
      open-delay="500"
      right
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" v-if="isUpdating" class="d-inline-flex align-center" :class="labelColor"><v-icon :color="iconColor" class="mr-1">update</v-icon> Updating...</div>
        
        <div v-bind="attrs" v-on="on" v-else-if="awaitingReboot" class="d-inline-flex align-center red--text"><v-icon color="red" class="mr-1">refresh</v-icon> Reboot to update</div>
        
        <div v-bind="attrs" v-on="on" v-else-if="hasUpdates" class="d-inline-flex align-center" :class="labelColor"><v-icon :color="iconColor" class="mr-1">system_update_alt</v-icon> {{ device.firmware_version }}</div>
        
        <div v-bind="attrs" v-on="on" class="d-inline-flex align-center" v-else>{{ device.firmware_version }}</div>
      </template>
      <span>
        Firmware: {{ device.firmware_version }}<br/>
        Cloud Agent: {{ device.state.cloud_version }}
      </span>
    </v-tooltip>
    
  </div>
</template>

<script>
  import DeviceFirmwareUpdater from '../../device/components/DeviceFirmwareUpdater.vue'
  
  export default {
    name: 'FirmwareVersionLabel',
    props: ['device'],
    components: {
      DeviceFirmwareUpdater
    },
    computed: {
      labelColor() {
        if (this.device.isOnline() && this.hasUpdates) {
          return 'info--text'
        }
        return 'disabled--text'
      },
      iconColor() {
        if (this.device.isOnline() && this.hasUpdates) {
          return 'info'
        }
        return 'disabled'
      },
      hasUpdates() {
        return this.device.hasUpdates()
      },
      awaitingReboot() {
        if (this.device.isOnline() && this.device.state && this.device.state.update_state) {
          if (this.device.product_name == 'edgecaster') {
            var daemonState = this.device.state.update_state.find(x => (x.module == 'daemon' || x.module == 'Daemon'))
            if (daemonState && (daemonState.status == 'Awaiting Reboot' || daemonState.status == 'awaiting reboot')) {
              return true
            }
          } else if (this.device.product_name == 'edgecaster_max') {
            var systemState = this.device.state.update_state.find(x => (x.module == 'system'))
            if (systemState && (systemState.status == 'Update downloaded' || systemState.status == 'update downloaded')) {
              return true
            }
          }
        }
        return false
      },
      isUpdating() {
        return this.device.isUpdating()
      }
    },
    data() {
      return {
        showFirmwareVersions: false,
      }
    }
  }
</script>