<template>
  <div>
    <div v-if="canReInstall && !hasUpdates">
      <v-card
        color="info"
        outlined
        dark
        class="my-3"
      >
        <v-card-text>
          <h4 class="text-uppercase white--text d-inline-flex align-center"><v-icon color="white" class="mr-2">system_update_alt</v-icon> cloud agent re-install</h4>
          <v-alert
            text
            outlined
            color="black"
            class="mt-3 mb-0"
            v-if="updateStatus == 'rejected' || updateStatus == 'error'"
          >
            {{ updateMessage }}
          </v-alert>
          
          <v-alert
            text
            outlined
            color="disabled"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-else-if="updateStatus == 'complete'"
          >
            {{ updateMessage }}
          </v-alert>
          
          <v-progress-linear
            class="mt-5 mb-2"
            color="white"
            indeterminate
            buffer-value="0"
            v-if="isUpdating"
          ></v-progress-linear>
          
          <v-btn @click="applyUpdate('cloud')" color="secondary" width="100%" class="mt-4" :disabled="disableUpdateButton">re-install latest version</v-btn>
        </v-card-text>
      </v-card>
    </div>
      
    <div v-if="hasUpdates">
      <v-card
        color="info"
        outlined
        dark
        class="my-3"
      >
        <v-card-text>
          <h4 class="text-uppercase white--text d-inline-flex align-center"><v-icon color="white" class="mr-2">system_update_alt</v-icon> cloud update available</h4>
          
          <v-alert
            text
            outlined
            color="black"
            class="mt-3 mb-0"
            v-if="updateStatus == 'rejected' || updateStatus == 'error'"
          >
            {{ updateMessage }}
          </v-alert>
          
          <v-alert
            text
            outlined
            color="disabled"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-else-if="updateStatus == 'complete'"
          >
            {{ updateMessage }}
          </v-alert>
          
          <v-alert
            text
            outlined
            color="white"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-else-if="requiresManualUpdate"
          >
            {{ version.original }} is available. However, the version you are on does not support self-updating.  Please follow the manual upgrade steps.
          </v-alert>
          
          <v-alert
            text
            outlined
            color="disabled"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-else-if="device && !device.isOnline() && !isUpdating"
          >
            {{ version.original }} is available. However, you are unable to apply updates while the device is offline.
          </v-alert>
          
          <div v-else-if="device && device.isOnline()">
            <v-alert
              text
              outlined
              color="white"
              icon="info_outline"
              class="mt-3 mb-0"
              v-if="devFirmware && !updateStatus"
            >
              You are currently running a development build, updating to an official release may give you an older version than you are currently running.
            </v-alert>
            
            <v-progress-linear
              class="mt-5 mb-2"
              color="white"
              indeterminate
              buffer-value="0"
              v-if="isUpdating"
            ></v-progress-linear>
            
            <v-btn @click="applyUpdate()" color="secondary" width="100%" class="mt-4" :disabled="disableUpdateButton">Update to {{ version.original }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceFirmwareUpdaterCloud',
    props: ['device'],
    data() {
      return {
        
      }
    },
    computed: {
      ...mapGetters('user', ['guid', 'isAdmin']),
      
      canEdit() {
        return this.device.canEdit()
      },
      
      version() {
        return this.device.updatesAvailable['cloud']
      },
      
      hasUpdates() {
        return (this.device.updatesAvailable['cloud']) ? true : false
      },
      
      canReInstall() {
        if (this.isAdmin && this.device.product_name == 'edgecaster') {
          return true
        }
        return false
      },
      
      disableUpdateButton() {
        if (!this.canEdit || !this.device.isOnline() || this.device.isUpdating()) {
          return true
        }
        return false
      },
      
      isUpdating() {
        return (this.device.command == 'update_cloud' && this.device.commandGUID) ? true : false
      },
      
      updateStatus() {
        return (this.device.command == 'update_cloud') ? this.device.command_status : false
      },
      
      updateMessage() {
        return (this.device.command == 'update_cloud') ? this.device.command_message : ''
      },
      
      devFirmware() {
        if (this.device.cloud_version && this.device.cloud_version.dev) {
          return true
        }
        return false
      },
      
      requiresManualUpdate() {
        if (this.device.cloud_version.manual_update_required) {
          return true
        }
        return false
      }
    },
    methods: {
      applyUpdate() {
        this.device.command_status = 'issued'
        this.device.command_message = 'Cloud Update Requested'
        
        this.axios.post('/devices/' + this.device.device_guid + '/updates/cloud', {'update': true}).then((response) => {
          console.log('DeviceFirmwareUpdaterCloud ' + this.device.device_guid + ' applyUpdate response', response)
          this.device.last_api_request_id = response.data.api_request_id
          
          this.device.command_status = 'accepted'
          this.device.command_message = 'Update Command Accepted'
          
          this.device.commandGUID = response.data.command_guid
          this.device.command = 'update_cloud'
          
          if (this.device.commandGUID) {
            this.device.commandResponseTimer = setTimeout(() => {
              this.commandResponseCheckAttempt++
              this.device.checkDeviceCommand(this.device.commandGUID)
            }, this.device.commandResponseCheckInterval)
          }
        }).catch((error) => {
          console.log('DeviceFirmwareUpdaterCloud ' + this.device.device_guid + ' applyUpdate error', this.$helpers.parseError(error))
          
          this.device.command_status = 'error'
          this.device.command_message = this.$helpers.parseError(error)
        })
      }
    }
  }
</script>