<template>
  <videon-metric-tile
    :heading="heading"
    :description="description"
    :icon="icon"
    :color="color"
    @click.native="showChart = true"
    style="cursor: pointer;"
  >
    <v-dialog
      v-model="showChart"
      max-width="90%"
    >
      <videon-chart :heading="heading" :devices="[device]" :metrics="metrics" :fill="fill" :deviceMetrics="deviceMetrics" :unit="unit" :explorerButton="true" small :showClose="true" @close="showChart = false"/>
    </v-dialog>
    
    <v-progress-linear
      :color="color"
      indeterminate
      buffer-value="0"
      v-if="loading"
      class="mt-3 mb-2"
    ></v-progress-linear>
    
    <line-chart v-if="!loading && !error" :chart-data="chartData" :chart-options="chartOptions()" class="metric-tile-chart mt-2"/>
    
    <p v-if="error" class="my-5">Error fetching metrics</p>
  </videon-metric-tile>
</template>

<script>
  import DeviceMetrics from './DeviceMetrics.js'  
  
  import moment from 'moment'
  
  export default {
    name: 'VideonChartTile',
    
    props: {
      heading: {
        type: String
      },
      icon: {
        type: String
      },
      
      device: {
        type: Object,
        required: true
      },
      metrics: {
        type: Array,
        required: true
      },
      
      fill: {
        type: Boolean,
        default: false
      },
      unit: {
        type: String,
        default: ''
      },
      thresholdWarning: {
        type: Number
      },
      thresholdCritical: {
        type: Number
      },
    },
    mounted() {
      this.fetchMetrics()
    },
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
    },
    computed: {
      chartData() {
        return {
          datasets: this.deviceMetrics.chartData().map((data, index) => ({
            label: data.label,
            data: data.data,
            spanGaps: false,
            lineTension: 0.2,
            fill: this.fill,
            borderWidth: 1,
            borderColor: this.$helpers.getColorHex(this.color, index),
            backgroundColor: this.$helpers.getColorHex(this.color, index),
            pointBackgroundColor: this.$helpers.getColorHex(this.color, index),
          })),
        };
      },
      
      color() {
        if (this.error || (this.last && this.thresholdCritical && this.last > this.thresholdCritical)) {
          return 'red'
        } else if (this.last && this.thresholdWarning && this.last > this.thresholdWarning) {
          return 'orange'
        }
        
        return 'blue'
      },
      description() {
        if (this.last) {
          if (this.unit == 'bytes') {
            return this.$helpers.byteFormat(this.last)
          } else {
            return this.last + this.unit
          }
        }
        return 'Unknown'
      }
    },
    data() {
      return {
        showChart: false,
        
        loading: true,
        error: false,
        
        deviceMetrics: new DeviceMetrics(),
        last: '',
        
        refreshTimer: false,
        refreshInterval: this.$helpers.getRefreshInterval(60000, 120000)
      }
    },
    
    methods: {
      fetchMetrics() {
        if (this.loading || document.hasFocus()) {
          this.deviceMetrics.devices = this.device
          this.deviceMetrics.metrics = this.metrics
          this.deviceMetrics.unit = this.unit
        
          var endTime = moment()
        
          // weird adjustment to land on a period interval and include the last period
          if (endTime.minute() >= 30) {
            endTime.set({minute: 0, second: 0})
            this.deviceMetrics.end_time = endTime.add('1', 'hour').toISOString()
          } else {
            endTime.set({minute: 30, second: 0})
            this.deviceMetrics.end_time = endTime.toISOString()
          }
        
          this.deviceMetrics.start_time = endTime.subtract('12', 'hour').toISOString()
        
          this.deviceMetrics.fetchMetrics().then(() => {
            this.last = this.deviceMetrics.lastMax()
            
            this.loading = false
          }).catch((error) => {
            this.error = true
            this.loading = false
            console.log('VideonChartTile fetchMetrics error', error.message)
          })
        }
        
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            this.fetchMetrics()
          }, this.refreshInterval)
        }
      },
      
      chartOptions() {
        var chartOptions = {
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false
          },
          scales: {
            x: {
              type: 'time',
              ticks: {
                display: false,
                autoSkip: true,
                source: 'data'
              }
            },
            y: {
              ticks: {
                display: true,
                callback: (value) => {
                  if (this.unit == 'bytes') {
                    return this.$helpers.byteFormat(value, false)
                  } if(this.unit == '%') {
                    return Number.isInteger(value) ? '' + value : null
                  } else {
                    return value.toFixed(2)
                  }
                }
              }
            }
          },
          elements: {
            point: {
              radius: 0,
              hoverRadius: 1,
            },
          }
        }
        
        if (this.unit == '%') {
          chartOptions.scales.y.min = 0
          chartOptions.scales.y.max = 100
        }
        
        return chartOptions
      }
      
    }
  }
</script>
<style scoped lang="scss">
  .metric-tile-chart {
    width: 95% !important;
    height: 55px !important;
  }
</style>