var requiredModules = ['Auth', 'I18n', 'Logger', 'PubSub'];

var amplifyPlugin = {
  install(Vue, modules) {
    var missingModules = requiredModules.filter(module => !(module in modules))
    
    if (missingModules.length > 0) {
      return new Error('Amplify requires the following modules: ' + missingModules.join(', '))
    }
    
    Vue.prototype.$Amplify = modules
  }
};

export default amplifyPlugin
