<template>
  <v-container>
    <v-row class="mx-2 my-1" align="end">
      <v-text-field
        class="mx-5"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search by Device Serial"
        hide-details
      ></v-text-field>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selection"
          item-key="device_guid"
          show-select
          :headers="headers"
          :items="items"
          :items-per-page="itemsPerPage"
          :loading="loading"
          hide-default-footer
        >
          <template v-slot:item.device_name="{ item }">
            <device-label :device="item" :clickable="false" :disabled="!item.isSelectable" />
          </template>
          <template v-slot:item.streaming_status="{ item }">
            <streaming-status-label :device="item" />
          </template>
          <template v-slot:item.device_ip="{ item }">
            <v-tooltip 
              open-on-hover
              open-delay="500"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.device_ip }}
                </span>
              </template>
              <span v-if="item.state.online">External IP: {{ item.external_ip }}</span>
              <span v-else>Device Offline</span>
            </v-tooltip>
          </template>
          <template v-slot:item.firmware_version="{ item }">
            <firmware-version-label :device="item" />
          </template>
          <template v-slot:item.metadata="{ header, item }">
            <span v-if="header.key && item.hasMetadataKey(header.key)">{{  $helpers.getLabel(item.metadataValue(header.key)) }}</span>
            <span v-else-if="!header.key">
              <v-chip
                small
                class="mr-1"
                label
                v-for="(data, index) in item.metadata"
                v-bind:key="index"
                outlined
              >
                <device-note v-if="data.key == 'note'" :device="item" />
                <span v-else>
                  <strong>{{ $helpers.getLabel(data.key) }}</strong>: {{ $helpers.getLabel(data.value) }}
                </span>
              </v-chip>
            </span>
          </template>
          <template v-slot:item.state="{ header, item }">
            <span v-if="header.key && item.state && item.state[header.key]">{{  $helpers.getLabel(item.state[header.key]) }}</span>
          </template>
        </v-data-table>
        <data-table-pagination v-model="page" :paginationTokens="paginationTokens" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceSelector',
    
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
      
        search: '',
        awaitingSearch: false,
      
        items: [],
        selection: [],
        
        limitedSelection: [],
      
        itemsPerPage: 50,
        page: 1,
        pageCount: 0,
        paginationTokens: []
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'organization']),
      ...mapGetters('userPreferences', ['deviceListLayout']),
      
      headers() {
        var sortableItems = [
          'streaming_status',
          'device_ip',
          'external_ip',
          'firmware_version',
          'serial_number',
          'mac_address'
        ]
        
        var headers = [
          { text: 'Device', align: 'start', value: 'device_name', sortable: true }
        ]
        
        for (var index in this.deviceListLayout) {
          // only include first 2 columns
          if (index > 1) {
            break
          }
          
          var layout = this.deviceListLayout[index]
          
          var thisHeader = {
            align: layout.class,
            value: layout.component,
            sortable: sortableItems.includes(layout.component) ? true : false
          }
          
          if (layout.component == 'metadata' && layout.key) {
            thisHeader.text = this.$helpers.getLabel(layout.key)
          } else {
            thisHeader.text = this.$helpers.getLabel(layout.component)
          }
          
          if (layout.key) {
            thisHeader.key = layout.key
          }
          
          headers.push(thisHeader)
        }
        
        return headers
      }
    },
    props: {
      value: {
        type: Array,
        required: true
      },
      existing: {
        type: Array,
        required: false,
        default: () => { return [] }
      },
      disableExisting: {
        type: Boolean,
        required: false
      },
      selectionLimit: {
        type: Number,
        required: false
      },
      productNames: {
        type: Array,
        required: false
      }
    },
    watch: {
      value() {
        this.selection = this.value
        this.checkSelectionLimit()
      },
      page() {
        this.searchDevices(this.page - 1)
      },
      itemsPerPage() {
        this.items = []
        this.page = 1
        this.searchDevices(this.page - 1)
      },
      selection() {
        this.selectionChanged()
      },
      existing() {
        this.searchDevices(0)
      },
      search() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.items = []
            this.page = 1
            this.searchDevices(0)
            this.awaitingSearch = false
          }, 2000)
        }
        this.awaitingSearch = true
      }
    },
    mounted() {
      if (this.value) {
        this.selection = this.value
      }
      
      this.searchDevices(0)
    },
    beforeDestroy() {
      console.log('DeviceSelector cleaning selection properties')
      this.items.map((dev) => {
        var device = this.$devices.getDevice(dev.device_guid)
        device.isSelectable = true
        device.selected = false
        return device
      })
      this.items = []
    },
    methods: {
      searchDevices(paginationIndex) {
        this.loading = true
        this.items = []
        
        const paginationToken = this.paginationTokens[paginationIndex - 1]
        
        var queryParams = {
          'org_guid': this.organization.org_guid,
          'pagination_size': this.itemsPerPage
        }
        
        if (this.search) {
          queryParams.serial_number = this.search
        }

        if (this.productNames) {
          queryParams.product_names = this.productNames
        }
        
        if (paginationToken) {
          queryParams.pagination_token = paginationToken
        }

        this.axios.get('/devices', {
          'params': queryParams,
          paramsSerializer: {
            indexes: null
          }
        }).then((response) => {
          console.log('DeviceSelector searchDevices response', response)
          
          if (response.data.devices.length > 0) {
            /*
            // HACK.. if we dont have a product_name use edgecaster
            response.data.devices.map((dev) => {
              if (!dev.product_name) {
                dev.product_name = 'edgecaster'
              }
              return dev
            }) 
            */
            this.items = response.data.devices.map((dev) => {
              var device = this.$devices.getDevice(dev.device_guid)
              
              var existingDevice = this.existing.find(x => x.device_guid === device.device_guid)
              if (existingDevice) {
                device.isSelectable = false
              } else {
                device.isSelectable = true
              }
              
              var selectedDevice = this.selection.find(x => x.device_guid === device.device_guid)
              if (selectedDevice) {
                device.selected = true
              }
              
              return device
            })
            
            if (response.data.pagination_token) {
              this.$set(this.paginationTokens, paginationIndex, response.data.pagination_token)
            }
          }
          
          this.loading = false
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      selectionChanged() {
        this.$emit('input', this.selection)
      },
      
      checkSelectionLimit() {
        if (this.selectionLimit > 0 && this.selection.length >= this.selectionLimit) {
          console.log('DeviceSelector checkSelectionLimit limit hit (' + this.selection.length + '/' + this.selectionLimit + ')')
          
          for (var i in this.items) {
            if (this.items[i].isSelectable && !this.selection.includes(this.items[i])) {
              this.limitedSelection.push(this.items[i])
              this.items[i].isSelectable = false
            }
          }
        } else if (this.limitedSelection.length > 0) {
          console.log('DeviceSelector checkSelectionLimit resetting limit')
          for (var x in this.limitedSelection) {
            for (var y in this.items) {
              if (this.limitedSelection[x].device_guid == this.items[y].device_guid) {
                this.items[y].isSelectable = true
              }
            }
          }
        }
      }
    } 
  }
</script>