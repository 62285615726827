<template>
  <videon-card
    heading="Please confirm your account..."
    max-width="440"
  >
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
      
      <v-row class="ma-1">
        <v-col cols="12">
          <div class="text-center mb-10">
            <v-progress-circular :size="100" :width="10" indeterminate color="primary"></v-progress-circular>
          </div>
          
          <p>
            An email was sent to <span class="primary--text">{{ email }}</span> to validate your account. 
            <br/><br/>
            To continue, please find the confirmation email and click the link verification link provided.  
            <br/><br/>
            After confirmation this process will automatically continue.
          </p>
          
          <v-btn
            color="primary"
            @click="sendConfirmation"
            class="mx-auto ma-2"
            width="100%"
          >
            Re-send confirmation email
          </v-btn>
          
        </v-col>
      </v-row>
    </v-card-text>
  </videon-card>
</template>

<script>
  import { mapState, mapGetters } from "vuex"
  
  export default {
    name: 'EmailConfirmation',
    
    computed: {
      ...mapGetters('user', ['email']),
      ...mapState('user', ['error', 'errorText']),
    },
    data() {
      return {
        verified: false
      }
    },
    methods: {
      sendConfirmation() {
        this.$emit('sendConfirmation', this.email)
      }
    }
  }
</script>
