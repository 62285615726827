<template>
  <videon-card
    heading="Standard Login"
    max-width="440"
  >
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error" class="ma-5">
        {{ errorText }}
      </v-alert>
      
      <v-row class="ma-1">
        <v-col cols="12">
        
          <div class="subtitle-1 mb-5">
            We have sent a password reset code to <span class="primary--text">{{ email }}</span>, please enter that code here to reset your password.
          </div>

          <v-form @submit.prevent="submit">
            <v-text-field
              name="code"
              autocomplete="off"
              v-model="code"
              :error-messages="codeErrors"
              label="Reset Code"
              hint="Password reset code"
              @input="$v.code.$touch()"
              @blur="$v.code.$touch()"
              outlined
            ></v-text-field>
            
            <v-divider class="mb-5 mt-2"></v-divider>
            
            <v-text-field
              name="password"
              autocomplete="new-password"
              v-model="password"
              :error-messages="passwordErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.password.$touch()"
              @blur="$v.password.$touch()"
              outlined
            ></v-text-field>
            <v-text-field
              name="password-confirm"
              autocomplete="new-password"
              v-model="passwordConfirm"
              :error-messages="passwordConfirmErrors"
              :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPass ? 'text' : 'password'"
              label="Confirm Password"
              hint="At least 8 characters"
              counter
              @click:append="showPass = !showPass"
              @input="$v.passwordConfirm.$touch()"
              @blur="$v.passwordConfirm.$touch()"
              outlined
            ></v-text-field>
          </v-form>
                    
          <v-btn
            color="primary"
            @click="submit"
            class="mx-auto ma-2 mt-10"
            width="100%"
          >
            Update Password
          </v-btn>
          <v-btn
            color="primary"
            @click="cancel"
            class="mx-auto ma-2"
            width="100%"
            text
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </videon-card>
</template>

<script>
  import { mapState, mapGetters } from "vuex"
  
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'ForgotPassword',
    
    mixins: [validationMixin],

    validations: {
      code: { required, minLength: minLength(6) },
      password: { required, minLength: minLength(8) },
      passwordConfirm: { required, minLength: minLength(8) },
    },

    data() {
      return {
        code: '',
        password: '',
        passwordConfirm: '',
        showPass: false,
      }
    },
    
    computed: {
      ...mapState('user', ['error', 'errorText']),
      ...mapGetters('user', ['email', 'organizations', 'organization']),
      
      codeErrors () {
        const errors = []
        if (!this.$v.code.$dirty) return errors
        !this.$v.code.minLength && errors.push('Reset Code needs to be longer')
        !this.$v.code.required && errors.push('Reset Code is required')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password needs to be longer')
        !this.$v.password.required && errors.push('Password is required')
        return errors
      },
      passwordConfirmErrors () {
        const errors = []
        if (!this.$v.passwordConfirm.$dirty) return errors
        !this.$v.passwordConfirm.minLength && errors.push('Password needs to be longer')
        !this.$v.passwordConfirm.required && errors.push('Password is required')
        this.passwordConfirm !== this.password && errors.push('Passwords don`t match')
        return errors
      },
    },
    mounted() {
      //this.$refs.password.$el.focus()
    },
    methods: {
      submit() {
        this.$v.$touch()

        if (this.email && this.code && this.password && this.password == this.passwordConfirm && this.passwordErrors.length < 1) {
          this.$emit('submit', {email: this.email, code: this.code, password: this.password})
        }
      },
      cancel() {
        this.$store.dispatch('user/resetEmail')
      },
    },
  }
</script>
