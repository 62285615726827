<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row><h5 class="text--disabled">Thumbnail / Preview Output</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Output Enabled"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-if="config.name !== undefined || !id" class="mb-3">
      <v-col cols="12">
        <v-text-field
          v-model="config.name"
          label="Output Name"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Output Configuration</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3">
      <v-col cols="6">
        <v-text-field
          v-model.number="config.width"
          label="Image Width (Pixels)"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="config.interval"
          label="Updates per Second"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
    
    <v-row class="mb-3" v-if="config.width && (config.width > inputPreviewMaxWidth)">
      <v-col cols="12">
        <v-alert
          type="error"
          text
          class=""
        >
          Configuring image width to a value greater than {{ inputPreviewMaxWidth }} may result in AV Input Preview only being visible in the local device interface.
        </v-alert>
      </v-col>
    </v-row>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Output Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ output }}</pre>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>
<script>  
  import { mapGetters } from 'vuex'

  export default {
    name: 'ThumbnailOutputDocument',
    
    props: ['output', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      
    },
    
    data() {
      return {
        valid: true,
        
        inputPreviewMaxWidth: 320,
        
        id: false,
        type: false,
        
        config: {
          enable: false,
          
          width: 320,
          interval: 5,
          
          sources: {
            audio: [],
            video: []
          }
        }
      }
    },
    
    watch: {
      output() {
        this.updateValues()
      }
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        console.log('ThumbnailOutputDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config)
      },
      
      updateValues() {
        if (this.output && this.output.config) {
          Object.keys(this.output).map((key) => {
            this[key] = this.output[key]
          })
          
          // htmlDecode name properties
          if (this.config.name) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        this.configChanged()
      },
      
      deleteOutput() {
        console.log('ThumbnailOutputDocument ' + this.device_guid + ' deleteOutput')
        this.$emit('deleteOutput')
      }
    }
  }
</script>