import Vue from 'vue'
import Amplify from '@aws-amplify/core'
import { PubSub } from '@aws-amplify/pubsub'
import { AWSIoTProvider } from '@aws-amplify/pubsub'

var APIURL = ''

if (location.hostname != 'localhost') {
  APIURL = location.protocol + '//' + 'api.' + location.hostname + '/v1'
}

// initial state
const state = {
  configured: false,
  
  DeploymentName: process.env.VUE_APP_DEPLOYMENT_NAME || 'LiveEdge Cloud',
  
  AmplifyConfigured: false,
  AWSRegion: localStorage.getItem('AWSRegion') || null,
  AWSUserPoolId: localStorage.getItem('AWSUserPoolId') || null,
  AWSUserPoolDomain: localStorage.getItem('AWSUserPoolDomain') || null,
  AWSUserPoolWebClientId: localStorage.getItem('AWSUserPoolWebClientId') || null,
  AWSIdentityPoolId: localStorage.getItem('AWSIdentityPoolId') || null,

  AWSOAuthScope: JSON.parse(localStorage.getItem('AWSOAuthScope') || '[]'),
  AWSOAuthResponseType: localStorage.getItem('AWSOAuthResponseType') || null,
  
  AWSS3ProfileBucket: localStorage.getItem('AWSS3ProfileBucket') || null,
  AWSIOTEndpoint: localStorage.getItem('AWSIOTEndpoint') || null,
  
  APIConfigured: false,
  VideonAPIURL: process.env.VUE_APP_API_URL || APIURL,
}

// getters
const getters = {
  isConfigured: state => state.configured,
  
  deploymentName: state => state.DeploymentName,
  
  AmplifyConfig: () => {
    if (state.AWSUserPoolId) {
      return {
        'Auth': {
          'region': state.AWSRegion,
          'userPoolId': state.AWSUserPoolId,
          'userPoolWebClientId': state.AWSUserPoolWebClientId,
          'identityPoolId': state.AWSIdentityPoolId,
          'mandatorySignIn': true,
          
          'oauth': {
            'domain': state.AWSUserPoolDomain,
            'scope': state.AWSOAuthScope,
            'redirectSignIn': location.origin + '/auth/oauth_complete',
            'redirectSignOut': location.origin + '/',
            'responseType': state.AWSOAuthResponseType
          }
        },
        'AWSS3': {
          'bucket': state.AWSS3ProfileBucket,
          'region': state.AWSRegion
        },
        'PubSub': {
          'aws_pubsub_region': state.AWSRegion,
          'aws_pubsub_endpoint': state.AWSIOTEndpoint,
        }
      }
    }
    return false
  },
  
  PubSubConfig: () => {
    return {
      'aws_pubsub_region': state.AWSRegion,
      'aws_pubsub_endpoint': state.AWSIOTEndpoint,
    }
  },
  
  VideonAPIConfig: () => {
    if (state.VideonAPIURL) {
      return {
        'url': state.VideonAPIURL
      }
    }
    return false
  }
}

// actions
const actions = {
  setInitialConfiguration({commit, getters, rootGetters, dispatch}) {
    // IOT base configuration
    Amplify.Logger.LOG_LEVEL = 'DEBUG' // TODO change this from VERBOSE
    Amplify.register(PubSub)
    
    // apply the config to Amplify
    var amplifyConfig = getters.AmplifyConfig
    
    if (amplifyConfig) {
      Amplify.configure(amplifyConfig)
      
      if (amplifyConfig.PubSub.aws_pubsub_endpoint) {
        // remove & re-add the pubsub provider
        PubSub.removePluggable('AWSIoTProvider')
        PubSub.addPluggable(new AWSIoTProvider())
      } else {
        console.log('ConfigStore setInitialConfiguration aws_pubsub_endpoint not defined')
      }
      
    } else {
      console.log('ConfigStore setInitialConfiguration amplifyConfig not defined')
    }
    
    // apply the config to Axios
    var apiConfig = getters.VideonAPIConfig
    Vue.axios.defaults.baseURL = apiConfig.url
    
    // before each request, check to see if our token has expired or not
    Vue.axios.interceptors.request.use(
      async config => {
        if (!config.noValidateAuth) {
          await dispatch('user/quickValidateAuth', null, {root: true})
        }
        
        var jwtToken = rootGetters['user/token']
        if (jwtToken) {
          var currentAuthorization = 'Bearer ' + jwtToken
          
          if (config.headers.Authorization && config.headers.Authorization != currentAuthorization) {
            config.headers.Authorization = currentAuthorization
            console.log('Axios request config Authorization update', config)
          }
        }
        
        return config
      },
      error => {
        console.log('Axios returned error', error)
        Promise.reject(error)
      }
    )
    
    // overly complex error inteception ;)
    Vue.axios.interceptors.response.use(
      response => {
        if (response.status === 200 || response.status === 201 || response.status === 202) {
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      },
      error => {
        console.log('Axios request error "' + error.message + '"', error.response)
        
        if (error.response && error.response.status) {
          switch (error.response.status) {
            case 400:
              //do something
              break
            case 401:
              // do something
              break
            case 403:
              if (error.response && error.response.data && error.response.data.error_code == 'ACCESS_DENIED') {
                // If we reach this point, the refresh token has expired (which will only happen after 24 hours)
                // TODO: Just do a signout
                Vue.helpers.forceLogin()
              }
              break
            case 404:
              // do something
              break
            case 500:
              console.log('Axios 500 error', JSON.stringify(error))
              break
            case 502:
              // do something
          }
          
          return Promise.reject(error.response)
        }
        
        return Promise.reject(error)
      }
    )
    
    console.log('ConfigStore setInitialConfiguration', amplifyConfig, apiConfig)
    commit('setConfigured')
  },
  
  setAmplifyConfig({commit, getters}, config) {
    commit('setAmplifyConfig', config)
    
    // apply the new config to Amplify
    var amplifyConfig = getters.AmplifyConfig
    Amplify.configure(amplifyConfig)
    
    console.log('ConfigStore setAmplifyConfig', amplifyConfig)
  },
  
  setVideonAPIConfig({commit, getters}, config) {
    commit('setVideonAPIConfig', config)
    
    // apply the new config to Axios
    var apiConfig = getters.VideonAPIConfig
    Vue.axios.defaults.baseURL = apiConfig.url
    
    console.log('ConfigStore setVideonAPIConfig', apiConfig)
  },
}

// mutations
const mutations = {
  setConfigured(state) {
    state.configured = true
  },
  setAmplifyConfig(state, config) {
    state.AWSRegion = config.AWSRegion
    state.AWSUserPoolId = config.AWSUserPoolId
    state.AWSUserPoolDomain = config.AWSUserPoolDomain
    state.AWSUserPoolWebClientId = config.AWSUserPoolWebClientId
    state.AWSIdentityPoolId = config.AWSIdentityPoolId

    state.AWSOAuthScope = config.AWSOAuthScope
    state.AWSOAuthResponseType = config.AWSOAuthResponseType

    state.AWSS3ProfileBucket = config.AWSS3ProfileBucket
    state.AWSIOTEndpoint = config.AWSIOTEndpoint
    
    localStorage.setItem('AWSRegion', config.AWSRegion)
    localStorage.setItem('AWSUserPoolId', config.AWSUserPoolId)
    localStorage.setItem('AWSUserPoolDomain', config.AWSUserPoolDomain)
    localStorage.setItem('AWSUserPoolWebClientId', config.AWSUserPoolWebClientId)
    localStorage.setItem('AWSIdentityPoolId', config.AWSIdentityPoolId)

    localStorage.setItem('AWSOAuthScope', JSON.stringify(config.AWSOAuthScope || []))
    localStorage.setItem('AWSOAuthResponseType', config.AWSOAuthResponseType)
    
    localStorage.setItem('AWSS3ProfileBucket', config.AWSS3ProfileBucket)
    localStorage.setItem('AWSIOTEndpoint', config.AWSIOTEndpoint)
    
    state.AmplifyConfigured = true
  },
  setVideonAPIConfig(state, config) {
    state.VideonAPIURL = config.VideonAPIURL
    
    state.APIConfigured = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
