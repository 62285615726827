import Vue from 'vue'

class VideonBanner {
  constructor(banner_id, banner_attributes = false) {
    this.banner_id = banner_id
    this.ready = false
    
    this.title = ''
    this.html = ''
    this.icon = ''
    this.color = 'accent'
    
    if (banner_attributes) {
      for (var property in banner_attributes) {
        this[property] = banner_attributes[property]
      }
      this.ready = true
    }
    
    this.error = false
    this.errorText = ''
    
    if (!this.ready) {
      this.fetchBanner()
    }
  }
  
  fetchBanner() {
    // hardcoded for first release
    this.title = 'Changes to Videon\'s LiveEdge® Cloud Services'
    this.html = 'We will begin enforcing Cloud licensing <a target="_blank" href="https://d152tt04.na1.hs-sales-engage.com/Ctc/V+23284/d152tt04/Jl22-6qcW7lCdLW6lZ3p7VMJC0L1sRSlqVXC5Vl16GB0hW59BzNN5vjz5gW8-YT926JVy23W6fQLrS65rzBZW2BkbPS1TkxVtW2gQbTV7yYy-2W7Q7Pwn43SVS4W2hkB723nTx5XN8JXjL1dTqgFW1L_kng3yvrCyW19GPBZ7b21H_W19khd48rPPnxW7q4m-G8T4jyRN8B2tL5NHwMlN1y08sj5CyR7N352LG4tz2XQN8J8Z9lRfr_pN8X1_5XZbFXmMNq6wNCMPblW5ckmq54bZPRWVZMsDz3wqC44W9kfys426X7HyW66x0Mf97ncHzf7zrT6x04">as explained on our website</a>, starting <strong>December 31, 2024</strong>. Current vCare Premium Support subscribers will be grandfathered into the LiveEdge Cloud Basic package, which includes all functionality before September 1, 2024. Those not subscribed to vCare currently have LiveEdge Cloud Free. As LiveEdge Cloud Pro features are rolled out, they will be available to all customers for demo purposes until <strong>December 31, 2024</strong>.'
    this.icon = 'mdi-bullhorn'
  }

}

export default new class Banners {
  constructor() {
    this.banners = []
  }
  
  defaultBanner() {
    return this.getBanner('default')
  }
  
  getBanner(banner_id, banner_attributes = false) {
    if (!banner_id) {
      console.log('Banners getBanner missing banner_id', banner_id, banner_attributes)
      return false
    }
    
    var banner = this.banners.find(x => x.banner_id === banner_id)
    if (banner) {
      return banner
    }
    
    banner = Vue.observable(new VideonBanner(banner_id, banner_attributes))
    this.banners.push(banner)
    
    return banner
  }
}