<template>
  <v-autocomplete
    v-model="selectedProfileGuid"
    :itemText="item => `${item.profile_name} (${$helpers.getLabel(item.profile_platform)})`"
    itemValue="profile_guid"
    :loading="loading"
    :items="profiles"
    :filter="filter"
    label="Entitlement Profile"
    hide-details
    outlined
  />
</template>

<script>
  export default {
    name: 'ProfileSelector',
    
    data() {
      return {
        loading: false,
        profiles: [],
        selectedProfileGuid: ''
      }
    },

    props: {
      value: {
        type: String,
        default: ''
      }
    },

    watch: {
      value(value) {
        this.selectedProfileGuid = value
      },

      selectedProfileGuid() {
        this.$emit('input', this.selectedProfileGuid || '')
      }
    },

    mounted() {
      this.loading = true
      this.$entitlementProfiles.allEntitlementProfiles().then(() => {
        this.profiles = this.$entitlementProfiles.profiles
        this.loading = false
      })
      this.selectedProfileGuid = this.value
    },

    methods: {
      filter(item, queryText, itemText) {
        queryText = queryText.toLocaleLowerCase().replace(' ', '_')
        return !item.isBaseProfile() && itemText.toLocaleLowerCase().indexOf(queryText) > -1
      }
    }
  }
</script>