<template>
  <v-list-item 
    @click="showDialog()"
    v-if="hasStorageDevices"
    :disabled="!device.isOnline()"
  >
    
    <v-dialog
      v-model="showSafelyRemoveConfirm"
      max-width="500px"
      v-if="selectedDeviceDetails"
    >
      <videon-card heading="Safely Remove Device" showClose="true" @close="showSafelyRemoveConfirm = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to unmount <span class="primary--text">{{ selectedDeviceDetails.name }}</span>?
          </p>
          <v-form @submit.prevent="confirmSafelyRemove">
            <v-text-field
              v-model="confirmText"
              label="Remove Confirmation"
              messages="Please type REMOVE above to proceed"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showSafelyRemoveConfirm = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmSafelyRemove"
              >
                Safely Remove
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showFormatConfirm"
      max-width="500px"
      v-if="selectedDeviceDetails"
    >
      <videon-card heading="Format Device" showClose="true" @close="showFormatConfirm = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to format <span class="primary--text">{{ selectedDeviceDetails.name }}</span>?
          </p>
          <v-form @submit.prevent="confirmFormatStorage">
            <v-text-field
              v-model="confirmText"
              label="Format Confirmation"
              messages="Please type FORMAT above to proceed"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showFormatConfirm = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmFormatStorage"
              >
                Format
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-dialog
      v-model="showProcessing"
      max-width="500px"
      persistent
    >
      <videon-card :heading="processingMessage">
        <v-card-text>
          <div class="ma-5 mt-8">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="800px"
    >
      <videon-card heading="Storage Devices" showClose="true" :ignoreHeaderMargin="!isVersionSupported" @close="showControl = false">
        <v-alert
          text
          color="orange"
          icon="mdi-alert-outline"
          v-if="!isVersionSupported"
        >
          This feature is not supported on {{ this.device.cloud_version.original }}, please update your cloud agent.
        </v-alert>
        <v-card-text>
          <v-alert dense outlined type="error" v-if="commandStatus == 'rejected' || commandStatus == 'failed'" >
            {{ commandMessage }}
          </v-alert>
          
          <v-alert dense outlined type="info" v-if="showRebootWarning" >
            After formatting storage devices you will need to reboot the {{ $helpers.getLabel(device.product_name) }} in order to use them.
          </v-alert>
          
          <v-row class="mb-3">
            <v-col cols="5">
              <v-list dense width="100%">
                <v-list-item-group
                  v-model="selectedDevice"
                  color="primary"
                >      
                  <v-list-item
                    v-for="(drive, index) in availableDevices"
                    :key="index"
                    :value="drive.devno"
                    two-line
                  >
                    <v-list-item-icon class="mr-1">
                      <v-icon v-if="drive.type == 'MMC' || drive.type == 'SD Card'">sd_card</v-icon>
                      <v-icon v-else-if="drive.type == 'USB'">mdi-usb-flash-drive</v-icon>
                      <v-icon v-else>folder</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="drive.type == 'OTHER'">Internal Storage</v-list-item-title>
                      <v-list-item-title v-else-if="drive.type == 'USB'">USB Storage</v-list-item-title>
                      <v-list-item-title v-else-if="drive.type == 'MMC' || drive.type == 'SD Card'">SD Card</v-list-item-title>
                      <v-list-item-title v-else v-text="drive.type || drive.uuid"></v-list-item-title>
                      <v-list-item-subtitle>{{ $helpers.byteFormat(drive.actual_size) }} total, {{ $helpers.byteFormat(drive.used_space) }} used</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col cols="7" v-if="selectedDeviceDetails">
              <h3>{{ selectedDeviceDetails.name }}</h3>
              
              <v-row align="center">
                <v-col cols="12" align="center" class="mt-5">
                  <v-btn
                    class="my-3"
                    color="secondary"
                    outlined
                    :disabled="!selectedDeviceDetails.removable"
                    @click="showSafelyRemoveConfirm = true"
                  >
                    Safely Remove Device
                  </v-btn>
                  <br/>
                  <v-btn
                    class="my-3"
                    color="error"
                    :disabled="!selectedDeviceDetails.formatable"
                    @click="showFormatConfirm = true"
                  >
                    Format Device
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          
          
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="primary"
              @click="showControl = false"
              text
            >
              Close
            </v-btn>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="pr-2">
      <v-icon class="material-icons-outlined">save</v-icon> 
      Storage Devices
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  export default {
    name: 'StorageDevices',
    
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b20'),
        
        showControl: false,
        
        selectedDevice: false,
        
        showSafelyRemoveConfirm: false,
        showFormatConfirm: false,
        
        confirmText: '',
        
        showProcessing: false,
        processingMessage: 'Processing...',
        
        showRebootWarning: false,
      }
    },
    
    mounted() {
      
    },
    
    computed: {
      isVersionSupported() {
        if (this.$helpers.versionCheck(this.minimumAgentVersion, this.device.cloud_version)) {
          return true
        } else if (this.device.cloud_version.dev) {
          return true
        }
        return false
      },
      
      processing() {
        if (this.device.isCommandProcessing()) {
          return true
        }
        return false
      },
      
      storageDevices() {
        if (this.device.state && this.device.state.storage_devices) {
          return this.device.state.storage_devices
        }
        return false
      },
      
      hasStorageDevices() {
        if (this.storageDevices.length > 0) {
          return true
        }
        return false
      },
      
      commandStatus() {
        return this.device.command_status
      },
      commandMessage() {
        return this.device.command_message
      },
      
      availableDevices() {
        if (!this.hasStorageDevices) {
          return []
        }
        
        var actualDevices =  this.device.state.storage_devices.filter((storageDevice) => {
          return storageDevice.uuid != ''
        })
        
        return actualDevices.map((storageDevice) => {
          // take the X / Y of size and make it usable
          var size = storageDevice.size.split(' / ')
          storageDevice.actual_size = size[1]
          storageDevice.used_space = size[0]
          
          return storageDevice
        })
      },
      
      
      selectedDeviceDetails() {
        if (this.selectedDevice) {
          var storageDevice = this.availableDevices.find((storageDevice) => { return storageDevice.devno == this.selectedDevice})
          if (storageDevice) {
            if (storageDevice.type == 'USB' || storageDevice.type == 'SD Card') {
              storageDevice.removable = true
              storageDevice.formatable = true
            } else {
              storageDevice.removable = false
              storageDevice.formatable = false
            }
            return storageDevice
          }
        }
        
        return false
      }
    },
    
    watch: {
      availableDevices() {
        if (this.showProcessing && !this.processing) {
          this.showProcessing = false
        }
      }
    },
    
    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      
      confirmSafelyRemove() {
        if (this.confirmText != 'REMOVE') {
          return
        }
        
        this.showSafelyRemoveConfirm = false
        this.processingMessage = 'Unmounting device...'
        this.showProcessing = true
        this.confirmText = ''
        
        console.log('StorageDevices ' + this.device.device_guid + ' confirmSafelyRemove', this.selectedDevice)
        this.device.runCommand('safely_remove_storage', {'storage_device_id': this.selectedDevice})
      },
      confirmFormatStorage() {
        if (this.confirmText != 'FORMAT') {
          return
        }
        
        this.showFormatConfirm = false
        this.processingMessage = 'Formatting device...'
        this.showProcessing = true
        this.confirmText = ''
        this.showRebootWarning = true
        
        console.log('StorageDevices ' + this.device.device_guid + ' confirmFormatStorage', this.selectedDevice)
        this.device.runCommand('format_storage', {'storage_device_id': this.selectedDevice})
      },
    }
  }
</script>