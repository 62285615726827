<template>
  <v-container class="pa-0 my-3">
    <h4>Period Selection</h4>
    <v-card outlined flat>
      <v-row class="mx-2" align="center">
        <v-col cols="3">
          <v-select
            v-model="range"
            :items="ranges"
            item-text="label"
            item-value="key"
            label="Period"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </v-col>
        <v-col v-if="range.key != 'custom'">
          <span class="subtitle-2">
            {{ period.start | moment("YYYY/MM/DD hh:mm:ss a") }}
          </span>
          
          <v-icon small>mdi-arrow-right</v-icon>
          
          <span class="subtitle-2">
            {{ period.end | moment("YYYY/MM/DD hh:mm:ss a") }}
          </span>
        </v-col>
        <v-col v-else>
          <v-menu
            v-model="menuOpen"
            :close-on-content-click="false"
            :close-on-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                single-line
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="customRange"
              :max="(new Date()).toISOString()"
              show-adjacent-months
              no-title
              range
            >
              <v-spacer></v-spacer>
              <v-btn
                @click="closeCustomTimeframe()"
                color="primary"
                small
              >
                Apply Range
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'
  
  export default {
    name: 'PeriodSelection',
    computed: {
      period() {
        var endTime = moment()
        var endTimeISO = ''
        
        // weird adjustment to land on a period interval and include the last period
        var smallPeriods = ['1hr', '6hrs']
        if (smallPeriods.includes(this.range.key)) {
          endTime.set({minute: 5 * Math.round( endTime.minute() / 5 ), second: 0})
          endTimeISO = endTime.toISOString()
        } else {
          if (endTime.minute() >= 30) {
            endTime.set({minute: 0, second: 0})
            endTimeISO = endTime.add('1', 'hour').toISOString()
          } else {
            endTime.set({minute: 30, second: 0})
            endTimeISO = endTime.toISOString()
          }
        }
        
        if (this.range.key == '1hr') {
          // 1hr, 1min periods
          return { start: endTime.subtract('1', 'hour').toISOString(), end: endTimeISO, period: 1 * 60, key: this.range.key}
        } else if (this.range.key == '6hrs') {
          // 6hrs, 5min periods
          return { start: endTime.subtract('6', 'hour').toISOString(), end: endTimeISO, period: 5 * 60, key: this.range.key}
        } else if (this.range.key == '2days') {
          // 2days (48hrs ago), 2hr periods
          return { start: endTime.subtract('48', 'hour').toISOString(), end: endTimeISO, period: (60 * 60), key: this.range.key}
        } else if (this.range.key == '5days') {
          // 5days ago, 6hr periods
          return { start: endTime.subtract('5', 'day').toISOString(), end: endTimeISO, period: 6 * (60 * 60), key: this.range.key}
        } else if (this.range.key == '30days') {
          // 30days ago, 24hr periods
          return { start: endTime.subtract('30', 'day').toISOString(), end: endTimeISO, period: 24 * (60 * 60), key: this.range.key}
        } else if (this.range.key == 'custom') {
          // Custom time frame, 24hr periods
          
          var startTimeISO = moment(this.customRange[0]).startOf('day').toISOString()
          endTimeISO = moment(this.customRange.slice(-1)[0]).endOf('day').toISOString()
          
          return { start: startTimeISO, end: endTimeISO, period: 12 * (60 * 60), key: this.range.key }
        } else {
          // 1day (24hrs ago), 30min periods
          return { start: endTime.subtract('24', 'hour').toISOString(), end: endTimeISO, period: 60 * 30, key: this.range.key}
        }
      },

      dateRangeText() {
        return this.customRange.join(' - ')
      }
    },
    data() {
      return {
        error: false,
        errorText: '',
        
        range: {key: '1day'},
        
        ranges: [
          {key: '1hr', label: 'Last hour'},
          {key: '6hrs', label: 'Last 6 hours'},
          {key: '1day', label: 'Last day'},
          {key: '2days', label: 'Last 2 days'},
          {key: '5days', label: 'Last 5 days'},
          {key: '30days', label: 'Last 30 days'},
          {key: 'custom', label: 'Custom Date Range'}
        ],

        menuOpen: false,
        
        customRange: [
          moment().subtract('24', 'hour').format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ]
      }
    },
    props: ['value', 'initialRange'],
    watch: {
      range() {
        this.selectionChanged()
      }
    },
    mounted() {
      if (this.initialRange) {
        this.range = {key: this.initialRange}
      }
      
      this.selectionChanged()
    },
    
    methods: {
      closeCustomTimeframe() {
        // If the user selects a date, and then selects a later date, we'll get a 4xx error from the lambdas.
        // Both for the sake of looking pretty, and to fix that, sort the dates before we do anything.
        this.customRange = this.customRange.sort((a, b) => moment(a).format('YYYYMMDD') - moment(b).format('YYYYMMDD'))
        
        if (this.customRange.length == 1) {
          var lastDay = moment(this.customRange[0])

          this.customRange.unshift(lastDay.subtract('24', 'hour').format('YYYY-MM-DD'))
        } else if(this.customRange.length == 2 && this.customRange[0] == this.customRange[1]) {
          // Check if the user pressed the same day twice (making a range)

          this.customRange[0] = moment(this.customRange[0]).subtract('24', 'hour').format('YYYY-MM-DD')
        }
        
        this.menuOpen = false
        
        this.selectionChanged()
      },
      selectionChanged() {
        this.$emit('input', this.period)
      }
    }
  }
</script>