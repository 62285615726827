<template>
  <div class="bg-color white--text">
    <v-container fluid fill-height>
      <div class="bg-color gradient-bg" />
      
      <img src="../assets/lander/circle-1.svg" loading="lazy" alt="circle icon" class="ts-circle-1" />
      <img src="../assets/lander/circle-4.svg" loading="lazy" alt="circle icon" class="ts-circle-3" />
      
      <v-row style="margin-top: 80px; margin-bottom: 60px; z-index:2;">
        <v-col cols="12" class="text-center">
         <h4 class="head-text-up">LiveEdge® Cloud Family</h4>
         <h1 class="heading">Thinking outside the box!</h1>
         <div class="">Remote health monitoring &amp; management for hybrid:cloud workflows</div>
         
         <div class="mt-10">
            <v-btn color="primary" class="pa-6" href="https://www.videonlabs.com/contact-sales" target="_blank">Let's talk about LiveEdge Cloud</v-btn>
         </div>
        </v-col>
      </v-row>
      
      <img src="../assets/lander/circle-2.svg" loading="lazy" alt="" class="lec-circle-2">
      <img src="../assets/lander/circle-5.svg" loading="lazy" alt="circle icon" class="lec-circle-4">
      
      <v-row class="d-none d-sm-block">
        <v-col cols="12" class="lec-hero-img-main-c">
          <div class="lec-hero-img-main-b">
            <img src="../assets/lander/lec-img-main-2x.jpg" loading="lazy" alt="" class="lec-hero-img-main-e" />
            <div class="lec-hero-img-main-2-b">
              <img src="../assets/lander/lec-img-main-2-2x.jpg" loading="lazy" alt="" class="lec-hero-img-main-2-e">
            </div>
            <div class="lec-hero-img-cloud-w">
              <div class="lec-hero-img-cloud-c">
                <div class="lec-hero-img-cloud-b">
                  <div class="lec-hero-img-cloud-block">
                    <img src="../assets/lander/lec-hero-bg-cloud-2.png" loading="lazy" alt="" class="lec-hero-img-cloud-e">
                  </div>
                </div>
              </div>
            </div>
         </div>
        </v-col>
      </v-row>
      
      <v-row class="my-10">
        <v-col cols="12" class="text-center">
          <div class="mt-md-10">
             <v-btn color="primary" text class="pa-6" href="https://www.videonlabs.com/liveedge-cloud" target="_blank">Click here to find out more...</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: "Lander",
    computed: {
      ...mapGetters('user', ['isLoggedIn']),
    },
    created() {
      console.log('breakopoint', this.$vuetify.breakpoint)
      if (this.$vuetify.breakpoint.width <= 500) {
        this.$router.push('/auth/login')
      }
    }
  }
</script>
<style lang="scss">
  .bg-color {
    background-color: #24222a;
    min-height: 1000px;
  }
  
  .gradient-bg {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    margin-right: -100px;
    margin-left: -100px;
    background-image: url('../assets/lander/hero-gradient.svg');
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .head-text-up {
    margin-top: 0px;
    margin-bottom: 16px;
    color: #ff6900;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 0.1em;
  }
  
  .heading {
    margin-top: 0px;
    margin-bottom: 24px;
    font-size: 54px;
    line-height: 54px;
    font-weight: 800;
  }
  
  
  .ts-circle-3 {
    position: absolute;
    left: auto;
    top: 20px;
    right: 5%;
    bottom: auto;
  }
  
  .ts-circle-1 {
    position: absolute;
    left: 25%;
    top: -20px;
    right: auto;
    bottom: auto;
  }
  
  .lec-circle-2 {
    position: absolute;
    left: 5%;
    top: 32%;
    right: auto;
    bottom: auto;
  }
  
  .lec-circle-4 {
    position: absolute;
    left: auto;
    top: 45%;
    right: 10%;
  }
  
  .lec-hero-img-main-c {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .lec-hero-img-main-b {
    position: relative;
    width: 100%;
    max-width: 498px;
  }
  
  .lec-hero-img-main-e {
    display: block;
    width: 100%;
    border-radius: 12px;
  }
  
  .lec-hero-img-main-2-b {
    position: absolute;
    left: -12.2%;
    top: auto;
    right: auto;
    bottom: -8.3%;
    width: 52%;
  }
  
  .lec-hero-img-main-2-e {
    display: block;
    width: 100%;
    border-radius: 12px;
  }
  
  .lec-hero-img-cloud-w {
    position: absolute;
    left: auto;
    top: 0%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 0px;
    height: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .lec-hero-img-cloud-c {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .lec-hero-img-cloud-b {
    position: absolute;
  }
  
  .lec-hero-img-cloud-e {
    display: block;
    width: 100%;
  }
  
  .lec-hero-img-cloud-block {
    position: relative;
    width: 580px;
  }
  
  
  
  .lec-hero-cards-w {
    position: relative;
    z-index: 1;
    margin-top: 102px;
  }
  
  .lec-hero-cards-c {
    position: relative;
    display: -ms-grid;
    display: grid;
    width: 100%;
    max-width: 1030px;
    margin-right: auto;
    margin-left: auto;
    grid-auto-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    text-align: center;
  }
  
  .lec-hero-cards-b {
    padding: 52px 25px 27px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: start;
    -ms-flex-align: start;
    align-items: start;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 0px;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
    grid-template-rows: auto auto auto;
    border-radius: 8px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.03)), to(hsla(0, 0%, 100%, 0)));
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.03), hsla(0, 0%, 100%, 0));
  }
  
  .lec-hero-card-img-b {
    width: 48px;
  }
  
  .lec-hero-card-img-e {
    display: block;
    width: 100%;
  }
  
  .lec-hero-card-img-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .lec-hero-card-text-e {
    margin-top: 21px;
    margin-bottom: 0px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #fff;
    font-size: 21px;
    line-height: 1.35;
    font-weight: 600;
  }
  
  
</style>