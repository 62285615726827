<template>
  <v-list-item 
    @click="showDialog()"
    v-if="ADBAllowed"
    :disabled="!device.isOnline() || (!device.hasFeature('services.adb.enabled') && !isDeviceAdmin)"
  >
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="540px"
    >
      <videon-card heading="ADB over IP" showClose="true" :ignoreHeaderMargin="!isVersionSupported" @close="showControl = false">
        <v-alert
          text
          color="orange"
          icon="mdi-alert-outline"
          v-if="!isVersionSupported"
        >
          This feature is not supported on {{ this.device.cloud_version.original }}, please update your cloud agent.
        </v-alert>
        <v-card-text>
          <v-alert
            text
            color="error"
            icon="terminal"
          >
            Enabling ADB over IP puts your device at risk. Please use discretion when using ADB and ensure it is disabled when not in use.
          </v-alert>
          <v-alert dense outlined type="error" v-if="commandStatus == 'rejected' || commandStatus == 'failed'" >
            {{ commandMessage }}
          </v-alert>
          <div v-if="processing">
            <v-progress-linear
              color="primary"
              indeterminate
              buffer-value="0"
              class="mb-3"
            ></v-progress-linear>
          </div>
          
          <div class="text-right">
            <v-btn
              class="ma-1"
              color="secondary"
              @click="showControl = false"
              text
            >
              Cancel
            </v-btn>
            
            <v-btn
              class="ma-1"
              color="primary"
              @click="toggleADB()"
              :disabled="!isVersionSupported || processing"
            >
              {{ (ADBEnabled) ? 'Disable' : 'Enable' }} ADB over IP
            </v-btn>
            
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-list-item-title class="pr-2">
      <v-icon class="material-icons-outlined">terminal</v-icon> 
      ADB over IP
    </v-list-item-title>
  </v-list-item>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'ADBControl',
    
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.0.0b20'),
        
        showControl: false,
      }
    },
    mounted() {
      
    },
    computed: {
      ...mapGetters('user', ['isDeviceAdmin']),
      
      isVersionSupported() {
        if (this.$helpers.versionCheck(this.minimumAgentVersion, this.device.cloud_version)) {
          return true
        } else if (this.device.cloud_version.dev) {
          return true
        }
        return false
      },
      
      processing() {
        if (this.device.isCommandProcessing()) {
          return true
        }
        return false
      },
      
      ADBAllowed() {
        var entitlements = this.device.entitlements()
        if (entitlements['services.adb.enabled'] == true) {
          return true
        }
        return false
      },
      ADBEnabled() {
        if (this.device.state && this.device.state.adb_port) {
          var adbPort = this.device.state.adb_port
          
          // TODO change this to not equal any of the disabled values...
          if (adbPort == 5555) {
            return true
          }
        }
        return false
      },
      
      commandStatus() {
        return this.device.command_status
      },
      commandMessage() {
        return this.device.command_message
      },
    },

    methods: {
      showDialog() {
        this.device.resetCommandState()
        this.showControl = true
      },
      toggleADB() {
        var enable = !this.ADBEnabled
        console.log('ADBControl ' + this.device.device_guid + ' toggleADB', enable)
        this.device.runCommand('enable_adb', enable)
      },
    }
  }
</script>