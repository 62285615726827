<template>
  <v-card 
    
  >
    <v-dialog
      v-model="showAddDeviceListColumn"
      max-width="640px"
    >
      <videon-card heading="Add Device List Column" showClose="true" @close="showAddDeviceListColumn = false">
        <v-card-text>
          <v-form @submit.prevent="addColumn">
            <v-select
              v-model="newColumnItem.component"
              :items="availableComponentsValues"
              @change="delete newColumnItem.key"
              label="Column"
              outlined
              hide-details
            ></v-select>
            
            <v-combobox
              class="mt-3"
              v-if="newColumnItem.component == 'metadata'"
              v-model="newColumnItem.key"
              :items="metadataKeyValues"
              :return-object="false"
              label="Metadata Key"
              outlined
              hide-details
            ></v-combobox>
            
            <v-select
              class="mt-3"
              v-if="newColumnItem.component == 'state'"
              v-model="newColumnItem.key"
              :items="stateKeyValues"
              label="State Key"
              outlined
              hide-details
            ></v-select>
            
            <v-checkbox
              v-model="newColumnCenter"
              label="Center content"
              hide-details
            ></v-checkbox>
            
            <v-checkbox
              v-model="newColumnNoWrap"
              label="Don't wrap content"
              hide-details
            ></v-checkbox>
            
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showAddDeviceListColumn = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="addColumn"
                outlined
                :disabled="!newColumnItem.component"
              >
                Add Column
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar
          height="32px"
          class="mx-auto"
        >
          <videon-logo />
        </v-avatar>
        Browser Specific Settings
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    
    <v-divider/>
    
    <v-alert dense outlined type="error" v-if="error" class="ma-5 mb-0">
      {{ errorText }}
    </v-alert>
    
    <v-card-text>
      <v-row>
        <v-col md="4">
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon size="32px">settings</v-icon>
              User Settings
            </v-toolbar-title>
          </v-toolbar>
          <div class="ml-10 mt-10">
            <v-switch
              inset
              label="Enable Dark Mode"
              :inputValue="darkMode"
              @change="toggleDarkMode"
            ></v-switch>
            
            <v-switch
              inset
              label="Hide Banners"
              :inputValue="hideBanner"
              @change="toggleHideBanner"
            ></v-switch>
            
            <v-switch
              v-if="isAdmin"
              inset
              label="Enable Shadow Debug"
              :inputValue="shadowDebug"
              @change="toggleShadowDebug"
            ></v-switch>
          </div>
        </v-col>
        <v-col md="8">
          <v-toolbar flat>
            <v-toolbar-title>
              <v-icon size="32px">list</v-icon>
              Device List
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              x-small
              outlined
              color="primary"
              @click="showAddDeviceListColumn = true"
            >
              <v-icon>mdi-plus</v-icon> Add Column
            </v-btn>
          </v-toolbar>
          
          <v-data-table
            :headers="deviceListHeaders"
            :items="deviceListColumns"
            no-data-text="No additional columns"
            hide-default-footer
          >
            <template v-slot:item.component="{ item, index }">
              <v-row no-gutters align="center">
                <span>{{ $helpers.getLabel(item.component) }}</span>
                
                <span v-if="item.component == 'metadata' && item.key">:&nbsp; {{ $helpers.getLabel(item.key) }}</span>
                
                <v-spacer/>
                
                <v-btn x-small text @click="moveUpColumn(index)" :disabled="index == 0"><v-icon>mdi-arrow-up</v-icon></v-btn>
                <v-btn x-small text @click="moveDownColumn(index)" :disabled="index == (deviceListColumns.length - 1)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                <v-btn x-small text @click="deleteColumn(index)"><v-icon>mdi-delete</v-icon></v-btn>
              </v-row>
            </template>
          </v-data-table>
          
          <v-row no-gutters class="mx-5">
            <v-radio-group
              :value="ignoreReactive"
              @change="toggleIgnoreReactive"
              mandatory
            >
              <v-radio
                :value="false"
              >
                <template v-slot:label>
                  Reactive Columns
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-1"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                        question_mark
                      </v-icon>
                    </template>
                    Columns may be hidden if the browser window is not wide enough to display them.
                  </v-tooltip>
                </template>
              </v-radio>
              <v-radio
                label="Horizontal Scrollbar"
                :value="true"
              ></v-radio>
            </v-radio-group>
          </v-row>
          
          <v-row no-gutters class="mt-5" align="baseline">
            <v-btn
              class="ma-1"
              @click="defaultColumns"
              outlined
              x-small
            >
              Use Defaults
            </v-btn>
            
            <v-spacer/>
            
            <v-btn
              class="ma-1"
              color="secondary"
              @click="resetColumns"
              text
              small
            >
              Undo Changes
            </v-btn>
            <v-btn
              class="ma-1"
              color="primary"
              @click="saveColumns"
              small
            >
              Save Column Layout
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'BrowserOptions',
 
    data() {
      return {
        error: false,
        errorText: '',
      
        loading: false,
        
        deviceListHeaders: [
          { text: 'Device List Columns', align: 'start', value: 'component', sortable: false },
        ],
        
        deviceListColumns: [],
        
        showAddDeviceListColumn: false,
        newColumnItem: { 'component': '', 'class': '' },
        newColumnCenter: true,
        newColumnNoWrap: true,
        
        availableComponentsValues: [
          { 'value': 'metadata', 'text': 'Metadata' },
          { 'value': 'streaming_status', 'text': 'Streaming Status' },
          { 'value': 'device_ip', 'text': 'Device IP' },
          { 'value': 'external_ip', 'text': 'External IP' },
          { 'value': 'firmware_version', 'text': 'Firmware Status' },
          { 'value': 'serial_number', 'text': 'Serial Number' },
          { 'value': 'mac_address', 'text': 'MAC Address' },
        ],
        
        metadataKeyValues: [
          { 'value': 'deployment_status', 'text': 'Deployment Status' },
          { 'value': 'location', 'text': 'Location' },
          { 'value': 'note', 'text': 'Note' },
        ],
        
        resetColClasses: 'd-none d-lg-table-cell d-xl-table-cell',
        standardColClasses: 'd-none d-lg-table-cell',
        extendedColClasses: 'd-none d-xl-table-cell',
      }
    },
    
    computed: {
      ...mapGetters('user', ['user', 'isAdmin']),
      ...mapGetters('userPreferences', ['darkMode', 'shadowDebug', 'hideBanner', 'ignoreReactive', 'deviceListLayout']),
    },
    
    mounted() {
      this.resetColumns()
    },
    
    methods: {
      toggleDarkMode() {
        this.$store.dispatch('userPreferences/setDarkMode', !this.darkMode)
      },
      
      toggleShadowDebug() {
        this.$store.dispatch('userPreferences/setShadowDebug', !this.shadowDebug)
      },
      
      toggleHideBanner() {
        this.$store.dispatch('userPreferences/setHideBanner', !this.hideBanner)
      },
      
      toggleIgnoreReactive() {
        this.$store.dispatch('userPreferences/setIgnoreReactive', !this.ignoreReactive)
      },
      
      defaultColumns() {
        this.$store.dispatch('userPreferences/setIgnoreReactive', false)
        this.$store.dispatch('userPreferences/setDeviceListLayout', false).then(() => {
          this.resetColumns()
        })
      }, 
      
      resetColumns() {
        this.deviceListColumns = JSON.parse(JSON.stringify(this.deviceListLayout))
      },
      
      saveColumns() {
        // reset & apply standard or extended classes to columns based on index
        var resetClasses = this.resetColClasses.split(' ')
        
        for (var index in this.deviceListColumns) {
          var item = this.deviceListColumns[index]
          
          // remove old references
          for (var thisClass of resetClasses) {
            item.class = item.class.replace(thisClass, '')
          }
          
          item.class = item.class.trim()
          
          if (!this.ignoreReactive) {
            // extended cols
            if (index > 4) {
              item.class += ' ' + this.extendedColClasses
            // standard cols
            } else if (index > 2) {
              item.class += ' ' + this.standardColClasses
            }
          }
          
          
          
          this.deviceListColumns[index] = item
        }
        
        this.$store.dispatch('userPreferences/setDeviceListLayout', JSON.parse(JSON.stringify(this.deviceListColumns)))
      },
      
      
      moveUpColumn(index) {
        var item = this.deviceListColumns[index]
        this.deviceListColumns.splice(index, 1)
        this.deviceListColumns.splice(index - 1, 0, item)
      }, 
      
      moveDownColumn(index) {
        var item = this.deviceListColumns[index]
        this.deviceListColumns.splice(index, 1)
        this.deviceListColumns.splice(index + 1, 0, item)
      },
      
      
      deleteColumn(index) {
        this.deviceListColumns.splice(index, 1)
      },
      
      addColumn() {
        if (this.newColumnItem.component) {
          if (this.newColumnCenter) {
            this.newColumnItem.class = 'center ' + this.newColumnItem.class
          }
          
          if (this.newColumnNoWrap) {
            this.newColumnItem.class = this.newColumnItem.class.trim() + ' text-no-wrap'
          }
          
          console.log('BrowserOptions addColumn', this.newColumnItem)
          this.deviceListColumns.push(this.newColumnItem)
          
          // reset
          this.newColumnItem = { 'component': '', 'class': '' }
        }
        this.showAddDeviceListColumn = false
      }
    }
  }
</script>