<template>
  <videon-card heading="Forget device" max-width="440px">
    <div v-if="loading" class="mx-3 mt-5">
      <v-progress-linear
        color="primary"
        indeterminate
        buffer-value="0"
      ></v-progress-linear>
    </div>
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      <p class="body-2 mb-5">
        Are you sure you want to forget this device?  Please enter a reason for this action for audit purposes.
      </p>
      <v-form @submit.prevent="adoptDevice">
        
        <v-text-field
          v-model="deviceSerial"
          label="Serial Number"
          hint="example: 1234-1234"
          required
          outlined
          :disabled="preparedURL"
        ></v-text-field>
        
        <v-text-field
          v-model="deleteDeviceReason"
          label="Reason"
          required
          outlined
        ></v-text-field>
        
        <div class="text-right">
          <v-btn
            class="ma-1"
            color="secondary"
            @click="cancelForget"
            text
          >
            Cancel
          </v-btn>
          <v-btn
            class="ma-1"
            color="primary"
            @click="forgetDevice"
          >
            Forget
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </videon-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'ForgetDevice',
    data() {
      return {
        error: false,
        errorText: '',
        
        loading: false,
        
        preparedURL: false,
        deviceSerial: '',
        deviceGUID: '',
        
        deleteDeviceReason: '',
      }
    },
    mounted() {
      this.validatePreparedURL()
    },
    computed: {
      ...mapGetters('user', ['guid', 'organization']),
      ...mapGetters('config', ['deploymentName']),
      orgName() {
        if (this.organization) {
          return this.organization.org_name
        }
        return ''
      },
    },
    methods: {
      validatePreparedURL() {
        console.log('ForgetDevice validatePreparedURL', this.$route)
        if (this.$route.query && this.$route.query.serial) {
          this.deviceSerial = this.$route.query.serial
          this.deviceGUID = (this.$route.query) ? this.$route.query.device_guid : ''
          this.preparedURL = true
        }
      },
      forgetDevice() {
        if (this.deleteDeviceReason) {
          this.loading = true
        
          this.error = false
          this.errorText = ''
        
          console.log('ForgetDevice forgetDevice', this.deviceSerial)
        
          this.axios.delete('/devices/' + this.deviceGUID, {'data': {'reason': this.deleteDeviceReason}})
          .then((response) => {
            console.log('ForgetDevice forgetDevice response', response)
          
            this.deviceSerial = ''
            this.deviceGUID = ''
          
            this.$router.push('/devices')
          
            this.loading = false
          }).catch((error) => {
            console.log('ForgetDevice forgetDevice error', error)
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }
      },
      cancelForget() {
        this.$router.push('/devices')
      }
    }
  }
</script>