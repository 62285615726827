<template>
    <img 
      referrerpolicy="no-referrer"
      v-if="displayPicture" 
      :src="displayPicture" 
      @error="imageError"
    >
    <v-icon :size="size" v-else>person</v-icon>
</template>

<script>
  
  export default {
    name: 'UserAvatar',
    props: {
      src: {
        type: String,
      },
      size: {
        type: Number,
        default: 32
      }
    },
    data() {
      return {
        noImage: false
      }
    },
    computed: {
      displayPicture() {
        if (!this.noImage && this.src) {
          return this.src
        }
        return false
      }
    },
    methods: {
      imageError() {
        this.noImage = true
      }
    }
  }
</script>