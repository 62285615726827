<template>
  <div class="d-inline-block text-center">
    <v-dialog
      v-if="device"
      v-model="showState"
      max-width="460px"
    >
      <videon-card :heading="device.deviceName() + ' Streaming Status'" showClose="true" @close="showState = false">
        <div v-if="!device.state" class="mx-5">
          <v-alert type="error">
            Device state is unknown
          </v-alert>
        </div>
        <v-card-text>
          <div v-if="device.state" class="ma-1">
            <v-row>
              <videon-state-tile
                heading="Device Online"
                class="text-capitalize"
              >
                <span 
                  :class="(!device.isOnline()) ? 'error--text' : ''"
                >
                  {{ device.isOnline() }}
                </span>
              </videon-state-tile>
              <videon-state-tile
                heading="Last Update"
              >
                {{ device.state.last_state_update | moment("YYYY/MM/DD hh:mm:ss a") }}
              </videon-state-tile>
            </v-row>
            
            <div v-if="inputsState && inputsState.length > 0">
              <div 
                v-for="(input, index) in inputsState" 
                v-bind:key="index"
                class="mt-3"
              >
                <v-row><h5 class="text--disabled"><span v-if="!online">Last Known </span>{{ inputName(input) }} Status</h5></v-row>
                <v-row><v-divider /></v-row>
                <div v-if="online && isPreviewSupported(index) && input.video_input_format != 'FORMAT_UNDEFINED'">
                  <v-row class="mt-5">
                    <v-col cols="12">
                      <input-preview :device_guid="device.device_guid" :input_id="input.id" />
                    </v-col>
                  </v-row>
                </div>
                <v-row class="mt-2">
                  <videon-state-tile
                    heading="Video Input"
                  >
                    {{ $helpers.getLabel(input.config.video_input_type) }}
                  </videon-state-tile>
                  <videon-state-tile
                    heading="Video Format"
                  >
                    <span 
                      :class="(input.video_input_format == 'FORMAT_UNDEFINED') ? 'error--text' : ''"
                    >
                      {{ $helpers.getFormatLabel(input.video_input_format) }}
                    </span>
                  </videon-state-tile>
                  <videon-state-tile
                    heading="Audio Input"
                  >
                    {{ $helpers.getLabel(input.config.audio_input_type) }}
                  </videon-state-tile>
                  <videon-state-tile
                    heading="Audio Detected"
                    class="text-capitalize"
                  >
                    <v-icon v-if="input.audio_input_detected" class="black--text">volume_up</v-icon>
                    <v-icon v-else class="red--text">volume_off</v-icon>
                    <span 
                      :class="(!input.audio_input_detected) ? 'error--text' : ''"
                    >
                      {{ $helpers.getLabel(input.audio_input_detected) }}
                    </span>
                  </videon-state-tile>
                </v-row>
              </div>
            </div>
            
            <div v-if="outputsState && outputsState.length > 0" class="mt-3">
              <v-row><h5 class="text--disabled"><span v-if="!online">Last Known </span>Output Status</h5></v-row>
              <v-row><v-divider /></v-row>
              <v-row class="mt-2">
                <videon-state-tile
                  v-for="(output, index) in outputsState" 
                  v-bind:key="index"
                  :heading="outputName(output)"
                >
                  <output-status-label :output="output" />
                </videon-state-tile>
              </v-row>
            </div>
          </div>
          
          <div v-if="!loading && outputsState.length == 0" class="mt-5">
            <v-alert type="error">
              Device has no configured outputs.
            </v-alert>
          </div>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <v-chip :color="status.color" label outlined small @click="showState = true">
      <v-progress-circular :size="12" :width="1" v-if="loading" indeterminate color="grey"></v-progress-circular>
      <span v-else>
        {{ status.label }}
      </span>
    </v-chip>
  </div>
</template>

<script>
  import InputPreview from '../../device/components/InputPreview.vue'

  export default {
    name: 'StreamingStatusLabel',
    
    components: {
      InputPreview
    },
    
    props: ['device', 'noSync'],
    
    computed: {
      loading() {
        var inputsShadow = this.$deviceShadows.getDeviceShadow(this.device.device_guid, 'Inputs') 
        var outputsShadow = this.$deviceShadows.getDeviceShadow(this.device.device_guid, 'Outputs') 
        
        if (this.device.error || (this.device.state && inputsShadow.ready && outputsShadow.ready)) {
          return false
        }
        return true
      },
      alerts() {
        return this.$alerts.alertsForDevice(this.device.device_guid)
      },
      online() {
        return this.device.isOnline()
      },
      inputsState() {
        return this.device.inputsState()
      },
      outputsState() {
        return this.device.outputsState()
      },
      status() {
        if (!this.loading) {
          if (this.alerts.length > 0) {
            return {'label': 'Device Alerts', 'color': 'red'}
          } else if (this.device.error) {
            return {'label': 'State Unknown', 'color': 'red'}
          } else if (!this.device.isOnline()) {
            return {'label': 'Device Offline', 'color': 'grey'}
          } else if (this.outputsState && this.outputsState.filter(output => this.$helpers.outputStatus(output).streaming === false && this.$helpers.outputStatus(output).enabled === true).length > 0) {
            return {'label': 'Error Streaming', 'color': 'red'}
          } else if (this.outputsState && this.outputsState.filter(output => this.$helpers.outputStatus(output).streaming === true).length > 0) {
            return {'label': 'Streaming', 'color': 'green'}
          } else if (this.outputsState && this.outputsState.filter(output => this.$helpers.outputStatus(output).streaming === false).length > 0) {
            return {'label': 'Not Streaming', 'color': 'orange'}
          } else if (this.outputsState) {
            return {'label': 'Not Configured', 'color': ''}
          }
        }
        return {'label': 'Unknown', 'color': 'grey'}
      },
    },
    mounted() {
      this.syncDocuments()
    },
    beforeDestroy() {
      if (this.refreshTimer) {
        clearTimeout(this.refreshTimer)
        this.refreshTimer = false
      }
    },
    data() {
      return {
        inputPreviewSupportedVersion: this.$helpers.parseVersion('cloud', 'v1.3.0'),
        
        showState: false,
        
        refreshTimer: false,
        refreshInterval: this.$helpers.getRefreshInterval(10000, 20000)
      }
    },
    methods: {
      inputName(shadowDocument) {
        if (shadowDocument.config.name) {
          return this.$helpers.htmlDecode(shadowDocument.config.name)
        }
        
        return 'Input #' + shadowDocument.id
      },
      outputName(shadowDocument) {
        if (shadowDocument.config.name) {
          return this.$helpers.htmlDecode(shadowDocument.config.name)
        }
        
        var outputName = 'Output #' + shadowDocument.id
        if (shadowDocument.type) {
          outputName += ' (' + this.$helpers.getLabel(shadowDocument.type) + ')'
        }
        return outputName
      },
      isPreviewSupported(index = 0) {
        if (this.device.thumbnailEnabled(index) && this.$helpers.versionCheck(this.inputPreviewSupportedVersion, this.device.cloud_version)) {
          return true
        }
        return false
      },
      syncDocuments() {
        if (this.device.state && !this.noSync) {
          this.device.syncDocuments()
        }
        
        if (this.refreshInterval) {
          this.refreshTimer = setTimeout(() => {
            this.syncDocuments()
          }, this.refreshInterval)
        }
      }
    }
  }
</script>