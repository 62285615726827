<template>
  <img :src="icon" />
</template>

<script>
  export default {
    props: {
        icon: String
    }
  };
</script>