<template>
  <v-card 
    class="mx-auto"
  >
    <v-dialog
      v-model="showDeleteUser"
      max-width="400px"
    >
      <videon-card heading="Delete User" showClose="true" @close="showDeleteUser = false">
        <v-card-text>
          <p class="subtitle-1 mt-3">
            Are you sure you want to delete <span class="primary--text">{{ name }}</span>?
          </p>
          <v-form @submit.prevent="confirmDeleteUser">
            <v-text-field
              v-model="deleteUserReason"
              label="Reason"
              required
              outlined
            ></v-text-field>
            <div class="text-right">
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showDeleteUser = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="confirmDeleteUser"
              >
                Delete
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    
    <v-toolbar flat>
      <v-toolbar-title>
        <v-avatar size="32" class="mx-2">
          <user-avatar :src="picture"/>
        </v-avatar>
        {{ name }}
      </v-toolbar-title>
      
      <v-spacer></v-spacer>
      
      <back-button />
      
      <div v-if="editable">
        <v-btn
          class="ma-1"
          small
          text
          @click="showDeleteUser = true"
          :disabled="isMe"
        >
          <v-icon>delete</v-icon> Delete
        </v-btn>
      </div>
    </v-toolbar>
    
    <v-divider/>
    
    <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      
      <v-card 
        flat
      >
        <v-card-text>
          <v-container>
            <v-row v-if="loading">
              <v-col cols="12">
                <v-progress-linear
                  color="primary"
                  indeterminate
                  buffer-value="0"
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row v-if="!loading">
              <v-col cols="12">
                <v-form @submit.prevent="saveChanges">
                  <v-text-field
                    name="name"
                    autocomplete="name"
                    v-model="name"
                    :error-messages="nameErrors"
                    label="Full Name"
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                    outlined
                    :disabled="!editable"
                  ></v-text-field>
                  <v-text-field
                    name="email"
                    autocomplete="email"
                    v-model="email"
                    :error-messages="emailErrors"
                    label="E-mail"
                    type="email"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                    outlined
                    :disabled="!editable || isSSO"
                  ></v-text-field>
                  
                  <vue-tel-input-vuetify
                    name="phone"
                    autocomplete="tel"
                    v-model="phone"
                    :error-messages="phoneErrors"
                    label="Phone Number"
                    @input="$v.phone.$touch()"
                    @blur="$v.phone.$touch()"
                    default-country="US"
                    :onlyCountries="['US', 'CA']"
                    :disabledFetchingCountry="true"
                    mode="international"
                    outlined
                    full-width
                    :disabled="!editable"
                  ></vue-tel-input-vuetify>
                  
                  <!-- TODO locale & timezone -->
                </v-form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions v-if="editable">
          <v-row
             align="center"
             justify="end"
             class="ma-2 mt-0"
           >
             <v-btn
               color="primary"
               @click="saveChanges"
               class="ma-1"
               small
             >
               Update User
             </v-btn>
           </v-row>
        </v-card-actions>
      </v-card>
      
      <v-card 
        v-if="isUserAdmin" 
        flat
      >
        <v-toolbar flat>
          <v-toolbar-title>
            Admin Groups
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            v-model="userGroups"
            item-key="name"
            show-select
            :headers="groupHeaders"
            :items="availableGroups"
            :loading="groupsLoading"
            hide-default-footer
          >
            <template v-slot:item.name="{ item }">
              <span class="subtitle-2">{{ item.name }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-row
             align="center"
             justify="end"
             class="ma-2 mt-0"
           >
             <v-btn
               color="primary"
               @click="updateGroups"
               class="ma-1"
               small
             >
               Update Groups
             </v-btn>
           </v-row>
        </v-card-actions>
      </v-card>
      
      <access-tokens-box :user_guid="user_guid" class="mt-5" flat/>
      <org-listing-box :user_guid="user_guid" class="mt-5" flat/>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  import { validationMixin } from 'vuelidate'
  import { required, minLength, email } from 'vuelidate/lib/validators'
  
  import AccessTokensBox from './AccessTokens.vue'
  import OrgListingBox from './OrgListing.vue'
  
  export default {
    mixins: [validationMixin],
    name: 'EditUser',
    
    components: {
      AccessTokensBox,
      OrgListingBox
    },
    
    validations: {
      name: { required },
      email: { required, email },
      phone: { },
      password: { required, minLength: minLength(8) },
      passwordConfirm: { required, minLength: minLength(8) },
    },
    
    data() {
      return {
        error: false,
        errorText: '',
      
        showDeleteUser: false,
        deleteUserReason: '',
      
        loading: false,
        editable: false,
        isMe: false,
        isSSO: false,
        
        name: 'User',
        email: '',
        phone: '',
      
        locale: 'en_US',
        timezone: 'America/New_York',
        
        picture: '',
        
        thisUser: null,
        
        groupHeaders: [
          { text: 'Group', align: 'start', value: 'name', sortable: false }, 
          { text: 'Description', align: 'start', value: 'description', sortable: false }
        ],
        
        groupsLoading: true,
        availableGroups: [],
        userGroups: [],
      }
    },
    computed: {
      ...mapGetters('user', ['user', 'guid', 'isUserAdmin']),
      
      nameErrors () {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.required && errors.push('Name is required.')
        return errors
      },
      phoneErrors () {
        const errors = []
        if (!this.$v.phone.$dirty) return errors
        return errors
      },
      emailErrors () {
        const errors = []
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('Must be valid e-mail')
        !this.$v.email.required && errors.push('E-mail is required')
        return errors
      },
      passwordErrors () {
        const errors = []
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.minLength && errors.push('Password needs to be longer')
        !this.$v.password.required && errors.push('Password is required')
        return errors
      },
      passwordConfirmErrors () {
        const errors = []
        if (!this.$v.passwordConfirm.$dirty) return errors
        !this.$v.passwordConfirm.minLength && errors.push('Password needs to be longer')
        !this.$v.passwordConfirm.required && errors.push('Password is required')
        this.passwordConfirm !== this.password && errors.push('Passwords don`t match')
        return errors
      },
    },
    props: {
      user_guid: {
        type: String,
        required: true
      }
    },
    watch: {
      
    },
    mounted() {
      this.fetchGroups()
      this.loadUser()
      
      if (this.guid == this.user_guid) {
        this.editable = true
        this.isMe = true
      } else if (this.isUserAdmin) {
        this.editable = true
      }
      
    },
    methods: {
      fetchGroups() {
        this.axios.get('/users/groups').then((response) => {
          console.log('EditUser fetchGroups', response)
          this.availableGroups = response.data.groups
          this.groupsLoading = false
        }).catch((error) => {
          console.log('EditUser fetchGroups error', error)
          this.groupsLoading = false
        })
      },
      
      loadUser() {
        this.loading = true
        
        if (!this.user_guid) {
          this.error = true
          this.errorText = 'user_guid not supplied'
          return
        }
        
        this.axios.get('/users/' + this.user_guid).then((response) => {
          console.log('EditUser loadUser', response)
          
          this.thisUser = response.data.user
          
          this.isSSO = (this.thisUser.status == 'EXTERNAL_PROVIDER') ? true : false
          
          this.name = this.thisUser.name
          this.email = this.thisUser.email
          this.phone = this.thisUser.phone_number
          this.locale = this.thisUser.locale
          this.timezone = this.thisUser.zoneinfo
          this.picture = this.thisUser.picture
          
          this.axios.get('/users/' + this.user_guid + '/groups').then((response) => {
            console.log('EditUser loadUser groups', response)
            
            this.userGroups = response.data.groups
            
            this.loading = false
          }).catch((error) => {
            this.error = true
            this.errorText = this.$helpers.parseError(error)
            this.loading = false
          })
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      },
      
      
      
      confirmDeleteUser() {
        console.log('EditUser confirmDeleteUser')
        
        if (this.deleteUserReason) {
          this.loading = true
          this.showDeleteUser = false
        
          this.axios.delete('/users/' + this.user_guid, {'data': {'reason': this.deleteUserReason}})
          .then((response) => {
            console.log('EditUser confirmDeleteUser response', response)
            
            this.deleteUserReason = ''
            
            this.error = false
            this.errorText = ''
            
            this.$users.invalidateUser(this.user_guid)
            
            this.$router.push('/admin/users')
          }).catch((error) => {
            console.log('EditUser confirmDeleteUser error', error)
          
            this.error = true
            this.errorText = this.$helpers.parseError(error)
          
            this.loading = false
          })
        }
      },
      
      revertChanges() {
        console.log('EditUser revertChanges')
        this.loadUser()
      },
      saveChanges() {
        console.log('EditUser saveChanges')
        this.loading = true
        
        var updateBody = {}
        
        if (this.name) {
          updateBody.name = this.name
        }
        
        if (this.email) {
          updateBody.email = this.email.toLowerCase()
        }
        
        if (this.phone) {
          updateBody.phone_number = this.phone.replace(/[\s-]+/g, '').toLowerCase()
        }
        
        this.axios.patch('/users/' + this.user_guid, updateBody)
        .then((response) => {
          console.log('EditUser saveChanges response', response)
          
          this.loadUser()
          
          this.$users.invalidateUser(this.user_guid)
        }).catch((error) => {
          console.log('EditUser saveChanges error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        
          this.loading = false
        })
      },
      
      updateGroups() {
        var updateGroups = this.userGroups.map((group) => { return group.name })
        
        console.log('EditUser updateGroups groups', updateGroups)
        
        this.axios.put('/users/' + this.user_guid + '/groups', { 'groups': updateGroups })
        .then((response) => {
          console.log('EditUser updateGroups response', response)
          
          this.loadUser()
          
          this.$users.invalidateUser(this.user_guid)
        }).catch((error) => {
          console.log('EditUser updateGroups error', error)
        
          this.error = true
          this.errorText = this.$helpers.parseError(error)
        
          this.loading = false
        })
      }
    }
  }
</script>