<template>
  <div>
    <v-card
      v-for="(first_layer, first_namespace) in divided_features"
      :key = "first_namespace"
      flat
    >
      <v-card-title>{{ $helpers.titleCase(first_namespace) }}</v-card-title>
      <v-card-text>
        <v-row>
          <videon-state-tile
            v-for="(features, second_namespace) in first_layer"
            :key="second_namespace"
            :heading="second_namespace"
          >
            <ul>
              <li
                v-for="(feature, index) in features"
                :key="index"
              >
                <feature-capability :feature="feature"></feature-capability>
              </li>
            </ul>
          </videon-state-tile>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
  import FeatureCapability from './FeatureCapability.vue'

  export default {
    name: 'SoftwareCapabilities',
    props: ['capabilities'],
    components: { FeatureCapability},

    computed: {
      divided_features() {
        var top_layer = {}

        function push_nested(obj, layers, value) {
          // If push_nested(obj, ['a', 'b', 'c'], value) was called,
          // it would be the same as if obj.a.b.c.push(value) were called

          var key = layers[0]

          if (!obj[key]) {
            obj[key] = layers.length === 1 ? [] : {}
          }

          if (layers.length === 1) {
            obj[key].push(value)
          } else {
            push_nested(obj[key], layers.slice(1), value)
          }
        }

        if (this.capabilities && this.capabilities.capabilities) {
          for (var feature of this.capabilities.capabilities) {
            var split_feature = feature.feature.split('.')

            // Here, we're getting the first two 'namespaces' from the feature.
            // So the feature 'services.adb.enabled' has the two namespaces 'services'
            // and 'adb'. The additional logic is to handle what happens if the feature
            // doesn't have two namespaces (ex. 'adb.enabled'). In that case, it will pad
            // the namespaces with empty strings.
            // Ex:
            //   'a.b.c.d' => ['a', 'b']
            //   'a.b.c'   => ['a', 'b']
            //   'a.b'     => ['a',  '']
            //   'b'       => ['',   '']
            var namespaces = split_feature.slice(0, Math.min(2, split_feature.length - 1))
                                          .concat(new Array(2).fill('')).slice(0, 2)

            push_nested(top_layer, namespaces, feature)
          }
        }

        return top_layer
      }
    }
  }
</script>