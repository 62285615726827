<template>
  <div v-if="canUpdate">
    <v-dialog
      v-if="device"
      v-model="showRebootDevice"
      max-width="480px"
    >
      <videon-card :heading="'Reboot ' + device.deviceName()" showClose="true" @close="showRebootDevice = false">
        <v-card-text>
          <p class="subtitle-1 my-5">
            Are you sure you want to reboot <span class="primary--text">{{ device.deviceName() }}</span>?
          </p>
          <v-form @submit.prevent="rebootDevice">
            <v-text-field
              v-model="rebootDeviceConfirm"
              label="Reboot Confirmation"
              messages="Please type CONFIRM above to proceed"
              required
              outlined
            ></v-text-field>
            <div class="mt-3 d-flex align-end">
              <v-spacer />
              <v-btn
                class="ma-1"
                color="secondary"
                @click="showRebootDevice = false"
                text
              >
                Cancel
              </v-btn>
              <v-btn
                class="ma-1"
                color="primary"
                @click="rebootDevice"
              >
                Reboot
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </videon-card>
    </v-dialog>
    
    <div v-if="!hasUpdates">
      <v-card
        color="info"
        outlined
        dark
        class="my-3"
      >
        <v-card-text>
          <h4 class="text-uppercase white--text d-inline-flex align-center"><v-icon color="white" class="mr-2">system_update_alt</v-icon> Firmware Update</h4>
          
          <v-alert
            text
            outlined
            color="disabled"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-if="updateStatus == 'complete'"
          >
            {{ updateMessage }}
          </v-alert>
          
          <v-progress-linear
            class="mt-5 mb-2"
            color="white"
            indeterminate
            buffer-value="0"
            v-if="isUpdating"
          ></v-progress-linear>
          
          <v-btn @click="updateAction('check_for_update')" color="secondary" width="100%" class="mt-4" :disabled="disableUpdateButton">Check for Update</v-btn>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="hasUpdates">
      <v-card
        color="info"
        outlined
        dark
        class="my-3"
      >
        <v-card-text>
          <h4 class="text-uppercase white--text d-inline-flex align-center"><v-icon color="white" class="mr-2">system_update_alt</v-icon> Firmware Update Available</h4>
          
          <v-alert
            text
            outlined
            color="black"
            class="mt-3 mb-0"
            v-if="updateStatus == 'rejected' || updateStatus == 'error'"
          >
            {{ updateMessage }}. This may be due to slow download speeds. Check back later to apply update.
          </v-alert>
          
          <v-alert
            text
            outlined
            color="disabled"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-else-if="updateStatus == 'complete'"
          >
            {{ updateMessage }}
          </v-alert>
          
          <v-alert
            text
            outlined
            color="disabled"
            icon="mdi-alert-outline"
            class="mt-3 mb-0"
            v-else-if="device && !device.isOnline() && !isUpdating"
          >
            {{ version.original }} is available. However, you are unable to apply updates while the device is offline.
          </v-alert>
          
          <div v-if="device && device.isOnline()">
            <v-progress-linear
              class="mt-5 mb-2"
              color="white"
              indeterminate
              buffer-value="0"
              v-if="isUpdating"
            ></v-progress-linear>
            <v-btn v-if="awaitingReboot" @click="showRebootDevice = true" color="red" width="100%" class="mt-4" :disabled="disableUpdateButton">Reboot To Apply Update</v-btn>
            <v-btn v-else @click="updateAction()" color="secondary" width="100%" class="mt-4" :disabled="disableUpdateButton">Update to {{ version.original }}</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'DeviceFirmwareUpdaterEdgecaster',
    props: ['device'],
    
    data() {
      return {
        minimumAgentVersion: this.$helpers.parseVersion('cloud', 'V1.5.0'),
        
        showRebootDevice: false,
        rebootDeviceConfirm: '',
      }
    },
    
    computed: {
      ...mapGetters('user', ['guid', 'isAdmin']),
      
      canUpdate() {
        if (this.device.state && this.device.state.update_state) {
          return true
        }
        return false
      },
      
      canEdit() {
        return this.device.canEdit()
      },
      
      version() {
        return this.device.updatesAvailable['device']
      },
      
      hasUpdates() {
        return (this.device.updatesAvailable['device']) ? true : false
      },
      
      awaitingReboot() {
        if (this.device.state && this.device.state.update_state) {
          var daemonState = this.device.state.update_state.find(x => (x.module == 'daemon' || x.module == 'Daemon'))
          if (daemonState.status == 'Awaiting Reboot' || daemonState.status == 'awaiting reboot') {
            return true
          }
        }
        return false
      },
      
      disableUpdateButton() {
        if (!this.canEdit || !this.device.isOnline() || this.device.isUpdating()) {
          return true
        }
        return false
      },
      
      isUpdating() {
        return (this.device.command == 'update_device' && this.device.commandGUID) ? true : false
      },
      
      updateStatus() {
        return (this.device.command == 'update_device') ? this.device.command_status : false
      },
      
      updateMessage() {
        return (this.device.command == 'update_device') ? this.device.command_message : ''
      },
    },
    methods: {
      rebootDevice() {
        if (this.rebootDeviceConfirm == 'CONFIRM') {
          this.device.runCommand('reboot_device')
          this.showRebootDevice = false
          this.$emit('close')
        }
      },
      
      updateAction(command = 'download_update') {
        this.device.command_status = 'issued'
        this.device.command_message = 'Update Requested'
        
        this.axios.post('/devices/' + this.device.device_guid + '/commands', {'command': command}).then((response) => {
          console.log('DeviceFirmwareUpdaterEdgecaster ' + this.device.device_guid + ' command response', response)
          this.device.last_api_request_id = response.data.api_request_id
          
          this.device.command_status = 'accepted'
          this.device.command_message = 'Update Command Accepted'
          
          this.device.commandGUID = response.data.command_guid
          this.device.command = 'update_device'
          
          if (this.device.commandGUID) {
            this.device.commandResponseTimer = setTimeout(() => {
              this.device.commandResponseCheckAttempt++
              this.device.checkDeviceCommand(this.device.commandGUID)
            }, this.device.commandResponseCheckInterval)
          }
        }).catch((error) => {
          console.log('DeviceFirmwareUpdaterEdgecaster ' + this.device.device_guid + ' updateAction error', this.$helpers.parseError(error))
      
          this.device.command_status = 'error'
          this.device.command_message = this.$helpers.parseError(error)
        })
      }
    }
  }
</script>