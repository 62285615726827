<template>
  <span>
    <v-dialog
      v-if="device"
      v-model="showControl"
      max-width="640px"
    >
      <v-card
        color=""
      >
        <v-card-title>
          Device Note
        </v-card-title>
        <v-card-text>
          <div class="">
            <v-textarea
              class="ma-1"
              v-model="note"
              :rules="[v => (v || '' ).length <= 200 || 'Description must be 200 characters or less']"
              auto-grow
              counter="200"
              hide-details="auto"
              :disabled="!canEdit"
            ></v-textarea>
          </div>
          <div class="text-right">
            <v-btn
              class="ma-1"
              @click="showControl = false"
              color="secondary"
              text
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="ma-1"
              @click="updateMetadata"
              :disabled="loading || !canEdit"
              :loading="loading"
            >
              Save
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <a
       @click="showDialog()"
    >
      Note
    </a>
  </span>
</template>

<script>
 export default {
    name: 'DeviceNote',
    
    props: ['device'],
    
    data() {
      return {
        showControl: false,
        loading: false,
        note: ''
      }
    },
    
    mounted() {
      
    },
    
    computed: {
      canEdit() {
        return this.device.canEdit()
      },
    },
    
    methods: {
      showDialog() {
        this.note = this.device.metadataValue('note')
        this.showControl = true
      },
      
      updateMetadata() {
        if (this.note.length > 200) {
          return
        }
        
        this.loading = true
        var metadata = [{ 'key': 'note', 'value': this.note }]
        
        console.log('DeviceNote updateMetadata ' + this.device.device_guid, metadata)
        
        // post to outputs endpoint
        this.axios.post('/devices/' + this.device.device_guid + '/metadata', metadata)
        .then((response) => {
          console.log('DeviceNote updateMetadata ' + this.device.device_guid + ' response', response)
          
          // fetch device state after waiting 5s
          setTimeout(() => {
            this.loading = false
            this.showControl = false
            
            this.device.fetchMetadata()
          }, 500)
        }).catch((error) => {
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          console.log('DeviceNote updateMetadata ' + this.device.device_guid + ' error', error)
        })
      }
    }
  }
</script>