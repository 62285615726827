//import Vue from 'vue'
var defaultDeviceListLayout = [
  { 'component': 'streaming_status', 'class': 'center'},
  { 'component': 'device_ip', 'class': 'center text-no-wrap d-none d-lg-table-cell'},
  { 'component': 'firmware_version', 'class': 'center d-none d-lg-table-cell'},
  { 'component': 'serial_number', 'class': 'center text-no-wrap d-none d-lg-table-cell'},
  { 'component': 'mac_address', 'class': 'center text-no-wrap d-none d-xl-table-cell'},
]

// initial state
const state = {
  darkMode: localStorage.getItem('darkMode') || false,
  shadowDebug: localStorage.getItem('shadowDebug') || false,
  hideBanner: localStorage.getItem('hideBanner') || false,
  ignoreReactive: localStorage.getItem('ignoreReactive') || false,
  
  deviceListLayout: (localStorage.getItem('deviceListLayout')) ? JSON.parse(localStorage.getItem('deviceListLayout')) : defaultDeviceListLayout
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  shadowDebug: state => state.shadowDebug,
  hideBanner: state => state.hideBanner,
  ignoreReactive: state => state.ignoreReactive,
  deviceListLayout: state => state.deviceListLayout,
}

// actions
const actions = {
  setDarkMode({commit}, enabled) {
    console.log('userPreferences setDarkMode', enabled)
    commit('setDarkMode', enabled)
  },
  setShadowDebug({commit}, enabled) {
    console.log('userPreferences setShadowDebug', enabled)
    commit('setShadowDebug', enabled)
  },
  setHideBanner({commit}, enabled) {
    console.log('userPreferences setHideBanner', enabled)
    commit('setHideBanner', enabled)
  },
  setIgnoreReactive({commit}, enabled) {
    console.log('userPreferences setIgnoreReactive', enabled)
    commit('setIgnoreReactive', enabled)
  },
  setDeviceListLayout({commit}, layout) {
    console.log('userPreferences setDeviceListLayout', layout)
    commit('setDeviceListLayout', layout)
  },
}

// mutations
const mutations = {
  setDarkMode(state, enabled) {
    state.darkMode = enabled
    if (enabled) {
      localStorage.setItem('darkMode', enabled)
    } else {
      localStorage.removeItem('darkMode')
    }
  },
  setShadowDebug(state, enabled) {
    state.shadowDebug = enabled
    if (enabled) {
      localStorage.setItem('shadowDebug', enabled)
    } else {
      localStorage.removeItem('shadowDebug')
    }
  },
  setHideBanner(state, enabled) {
    state.hideBanner = enabled
    if (enabled) {
      localStorage.setItem('hideBanner', enabled)
    } else {
      localStorage.removeItem('hideBanner')
    }
  },
  setIgnoreReactive(state, enabled) {
    state.ignoreReactive = enabled
    if (enabled) {
      localStorage.setItem('ignoreReactive', enabled)
    } else {
      localStorage.removeItem('ignoreReactive')
    }
  },
  setDeviceListLayout(state, layout) {
    if (layout) {
      state.deviceListLayout = layout
      localStorage.setItem('deviceListLayout', JSON.stringify(layout))
    } else {
      state.deviceListLayout = defaultDeviceListLayout
      localStorage.removeItem('deviceListLayout')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}